import Icon from "@mdi/react";
import { mdiDeleteOutline } from "@mdi/js";
import {
  Alert,
  Button,
  Form,
  Input,
  Popconfirm,
  Table,
  Tag,
  Typography,
} from "antd";
import CategorySelect from "components/CategorySelect";
import { ReactElement, useState } from "react";
import { FormattedMessage } from "react-intl";
import categorizationRuleService from "services/categorizationRuleService";
import categoryService from "services/categoryService";
import useSWR, { mutate } from "swr";
import errorHandler from "utils/errorHandler";
import { findTree } from "utils/tree";

const { Text } = Typography;

function CompanyCategorizationRulesForm() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ReactElement | undefined>(undefined);
  const [form] = Form.useForm();

  const { data: categorizationRules } = useSWR(
    "categorizationRuleService.getAll",
    categorizationRuleService.getAll
  );
  const {
    data: categories,
    // error
  } = useSWR(
    "categoryService.getAllWithoutDefault",
    categoryService.getAllWithoutDefault
  );

  const deletecategorizationRule = async (categorizationRuleId: number) => {
    await categorizationRuleService
      .remove(categorizationRuleId)
      .catch(errorHandler(setError));

    mutate("categorizationRuleService.getAll");
  };

  const onFinish = async (values: any) => {
    setLoading(true);

    const { keywords, category } = values;

    await categorizationRuleService
      .create({
        keywords: keywords.trim(),
        category,
      })
      .catch((e) => {
        if (e?.response?.data?.message?.[0]?.messages?.[0]?.id) {
          setError(
            <FormattedMessage
              id={e?.response?.data?.message?.[0]?.messages?.[0]?.id || -1}
              defaultMessage="Un problème est survenu. Veuillez rafraîchir la page ou essayer ultérieurement."
              description="Server response to login"
            />
          );
          setLoading(false);
        } else {
          throw e;
        }
      })
      .catch(errorHandler(setError));

    mutate("categorizationRuleService.getAll");
    form.resetFields();
    setError(undefined);
    setLoading(false);
  };

  const columns = [
    {
      title: "Mots clés",
      dataIndex: "keywords",
      render: (keywords: string) => <Tag>{keywords}</Tag>,
    },
    {
      title: "Catégorie",
      dataIndex: "category",
      render: (category: number) => (
        <Tag>
          {findTree({
            categories: categories || [],
            categoryId: category,
          })?.name || "Pas de catégorie"}
        </Tag>
      ),
    },
    {
      title: "Action",
      render: (_: any, record: any) => (
        <Popconfirm
          onConfirm={() => deletecategorizationRule(record.id)}
          okText="Confirmer"
          title={
            <>
              Êtes-vous sûr de vouloir supprimer la règle de catégorisation
              associée aux mots clés :{" "}
              <Text mark strong>
                {record.keywords}
              </Text>{" "}
              ?
            </>
          }
          placement="top"
        >
          <span>
            <Button
              type="link"
              icon={<Icon path={mdiDeleteOutline} size={0.9} />}
            />
          </span>
        </Popconfirm>
      ),
    },
  ];

  const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 8 },
  };
  const formTailLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 8, offset: 4 },
  };

  return (
    <>
      <div>{error && <Alert type="error" message={error} />}</div>
      <div style={{ marginTop: 15 }}>
        <Form
          name="invite-user"
          {...formItemLayout}
          requiredMark={false}
          onFinish={onFinish}
          form={form}
        >
          <Form.Item
            name="keywords"
            label="Mots clés"
            rules={[{ required: true, message: "Requis" }]}
          >
            <Input placeholder="Phrase exacte à détecter" />
          </Form.Item>
          <CategorySelect
            categories={categories || []}
            setVat={(vat?: number) => form.setFieldsValue({ vat })}
          />
          <Form.Item {...formTailLayout}>
            <Button type="primary" htmlType="submit" block loading={loading}>
              Ajouter
            </Button>
          </Form.Item>
        </Form>
      </div>

      <div style={{ marginTop: 45 }}>
        <Table
          loading={!categorizationRules}
          locale={{
            emptyText: "Aucune transaction à afficher",
          }}
          dataSource={categorizationRules}
          bordered={true}
          size="small"
          columns={columns}
          rowKey="id"
        />
      </div>
    </>
  );
}

export default CompanyCategorizationRulesForm;
