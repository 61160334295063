import { PaginationProps, Popover, Table, Tag } from "antd";
import { ColumnsType } from "antd/es/table";
import { FC } from "react";
import Highlighter from "react-highlight-words";
import { Category, Transaction } from "types";
import { formatTransactionAmount } from "utils/amount";
import { CurrencyCode } from "utils/currency";
import { getFormattedFullDate } from "utils/date";
import { findTree } from "utils/tree";

interface Props {
  transactions: Transaction[];
  categories: Category[];
  changeParams: (
    pagination: PaginationProps,
    filters: any,
    sorter: any
  ) => void;
  pagination: PaginationProps;
  loading: boolean;
  setSelectedTransaction: (transaction: Transaction) => void;
  searchText?: string;
}

const ReconciliationTransactionsTable: FC<Props> = ({
  transactions,
  categories,
  changeParams,
  pagination,
  loading,
  setSelectedTransaction,
  searchText = "",
}) => {
  const columns: ColumnsType<Transaction> = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: "12%",
      sorter: true,
      render: (date: string) => getFormattedFullDate(new Date(date)),
    },
    {
      title: "Libellé",
      dataIndex: "description",
      key: "description",
      width: "30%",
      ellipsis: true,
      sorter: true,
      render: (_: any, transaction) => (
        <Popover
          mouseEnterDelay={1}
          placement="topLeft"
          content={
            <div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span>
                  <Tag color="gold">
                    {transaction.account.customName || transaction.account.name}
                  </Tag>
                </span>
                <span>
                  <Highlighter
                    highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={
                      transaction.note
                        ? `${transaction.raw_description} • ${transaction.note}`
                        : transaction.raw_description
                    }
                  />
                </span>
              </div>
            </div>
          }
        >
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={
              transaction.note
                ? `${transaction.raw_description} • ${transaction.note}`
                : transaction.raw_description
            }
          />
        </Popover>
      ),
    },
    {
      title: "Catégorie",
      dataIndex: "category",
      key: "category",
      width: "25%",
      sorter: true,
      render: (_: any, transaction) => (
        <Tag>
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={
              findTree({
                categories: categories || [],
                categoryId: transaction.category,
              })?.name || "Pas de catégorie"
            }
          />
        </Tag>
      ),
    },
    {
      title: "Montant TTC",
      dataIndex: "amount",
      key: "amount",
      width: "12%",
      render: (_: any, transaction) =>
        formatTransactionAmount(transaction, searchText),
      sorter: true,
      align: "right",
    },
    {
      title: "Restant à rapprocher",
      key: "remaining",
      width: "12%",
      render: (_: any, transaction) =>
        formatTransactionAmount({
          currency_code: CurrencyCode.EUR,
          amount:
            transaction.amount -
            transaction.invoices.reduce(
              (acc, invoice) => acc + invoice.amount,
              0
            ),
        }),
      align: "right",
    },
  ];

  return (
    <Table
      locale={{
        emptyText: "Aucune facture à afficher",
      }}
      columns={columns}
      dataSource={transactions.map((transaction) => ({
        ...transaction,
        key: transaction.id,
      }))}
      size="small"
      onChange={changeParams}
      loading={loading}
      pagination={pagination}
      rowKey="id"
      showSorterTooltip={false}
      rowClassName={(transaction) =>
        transaction.ignored ? "table-row-ignored" : ""
      }
      onRow={(transaction) => {
        const remaining =
          transaction.amount -
          transaction.invoices.reduce(
            (acc, invoice) => acc + invoice.amount,
            0
          );
        return remaining === 0
          ? {
              style: { cursor: "not-allowed" },
            }
          : {
              onClick: () => {
                setSelectedTransaction(transaction);
              },
              style: { cursor: "pointer" },
            };
      }}
    />
  );
};

export default ReconciliationTransactionsTable;
