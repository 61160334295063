import Loader from "components/Loader";
import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import bridgeapiService from "services/bridgeapiService";
import errorHandler from "utils/errorHandler";

const BankCallbackPage = () => {
  const [loaded, setLoaded] = useState(false);

  // const [searchParams] = useSearchParams();
  // const source = searchParams.get("source");
  // const success = searchParams.get("success") === "true";
  // const userUuid = searchParams.get("user_uuid");
  // const step = searchParams.get("step");
  // const itemId = searchParams.get("item_id");

  useEffect(() => {
    bridgeapiService
      .connectCallback()
      .then(() => {
        setLoaded(true);
      })
      .catch((e) => {
        setLoaded(true);
        throw e;
      })
      .catch(errorHandler());
  }, []);

  if (!loaded) {
    return <Loader />;
  }

  return <Navigate to="/register/your-banks" replace={true} />;
};

export default BankCallbackPage;
