import { FC } from "react";
import "./index.css";

const LogoZenfirst: FC = () => {
  return (
    <img className="LogoZenfirst" src="/img/logo-zenfirst.png" alt="logo" />
  );
};

export default LogoZenfirst;
