import { Account } from "types";
import axios from "utils/setupAxios";

export async function getAll(): Promise<Account[]> {
  return axios.get(`/accounts?active=true`).then((res) => res.data);
}

export async function update(accountId: number, values: any): Promise<Account> {
  return axios.put(`/accounts/${accountId}`, values).then((res) => res.data);
}

const accountService = { getAll, update };
export default accountService;
