import Icon from "@mdi/react";
import {
  mdiCheckCircleOutline,
  mdiEyeOffOutline,
  mdiTagMultipleOutline,
  mdiCloseCircleOutline,
  mdiDeleteOutline,
} from "@mdi/js";
import { ExclamationCircleOutlined, FilterOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Dropdown,
  MenuProps,
  Modal,
  Space,
  TreeSelect,
  Typography,
} from "antd";
import numeral from "numeral";
import { ReactElement, useRef, useState } from "react";
import { Category, Invoice, Transaction } from "types";
import { computeSumOfOperation, isCashIn, isCashOut } from "utils/amount";
import { KindEnum } from "utils/kind";
import { formatTree } from "utils/tree";

const confirmProps = {
  okText: "Confirmer",
  cancelText: "Annuler",
  title: "Êtes-vous sûr ?",
  icon: <ExclamationCircleOutlined />,
};

const confirmDeleteInvoiceContent = ({
  numberOfOperations,
}: {
  numberOfOperations: number;
}) => (
  <>
    <div style={{ marginBottom: 15 }}>
      Vous êtes sur le point de supprimer toutes les opérations sélectionnées.
    </div>
    <ul>
      <li>
        <b>Opérations à supprimer : {numberOfOperations}</b>
      </li>
    </ul>
  </>
);

function BatchAction({
  categories,
  transactions,
  handleCategorizeMany,
  handleUpdateManyCategory,
  handleDeleteMany,
  selectedRowKeys,
  setSelectedRowKeys,
  mainAction = "categorize",
}: {
  categories: Category[];
  transactions: (Transaction | Invoice)[];
  handleCategorizeMany: (
    transactionIds: number[],
    categoryId: number
  ) => Promise<void>;
  handleUpdateManyCategory: (
    transactionIds: number[],
    params: any
  ) => Promise<void>;
  handleDeleteMany?: (transactionIds: number[]) => Promise<void>;
  selectedRowKeys: number[];
  setSelectedRowKeys: (selectedRowKeys: number[]) => void;
  mainAction: "categorize" | "validate";
}) {
  const ref = useRef(null);
  const [open, setOpen] = useState(false);
  const [category, setCategory] = useState<number | undefined>(undefined);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const selectedTransactions = transactions.filter(
    (transaction) => selectedRowKeys.indexOf(transaction.id) !== -1
  );

  const hasPositiveTransactions = selectedTransactions.some((transaction) =>
    isCashIn(transaction)
  );
  const hasNegativeTransactions = selectedTransactions.some((transaction) =>
    isCashOut(transaction)
  );
  const hasSyncedInvoices = selectedTransactions.some(
    (invoice) =>
      // @ts-ignore
      invoice?.provider
  );
  const hasSyncedTransactions = selectedTransactions.some(
    (transaction) =>
      // @ts-ignore
      !!transaction?.account && !transaction?.account?.isManualData
  );
  const allTransactionsAreIgnored = selectedTransactions.every(
    (transaction) => transaction?.ignored
  );

  const formatFunction = (category: Category) => ({
    title: category.name,
    value: category.id.toString(),
    selectable: !category.children?.length,
    ...category,
  });

  const formattedCategories = formatTree({
    categories: categories.filter((category) => {
      if (category.kind === KindEnum.cashIn && hasPositiveTransactions) {
        return true;
      } else if (
        category.kind === KindEnum.cashOut &&
        hasNegativeTransactions
      ) {
        return true;
      } else {
        return false;
      }
    }),
    formatFunction,
  });

  const handleChange = (categoryId: string) => {
    setCategory(parseInt(categoryId, 10));
  };

  const handleOk = () => {
    if (!category) {
      return;
    }
    setConfirmLoading(true);
    handleCategorizeMany(selectedRowKeys, category).then(() => {
      setSelectedRowKeys([]);
      setCategory(undefined);
      setOpen(false);
      setConfirmLoading(false);
    });
  };
  const handleCancel = () => {
    setOpen(false);
    setCategory(undefined);
  };

  const clearSelection = () => {
    setSelectedRowKeys([]);
  };

  const hasSelected = selectedRowKeys.length > 0;

  let message: string | ReactElement = "";
  if (hasSelected) {
    message = (
      <>
        <Button type="link" onClick={clearSelection}>
          {selectedRowKeys.length} transaction
          {selectedRowKeys.length >= 2 ? "s" : ""}{" "}
          <Icon
            path={mdiCloseCircleOutline}
            size={0.55}
            style={{
              marginLeft: "6",
              verticalAlign: "middle",
            }}
          />
        </Button>
        Total :{" "}
        {`${numeral(
          computeSumOfOperation(
            transactions.filter(
              (transaction) => selectedRowKeys.indexOf(transaction.id) !== -1
            )
          )
        ).format("0,0.0", Math.floor)} €`}
      </>
    );
  }

  const handleCategorize = () => setOpen(true);
  const handleValidate = () => {
    setLoading(true);
    handleUpdateManyCategory(selectedRowKeys, { precategorized: false }).then(
      () => {
        setSelectedRowKeys([]);
        setLoading(false);
      }
    );
  };
  const handleIgnore = () => {
    setLoading(true);
    handleUpdateManyCategory(selectedRowKeys, { ignored: true }).then(() => {
      setSelectedRowKeys([]);
      setLoading(false);
    });
  };
  const handleUnignore = () => {
    setLoading(true);
    handleUpdateManyCategory(selectedRowKeys, { ignored: false }).then(() => {
      setSelectedRowKeys([]);
      setLoading(false);
    });
  };
  const handleDelete = () => {
    setLoading(true);
    if (handleDeleteMany) {
      return handleDeleteMany(selectedRowKeys).then(() => {
        setSelectedRowKeys([]);
        setLoading(false);
      });
    }
  };

  const handleMenuClick = (e: any) => {
    if (e.key === "categorize") {
      handleCategorize();
    } else if (e.key === "validate") {
      handleValidate();
    } else if (e.key === "ignore") {
      handleIgnore();
    } else if (e.key === "unignore") {
      handleUnignore();
    } else if (e.key === "delete") {
      Modal.confirm({
        ...confirmProps,
        onOk() {
          return handleDelete();
        },
        content: confirmDeleteInvoiceContent({
          numberOfOperations: selectedRowKeys.length,
        }),
      });
    }
  };

  const menuItems: MenuProps["items"] = [
    {
      icon: <Icon path={mdiTagMultipleOutline} size={0.65} />,
      key: "categorize",
      label: "Catégoriser",
    },
    {
      icon: <Icon path={mdiCheckCircleOutline} size={0.65} />,
      key: "validate",
      label: "Valider les précatégories",
    },
    {
      icon: <Icon path={mdiEyeOffOutline} size={0.65} />,
      key: allTransactionsAreIgnored ? "unignore" : "ignore",
      label: allTransactionsAreIgnored ? "Ne plus masquer" : "Masquer",
    },
    ...(!hasSyncedTransactions || hasSyncedInvoices
      ? [
          {
            icon: <Icon path={mdiDeleteOutline} size={0.65} />,
            key: "delete",
            label: "Supprimer",
          },
        ]
      : []),
  ];
  const menu = { items: menuItems, onClick: handleMenuClick };

  return (
    <Space style={{ marginBottom: 16 }}>
      <Dropdown.Button
        type="primary"
        loading={loading}
        onClick={
          mainAction === "categorize" ? handleCategorize : handleValidate
        }
        disabled={!hasSelected}
        menu={menu}
      >
        {mainAction === "categorize"
          ? "Catégoriser"
          : "Valider les précatégories"}
      </Dropdown.Button>

      <Modal
        title="Catégoriser plusieurs transactions"
        open={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        okText="Valider"
        destroyOnClose
        okButtonProps={{
          disabled: hasPositiveTransactions && hasNegativeTransactions,
        }}
      >
        {hasPositiveTransactions && hasNegativeTransactions ? (
          <>
            <Alert
              message="Catégorisation impossible : montants positifs et négatifs sélectionnés."
              type="error"
            />
            <Alert
              message={
                <>
                  Filtrez la colonne "Montant TTC" avec <FilterOutlined />
                </>
              }
              type="warning"
            />
          </>
        ) : (
          <>
            <div style={{ marginTop: 32, marginBottom: 16 }}>
              Sélection actuelle : {selectedRowKeys.length}
            </div>
            <TreeSelect
              ref={ref}
              value={category?.toString()}
              virtual={false}
              disabled={selectedRowKeys.length === 0}
              showSearch
              treeNodeFilterProp="title"
              style={{ width: "100%" }}
              dropdownStyle={{ maxHeight: 400 }}
              placeholder={
                <Typography.Text mark strong>
                  Sélectionnez une catégorie
                </Typography.Text>
              }
              treeDefaultExpandAll
              onChange={handleChange}
              getPopupContainer={(trigger) => trigger.parentNode}
              treeData={formattedCategories}
            />
          </>
        )}
      </Modal>
      <div style={{ marginLeft: 8 }}>{message}</div>
    </Space>
  );
}

export default BatchAction;
