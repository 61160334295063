import { Avatar, Button, List, Popconfirm, Space, Typography } from "antd";
import { FC } from "react";
import { Integration } from "types";
import { providerName } from "utils/constants";
import { getFormattedDateTime } from "utils/date";
import { IntegrationModalParams, oAuthIntegrations } from ".";
import { mdiDeleteOutline, mdiSquareEditOutline, mdiSync } from "@mdi/js";
import Icon from "@mdi/react";

interface Props {
  integration: Integration;
  deleteIntegration: (integrationId: number) => Promise<void>;
  refreshIntegration: (integrationId: number) => Promise<void>;
  setModalParams: (modalParams: IntegrationModalParams) => void;
}

const IntegrationItem: FC<Props> = ({
  integration,
  deleteIntegration,
  refreshIntegration,
  setModalParams,
}) => {
  const handleDelete = (integrationId: number) => () => {
    deleteIntegration(integrationId);
  };

  const handleRefresh = (integrationId: number) => () => {
    refreshIntegration(integrationId);
  };

  const handleRefreshConnection = (integrationProvider: string) => () =>
    (window.location.href = `${process.env.REACT_APP_BASE_URL}connect/${integrationProvider}`);

  const handleEditIntegration = (integration: Integration) => () =>
    setModalParams({
      open: true,
      provider: integration.provider,
      editingRecord: integration,
    });

  return (
    <List.Item>
      <List.Item.Meta
        avatar={
          <Avatar shape="square" src={`/img/${integration.provider}-logo.png`}>
            {providerName[integration.provider]}
          </Avatar>
        }
        title={
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>{providerName[integration.provider]}</div>
            <div>
              <Button
                type={"link"}
                onClick={
                  oAuthIntegrations.indexOf(integration.provider) !== -1
                    ? handleRefreshConnection(integration.provider)
                    : handleEditIntegration(integration)
                }
                icon={<Icon path={mdiSquareEditOutline} size={0.85} />}
              />
              <Popconfirm
                title={
                  <Space direction="vertical">
                    <Typography.Text>
                      Êtes-vous sûr de vouloir supprimer cette intégration ?
                    </Typography.Text>
                    <Typography.Text type="danger">
                      Toutes vos factures catégorisées seront perdues.
                    </Typography.Text>
                  </Space>
                }
                okButtonProps={{ danger: true }}
                onConfirm={handleDelete(integration.id)}
                okText="Confirmer"
                placement="topLeft"
              >
                <Button
                  type="link"
                  icon={
                    <Button
                      type="link"
                      icon={<Icon path={mdiDeleteOutline} size={0.85} />}
                    />
                  }
                />
              </Popconfirm>
            </div>
          </div>
        }
        description={
          <>
            <Typography.Text type="secondary" style={{ fontSize: 12 }}>
              Dernière synchronisation:{" "}
              {getFormattedDateTime(new Date(integration.lastRefreshDate))}{" "}
            </Typography.Text>
            <Button
              type="link"
              onClick={handleRefresh(integration.id)}
              disabled={integration.toRefresh}
              icon={
                <Icon
                  path={mdiSync}
                  size={0.9}
                  spin={integration.toRefresh}
                  style={{ verticalAlign: "middle" }}
                  rotate={-45}
                />
              }
            />
            {integration.status_code_description && (
              <>
                <br />
                <Typography.Text type="warning">
                  {integration.status_code_description}
                </Typography.Text>
              </>
            )}
            {integration.disconnected && (
              <>
                <br />
                <Typography.Text type="warning">
                  La dernière synchronisation a répondu un message d'erreur.{" "}
                  {oAuthIntegrations.indexOf(integration.provider) !== -1 && (
                    <Button
                      type="link"
                      onClick={handleRefreshConnection(integration.provider)}
                      style={{ padding: 0 }}
                    >
                      Cliquez ici pour rafraîchir la connexion.
                    </Button>
                  )}
                </Typography.Text>
              </>
            )}
          </>
        }
      />
    </List.Item>
  );
};

export default IntegrationItem;
