import { Alert, Button, Divider, Form, Input, Select, Steps } from "antd";
import OnboardingLayout from "components/OnboardingLayout";
import { AuthContext } from "contexts";
import { FC, ReactElement, useContext, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate, useSearchParams } from "react-router-dom";
import companyService from "services/companyService";
import userService from "services/userService";
import { cache } from "swr";
import errorHandler from "utils/errorHandler";
import marketingTools from "utils/marketingTools";
import "./index.css";

const businessCategories = [
  {
    id: "craftsman",
    name: "Artisan",
  },
  {
    id: "agency",
    name: "Agence",
  },
  {
    id: "nonprofit",
    name: "Association",
  },
  {
    id: "construction",
    name: "BTP",
  },
  {
    id: "ecommerce",
    name: "E-commerce et boutique physique",
  },
  {
    id: "software",
    name: "Editeur de logiciel",
  },
  {
    id: "industry",
    name: "Industrie",
  },
  {
    id: "restaurant",
    name: "Restauration",
  },
  {
    id: "other",
    name: "Autre",
  },
];

const BusinessKindForm = ({
  setError,
  isDemoCompany = false,
}: {
  isDemoCompany: boolean;
  setError: (errorMessage: ReactElement | undefined) => void;
}) => {
  const { me, setMe } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const onBusinessKindChange = (value: string) => {
    form.setFieldsValue({ businessKind: value });
  };

  const onFinish = (values: { businessKind: string; name: string }) => {
    if (!me) {
      return;
    }

    setLoading(true);
    const { businessKind, name } = values;
    const data = {
      businessKind,
      name: name.trim(),
      isDemoCompany,
    };
    companyService
      .create(data)
      .then((newCompany) => {
        window.$crisp.push(["set", "user:company", [name]]);
        window.$crisp.push(["set", "session:segments", [[businessKind]]]);
        return userService.setCompany({ company: newCompany.id });
      })
      .then((me) => {
        setMe(me);
        cache.clear();
        marketingTools.hasChangedCompany(me.company);
        navigate("/register/sync", { replace: true });
      })
      .catch((e) => {
        if (e?.response?.data?.message?.id) {
          setError(
            <FormattedMessage
              id={e?.response?.data?.message?.id || -1}
              defaultMessage="Un problème est survenu. Veuillez rafraîchir la page ou essayer ultérieurement."
              description="Server response to login"
            />
          );
          setLoading(false);
        } else {
          throw e;
        }
      })
      .catch(errorHandler(setError));
  };
  return (
    <Form
      name="create-company"
      className="login-form"
      onFinish={onFinish}
      form={form}
      layout="vertical"
      requiredMark={false}
      initialValues={{ name: isDemoCompany ? "Compte de démonstration" : "" }}
    >
      <Form.Item
        label="Nom de l'entreprise"
        name="name"
        rules={[{ required: true, message: "Requis" }]}
      >
        <Input autoComplete="organization" placeholder="Entreprise" />
      </Form.Item>
      <Form.Item
        label="Type d'activité"
        name="businessKind"
        id="businessKind"
        rules={[{ required: true, message: "Requis" }]}
      >
        <Select
          placeholder="Sélectionner le type d'activité"
          onChange={onBusinessKindChange}
          getPopupContainer={(trigger) => trigger.parentNode}
          onInputKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
        >
          {businessCategories.map((businessCategory) => (
            <Select.Option
              key={businessCategory.id}
              value={businessCategory.id}
            >
              {businessCategory.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Button
        type="primary"
        htmlType="submit"
        className="FormButton"
        loading={loading}
        block
        style={{ marginTop: 30 }}
      >
        Continuer
      </Button>
    </Form>
  );
};

const OnboardingCreateCompanyPage: FC = () => {
  const [error, setError] = useState<ReactElement | undefined>(undefined);
  const [searchParams] = useSearchParams();

  const isDemoCompany = searchParams.get("isDemoCompany") === "true";

  return (
    <OnboardingLayout>
      <div className="OnboardingCreateCompanyPage">
        <Steps progressDot current={0}>
          <Steps.Step title="Inscription" />
          <Steps.Step title="Synchronisation" />
          <Steps.Step title="Finalisation" />
        </Steps>
        <Divider />
        <div className="animated">
          <h1>
            {isDemoCompany
              ? "Créer un compte de démonstration"
              : "Ajouter votre entreprise"}
          </h1>
          {error && <Alert type="error" message={error} />}
          <div style={{ marginTop: 20 }}>
            <BusinessKindForm
              setError={setError}
              isDemoCompany={isDemoCompany}
            />
          </div>
        </div>
      </div>
    </OnboardingLayout>
  );
};

export default OnboardingCreateCompanyPage;
