import { ImportTransactionsMapping } from "types";
import axios from "utils/setupAxios";

export async function getAll(): Promise<ImportTransactionsMapping[]> {
  return axios.get(`/import-transactions-mappings`).then((res) => res.data);
}

export async function create(values: ImportTransactionsMapping): Promise<ImportTransactionsMapping> {
  return axios.post("/import-transactions-mappings", values).then((res) => res.data);
}

export async function update(
  importTransactionMappingId: number,
  values: ImportTransactionsMapping
): Promise<ImportTransactionsMapping> {
  return axios
    .put(`/import-transactions-mappings/${importTransactionMappingId}`, values)
    .then((res) => res.data);
}

const importTransactionsMappingService = { getAll, create, update };
export default importTransactionsMappingService;