import { endOfMonth, format, parseISO, startOfMonth } from "date-fns";
import { Category, ImportInvoices, Invoice } from "types";
import { KindEnum } from "utils/kind";
import axios from "utils/setupAxios";

export type InvoiceQuickFilterType =
  | "all"
  | "precategorized"
  | "uncategorized"
  | "late"
  | "prereconciliated";

export async function find({
  date,
  category,
  ignored,
  query,
}: {
  date?: string;
  category?: Category;
  ignored?: boolean;
  query?: string;
}): Promise<Invoice[]> {
  let options = "?paid=false";
  if (date) {
    const startDate = startOfMonth(parseISO(date));
    const endDate = endOfMonth(startDate);
    const startDateString = format(startDate, "yyyy-MM-dd");
    const endDateString = format(endDate, "yyyy-MM-dd");
    if (endDate.getTime() <= new Date().getTime()) {
      return [];
    } else if (
      startDate.getTime() <= new Date().getTime() &&
      endDate.getTime() > new Date().getTime()
    ) {
      options += `&dueDate_lte=${endDateString}`;
    } else {
      options += `&dueDate_gte=${startDateString}&dueDate_lte=${endDateString}`;
    }
  }
  if (category) {
    if (category.isDefaultCategory) {
      if (category.kind === KindEnum.cashIn) {
        options += "&amount_gt=0&category_null=true";
      } else if (category.kind === KindEnum.cashOut) {
        options += "&amount_lt=0&category_null=true";
      }
    } else {
      options += `&category=${category.id}`;
    }
  }
  if (ignored) {
    options += "&ignored=true";
  }

  return axios.get(`/invoices${options}${query || ""}`).then((res) => res.data);
}

export async function count({
  date,
  category,
  ignored,
  query,
}: {
  date?: string;
  category?: Category;
  ignored?: boolean;
  query?: string;
}): Promise<number> {
  let options = "?paid=false";
  if (date) {
    const startDate = startOfMonth(parseISO(date));
    const endDate = endOfMonth(startDate);
    const startDateString = format(startDate, "yyyy-MM-dd");
    const endDateString = format(endDate, "yyyy-MM-dd");
    if (endDate.getTime() <= new Date().getTime()) {
      return 0;
    } else if (
      startDate.getTime() <= new Date().getTime() &&
      endDate.getTime() > new Date().getTime()
    ) {
      options += `&dueDate_lte=${endDateString}`;
    } else {
      options += `&dueDate_gte=${startDateString}&dueDate_lte=${endDateString}`;
    }
  }
  if (category) {
    if (category.isDefaultCategory) {
      if (category.kind === KindEnum.cashIn) {
        options += "&amount_gt=0&category_null=true";
      } else if (category.kind === KindEnum.cashOut) {
        options += "&amount_lt=0&category_null=true";
      }
    } else {
      options += `&category=${category.id}`;
    }
  }
  if (ignored) {
    options += "&ignored=true";
  }

  return axios
    .get(`/invoices/count${options}${query || ""}`)
    .then((res) => res.data);
}

export async function getAll({
  query,
  quickFilter,
}: {
  query?: string;
  quickFilter?: InvoiceQuickFilterType;
}): Promise<Invoice[]> {
  let options = "?paid=false";
  if (quickFilter === "precategorized") {
    options += "&category_null=false&precategorized=true";
  } else if (quickFilter === "uncategorized") {
    options += "&category_null=true";
  } else if (quickFilter === "late") {
    options += `&dueDate_lte=${new Date().toISOString()}&paid_ne=true`;
  } else if (quickFilter === "prereconciliated") {
    options += "&prereconciliated=true&paid=true";
  }

  return axios.get(`/invoices${options}${query || ""}`).then((res) => res.data);
}

export async function getAllCount({
  query,
  quickFilter,
}: {
  query?: string;
  quickFilter?: InvoiceQuickFilterType;
}): Promise<number> {
  let options = "?paid=false";
  if (quickFilter === "precategorized") {
    options += "&category_null=false&precategorized=true";
  } else if (quickFilter === "uncategorized") {
    options += "&category_null=true";
  } else if (quickFilter === "late") {
    options += `&dueDate_lte=${new Date().toISOString()}&paid_ne=true`;
  } else if (quickFilter === "prereconciliated") {
    options += "&prereconciliated=true&paid=true";
  }

  return axios
    .get(`/invoices/count${options}${query || ""}`)
    .then((res) => res.data);
}

export async function getAllUncategorizedCount() {
  return getAllCount({ quickFilter: "uncategorized" });
}

export async function getAllPrecategorizedCount() {
  return getAllCount({ quickFilter: "precategorized" });
}

export async function getAllLateCount() {
  return getAllCount({ quickFilter: "late" });
}

export async function getAllPrereconciliatedCount() {
  return getAllCount({ quickFilter: "prereconciliated" });
}

export async function update(invoiceId: number, values: any): Promise<Invoice> {
  return axios.put(`/invoices/${invoiceId}`, values).then((res) => res.data);
}

export async function updateMany(
  invoiceIds: number[],
  values: any
): Promise<number> {
  return axios
    .post(`/invoices/update-many`, {
      ids: invoiceIds,
      ...values,
    })
    .then((res) => res.data);
}

export async function categorizeMany(
  transactionIds: number[],
  categoryId: number
): Promise<number> {
  return axios
    .post(`/invoices/categorize-many`, {
      ids: transactionIds,
      category: categoryId,
    })
    .then((res) => res.data);
}

export async function create(values: any): Promise<Invoice> {
  return axios.post("/invoices", values).then((res) => res.data);
}

export async function createMany(values: any): Promise<Invoice[]> {
  return axios.post("/invoices/create-many", values).then((res) => res.data);
}

export async function importInvoices(
  values: ImportInvoices
): Promise<Invoice[]> {
  return axios
    .post("/invoices/import-invoices", values)
    .then((res) => res.data);
}

export async function remove(invoiceId: number): Promise<Invoice> {
  return axios.delete(`/invoices/${invoiceId}`).then((res) => res.data);
}

export async function removeMany(invoiceIds: number[]): Promise<number> {
  return axios
    .post("/invoices/delete-many", { ids: invoiceIds })
    .then((res) => res.data);
}

const invoiceService = {
  find,
  count,
  getAll,
  getAllCount,
  getAllUncategorizedCount,
  getAllPrecategorizedCount,
  getAllLateCount,
  getAllPrereconciliatedCount,
  update,
  updateMany,
  categorizeMany,
  create,
  createMany,
  importInvoices,
  remove,
  removeMany,
};
export default invoiceService;
