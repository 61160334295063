import { DashOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import {
  Badge,
  Button,
  DatePicker,
  Dropdown,
  Form,
  Input,
  InputRef,
  Modal,
  PaginationProps,
  Popover,
  Space,
  Table,
  Tag,
  Typography,
} from "antd";
import { ColumnsType } from "antd/es/table";
import CategorizeSelect from "components/CategorizeSelect";
import ConfirmAddSubCategoryContent from "components/ConfirmAddSubCategoryContent";
import TransactionNoteModal from "components/TransactionNoteModal";
import TransactionReconciliationModal from "components/TransactionReconciliationModal";
import TransactionSplittingModal from "components/TransactionSplittingModal";
import { AuthContext } from "contexts";
import dayjs, { Dayjs } from "dayjs";
import { FC, ReactNode, useContext, useEffect, useRef, useState } from "react";
import Highlighter from "react-highlight-words";
import categoryService, {
  CreateCategoryValues,
} from "services/categoryService";
import { CategoriesWithAmounts, Category, Invoice, Transaction } from "types";
import { formatTransactionAmount } from "utils/amount";
import { getFormattedFullDate } from "utils/date";
import { KindEnum } from "utils/kind";
import "./index.css";
import {
  mdiCalendarBlankOutline,
  mdiCheckCircleOutline,
  mdiEyeOffOutline,
  mdiNoteMultipleOutline,
  mdiRepeatVariant,
  mdiRestore,
  mdiSourceFork,
} from "@mdi/js";
import Icon from "@mdi/react";

const { confirm } = Modal;

export type TransactionReconciliationModalParams = {
  open: boolean;
  reconciliatingTransaction?: Transaction;
};

export type TransactionSplittingModalParams = {
  open: boolean;
  splittingTransaction?: Transaction;
};
export type TransactionNoteModalParams = {
  open: boolean;
  transaction?: Transaction | Invoice;
};

const dateDisplayFormat = "DD/MM/YYYY";

const confirmProps = {
  okText: "Confirmer",
  cancelText: "Annuler",
  title: "Êtes-vous sûr ?",
  icon: <ExclamationCircleOutlined />,
};

interface Props {
  transactions: Transaction[];
  categories: Category[];
  handleCategorizeMany: (
    transactionIds: number[],
    categoryId: number
  ) => Promise<void>;
  handleValidateCategory: (transaction: Transaction) => Promise<void>;
  handleValidatePrereconciliation: (
    invoice: Invoice,
    isValidated: boolean
  ) => Promise<void>;
  loading: boolean;
  handleAddCategoryAndCategorize: (
    values: CreateCategoryValues,
    kind: string,
    transactionIds: number[],
    parent?: number
  ) => Promise<void>;
  handleUpdate: (transactionId: number, params: any) => Promise<void>;
  handleReconciliate: (
    transactionId: number,
    invoiceIds: number[],
    shouldCreateRemainingInvoice: boolean
  ) => Promise<void>;
  handleSplit: (
    transactionId: number,
    categoriesWithAmounts: CategoriesWithAmounts[]
  ) => Promise<void>;
  handleUnsplit: (transactionId: number) => Promise<void>;
  handleChangeDate: (transactionId: number, newDate: Date) => Promise<void>;
  handleUnchangeDate: (transactionId: number) => Promise<void>;
  changeParams: (
    pagination: PaginationProps,
    filters: any,
    sorter: any
  ) => void;
  pagination: PaginationProps;
  selectedRowKeys?: number[];
  setSelectedRowKeys?: (selectedRowKeys: number[]) => void;
  withRowSelection?: boolean;
  sameSignSelection?: boolean;
  emptyText?: string | ReactNode;
  searchText?: string;
}

const TransactionsTable: FC<Props> = ({
  transactions,
  categories,
  handleCategorizeMany,
  handleValidateCategory,
  handleValidatePrereconciliation,
  loading,
  handleAddCategoryAndCategorize,
  handleUpdate,
  handleReconciliate,
  handleSplit,
  handleUnsplit,
  handleChangeDate,
  handleUnchangeDate,
  changeParams,
  pagination,
  selectedRowKeys = [],
  setSelectedRowKeys = () => {},
  withRowSelection = true,
  sameSignSelection = false,
  emptyText = (
    <div style={{ marginTop: 20, marginBottom: 20 }}>
      Aucune transaction à afficher.
    </div>
  ),
  searchText = "",
}) => {
  const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([]);
  const [form] = Form.useForm<CreateCategoryValues>();
  const [modalLoading, setModalLoading] = useState(false);
  const [modalSubCategoryLoading, setModalSubCategoryLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openSubCategory, setOpenSubCategory] = useState(false);
  const [kind, setKind] = useState<KindEnum>(KindEnum.cashIn);
  const [currentCategory, setCurrentCategory] = useState<Category>();
  const [currentTransaction, setCurrentTransaction] = useState<
    Transaction | undefined
  >(undefined);
  const ref = useRef<InputRef>(null);

  const [modalReconciliationParams, setModalReconciliationParams] =
    useState<TransactionReconciliationModalParams>({
      open: false,
    });
  const [modalSplittingParams, setModalSplittingParams] =
    useState<TransactionSplittingModalParams>({
      open: false,
    });
  const [modalNoteParams, setModalNoteParams] =
    useState<TransactionNoteModalParams>({
      open: false,
    });

  const { me } = useContext(AuthContext);

  const hasSelected = selectedRowKeys.length > 0;

  const showModal = (transaction: Transaction, kind: KindEnum) => {
    setCurrentTransaction(transaction);
    setKind(kind);
    setOpen(true);
  };

  const showSubCategoryModal = (
    transaction: Transaction,
    kind: KindEnum,
    parent: Category
  ) => {
    setCurrentTransaction(transaction);
    setKind(kind);
    setCurrentCategory(parent);
    setOpenSubCategory(true);
  };

  useEffect(() => {
    if (open === true) {
      setTimeout(() => ref.current!.focus());
    }
  }, [open]);

  const handleOkModal = async () => {
    if (!currentTransaction) {
      return;
    }
    const values = await form.validateFields();

    const transactionIds = [currentTransaction.id];
    setModalLoading(true);

    await handleAddCategoryAndCategorize(values, kind, transactionIds);

    setModalLoading(false);
    setOpen(false);
    form.resetFields();
  };

  const handleSubCategoryOkModal = async () => {
    if (!currentCategory || !currentTransaction) {
      return;
    }
    const values = await form.validateFields();

    const transactionIds = [currentTransaction.id];
    setModalSubCategoryLoading(true);

    const { transactionCount, invoiceCount, goalCount } =
      await categoryService.isDroppableOver(currentCategory.id);

    if (transactionCount === 0 && invoiceCount === 0 && goalCount === 0) {
      handleAddCategoryAndCategorize(
        { ...values, vat: currentCategory?.vat || 0 },
        kind,
        transactionIds,
        currentCategory.id
      ).then(() => {
        setModalSubCategoryLoading(false);
        setOpenSubCategory(false);
        form.resetFields();
      });
    } else {
      confirm({
        ...confirmProps,
        onOk() {
          handleAddCategoryAndCategorize(
            { ...values, vat: currentCategory?.vat || 0 },
            kind,
            transactionIds,
            currentCategory.id
          ).then(() => {
            setModalSubCategoryLoading(false);
            setOpenSubCategory(false);
            form.resetFields();
          });
        },
        onCancel() {
          setModalSubCategoryLoading(false);
          setOpenSubCategory(false);
          form.resetFields();
        },
        content: (
          <ConfirmAddSubCategoryContent
            parentName={currentCategory.name}
            transactionCount={transactionCount}
            invoiceCount={invoiceCount}
            goalCount={goalCount}
          />
        ),
      });
    }
  };

  const handleCancelModal = () => {
    setOpen(false);
    form.resetFields();
  };

  const handleCancelSubCategoryModal = () => {
    setOpenSubCategory(false);
    form.resetFields();
  };

  const handleMenuClick = (transaction: Transaction) => (e: any) => {
    if (e.key === "ignore") {
      handleUpdate(transaction.id, { ignored: !transaction.ignored });
    } else if (e.key === "reconciliate") {
      setModalReconciliationParams({
        open: true,
        reconciliatingTransaction: transaction,
      });
    } else if (e.key === "split") {
      setModalSplittingParams({
        open: true,
        splittingTransaction: transaction,
      });
    } else if (e.key === "note") {
      setModalNoteParams({
        open: true,
        transaction,
      });
    } else if (e.key === "unsplit") {
      handleUnsplit(transaction.parent);
    } else if (e.key === "unchangeDate") {
      handleUnchangeDate(transaction.id);
    } else if (e.key === "changeKind") {
      const opposedKind =
        transaction.amount > 0 ? KindEnum.cashOut : KindEnum.cashIn;
      handleUpdate(transaction.id, {
        kind: transaction.kind ? null : opposedKind,
      });
    }
  };

  const handleRowExpand = (rowKey: number) => {
    const rowIndex = expandedRowKeys.indexOf(rowKey);
    if (rowIndex === -1) {
      setExpandedRowKeys([...expandedRowKeys, rowKey]);
    } else {
      const newExpandedRowKeys = [...expandedRowKeys];
      newExpandedRowKeys.splice(rowIndex, 1);
      setExpandedRowKeys(newExpandedRowKeys);
    }
  };

  const columns: ColumnsType<Transaction> = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: 128,
      sorter: true,
      render: (date: string) => getFormattedFullDate(new Date(date)),
    },
    {
      title: "Libellé",
      dataIndex: "raw_description",
      key: "raw_description",
      ellipsis: true,
      sorter: true,
      render: (_: any, transaction: Transaction) => (
        <Space direction="vertical">
          <Popover
            mouseEnterDelay={1}
            placement="topLeft"
            content={
              <div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span>
                    <Tag color="gold">
                      {transaction.account.customName ||
                        transaction.account.name}
                    </Tag>
                  </span>
                  <span>
                    <Highlighter
                      highlightStyle={{
                        backgroundColor: "#ffc069",
                        padding: 0,
                      }}
                      searchWords={[searchText]}
                      autoEscape
                      textToHighlight={
                        transaction.note
                          ? `${transaction.raw_description} • ${transaction.note}`
                          : transaction.raw_description
                      }
                    />
                  </span>
                </div>
              </div>
            }
          >
            <Highlighter
              highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={
                transaction.note
                  ? `${transaction.raw_description} • ${transaction.note}`
                  : transaction.raw_description
              }
            />
          </Popover>
          {transaction.invoices.filter((invoice) => invoice.prereconciliated)
            .length !== 0 && (
            <span
              style={{ cursor: "pointer" }}
              onClick={() =>
                setModalReconciliationParams({
                  open: true,
                  reconciliatingTransaction: transaction,
                })
              }
            >
              <Icon
                path={mdiRepeatVariant}
                size={0.7}
                style={{ verticalAlign: "-20%" }}
              />
              <Popover
                mouseEnterDelay={1}
                placement="topLeft"
                content={transaction.invoices
                  .filter((invoice) => invoice.prereconciliated)
                  .map((invoice) => (
                    <Tag key={invoice.id}>{invoice.description}</Tag>
                  ))}
              >
                {transaction.invoices
                  .filter((invoice) => invoice.prereconciliated)
                  .map((invoice) => (
                    <Tag key={invoice.id}>{invoice.description}</Tag>
                  ))}
              </Popover>
            </span>
          )}
        </Space>
      ),
    },
    {
      title: "Catégorie",
      dataIndex: "category",
      key: "category",
      width: 360,
      sorter: true,
      render: (_: any, transaction: Transaction) => (
        <>
          <CategorizeSelect
            categories={categories}
            handleCategorizeMany={handleCategorizeMany}
            transaction={transaction}
            disabled={hasSelected}
            showModal={showModal}
            showSubCategoryModal={showSubCategoryModal}
            searchText={searchText}
          />
          {transaction.category && transaction.precategorized && (
            <Button
              style={{ position: "absolute" }}
              type="link"
              onClick={() => {
                handleValidateCategory(transaction);
              }}
            >
              <Icon
                path={mdiCheckCircleOutline}
                size={1.2}
                style={{
                  color: "#52c41a",
                  position: "absolute",
                  top: "50%",
                  left: "70%",
                  transform: "translate(-50%, -50%)",
                }}
              />
            </Button>
          )}
        </>
      ),
    },
    {
      title: "Montant TTC",
      dataIndex: "amount",
      key: "amount",
      width: 160,
      render: (_: any, transaction: Transaction) =>
        formatTransactionAmount(transaction, searchText),
      sorter: true,
      align: "right",
      filters: [
        { text: "Encaissements", value: KindEnum.cashIn },
        { text: "Décaissements", value: KindEnum.cashOut },
      ],
      filterMultiple: false,
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      width: 140,
      render: (_: any, transaction: Transaction) => (
        <Space>
          <Dropdown
            getPopupContainer={(trigger) => trigger}
            trigger={["click"]}
            menu={{
              onClick: handleMenuClick(transaction),
              selectedKeys: [
                !!transaction.parent ? "unsplit" : "",
                !!transaction.originalDate ? "unchangeDate" : "",
                transaction.kind ? "changeKind" : "",
                transaction.ignored ? "ignore" : "",
              ].filter(Boolean),
              items: [
                {
                  icon: (
                    <Icon
                      path={mdiNoteMultipleOutline}
                      size={0.6}
                      style={{ verticalAlign: "middle" }}
                    />
                  ),
                  key: "note",
                  label: "Mémo",
                },
                !transaction.originalDate
                  ? {
                      icon: (
                        <Icon
                          path={mdiCalendarBlankOutline}
                          size={0.6}
                          style={{ verticalAlign: "middle" }}
                        />
                      ),
                      key: "changeDate",
                      label: "Changer la date",
                      children: [
                        {
                          key: "changeDateSubMenu",
                          label: (
                            <div style={{ padding: 6 }}>
                              <DatePicker
                                format={dateDisplayFormat}
                                allowClear={false}
                                defaultValue={dayjs(transaction.date)}
                                disabledDate={(currentDate: Dayjs) =>
                                  currentDate.isAfter(dayjs())
                                }
                                onChange={(dayjsDate: Dayjs | null) => {
                                  if (!dayjsDate) {
                                    return;
                                  }
                                  const date = dayjsDate.toDate();
                                  handleChangeDate(transaction.id, date);
                                }}
                              />
                            </div>
                          ),
                        },
                      ],
                    }
                  : {
                      key: "unchangeDate",
                      icon: (
                        <Icon
                          path={mdiCalendarBlankOutline}
                          size={0.6}
                          style={{ verticalAlign: "middle" }}
                        />
                      ),
                      label: "Retablir la date d'origine",
                    },
                ...(me?.company?.isInvoiceEnabled
                  ? [
                      {
                        icon: (
                          <Icon
                            path={mdiRepeatVariant}
                            size={0.6}
                            style={{ verticalAlign: "middle" }}
                          />
                        ),
                        key: "reconciliate",
                        label: "Rapprocher",
                      },
                    ]
                  : []),
                !transaction.parent
                  ? {
                      icon: (
                        <Icon
                          path={mdiSourceFork}
                          size={0.6}
                          style={{ verticalAlign: "middle" }}
                        />
                      ),
                      key: "split",
                      label: "Diviser en plusieurs",
                    }
                  : {
                      icon: (
                        <Icon
                          path={mdiSourceFork}
                          size={0.6}
                          style={{ verticalAlign: "middle" }}
                        />
                      ),
                      key: "unsplit",
                      label: "Ne plus diviser",
                    },
                {
                  icon: (
                    <Icon
                      path={mdiRestore}
                      size={0.6}
                      rotate={-30}
                      style={{ verticalAlign: "middle" }}
                    />
                  ),
                  key: "changeKind",
                  label: transaction.kind
                    ? "Ne plus utiliser les catégories opposées"
                    : "Utiliser les catégories opposées",
                },

                {
                  icon: <Icon path={mdiEyeOffOutline} size={0.6} />,
                  key: "ignore",
                  label: transaction.ignored
                    ? "Ne plus masquer"
                    : "Masquer la transaction (des totaux et statistiques)",
                },
              ],
            }}
            placement="topRight"
          >
            <Button size="small">
              <DashOutlined />
            </Button>
          </Dropdown>
          {Boolean(transaction.invoices?.length) && (
            <Button
              type="link"
              size="small"
              onClick={() => {
                handleRowExpand(transaction.id);
              }}
            >
              <Icon
                path={mdiRepeatVariant}
                size={0.8}
                style={{ verticalAlign: "middle" }}
              />
            </Button>
          )}
          {Boolean(transaction.parent) && (
            <Icon
              path={mdiSourceFork}
              size={0.7}
              style={{ verticalAlign: "-15%" }}
            />
          )}
          {Boolean(transaction.kind) && (
            <Icon
              path={mdiRestore}
              size={0.7}
              rotate={-30}
              style={{ verticalAlign: "-15%" }}
            />
          )}
        </Space>
      ),
    },
  ];

  const onSelectChange = (selectedRowKeys: number[]) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const title = kind === KindEnum.cashIn ? "Encaissements" : "Décaissements";

  const expandedRowRender = (transaction: Transaction) => {
    const invoiceColumns: ColumnsType<Invoice> = [
      {
        title: "Date échéance",
        dataIndex: "dueDate",
        key: "dueDate",
        width: "12%",
        render: (date: string) => getFormattedFullDate(new Date(date)),
      },
      {
        title: "Libellé",
        dataIndex: "description",
        key: "description",
        width: "35%",
        ellipsis: true,
        render: (_: any, invoice: Invoice) =>
          invoice.note
            ? `${invoice.description} • ${invoice.note}`
            : invoice.description,
      },
      {
        title: "Statut",
        dataIndex: "dueDate",
        key: "status",
        width: "30%",
        render: (_: any, invoice: Invoice) => {
          if (invoice.paid) {
            return <Tag color="green">Payée</Tag>;
          } else if (
            new Date(invoice.dueDate).getTime() > new Date().getTime()
          ) {
            return <Tag color="gold">En attente</Tag>;
          } else {
            return <Tag color="red">En retard</Tag>;
          }
        },
      },
      {
        title: "Montant TTC",
        dataIndex: "amount",
        key: "amount",
        width: "12%",
        render: (_: any, invoice: Invoice) => formatTransactionAmount(invoice),
        align: "right",
      },
      {
        title: "",
        key: "space",
        dataIndex: "space",
      },
    ];

    return (
      <>
        <Typography.Title level={4} style={{ textAlign: "center" }}>
          <Space>
            Factures rapprochées
            <Badge
              count={transaction.invoices.length}
              style={{
                color: "#999",
                backgroundColor: "#fff",
                boxShadow: "0 0 0 1px #d9d9d9 inset",
              }}
            />
          </Space>
        </Typography.Title>
        <Table
          columns={invoiceColumns}
          dataSource={transaction.invoices}
          rowKey="id"
          pagination={false}
        />
      </>
    );
  };

  return (
    <>
      <Modal
        title={
          <Space>
            Catégoriser
            <Typography.Text code>
              {currentTransaction?.raw_description}
            </Typography.Text>
          </Space>
        }
        open={open}
        confirmLoading={modalLoading}
        okText="Créer et catégoriser la transaction"
        onOk={handleOkModal}
        onCancel={handleCancelModal}
        forceRender
        destroyOnClose
      >
        <div style={{ marginBottom: 15 }}>
          Ajout d'une catégorie en : <b>{title}</b>
        </div>
        <Form form={form} layout="vertical">
          <Form.Item
            name="name"
            label="Nom de la catégorie"
            rules={[{ required: true, message: "Requis" }]}
          >
            <Input ref={ref} onPressEnter={handleOkModal} />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title={
          <Space>
            Catégoriser
            <Typography.Text code>
              {currentTransaction?.raw_description}
            </Typography.Text>
          </Space>
        }
        open={openSubCategory}
        confirmLoading={modalSubCategoryLoading}
        okText="Créer et catégoriser la transaction"
        onOk={handleSubCategoryOkModal}
        onCancel={handleCancelSubCategoryModal}
        forceRender
        destroyOnClose
      >
        <div style={{ marginBottom: 15 }}>
          Ajout d'une sous-catégorie à : <b>{currentCategory?.name}</b>
        </div>
        <Form form={form} layout="vertical">
          <Form.Item
            name="name"
            label="Nom de la sous-catégorie"
            rules={[{ required: true, message: "Requis" }]}
          >
            <Input ref={ref} onPressEnter={handleSubCategoryOkModal} />
          </Form.Item>
        </Form>
      </Modal>
      <TransactionNoteModal
        addNote={(transactionId: number, note: string) =>
          handleUpdate(transactionId, { note })
        }
        modalParams={modalNoteParams}
        setModalParams={setModalNoteParams}
      />
      <TransactionReconciliationModal
        reconciliateTransaction={handleReconciliate}
        modalParams={modalReconciliationParams}
        setModalParams={setModalReconciliationParams}
        validatePrereconciliation={handleValidatePrereconciliation}
      />
      <TransactionSplittingModal
        splitTransaction={handleSplit}
        modalParams={modalSplittingParams}
        setModalParams={setModalSplittingParams}
      />
      <Table
        locale={{
          emptyText,
        }}
        columns={columns}
        dataSource={transactions}
        size="small"
        loading={loading}
        // @ts-ignore
        onChange={changeParams}
        pagination={{
          showSizeChanger: true,
          position: ["bottomCenter"],
          ...pagination,
        }}
        rowKey="id"
        showSorterTooltip={false}
        // @ts-ignore
        rowClassName={(transaction: Transaction) =>
          transaction.ignored && "table-row-ignored"
        }
        expandable={{
          expandIconColumnIndex: -1,
          expandedRowRender,
          expandedRowKeys,
        }}
        {...(withRowSelection && {
          rowSelection: {
            onChange: onSelectChange,
            selectedRowKeys,
          },
        })}
      />
    </>
  );
};

export default TransactionsTable;
