import { Alert, Col, message, Row, Tabs } from "antd";
import { ModalParams } from "components/CashPlanPage";
import InvoicesTable from "components/InvoicesTable";
import MainChartDatePicker from "components/MainChartDatePicker";
import TransactionsTable from "components/TransactionsTable";
import { AuthContext } from "contexts";
import { addMonths, format, parseISO } from "date-fns";
import { FC, ReactElement, useContext, useEffect, useState } from "react";
import categoryService, {
  CreateCategoryValues,
} from "services/categoryService";
import goalService from "services/goalService";
import invoiceService from "services/invoiceService";
import transactionService from "services/transactionService";
import useSWR, { mutate } from "swr";
import { CategoriesWithAmounts, Invoice, Transaction } from "types";
import { sumOfAmounts } from "utils/amount";
import { CurrencyCode } from "utils/currency";
import { isPastMonth, isPresentMonth } from "utils/date";
import errorHandler from "utils/errorHandler";
import { KindEnum } from "utils/kind";
import { computeQuery } from "utils/strapi";
import { MainModalTable } from "./MainModalTable";

interface Props {
  scenarioId: number | undefined;
  modalParams: ModalParams;
}

const MainModalContent: FC<Props> = ({ scenarioId, modalParams }) => {
  const [date, setDate] = useState<string>(modalParams.date);
  const { me } = useContext(AuthContext);

  const [sumOfTransactions, setSumOfTransactions] = useState(0);
  const [sumOfInvoices, setSumOfInvoices] = useState(0);
  const [goal, setGoal] = useState<number | null>(0);
  const [note, setNote] = useState("");
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [invoices, setInvoices] = useState<Invoice[]>([]);

  const {
    data: categories,
    // error
  } = useSWR(
    "categoryService.getAllWithoutDefault",
    categoryService.getAllWithoutDefault
  );
  const [loaded, setLoaded] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [error, setError] = useState<ReactElement | undefined>(undefined);
  const [key, setKey] = useState("1");

  const handleCategorizeMany = (
    transactionIds: number[],
    categoryId: number
  ) => {
    return transactionService
      .categorizeMany(transactionIds, categoryId)
      .then((updatedTransactions) => {
        if (updatedTransactions > transactionIds.length) {
          const precategorizedTransactions =
            updatedTransactions - transactionIds.length;
          message.success(
            precategorizedTransactions === 1
              ? "Une transaction similaire a été précatégorisée dans la même catégorie."
              : `${precategorizedTransactions} transactions similaires ont été précatégorisées dans la même catégorie.`
          );
        }
        return loadData(key, date);
      })
      .catch(errorHandler(setError));
  };

  const handleCategorizeManyInvoices = (
    invoiceIds: number[],
    categoryId: number
  ) => {
    return invoiceService
      .categorizeMany(invoiceIds, categoryId)
      .then((updatedInvoices) => {
        if (updatedInvoices > invoiceIds.length) {
          const precategorizedTransactions =
            updatedInvoices - invoiceIds.length;
          message.success(
            precategorizedTransactions === 1
              ? "Une facture similaire a été précatégorisée dans la même catégorie."
              : `${precategorizedTransactions} factures similaires ont été précatégorisées dans la même catégorie.`
          );
        }
        return loadData(key, date);
      })
      .catch(errorHandler(setError));
  };

  const handleReconciliateTransaction = (
    transactionId: number,
    invoiceIds: number[],
    shouldCreateRemainingInvoice: boolean
  ) => {
    return transactionService
      .reconciliate(transactionId, invoiceIds, shouldCreateRemainingInvoice)
      .then(() => {
        return loadData(key, date);
      })
      .catch(errorHandler(setError));
  };

  const handleReconciliateInvoice = (
    transactionId: number,
    invoiceIds: number[],
    shouldCreateRemainingInvoice: boolean
  ) => {
    return transactionService
      .reconciliate(transactionId, invoiceIds, shouldCreateRemainingInvoice)
      .then(() => {
        return loadData(key, date);
      })
      .catch(errorHandler(setError));
  };

  const handleUpdate = (transactionId: number, params: any) => {
    return transactionService
      .update(transactionId, params)
      .then(() => {
        return loadData(key, date);
      })
      .catch(errorHandler(setError));
  };

  const handleSplit = (
    transactionId: number,
    categoriesWithAmounts: CategoriesWithAmounts[]
  ) => {
    return transactionService
      .split(transactionId, categoriesWithAmounts)
      .then(() => {
        return loadData(key, date);
      })
      .catch(errorHandler(setError));
  };

  const handleUnsplit = (transactionId: number) => {
    return transactionService
      .unsplit(transactionId)
      .then(() => {
        return loadData(key, date);
      })
      .catch(errorHandler(setError));
  };

  const handleChangeDate = (transactionId: number, newDate: Date) => {
    return transactionService
      .changeDate(transactionId, newDate)
      .then(() => {
        return loadData(key, date);
      })
      .catch(errorHandler(setError));
  };

  const handleUnchangeDate = (transactionId: number) => {
    return transactionService
      .unchangeDate(transactionId)
      .then(() => {
        return loadData(key, date);
      })
      .catch(errorHandler(setError));
  };

  const handleUpdateInvoices = (invoiceId: number, params: any) => {
    return invoiceService
      .update(invoiceId, params)
      .then(() => {
        return loadData(key, date);
      })
      .catch(errorHandler(setError));
  };

  const handleDeleteInvoices = (invoiceId: number) => {
    return invoiceService
      .remove(invoiceId)
      .then(() => {
        return loadData(key, date);
      })
      .catch(errorHandler(setError));
  };

  const handleDuplicateInvoices = (values: {
    description: string;
    amount: number;
    transactionDate: string;
    dueDate: string;
    currency_code: CurrencyCode;
    category?: number;
    vat: number;
  }) => {
    return invoiceService
      .create(values)
      .then(() => {
        return loadData(key, date);
      })
      .catch(errorHandler(setError));
  };

  const handleValidateCategory = (transaction: Transaction) => {
    return transactionService
      .update(transaction.id, { category: transaction.category })
      .then(() => {
        return loadData(key, date);
      })
      .catch(errorHandler(setError));
  };

  const handleValidatePrereconciliation = (
    invoice: Invoice,
    isValidated: boolean
  ) => {
    return invoiceService
      .update(invoice.id, { paid: isValidated })
      .then(() => {
        return loadData(key, date);
      })
      .catch(errorHandler(setError));
  };

  const handleValidateCategoryInvoices = (invoice: Invoice) => {
    return invoiceService
      .update(invoice.id, { category: invoice.category })
      .then(() => {
        return loadData(key, date);
      })
      .catch(errorHandler(setError));
  };

  const handleAddCategoryAndCategorize = async (
    values: CreateCategoryValues,
    kind: string,
    transactionIds: number[],
    parent?: number
  ) => {
    if (!categories) {
      return;
    }
    const newCategory = { ...values, kind, parent };
    const category = await categoryService.create(newCategory);

    mutate(
      "categoryService.getAllWithoutDefault",
      [...categories, { ...category }],
      false
    );
    mutate("categoryService.getAllWithoutDefault");
    return handleCategorizeMany(transactionIds, category.id);
  };

  const handleAddCategoryAndCategorizeInvoices = async (
    values: CreateCategoryValues,
    kind: string,
    invoiceIds: number[],
    parent?: number
  ) => {
    if (!categories) {
      return;
    }
    const newCategory = { ...values, kind, parent };
    const category = await categoryService.create(newCategory);

    mutate(
      "categoryService.getAllWithoutDefault",
      [...categories, { ...category }],
      false
    );
    mutate("categoryService.getAllWithoutDefault");
    return handleCategorizeManyInvoices(invoiceIds, category.id);
  };

  const loadData = (
    key: string,
    date: string,
    newPagination?: any,
    filters?: any,
    sorter?: any
  ) => {
    setKey(key);

    const updatedPagination = {
      ...pagination,
      ...newPagination,
    };
    setPagination(updatedPagination);

    const updatedFilteredInfo = {
      ...filteredInfo,
      ...filters,
    };
    setFilteredInfo(updatedFilteredInfo);

    const updatedSortedInfo = {
      ...sortedInfo,
      ...sorter,
    };
    setSortedInfo(updatedSortedInfo);

    if (key === "1") {
      const query = computeQuery({
        pagination: updatedPagination,
        filteredInfo: updatedFilteredInfo,
        sortedInfo: updatedSortedInfo,
        defaultSortColum: "date",
      });

      return Promise.all([
        goalService.find({
          scenarioId,
          categoryId: modalParams.category?.id,
          date,
        }),
        transactionService.find({
          category: modalParams.category,
          ignored: modalParams.ignored,
          date,
          query,
        }),
        transactionService.find({
          category: modalParams.category,
          ignored: modalParams.ignored,
          date,
        }),
        invoiceService.find({
          category: modalParams.category,
          ignored: modalParams.ignored,
          date,
        }),
        transactionService.count({
          category: modalParams.category,
          ignored: modalParams.ignored,
          date,
        }),
      ])
        .then(
          ([
            dataGoal,
            transactions,
            allTransactions,
            allInvoices,
            transactionsCount,
          ]) => {
            const {
              data: [goal],
            } = dataGoal;

            const categorySign =
              modalParams?.category?.kind === KindEnum.cashIn ? 1 : -1;

            const currentGoal = goal ? goal.amount : 0;
            const currentNote = goal ? goal.note : "";
            setGoal(currentGoal);
            setNote(currentNote);
            setSumOfTransactions(
              categorySign *
                sumOfAmounts(
                  allTransactions
                    .filter(({ ignored }) => !ignored)
                    .map(({ amount }) => amount)
                )
            );
            setSumOfInvoices(
              categorySign *
                sumOfAmounts(
                  allInvoices
                    .filter(({ ignored }) => !ignored)
                    .map(({ amount }) => amount)
                )
            );
            setDate(date);

            setPagination((pagination) => ({
              ...pagination,
              total: transactionsCount,
            }));
            setTransactions(transactions);
            setLoaded(true);
          }
        )
        .catch(errorHandler(setError));
    } else {
      const query = computeQuery({
        pagination: updatedPagination,
        filteredInfo: updatedFilteredInfo,
        sortedInfo: updatedSortedInfo,
        defaultSortColum: "dueDate",
      });

      return Promise.all([
        goalService.find({
          scenarioId,
          categoryId: modalParams.category?.id,
          date,
        }),
        invoiceService.find({
          category: modalParams.category,
          ignored: modalParams.ignored,
          date,
          query,
        }),
        transactionService.find({
          category: modalParams.category,
          ignored: modalParams.ignored,
          date,
        }),
        invoiceService.find({
          category: modalParams.category,
          ignored: modalParams.ignored,
          date,
        }),
        invoiceService.count({
          category: modalParams.category,
          ignored: modalParams.ignored,
          date,
        }),
      ])
        .then(
          ([
            dataGoal,
            invoices,
            allTransactions,
            allInvoices,
            invoicesCount,
          ]) => {
            const {
              data: [goal],
            } = dataGoal;

            const categorySign =
              modalParams?.category?.kind === KindEnum.cashIn ? 1 : -1;

            const currentGoal = goal ? goal.amount : 0;
            const currentNote = goal ? goal.note : "";
            setGoal(currentGoal);
            setNote(currentNote);
            setSumOfTransactions(
              categorySign *
                sumOfAmounts(
                  allTransactions
                    .filter(({ ignored }) => !ignored)
                    .map(({ amount }) => amount)
                )
            );
            setSumOfInvoices(
              categorySign *
                sumOfAmounts(
                  allInvoices
                    .filter(({ ignored }) => !ignored)
                    .map(({ amount }) => amount)
                )
            );
            setDate(date);

            setPagination((pagination) => ({
              ...pagination,
              total: invoicesCount,
            }));

            setInvoices(invoices);
            setLoaded(true);
          }
        )
        .catch(errorHandler(setError));
    }
  };

  useEffect(() => {
    if (modalParams.open && modalParams.date && scenarioId) {
      const newKey =
        isPastMonth(parseISO(modalParams.date)) ||
        isPresentMonth(parseISO(modalParams.date))
          ? "1"
          : "2";
      loadData(newKey, modalParams.date);
    }
    // eslint-disable-next-line
  }, [modalParams, scenarioId]);

  useEffect(() => {
    mutate("categoryService.getAllWithoutDefault");
  }, []);

  const incrementDateModal = (numberOfMonth: number) => {
    if (!date) {
      return;
    }
    const newDateObj = addMonths(parseISO(date), numberOfMonth);
    const newDate = format(newDateObj, "yyyy-MM-dd");
    const newKey =
      isPastMonth(newDateObj) || isPresentMonth(newDateObj) ? "1" : "2";
    loadData(newKey, newDate, { current: 1 });
  };

  const setDateModal = (dateObj: Date) => {
    const newDate = format(dateObj, "yyyy-MM-dd");
    const newKey = isPastMonth(dateObj) || isPresentMonth(dateObj) ? "1" : "2";
    loadData(newKey, newDate, { current: 1 });
  };

  const changeParams = (pagination: any, filters: any, sorter: any) => {
    loadData(key, date, pagination, filters, sorter);
  };

  const onChangeTab = (key: string) => {
    loadData(
      key,
      date,
      {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      { raw_description: null, description: null },
      { column: undefined, order: undefined }
    );
  };

  return (
    <div id="MainModalContent">
      {error && <Alert type="error" message={error} />}
      <Row gutter={[16, 16]}>
        <Col span={24} style={{ textAlign: "center" }}>
          <MainChartDatePicker
            date={parseISO(date)}
            incrementStartingMonth={incrementDateModal}
            setDate={setDateModal}
          />
        </Col>
        <Col span={24}>
          <MainModalTable
            dateString={date}
            scenarioId={scenarioId}
            category={modalParams.category}
            goal={goal}
            setGoal={setGoal}
            note={note}
            setNote={setNote}
            sumOfTransactions={sumOfTransactions}
            sumOfInvoices={sumOfInvoices}
            withInvoices={!!me?.company?.isInvoiceEnabled}
          />
        </Col>
        <Col span={24}>
          <Tabs
            activeKey={key}
            animated={false}
            onChange={onChangeTab}
            tabBarGutter={50}
            items={[
              ...(isPastMonth(parseISO(date)) || isPresentMonth(parseISO(date))
                ? [
                    {
                      label: "Réalisé",
                      key: "1",
                      children: (
                        <TransactionsTable
                          transactions={transactions}
                          categories={categories || []}
                          handleCategorizeMany={handleCategorizeMany}
                          handleValidateCategory={handleValidateCategory}
                          handleValidatePrereconciliation={
                            handleValidatePrereconciliation
                          }
                          handleReconciliate={handleReconciliateTransaction}
                          loading={!loaded}
                          handleAddCategoryAndCategorize={
                            handleAddCategoryAndCategorize
                          }
                          handleUpdate={handleUpdate}
                          handleSplit={handleSplit}
                          handleUnsplit={handleUnsplit}
                          handleChangeDate={handleChangeDate}
                          handleUnchangeDate={handleUnchangeDate}
                          changeParams={changeParams}
                          pagination={pagination}
                          withRowSelection={false}
                        />
                      ),
                    },
                  ]
                : []),
              ...(me?.company?.isInvoiceEnabled && !isPastMonth(parseISO(date))
                ? [
                    {
                      label: "Engagé",
                      key: "2",
                      children: (
                        <InvoicesTable
                          invoices={invoices}
                          categories={categories || []}
                          handleCategorizeMany={handleCategorizeManyInvoices}
                          handleValidateCategory={
                            handleValidateCategoryInvoices
                          }
                          handleValidatePrereconciliation={
                            handleValidatePrereconciliation
                          }
                          handleReconciliate={handleReconciliateInvoice}
                          loading={!loaded}
                          handleAddCategoryAndCategorize={
                            handleAddCategoryAndCategorizeInvoices
                          }
                          handleDuplicate={handleDuplicateInvoices}
                          handleUpdate={handleUpdateInvoices}
                          handleDelete={handleDeleteInvoices}
                          changeParams={changeParams}
                          pagination={pagination}
                          withRowSelection={false}
                        />
                      ),
                    },
                  ]
                : []),
            ]}
          />
        </Col>
      </Row>
    </div>
  );
};

export default MainModalContent;
