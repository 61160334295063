// 1.0.1
function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function cleanDescription(description: string) {
  return description
    .replace(/[ ]{2,}/g, " ")
    .split(" ")
    .filter((str: string) => !/\d/.test(str))
    .map((str: string) => str.toLowerCase())
    .map(capitalizeFirstLetter)
    .join(" ");
}

export { capitalizeFirstLetter, cleanDescription };
