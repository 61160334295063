import { Category } from "types";

// 1.1.1
const formatTree: any = ({
  categories,
  formatFunction,
  level = 0,
}: {
  categories: Category[];
  formatFunction: (category: Category, level?: number) => any;
  level: number;
}) =>
  categories.map((category: Category) =>
    category.children?.length
      ? {
          ...formatFunction(category, level),
          children: formatTree({
            categories: category.children,
            formatFunction,
            level: level + 1,
          }),
        }
      : formatFunction(category, level)
  );

const findTree = ({
  categories,
  categoryId,
  field = "id",
}: {
  categories?: Category[];
  categoryId?: number;
  field?: keyof Category;
}): Category | undefined => {
  if (!categories || categories.length === 0 || !categoryId) {
    return undefined;
  }

  const category = categories.find(
    (category) => category[field] === categoryId
  );
  if (category) {
    return category;
  }

  const allChildren = categories.reduce((acc: Category[], category) => {
    return category.children?.length ? [...acc, ...category.children] : acc;
  }, []);

  return findTree({ categories: allChildren, categoryId, field });
};

const applyOnLeaves = ({
  categories,
  fn,
}: {
  categories: Category[];
  fn: (category: Category) => void;
}) => {
  categories.forEach((category) => {
    if (category.children?.length) {
      applyOnLeaves({ categories: category.children, fn });
    } else {
      fn(category);
    }
  });
};

const applyOnNotLeaves = ({
  categories,
  fn,
}: {
  categories: Category[];
  fn: (category: Category) => void;
}) => {
  categories.forEach((category) => {
    if (category.children?.length) {
      applyOnNotLeaves({ categories: category.children, fn });
      fn(category);
    }
  });
};

const applyOnAllNodesChildrenFirst = ({
  categories,
  fn,
}: {
  categories: Category[];
  fn: (category: Category) => void;
}) => {
  categories.forEach((category) => {
    applyOnAllNodesChildrenFirst({ categories: category.children || [], fn });
    fn(category);
  });
};

const applyOnAllNodesChildrenAfter = (
  {
    categories,
    fn,
  }: {
    categories: Category[];
    fn: (category: Category, level: number) => any;
  },
  level = 0
) => {
  categories.forEach((category) => {
    fn(category, level);
    if (category.children?.length) {
      applyOnAllNodesChildrenAfter(
        { categories: category.children, fn },
        level + 1
      );
    }
  });
};

const getCategoryChildren = ({
  categories,
  parentId,
}: {
  categories: Category[];
  parentId: number | null;
}): Category[] => {
  return categories
    .filter((category) => category.parent === parentId)
    .map((category) => {
      const children = getCategoryChildren({
        categories,
        parentId: category.id,
      });
      return children.length
        ? {
            ...category,
            children,
          }
        : category;
    });
};

const hasChildren = (category: Category) => Boolean(category.children?.length);

export {
  formatTree,
  findTree,
  applyOnLeaves,
  applyOnNotLeaves,
  applyOnAllNodesChildrenFirst,
  applyOnAllNodesChildrenAfter,
  getCategoryChildren,
  hasChildren,
};
