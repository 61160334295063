import { Button, Checkbox, Col, Form, Input, Row } from "antd";
import { FC, ReactElement, useState } from "react";
import { FormattedMessage } from "react-intl";
import authService from "services/authService";
import { Me } from "types";
import { getCookie } from "utils/attribution";
import { applicationName } from "utils/constants";
import errorHandler from "utils/errorHandler";
import marketingTools from "utils/marketingTools";

interface Props {
  setError: (errorMessage: ReactElement | undefined) => void;
  logIn: (token: string, me: Me) => void;
  initialValues?: {
    email?: string;
    firstName?: string;
    lastName?: string;
    ref?: string;
  };
}

export const RegisterForm: FC<Props> = ({ setError, logIn, initialValues }) => {
  const [loading, setLoading] = useState(false);

  const onFinish = (values: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    password: string;
    ref: string;
  }) => {
    setLoading(true);

    const referrer = getCookie("referrer");
    const utm_campaign = getCookie("utm_campaign");
    const utm_medium = getCookie("utm_medium");
    const utm_source = getCookie("utm_source");
    const app_domain = process.env.REACT_APP_DOMAIN || "";

    const { firstName, lastName, email, phone, password, ref } = values;

    authService
      .register({
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        email: email.trim(),
        phone: phone,
        password: password,
        referrer,
        utm_campaign,
        utm_medium,
        utm_source,
        app_domain,
        ref,
      })
      .then(({ jwt, user }) => {
        logIn(jwt, user);

        marketingTools.hasRegistered(user);
      })
      .catch((e) => {
        if (e?.response?.data?.message?.[0]?.messages?.[0]?.id) {
          setError(
            <FormattedMessage
              id={e?.response?.data?.message?.[0]?.messages?.[0]?.id || -1}
              defaultMessage="Un problème est survenu. Veuillez rafraîchir la page ou essayer ultérieurement."
              description="Server response to login"
            />
          );
          setLoading(false);
        } else {
          throw e;
        }
      })
      .catch(errorHandler(setError));
  };

  return (
    <Form
      name="register"
      onFinish={onFinish}
      className="login-form animated"
      layout="vertical"
      requiredMark={false}
      initialValues={initialValues}
    >
      <Form.Item
        label="Entrez votre email"
        name="email"
        rules={[
          { required: true, message: "Requis" },
          { type: "email", message: "Email invalide" },
        ]}
      >
        <Input
          type="email"
          autoComplete="username email"
          placeholder="nom@entreprise.com"
        />
      </Form.Item>
      <Form.Item
        label="Choisissez votre mot de passe"
        name="password"
        rules={[{ required: true, message: "Requis" }]}
      >
        <Input.Password
          autoComplete="new-password"
          visibilityToggle={false}
          placeholder="••••••••"
        />
      </Form.Item>
      <Row gutter={8}>
        <Col span="12">
          <Form.Item
            label="Votre prénom"
            name="firstName"
            rules={[{ required: true, message: "Requis" }]}
          >
            <Input autoComplete="given-name" placeholder="Prénom" />
          </Form.Item>
        </Col>
        <Col span="12">
          <Form.Item
            label="Votre nom"
            name="lastName"
            rules={[{ required: true, message: "Requis" }]}
          >
            <Input autoComplete="family-name" placeholder="Nom" />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        label="Entrez votre téléphone"
        name="phone"
        rules={[{ required: true, message: "Requis" }]}
      >
        <Input autoComplete="tel" type="tel" placeholder="06XXXXXXXX" />
      </Form.Item>
      <Form.Item name="ref" hidden></Form.Item>
      <Form.Item
        name="cgu"
        valuePropName="checked"
        rules={[
          {
            validator: (_, value) =>
              value
                ? Promise.resolve()
                : Promise.reject(
                    "Veuillez lire et valider nos conditions d'utilisation"
                  ),
          },
        ]}
      >
        <Checkbox>
          J'accepte les{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.ingeneo.eu/conditions-generales-dutilisation/"
          >
            Conditions Générales d'Utilisation des Services
          </a>{" "}
          {applicationName}.
        </Checkbox>
      </Form.Item>
      <Button type="primary" htmlType="submit" loading={loading} block>
        Créer un compte
      </Button>
    </Form>
  );
};
