import { Alert, Button, Form, Input } from "antd";
import UnloggedLayout from "components/UnloggedLayout";
import { AuthContext } from "contexts";
import { ReactElement, useContext, useState } from "react";
import { FormattedMessage } from "react-intl";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import authService from "services/authService";
import errorHandler from "utils/errorHandler";
import "./index.css";

function LoginPage() {
  const [error, setError] = useState<ReactElement | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const { logIn } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  const from = ((location.state as any)?.from.pathname as string) || "/";

  const forgotPassword = searchParams.get("forgotPassword") === "true";
  const invalidResetTokenError =
    searchParams.get("invalidResetTokenError") === "true";
  const emailAlreadyTaken = searchParams.get("emailAlreadyTaken") === "true";
  const emailNotConfirmed = searchParams.get("emailNotConfirmed") === "true";

  const onFinish = (values: { email: string; password: string }) => {
    const { email, password } = values;
    setLoading(true);

    authService
      .login({
        identifier: email.trim(),
        password: password,
      })
      .then(({ jwt, user }) => {
        logIn(jwt, user);
        navigate(from, { replace: true });
      })
      .catch((e) => {
        if (e?.response?.data?.message?.[0]?.messages?.[0]?.id) {
          setError(
            <FormattedMessage
              id={e?.response?.data?.message?.[0]?.messages?.[0]?.id || -1}
              defaultMessage="Un problème est survenu. Veuillez rafraîchir la page ou essayer ultérieurement."
              description="Server response to login"
            />
          );
          setLoading(false);
        } else {
          throw e;
        }
      })
      .catch(errorHandler(setError));
  };
  return (
    <UnloggedLayout>
      <div className="form-container">
        <h1>Me connecter</h1>
        <p>
          Vous n'avez pas de compte ?{" "}
          <Link to="/register">Créer un compte</Link>
        </p>
        {invalidResetTokenError && !error && (
          <Alert
            message="Code de réinitialisation invalide ou expiré."
            type="error"
          />
        )}
        {forgotPassword && !error && (
          <Alert
            message="Vous allez recevoir un email avec les instructions pour réinitialiser votre mot de passe."
            type="success"
          />
        )}
        {emailAlreadyTaken && !error && (
          <Alert
            message="Cet email est déjà lié à un compte. Utilisez-vous un mot de passe ?"
            type="error"
          />
        )}
        {emailNotConfirmed && !error && (
          <Alert
            message="Votre email n'a pas été vérifié. Avez-vous consulté votre boîte email pour le confirmer ?"
            type="error"
          />
        )}
        {error && <Alert type="error" message={error} />}
        <Form
          name="login"
          onFinish={onFinish}
          className="login-form"
          layout="vertical"
          requiredMark={false}
        >
          <Form.Item
            label="Votre email"
            id="email"
            name="email"
            rules={[
              { required: true, message: "Requis" },
              { type: "email", message: "Email invalide" },
            ]}
          >
            <Input
              type="email"
              autoComplete="username email"
              placeholder="Saisissez votre adresse e-mail"
            />
          </Form.Item>
          <Form.Item
            id="password"
            name="password"
            label="Mot de passe"
            rules={[{ required: true, message: "Requis" }]}
          >
            <Input.Password
              autoComplete="current-password"
              visibilityToggle={false}
              placeholder="••••••••"
            />
          </Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="FormButton"
            loading={loading}
            block
          >
            Me connecter
          </Button>
        </Form>{" "}
        <p>
          <Link to="/forgot">J'ai oublié mon mot de passe</Link>
        </p>
        {/* <p>
          <Button
            type="default"
            htmlType="submit"
            className="FormButton"
            block
            onClick={() => {
              window.location.href = `${process.env.REACT_APP_BASE_URL}connect/auth0`;
            }}
            icon={
              <Icon
                component={() => (
                  <img
                    src={`/logo192.png`}
                    style={{ width: 32 }}
                    alt="gcl icon"
                  />
                )}
              />
            }
          >
            Se connecter avec SSO Zenfirst
          </Button>
        </p> */}
      </div>
    </UnloggedLayout>
  );
}

export default LoginPage;
