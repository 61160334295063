import { Card, Col, Row, Select } from "antd";
import { State } from "components/CashPlanPage";
import LineChartByCategory from "components/LineChartByCategory";
import Loader from "components/Loader";
import MainChartDatePicker from "components/MainChartDatePicker";
import PieChartByCategory from "components/PieChartByCategory";
import { AuthContext } from "contexts";
import { addMonths } from "date-fns";
import format from "date-fns/format";
import startOfMonth from "date-fns/startOfMonth";
import subMonths from "date-fns/subMonths";
import { FC, ReactElement, useContext, useEffect, useState } from "react";
import categoryService from "services/categoryService";
import dashboardService from "services/dashboardService";
import { Scenario } from "types";
import { defaultCurrencyCode } from "utils/currency";
import { formatSourceData, formatStatisticData } from "utils/dashboardUtils";
import { isPastMonth } from "utils/date";
import errorHandler from "utils/errorHandler";
import { KindEnum } from "utils/kind";

interface Props {
  setError: (errorMessage: ReactElement | undefined) => void;
}

const StatisticsContainer: FC<Props> = ({ setError }) => {
  const { me } = useContext(AuthContext);
  const [kind, setKind] = useState<KindEnum>(KindEnum.cashIn);
  const [pieChartMonth, setPieChartMonth] = useState(startOfMonth(new Date()));

  const [state, setState] = useState<State>({
    sourceData: null,
    statisticData: null,
    // GENERAL SETTINGS
    startingMonth: startOfMonth(subMonths(new Date(), 6)),
    currentScenarioId: undefined, // not loaded if null
    baseScenarioId: undefined, // not loaded if null
    loaded: false,
    // MODAL LIST TRANSACTIONS
    modalParams: {
      category: undefined,
      ignored: false,
      date: format(new Date(), "yyyy-MM-dd"),
      open: false,
    },
    exportModalVisible: false,
    // TABLE
    tableEditing: false,
    // DATA
    categories: [],
    scenarios: [],
    checkedScenarios: {},
  });

  const loadData = async (
    startingMonth: Date,
    scenarioId: number | undefined,
    checkedScenarios: Record<number | string, boolean>
  ) => {
    const startingMonthString = format(startingMonth, "yyyy-MM-dd");

    return Promise.all([
      dashboardService.get(startingMonthString),
      categoryService.getAll(),
    ])
      .then(([res, categories]) => {
        const { transactions, invoices, scenarios } = res.data;

        let currentScenarioId = scenarioId;
        const baseScenario = scenarios.find(
          (scenario: Scenario) => scenario.isDefaultScenario
        );
        const baseScenarioId = baseScenario.id;
        if (!currentScenarioId) {
          currentScenarioId = baseScenarioId;
        }

        const sourceData = formatSourceData({
          // Display data
          startingMonth,
          numberOfMonth: 12,
          // Real world data
          transactions,
          invoices,
          // Structure of the table
          categories,
          // Scenarios
          scenarios,
          defaultCurrencyCode,
        });

        const statisticData = formatStatisticData({
          sourceData,
          startingMonth,
          numberOfMonth: 12,
          scenarios,
          categories,
          baseScenarioId,
          isSimpleForecastModeEnabled:
            me?.company?.isSimpleForecastModeEnabled || false,
        });

        const newState: State = {
          ...state,
          sourceData,
          statisticData,
          startingMonth,
          currentScenarioId,
          baseScenarioId,
          categories,
          scenarios,
          loaded: true,
          checkedScenarios,
        };
        setState(newState);
      })
      .catch(errorHandler(setError));
  };

  useEffect(() => {
    const { startingMonth, currentScenarioId, checkedScenarios } = state;

    loadData(startingMonth, currentScenarioId, checkedScenarios);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { statisticData, categories, startingMonth, baseScenarioId, loaded } =
    state;
  const options = [
    { name: "Encaissements", key: KindEnum.cashIn },
    { name: "Décaissements", key: KindEnum.cashOut },
  ];

  const incrementPieChartMonth = (numberOfMonth: number) => {
    const newPieChartMonth = addMonths(pieChartMonth, numberOfMonth);
    if (
      newPieChartMonth.getTime() >= addMonths(new Date(), 5).getTime() ||
      newPieChartMonth.getTime() <= subMonths(new Date(), 7).getTime()
    ) {
      return;
    }
    setPieChartMonth(addMonths(pieChartMonth, numberOfMonth));
  };

  const setDate = (newPieChartMonth: Date) => {
    if (
      newPieChartMonth.getTime() >= addMonths(new Date(), 5).getTime() ||
      newPieChartMonth.getTime() <= subMonths(new Date(), 7).getTime()
    ) {
      return;
    }
    setPieChartMonth(newPieChartMonth);
  };

  const isPast = isPastMonth(pieChartMonth);

  return !loaded || !baseScenarioId || !statisticData ? (
    <Loader />
  ) : (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Card
            title={"Répartition des flux par mois"}
            style={{
              height: "100%",
            }}
            extra={
              <MainChartDatePicker
                date={pieChartMonth}
                incrementStartingMonth={incrementPieChartMonth}
                setDate={setDate}
              />
            }
          >
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={24} md={24} lg={12}>
                <Card
                  type="inner"
                  title={`Encaissements${!isPast ? " (prévisions)" : ""}`}
                  bodyStyle={{ padding: "24px 0" }}
                >
                  <PieChartByCategory
                    data={statisticData}
                    categories={categories}
                    kind={KindEnum.cashIn}
                    pieChartMonth={pieChartMonth}
                    baseScenarioId={baseScenarioId}
                  />
                </Card>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12}>
                <Card
                  type="inner"
                  title={`Décaissements${!isPast ? " (prévisions)" : ""}`}
                  bodyStyle={{ padding: "24px 0" }}
                >
                  <PieChartByCategory
                    data={statisticData}
                    categories={categories}
                    kind={KindEnum.cashOut}
                    pieChartMonth={pieChartMonth}
                    baseScenarioId={baseScenarioId}
                  />
                </Card>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Card
            title={"Flux par catégories sur 12 mois"}
            style={{
              height: "100%",
            }}
            extra={
              <Select
                value={kind}
                onChange={(option) => setKind(option)}
                style={{ width: 180 }}
                getPopupContainer={(trigger) => trigger.parentNode}
              >
                {options.map(({ name, key }: any) => (
                  <Select.Option key={key} value={key}>
                    {name}
                  </Select.Option>
                ))}
              </Select>
            }
          >
            <LineChartByCategory
              data={statisticData}
              categories={categories}
              kind={kind}
              loaded={loaded}
              startingMonth={startingMonth}
              baseScenarioId={baseScenarioId}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default StatisticsContainer;
