import { Company, Plans } from "types";
import axios from "utils/setupAxios";

export async function setup(): Promise<Plans> {
  return axios.get(`/stripe/setup`).then((res) => res.data);
}

export async function createCheckoutSessionSubscription({
  planId,
  isYearly,
  numberOfBanks,
}: {
  planId: string;
  isYearly: boolean;
  numberOfBanks: number;
}): Promise<{ url: string }> {
  return axios
    .post(`/stripe/create-checkout-session`, {
      planId,
      isYearly,
      numberOfBanks,
    })
    .then((res) => res.data);
}

export async function customerPortal(): Promise<{ url: string }> {
  return axios.post(`/stripe/customer-portal`).then((res) => res.data);
}

export async function createCheckoutSessionSetupIntent(): Promise<{
  url: string;
}> {
  return axios
    .post("/stripe/create-checkout-session-setup-intent")
    .then((res) => res.data);
}

export async function createSubscription({
  planId,
  isYearly,
  numberOfBanks,
}: {
  planId: string;
  isYearly: boolean;
  numberOfBanks: number;
}): Promise<Company> {
  return axios
    .post(`/stripe/create-subscription`, {
      planId,
      isYearly,
      numberOfBanks,
    })
    .then((res) => res.data);
}

export async function cancelSubscription(): Promise<Company> {
  return axios.post(`/stripe/cancel-subscription`).then((res) => res.data);
}

const stripeService = {
  setup,
  createCheckoutSessionSubscription,
  customerPortal,
  createCheckoutSessionSetupIntent,
  createSubscription,
  cancelSubscription,
};
export default stripeService;
