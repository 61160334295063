import { Alert, Button, Form, Input } from "antd";
import UnloggedLayout from "components/UnloggedLayout";
import { AuthContext } from "contexts";
import { FC, ReactElement, useContext, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import authService from "services/authService";
import { Me } from "types";
import errorHandler from "utils/errorHandler";

interface Props {
  setError: (errorMessage: ReactElement | undefined) => void;
  logIn: (token: string, me: Me) => void;
}

const ResetForm: FC<Props> = ({ setError, logIn }) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { code } = useParams();

  const onFinish = (values: {
    code: string;
    password: string;
    passwordConfirmation: string;
  }) => {
    if (code) {
      setLoading(true);

      const { password, passwordConfirmation } = values;

      authService
        .reset({
          code,
          password,
          passwordConfirmation,
        })
        .then(({ jwt, user }) => {
          logIn(jwt, user);
          navigate("/", { replace: true });
        })
        .catch((e) => {
          if (
            e?.response?.data?.message?.[0]?.messages?.[0]?.id ===
            "Auth.form.error.code.provide"
          ) {
            navigate("/login?invalidResetTokenError=true");
          } else if (e?.response?.data?.message?.[0]?.messages?.[0]?.id) {
            setError(
              <FormattedMessage
                id={e?.response?.data?.message?.[0]?.messages?.[0]?.id || -1}
                defaultMessage="Un problème est survenu. Veuillez rafraîchir la page ou essayer ultérieurement."
                description="Server response to reset"
              />
            );
            setLoading(false);
          } else {
            throw e;
          }
        })
        .catch(errorHandler(setError));
    } else {
      navigate("/login?invalidResetTokenError=true");
    }
  };

  return (
    <Form
      name="reset"
      onFinish={onFinish}
      className="login-form"
      layout="vertical"
      requiredMark={false}
      autoComplete="off"
    >
      <Form.Item
        label="Le nouveau mot de passe"
        id="password"
        name="password"
        rules={[{ required: true, message: "Requis" }]}
      >
        <Input.Password
          autoComplete="new-password"
          visibilityToggle={false}
          placeholder="••••••••"
        />
      </Form.Item>
      <Form.Item
        id="passwordConfirmation"
        name="passwordConfirmation"
        label="Confirmez le nouveau mot de passe"
        rules={[{ required: true, message: "Requis" }]}
      >
        <Input.Password
          autoComplete="new-password"
          visibilityToggle={false}
          placeholder="••••••••"
        />
      </Form.Item>
      <Button
        type="primary"
        htmlType="submit"
        className="FormButton"
        loading={loading}
        block
      >
        Réinitialiser mon mot de passe
      </Button>
    </Form>
  );
};
const ResetPage: FC = () => {
  const { logIn } = useContext(AuthContext);
  const [error, setError] = useState<ReactElement | undefined>(undefined);

  return (
    <UnloggedLayout>
      <div className="form-container">
        <h1>Réinitialiser mon mot de passe</h1>
        {error && <Alert type="error" message={error} />}
        <ResetForm setError={setError} logIn={logIn} />
      </div>
    </UnloggedLayout>
  );
};

export default ResetPage;
