import { TreeSelect, Typography } from "antd";
import { FC } from "react";
import Highlighter from "react-highlight-words";
import { Category } from "types";
import { KindEnum } from "utils/kind";
import { findTree, formatTree } from "utils/tree";

const { Text } = Typography;

interface Props {
  categories: Category[];
  kind?: KindEnum;
  searchText?: string;
  handleChange: (categoryId: number) => void;
}

const SplittingCategorySelect: FC<Props> = ({
  categories,
  kind,
  searchText = "",
  handleChange,
}) => {
  const cashInCategories = categories.filter(
    (category) => category.kind === KindEnum.cashIn
  );
  const cashOutCategories = categories.filter(
    (category) => category.kind === KindEnum.cashOut
  );

  const formatFunction = (category: Category) => ({
    title: (
      <Highlighter
        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={category.name}
      />
    ),
    searchValue: category.name,
    value: category.id,
    disabled: Boolean(kind) && category.kind !== kind,
    selectable: !category.children?.length,
    ...category,
  });

  const formattedCategories = formatTree({
    categories: [
      {
        name: "Encaissements",
        id: "cashIn",
        kind: KindEnum.cashIn,
        children: cashInCategories,
      },
      {
        name: "Décaissements",
        id: "cashOut",
        kind: KindEnum.cashOut,
        children: cashOutCategories,
      },
    ],
    formatFunction,
  });

  return (
    <TreeSelect
      virtual={false}
      showSearch
      treeNodeFilterProp="searchValue"
      style={{ width: "80%" }}
      dropdownStyle={{ maxHeight: 400 }}
      placeholder={
        <Text mark strong>
          Sélectionnez une catégorie
        </Text>
      }
      onChange={(categoryId: number) => {
        const currentCategory = findTree({
          categories,
          categoryId,
        });
        if (currentCategory) {
          handleChange(categoryId);
        }
      }}
      treeDefaultExpandAll
      treeData={formattedCategories}
    />
  );
};

export default SplittingCategorySelect;
