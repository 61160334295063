import { Button, Form, Input, Modal } from "antd";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { providerEnum, providerName } from "utils/constants";
import { IntegrationModalParams } from ".";

interface Props {
  modalParams: IntegrationModalParams;
  setModalParams: (modalParams: IntegrationModalParams) => void;
}

const tooltip = (provider?: string) => {
  if (provider === providerEnum.axonaut) {
    return {
      title: (
        <>
          Votre clé API Axonaut est nécessaire pour la synchronisation de vos
          factures, copiez-collez là depuis{" "}
          <a
            href="https://axonaut.com/utilities/api"
            target="_blank"
            rel="noopener noreferrer"
          >
            cette page de votre compte Axonaut
          </a>
          .
        </>
      ),
    };
  } else if (provider === providerEnum.evoliz) {
    return {
      title: (
        <>
          Vos clés API Evoliz sont nécessaires pour la synchronisation de vos
          factures, allez sur{" "}
          <a
            href="https://www.evoliz.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Evoliz
          </a>
          puis accédez au Menu en haut à droite / Mon Profil / Section
          Développeurs / Créer la clé API.
        </>
      ),
    };
  } else if (provider === providerEnum.gesco) {
    return {
      title: (
        <>
          Votre clé API Zenfirst Facture est nécessaire pour la synchronisation
          de vos factures.
          <br />
          <br />
          <a
            href="https://fact.zenfirst.fr/listeSoc/Soc/41/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Cliquez ici
          </a>{" "}
          pour accéder aux paramètres de Zenfirst Facture.
          <br />
          <br />
          Sélectionnez la société que vous voulez associer.
          <br />
          <ul>
            <li>- Dans l'onglet Général, vous trouverez le Code Société</li>
            <li>
              - Dans l'onglet Paramétrage, vous trouverez la Clé Api Tréso
            </li>
          </ul>
        </>
      ),
    };
  } else {
    return {};
  }
};

const IntegrationAddModal: FC<Props> = ({ modalParams, setModalParams }) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { open, provider, editingRecord } = modalParams;

  useEffect(() => {
    if (open === true && editingRecord) {
      const { groupId } = editingRecord;
      form.setFieldsValue({
        groupId,
      });
    } else if (open === true) {
      form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleOkModal = async () => {
    const values = await form.validateFields();
    const { access_token, access_secret, groupId } = values;

    setLoading(true);

    setTimeout(() => {
      navigate(
        `/integrations/${provider}/callback?access_token=${access_token}&access_secret=${access_secret}&groupId=${groupId}`
      );
    }, 1000);
    setModalParams({ open: false });
  };

  const handleCancelModal = () => {
    setModalParams({ open: false });
  };

  return (
    <Modal
      title={`Synchroniser ses factures depuis ${providerName[provider || ""]}`}
      open={open}
      onOk={handleOkModal}
      onCancel={handleCancelModal}
      forceRender
      destroyOnClose
      footer={[
        <Button key="back" onClick={handleCancelModal}>
          Annuler
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handleOkModal}
          loading={loading}
        >
          Démarrer la synchronisation
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical" name={`add-integration-form`}>
        {provider === "axonaut" && (
          <Form.Item
            name="access_token"
            label="Votre clé publique API"
            tooltip={tooltip(provider)}
            rules={[{ required: true, message: "Requis" }]}
          >
            <Input onPressEnter={handleOkModal} />
          </Form.Item>
        )}
        {provider === "evoliz" && (
          <>
            <Form.Item
              name="access_token"
              label="Votre clé publique API"
              tooltip={tooltip(provider)}
              rules={[{ required: true, message: "Requis" }]}
            >
              <Input onPressEnter={handleOkModal} />
            </Form.Item>
            <Form.Item
              name="access_secret"
              label="Votre clé secrète API"
              tooltip={tooltip(provider)}
              rules={[{ required: true, message: "Requis" }]}
            >
              <Input onPressEnter={handleOkModal} />
            </Form.Item>
          </>
        )}
        {provider === "gesco" && (
          <>
            <Form.Item
              name="groupId"
              label="Votre code société"
              tooltip={tooltip(provider)}
              rules={[{ required: true, message: "Requis" }]}
            >
              <Input onPressEnter={handleOkModal} />
            </Form.Item>
            <Form.Item
              name="access_token"
              label="Votre clé API Tréso"
              tooltip={tooltip(provider)}
              rules={[{ required: true, message: "Requis" }]}
            >
              <Input onPressEnter={handleOkModal} />
            </Form.Item>
          </>
        )}
        {provider === "ingeneo" && (
          <>
            <Form.Item
              name="groupId"
              label="Votre code société"
              tooltip={tooltip(provider)}
              rules={[{ required: true, message: "Requis" }]}
            >
              <Input onPressEnter={handleOkModal} />
            </Form.Item>
          </>
        )}
      </Form>
    </Modal>
  );
};

export default IntegrationAddModal;
