import { FC } from "react";
import "./index.css";

const LogoZenfirst: FC = () => {
  return (
    <img
      className="LogoZenfirstTreso"
      src="/img/logo-zenfirst-treso.png"
      alt="logo"
    />
  );
};

export default LogoZenfirst;
