import { Form } from "antd";
import React, { FC, useEffect, useRef } from "react";
import { getPlannedValue } from "utils/dashboardUtils";
import { EditableContext, Item } from "./index";

function usePreviousSourceData(sourceData: Item) {
  const ref = useRef<Item | undefined>();
  useEffect(() => {
    ref.current = sourceData;
  });
  return ref.current;
}

function usePreviousScenario(value: number) {
  const ref = useRef<number | undefined>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

interface EditableRowProps {
  scenarioId: number;
  baseScenarioId: number;
  index: number;
  record: Item;
  sourceData: Item;
}

export const EditableRow: FC<EditableRowProps> = ({
  scenarioId,
  baseScenarioId,
  index,
  record,
  sourceData,
  ...props
}) => {
  const [form] = Form.useForm();
  const refScenarioId = usePreviousScenario(scenarioId);
  const refSourceData = usePreviousSourceData(sourceData || {});

  const initialValues: Record<string, number | null> = {};

  Object.keys(sourceData || []).forEach((key) => {
    // Only keep keys that contain data: the date keys
    if (typeof sourceData[key] === typeof {}) {
      initialValues[key] = getPlannedValue(
        sourceData[key],
        scenarioId,
        baseScenarioId
      );
    }
  });

  useEffect(() => {
    if (refScenarioId !== scenarioId) {
      form.resetFields();
    }
  }, [scenarioId, refScenarioId, form]);

  useEffect(() => {
    Object.keys(sourceData || []).forEach((key) => {
      if (
        refSourceData &&
        typeof sourceData[key] === typeof {} &&
        sourceData[key] !== refSourceData[key]
      ) {
        form.resetFields([key]);
      }
    });
  }, [sourceData, refSourceData, form]);

  return (
    <Form
      name={record.key}
      initialValues={initialValues}
      form={form}
      component={false}
    >
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};
