import { Button } from "antd";
import { useState } from "react";
import stripeService from "services/stripeService";

export const PortalButton = ({ customText }: { customText?: string }) => {
  const [loading, setLoading] = useState(false);
  const handleClick = async () => {
    setLoading(true);
    // Call your backend to create the Checkout session.
    const { url } = await stripeService.customerPortal();
    window.location.href = url;
  };

  return (
    <Button
      style={{ margin: "25px 10px 5px" }}
      type="default"
      loading={loading}
      onClick={handleClick}
    >
      {customText || "Informations de paiement et facturation"}
    </Button>
  );
};
