import { BankOutlined, SwapOutlined } from "@ant-design/icons";
import {
  mdiAccountOutline,
  mdiArrowRightThinCircleOutline,
  mdiChartBoxOutline,
  mdiCogOutline,
  mdiFileDocumentOutline,
  mdiRepeatVariant,
  mdiTagMultipleOutline,
  mdiViewDashboardOutline,
} from "@mdi/js";
import Icon from "@mdi/react";
import {
  Alert,
  Avatar,
  Badge,
  Breadcrumb,
  Button,
  Dropdown,
  Layout,
  Menu,
  Space,
  Typography,
} from "antd";
import { Footer } from "antd/es/layout/layout";
import { AuthContext } from "contexts";
import { ErrorContext } from "contexts/error";
import { FC, MouseEvent, ReactElement, useContext, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import companyService from "services/companyService";
import integrationService from "services/integrationService";
import invoiceService from "services/invoiceService";
import itemService from "services/itemService";
import transactionService from "services/transactionService";
import useSWR from "swr";
import checkIfBankActionRequired from "utils/checkIfBankActionRequired";
import checkIfIntegrationActionRequired from "utils/checkIfIntegrationActionRequired";
import { plans, rolesEnum } from "utils/constants";
import getMessageTrialRemainingDays from "utils/getMessageTrialRemainingDays";
import "./index.css";

const { Content, Sider } = Layout;
const { Title, Text } = Typography;

interface Props {
  title: string | ReactElement;
  action?: ReactElement;
  children: ReactElement;
}

const GeneralLayout: FC<Props> = ({ title, action, children }) => {
  const { data: transactionUncategorizedCount } = useSWR(
    "transactionService.getAllUncategorizedCount",
    transactionService.getAllUncategorizedCount
  );
  const { data: transactionPrecategorizedCount } = useSWR(
    "transactionService.getAllPrecategorizedCount",
    transactionService.getAllPrecategorizedCount
  );
  const { data: invoiceUncategorizedCount } = useSWR(
    "invoiceService.getAllUncategorizedCount",
    invoiceService.getAllUncategorizedCount
  );
  const { data: invoicePrecategorizedCount } = useSWR(
    "invoiceService.getAllPrecategorizedCount",
    invoiceService.getAllPrecategorizedCount
  );
  const { data: invoiceLateCount } = useSWR(
    "invoiceService.getAllLateCount",
    invoiceService.getAllLateCount
  );
  const { data: invoicePrereconciliatedCount } = useSWR(
    "invoiceService.getAllPrereconciliatedCount",
    invoiceService.getAllPrereconciliatedCount
  );
  const { data: items } = useSWR("itemService.getAll", itemService.getAll);
  const { data: integrations } = useSWR(
    "integrationService.getAll",
    integrationService.getAll
  );
  const { me, setMe } = useContext(AuthContext);
  const { error, setError } = useContext(ErrorContext);
  const navigate = useNavigate();
  const location = useLocation();

  const isOnTrial = me?.company?.trialEndDate;
  const isOnFreePlan = me?.company?.plan === plans.START_PLAN_ID;
  const isReadOnly = me?.role?.id === rolesEnum["READ"];
  const isAdmin = me?.role?.id === rolesEnum["ADMIN"];
  const isMultiCompany = me?.company.isMultiCompany;

  const bankActionRequired = checkIfBankActionRequired(items || []);
  const integrationActionRequired = checkIfIntegrationActionRequired(
    integrations || []
  );
  const hasPaymentFailed = me?.company?.paymentFailed;
  const isInvoiceEnabled = me?.company?.isInvoiceEnabled;
  const isMigrating = me?.company?.migrationAsked;
  const isDemoCompany = me?.company?.isDemoCompany;

  const handleConvertToFreeVersion = () => {
    if (!me || !me.company) {
      return;
    }

    companyService
      .update(me.company.id, { isDemoCompany: false })
      .then((newCompany) => {
        const newMe = { ...me };
        newMe.company = newCompany;
        setMe(newMe);
      });
  };

  useEffect(() => {
    setError(undefined);
  }, [location, setError]);

  const handleLogout = (event: MouseEvent) => {
    event.preventDefault();

    navigate("/logout");
  };

  const handleChat = (event: MouseEvent) => {
    event.preventDefault();
    window.$crisp.push(["do", "chat:toggle"]);
  };

  const selectedKey = location?.pathname.split("/").slice(0, 2).join("/");
  const selectedKeys = [selectedKey];

  return (
    <Layout hasSider className="GeneralLayout">
      <Sider
        theme="light"
        collapsed
        style={{
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Link to="/">
              <img
                alt="zenfirst logo"
                className="Logo"
                src="/logo192.png"
              ></img>
            </Link>
            <Menu
              selectedKeys={selectedKeys}
              items={[
                {
                  key: "/",
                  title: "Plan de trésorerie",
                  icon: <Icon path={mdiViewDashboardOutline} size={0.82} />,

                  label: <Link to="/"></Link>,
                },
                ...(!isReadOnly && isInvoiceEnabled && !isMultiCompany
                  ? [
                      {
                        key: "/invoices",
                        title: "Factures engagées",
                        label: <Link to="/invoices"></Link>,
                        icon: (
                          <Badge
                            offset={[4, 4]}
                            dot={
                              Boolean(invoiceUncategorizedCount) ||
                              Boolean(invoicePrecategorizedCount) ||
                              Boolean(invoiceLateCount) ||
                              Boolean(invoicePrereconciliatedCount)
                            }
                          >
                            <Icon path={mdiFileDocumentOutline} size={0.82} />
                          </Badge>
                        ),
                      },
                    ]
                  : []),
                ...(!isReadOnly && !isMultiCompany
                  ? [
                      {
                        key: "/transactions",
                        title: "Transactions bancaires",
                        label: <Link to="/transactions"></Link>,
                        icon: (
                          <Badge
                            offset={[4, 8]}
                            dot={
                              Boolean(transactionUncategorizedCount) ||
                              Boolean(transactionPrecategorizedCount)
                            }
                          >
                            <SwapOutlined />
                          </Badge>
                        ),
                      },
                    ]
                  : []),
                ...(!isReadOnly && !isMultiCompany
                  ? [
                      {
                        key: "/categories",
                        title: "Catégories",
                        label: <Link to="/categories"></Link>,
                        icon: (
                          <Icon
                            path={mdiTagMultipleOutline}
                            size={0.82}
                            rotate={90}
                          />
                        ),
                      },
                    ]
                  : []),
              ]}
            />
          </div>
          <div className="BottomMenu">
            <Menu
              selectedKeys={selectedKeys}
              items={[
                // {
                //   key: "help-section",
                //   title: "",
                //   label: (
                //     <Dropdown
                //       trigger={["click"]}
                //       placement="topLeft"
                //       overlayStyle={{ minWidth: 200 }}
                //       dropdownRender={(menus) => (
                //         <div className="MenuContainer">
                //           <div
                //             className="MenuHeader"
                //             style={{
                //               backgroundColor: "rgb(243, 245, 248)",
                //               padding: "16px",
                //             }}
                //           >
                //             <div>
                //               <Text strong>Aide</Text>
                //             </div>
                //           </div>
                //           {menus}
                //         </div>
                //       )}
                //       menu={{
                //         style: { width: "auto", borderRight: 0 },
                //         selectedKeys,
                //         items: [
                //           {
                //             key: "roadmap",
                //             title: "",
                //             label: (
                //               <a
                //                 target="_blank"
                //                 rel="noopener noreferrer"
                //                 href="https://www.notion.so/e43bdadb0ffc4feb87aaa6d00d3f48f3?v=49e9eef0b1f842e49641c1c9cee6cd19"
                //               >
                //                 <Space>
                //                   <Icon
                //                     path={mdiCompassOutline}
                //                     size={0.7}
                //                     style={{ verticalAlign: "-15%" }}
                //                   />
                //                   Feuille de route publique
                //                 </Space>
                //               </a>
                //             ),
                //           },
                //           {
                //             key: "updates",
                //             title: "",
                //             label: (
                //               <a
                //                 target="_blank"
                //                 rel="noopener noreferrer"
                //                 href="https://www.notion.so/Zenfirst-Nouveaut-s-Produit-45185e20910047a9a1d04f1ace29a929"
                //               >
                //                 <Space>
                //                   <Icon
                //                     path={mdiBellOutline}
                //                     size={0.7}
                //                     style={{ verticalAlign: "-15%" }}
                //                   />
                //                   Découvrir les nouveautés
                //                 </Space>
                //               </a>
                //             ),
                //           },
                //           {
                //             key: "help",
                //             title: "",
                //             label: (
                //               <a
                //                 target="_blank"
                //                 rel="noopener noreferrer"
                //                 href="https://help.zenfirst.fr"
                //               >
                //                 <Space>
                //                   <Icon
                //                     path={mdiHelpCircleOutline}
                //                     size={0.7}
                //                     style={{ verticalAlign: "-15%" }}
                //                   />
                //                   Centre d'aide
                //                 </Space>
                //               </a>
                //             ),
                //           },
                //           { type: "divider" },
                //           {
                //             key: "chat",
                //             title: "",
                //             label: (
                //               <Link onClick={handleChat} to="#">
                //                 <Space>
                //                   <Icon
                //                     path={mdiChatProcessingOutline}
                //                     size={0.7}
                //                     style={{ verticalAlign: "-15%" }}
                //                   />
                //                   Parler avec le support
                //                 </Space>
                //               </Link>
                //             ),
                //           },
                //         ],
                //       }}
                //     >
                //       <QuestionCircleOutlined />
                //     </Dropdown>
                //   ),
                // },
                {
                  key: "setting",
                  title: "",
                  label: (
                    <Badge offset={[0, 4]} dot={me?.hasNewInvitation}>
                      <Dropdown
                        trigger={["click"]}
                        placement="topLeft"
                        overlayStyle={{ minWidth: 200 }}
                        dropdownRender={(menus) => (
                          <div className="MenuContainer">
                            <div
                              className="MenuHeader"
                              style={{
                                backgroundColor: "rgb(243, 245, 248)",
                                padding: "16px",
                              }}
                            >
                              <div>
                                <Text
                                  strong
                                >{`${me?.firstName} ${me?.lastName}`}</Text>
                              </div>
                              <div>
                                <Text type="secondary">
                                  {me?.company?.name}
                                </Text>
                              </div>
                            </div>
                            {menus}
                          </div>
                        )}
                        menu={{
                          style: { width: "auto", borderRight: 0 },
                          selectedKeys,
                          items: [
                            {
                              key: "/profile",
                              title: "",
                              label: (
                                <Link to="/profile">
                                  <Space>
                                    <Icon
                                      path={mdiAccountOutline}
                                      size={0.65}
                                      style={{ verticalAlign: "-15%" }}
                                    />
                                    Mon Profil
                                  </Space>
                                </Link>
                              ),
                            },
                            ...(!isMultiCompany
                              ? [
                                  {
                                    key: "/analysis",
                                    title: "",
                                    label: (
                                      <Link to="/analysis">
                                        <Space>
                                          <Icon
                                            path={mdiChartBoxOutline}
                                            size={0.65}
                                            style={{ verticalAlign: "-15%" }}
                                          />
                                          Analyses
                                        </Space>
                                      </Link>
                                    ),
                                  },
                                ]
                              : []),
                            ...(!isReadOnly
                              ? [
                                  {
                                    key: "/company",
                                    title: "",
                                    label: (
                                      <Link to="/company">
                                        <Space>
                                          <Icon
                                            path={mdiCogOutline}
                                            size={0.65}
                                            style={{ verticalAlign: "-15%" }}
                                          />
                                          Paramètres
                                        </Space>
                                      </Link>
                                    ),
                                  },
                                ]
                              : []),
                            // ...(isAdmin && !isMultiCompany
                            //   ? [
                            //       {
                            //         key: "/billing",
                            //         title: "",
                            //         label: (
                            //           <Link to="/billing">
                            //             <Space>
                            //               <Icon
                            //                 path={mdiCreditCardOutline}
                            //                 size={0.65}
                            //                 style={{ verticalAlign: "-15%" }}
                            //               />
                            //               Abonnement
                            //             </Space>
                            //           </Link>
                            //         ),
                            //       },
                            //     ]
                            //   : []),
                            ...(!isReadOnly && !isMultiCompany
                              ? [
                                  {
                                    key: "/banks",
                                    title: "",
                                    label: (
                                      <Link to="/banks">
                                        <Space>
                                          <BankOutlined />
                                          {isInvoiceEnabled
                                            ? "Banques et intégrations"
                                            : "Banques"}
                                        </Space>
                                      </Link>
                                    ),
                                  },
                                ]
                              : []),
                            { type: "divider" },
                            {
                              key: "/change-company",
                              title: "",
                              label: (
                                <Link to="/change-company">
                                  <Badge
                                    offset={[6, 0]}
                                    dot={me?.hasNewInvitation}
                                  >
                                    <Space>
                                      <Icon
                                        path={mdiRepeatVariant}
                                        size={0.7}
                                        style={{
                                          verticalAlign: "-15%",
                                        }}
                                      />
                                      Changer d'entreprise
                                    </Space>
                                  </Badge>
                                </Link>
                              ),
                            },
                            {
                              key: "/logout",
                              title: "",
                              label: (
                                <Link onClick={handleLogout} to="#">
                                  <Space>
                                    <Icon
                                      path={mdiArrowRightThinCircleOutline}
                                      size={0.7}
                                      style={{
                                        verticalAlign: "-15%",
                                      }}
                                    />
                                    Déconnexion
                                  </Space>
                                </Link>
                              ),
                            },
                          ],
                        }}
                      >
                        <Avatar style={{ backgroundColor: "#1E3758" }}>
                          {me?.company?.name?.substring(0, 3)}
                        </Avatar>
                      </Dropdown>
                    </Badge>
                  ),
                },
              ]}
            />
          </div>
        </div>
      </Sider>
      <Layout
        className="site-layout"
        style={{ marginLeft: 80, minHeight: "100vh" }}
      >
        <Content className="ContentComponent">
          <div
            style={{
              marginTop: 10,
              marginRight: 10,
              marginLeft: 10,
              marginBottom: -10,
            }}
          >
            {error && <Alert type="error" showIcon message={error} />}
            {isMultiCompany && (
              <Alert
                type="info"
                showIcon
                message={
                  <>
                    Le plan de trésorerie consolidé est en version beta.{" "}
                    <Button type="link" onClick={handleChat}>
                      Donnez-nous votre avis sur le chat
                    </Button>
                  </>
                }
              />
            )}
            {isDemoCompany && (
              <Alert
                type="warning"
                showIcon
                message={
                  <Space>
                    Vous êtes actuellement sur une version de démonstration.
                    <Button type="primary" onClick={handleConvertToFreeVersion}>
                      Convertir en version gratuite
                    </Button>
                  </Space>
                }
              />
            )}
            {isMigrating && (
              <Alert
                type="error"
                showIcon
                message={
                  <>
                    Vos données ont bien été migrées. Veuillez synchroniser vos
                    banques puis prévenir le support sur le chat en{" "}
                    <Link onClick={handleChat} to="#">
                      cliquant ici
                    </Link>
                    . Nous ferons la jonction entre vos données synchronisées et
                    vos données migrées.
                  </>
                }
              />
            )}
            {(isOnTrial || isOnFreePlan) &&
              !isMultiCompany &&
              me?.company &&
              location?.pathname !== "/billing" && (
                <Alert
                  message={
                    <Space>
                      {isOnTrial
                        ? `${getMessageTrialRemainingDays(me?.company)}.`
                        : "Vous êtes en version gratuite et disposez d'un accès limité."}
                      {isAdmin && (
                        <>
                          <Button
                            type="primary"
                            onClick={() => navigate("/billing")}
                          >
                            Choisir un abonnement
                          </Button>
                        </>
                      )}
                    </Space>
                  }
                  type="warning"
                  showIcon
                />
              )}
            {hasPaymentFailed && location?.pathname !== "/billing" && (
              <Alert
                message={
                  <Space>
                    Votre dernier paiement a échoué, votre compte sera bientôt
                    désactivé sans action de votre part.
                    <Button type="primary" onClick={() => navigate("/billing")}>
                      Mettre à jour mon moyen de paiement
                    </Button>
                  </Space>
                }
                type="warning"
                showIcon
              />
            )}
            {bankActionRequired &&
              !isMultiCompany &&
              location?.pathname !== "/billing" &&
              location?.pathname !== "/banks" && (
                <Alert
                  message={
                    <>
                      Action requise, veuillez rafraîchir{" "}
                      <Link to="/banks">la synchronisation bancaire.</Link>
                    </>
                  }
                  type="warning"
                  showIcon
                />
              )}
            {integrationActionRequired &&
              !isMultiCompany &&
              location?.pathname !== "/billing" &&
              location?.pathname !== "/integrations" && (
                <Alert
                  message={
                    <>
                      Action requise, veuillez reconnecter une de vos{" "}
                      <Link to="/integrations">intégrations.</Link>
                    </>
                  }
                  type="warning"
                  showIcon
                />
              )}
          </div>
          {title && (
            <div className="ContentHeader">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Title level={4}>{title}</Title>
                </Breadcrumb.Item>
              </Breadcrumb>
              <div style={{ flexGrow: 1 }}></div>
              {action}
            </div>
          )}
          <div className="ContentBody">{children}</div>
        </Content>
        <Footer style={{ textAlign: "center" }}></Footer>
      </Layout>
    </Layout>
  );
};

export default GeneralLayout;
