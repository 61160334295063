import { PlusSquareOutlined } from "@ant-design/icons";
import { Button, Dropdown, List, MenuProps, Space, Tooltip } from "antd";
import BankItem from "components/BankItem";
import { AuthContext } from "contexts";
import { ErrorContext } from "contexts/error";
import { FC, useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import accountService from "services/accountService";
import bridgeapiService from "services/bridgeapiService";
import companyService from "services/companyService";
import dashboardService from "services/dashboardService";
import itemService from "services/itemService";
import transactionService from "services/transactionService";
import useSWR, { mutate } from "swr";
import { ImportTransactions, Item } from "types";
import errorHandler from "utils/errorHandler";

interface Props {
  isRegister: boolean;
}

const BankConnector: FC<Props> = ({ isRegister }) => {
  const [interval, setInterval] = useState(0);
  const { data: items } = useSWR("itemService.getAll", itemService.getAll, {
    refreshInterval: interval,
  });
  const { me, setMe } = useContext(AuthContext);
  const { setError } = useContext(ErrorContext);

  const [listLoading, setListLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [nextLoading, setNextLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (items?.some((item) => item.toRefresh)) {
      setInterval(5000);
    } else if (interval === 5000) {
      setInterval(0);
      mutate("transactionService.getAllUncategorizedCount");
      mutate("transactionService.getAllPrecategorizedCount");
    }
  }, [items, interval]);

  useEffect(() => {
    const timer = setTimeout(
      () => window.$crisp.push(["do", "trigger:run", ["your-banks-page"]]),
      45000
    );
    return () => clearTimeout(timer);
  }, []);

  const handleAddManualBank = async () => {
    setButtonLoading(true);

    await itemService.create();

    mutate("itemService.getAll");
    setButtonLoading(false);
  };

  const handleMenuClick = (e: any) => {
    if (e.key === "add") {
      handleAddItem();
    }
    if (e.key === "add-manual") {
      handleAddManualBank();
    }
  };

  const menuItems: MenuProps["items"] = [
    {
      icon: <PlusSquareOutlined />,
      key: "add",
      label: "Ajouter une banque",
    },
    {
      icon: <PlusSquareOutlined />,
      key: "add-manual",
      label: "Ajouter une banque manuelle",
    },
  ];
  const menu = { items: menuItems, onClick: handleMenuClick };

  const handleAddItem = async () => {
    setButtonLoading(true);
    return bridgeapiService
      .getAddItemUrl()
      .then((res) => {
        window.location.href = res.data.redirect_url;
      })
      .catch(errorHandler(setError));
  };

  const setActive = async (
    itemId: number,
    accountId: number,
    checked: boolean
  ) => {
    if (!items) {
      return;
    }

    mutate(
      "itemService.getAll",
      (items: Item[]) => {
        const newItems = items.map((item) =>
          item.id !== itemId
            ? item
            : {
                ...item,
                accounts: item.accounts.map((account) =>
                  account.id !== accountId
                    ? account
                    : {
                        ...account,
                        active: checked,
                        disabled: true,
                      }
                ),
              }
        );

        return newItems;
      },
      false
    );

    await accountService.update(accountId, { active: checked });

    setError(undefined);
    mutate("itemService.getAll");
    mutate("transactionService.getAllUncategorizedCount");
    mutate("transactionService.getAllPrecategorizedCount");
  };

  const deleteItem = async (itemId: number) => {
    if (!items) {
      return;
    }

    mutate(
      "itemService.getAll",
      (items: Item[]) => {
        const newItems = [...items];
        const index = newItems.findIndex((item) => item.id === itemId);
        newItems.splice(index, 1);

        return newItems;
      },
      false
    );

    await itemService.remove(itemId);

    mutate("itemService.getAll");
    mutate("transactionService.getAllUncategorizedCount");
    mutate("transactionService.getAllPrecategorizedCount");
  };

  const refreshItem = async (itemId: number) => {
    if (!items) {
      return;
    }

    mutate(
      "itemService.getAll",
      (items: Item[]) => {
        const newItems = [...items];
        const index = newItems.findIndex((item) => item.id === itemId);
        newItems.splice(index, 1, {
          ...newItems[index],
          toRefresh: true,
        });

        return newItems;
      },
      false
    );

    await itemService.update(itemId, { toRefresh: true });

    mutate("itemService.getAll");
  };

  const retryConnection = (itemId: number) => {
    setListLoading(true);
    return bridgeapiService
      .getEditItemUrl(itemId)
      .then((res) => {
        window.location.href = res.data.redirect_url;
        setListLoading(false);
      })
      .catch(errorHandler(setError));
  };

  const sca = (itemId: number) => {
    setListLoading(true);
    return bridgeapiService
      .getSCAUrl(itemId)
      .then((res) => {
        window.location.href = res.data.redirect_url;
        setListLoading(false);
      })
      .catch(errorHandler(setError));
  };

  const proValidation = async () => {
    setListLoading(true);
    return bridgeapiService
      .getProValidationUrl()
      .then((res) => {
        window.location.href = res.data.redirect_url;
      })
      .catch(errorHandler(setError));
  };

  const goToNextStep = () => {
    if (!me || !me.company) {
      return;
    }

    setNextLoading(true);
    if (me.company.isOnboarding) {
      if (process.env.NODE_ENV !== "development") {
        window.$crisp.push(["do", "trigger:run", ["has-onboarded"]]);
      }
      companyService
        .update(me.company.id, { isOnboarding: false })
        .then((newCompany) => {
          const newMe = { ...me };
          newMe.company = newCompany;
          setMe(newMe);
          setNextLoading(false);
        })
        .then(() => navigate("/register/finish"))
        .catch(errorHandler(setError));
    } else {
      navigate("/register/finish");
    }
  };

  const generateDemoData = () => {
    setNextLoading(true);

    dashboardService.generateDemoData().then(() => {
      mutate("itemService.getAll");
      mutate("transactionService.getAllUncategorizedCount");
      mutate("transactionService.getAllPrecategorizedCount");
      setNextLoading(false);
    });
  };

  const updateAccount = (
    accountId: number,
    {
      balance,
      customName,
      hide,
    }: { balance: number; customName: string; hide: boolean }
  ) => {
    return accountService
      .update(accountId, { balance, customName, hide })
      .then(() => {
        mutate("itemService.getAll");
      });
  };

  const importTransactions = async (values: ImportTransactions) => {
    transactionService.createMany(values);
  };

  const loaded = !!items;

  const isRefreshing = Boolean(items?.some((item) => item.toRefresh));

  return (
    <div className="BankConnector">
      <List
        locale={{ emptyText: "Aucune banque connectée" }}
        loading={!loaded || listLoading}
        itemLayout="vertical"
        dataSource={items}
        header={<h1>Liste de vos comptes bancaires</h1>}
        renderItem={(item) => (
          <BankItem
            item={item}
            deleteItem={deleteItem}
            setActive={setActive}
            sca={sca}
            proValidation={proValidation}
            retryConnection={retryConnection}
            isRegister={isRegister}
            isDemoCompany={!!me?.company?.isDemoCompany}
            importTransactions={importTransactions}
            updateAccount={updateAccount}
            refreshItem={refreshItem}
            isRefreshing={isRefreshing}
          />
        )}
      />
      <Space
        style={{
          display: "flex",
          justifyContent: "space-around",
          marginTop: 30,
        }}
      >
        {!me?.company?.isDemoCompany && (
          <>
            {isRegister ? (
              <Button
                type="default"
                className="FormButton"
                onClick={handleAddItem}
                loading={buttonLoading}
              >
                Ajouter une banque
              </Button>
            ) : (
              <>
                {me?.company.numberOfBanks !== undefined &&
                items !== undefined &&
                items.filter(
                  (item) => !item.isMigrationData && !item.isManualData
                ).length >= me.company.numberOfBanks ? (
                  <Space>
                    <Tooltip
                      placement="topLeft"
                      title={
                        <>
                          Vous devez modifier votre abonnement pour ajouter une
                          banque supplémentaire.
                          <br />
                          <Link to="/billing">
                            Cliquez pour accéder à la page de gestion des
                            abonnements.
                          </Link>
                        </>
                      }
                    >
                      <Button
                        type="primary"
                        className="FormButton"
                        disabled={true}
                      >
                        Ajouter une banque
                      </Button>
                    </Tooltip>
                    <Button
                      type="primary"
                      className="FormButton"
                      onClick={handleAddManualBank}
                      loading={buttonLoading}
                    >
                      Ajouter une banque manuelle
                    </Button>
                  </Space>
                ) : (
                  <Dropdown.Button
                    type="primary"
                    className="FormButton"
                    onClick={handleAddItem}
                    loading={buttonLoading}
                    menu={menu}
                  >
                    Ajouter une banque
                  </Dropdown.Button>
                )}
              </>
            )}
          </>
        )}
        {isRegister &&
          (me &&
          items &&
          ((!me?.company?.migrationAsked && items?.length >= 1) ||
            (me?.company?.migrationAsked && items?.length >= 2)) ? (
            <Button
              type="primary"
              className="FormButton"
              onClick={goToNextStep}
              loading={nextLoading}
            >
              Étape suivante
            </Button>
          ) : (
            <Button
              type="primary"
              className="FormButton"
              onClick={generateDemoData}
              loading={nextLoading}
            >
              Générer données de démo
            </Button>
          ))}
      </Space>
    </div>
  );
};

export default BankConnector;
