import { Button, Checkbox, Form, Input } from "antd";
import { Store } from "antd/es/form/interface";
import Loader from "components/Loader";
import { AuthContext } from "contexts";
import { FC, ReactElement, useContext, useState } from "react";
import userService from "services/userService";
import errorHandler from "utils/errorHandler";

interface Props {
  setError: (errorMessage: ReactElement | undefined) => void;
}

export const ProfileForm: FC<Props> = ({ setError }) => {
  const { me, setMe } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 8 },
  };
  const formTailLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 8, offset: 4 },
  };

  const onFinish = (values: Store) => {
    if (!me) {
      return;
    }
    setLoading(true);
    userService
      .update(me.id, values)
      .then((me) => {
        setMe(me);
        setLoading(false);
      })
      .catch(errorHandler(setError));
  };

  return (
    <div style={{ marginTop: 15 }}>
      {!me ? (
        <Loader />
      ) : (
        <Form
          name="update-user"
          {...formItemLayout}
          onFinish={onFinish}
          requiredMark={false}
          initialValues={{
            firstName: me?.firstName,
            lastName: me?.lastName,
            email: me?.email,
            weeklyAccountStatement: me?.weeklyAccountStatement,
            monthlyReport: me?.monthlyReport,
          }}
        >
          <Form.Item label="Email">
            <span className="ant-form-text">{me?.email}</span>
          </Form.Item>
          <Form.Item
            label="Prénom"
            name="firstName"
            rules={[
              {
                required: true,
                message: "Requis",
              },
            ]}
          >
            <Input autoComplete="given-name" placeholder="Prénom" />
          </Form.Item>
          <Form.Item
            label="Nom de famille"
            name="lastName"
            rules={[
              {
                required: true,
                message: "Requis",
              },
            ]}
          >
            <Input autoComplete="family-name" placeholder="Nom de famille" />
          </Form.Item>
          <Form.Item
            {...formTailLayout}
            name="weeklyAccountStatement"
            valuePropName="checked"
          >
            <Checkbox>
              Recevoir les relevés bancaires hebdomadaires par email
            </Checkbox>
          </Form.Item>
          <Form.Item
            {...formTailLayout}
            name="monthlyReport"
            valuePropName="checked"
          >
            <Checkbox>Recevoir les rapports mensuels par email</Checkbox>
          </Form.Item>
          {/* <Form.Item {...formTailLayout} name="transfersAlertStop">
            <Checkbox>Alertes de virements par mail</Checkbox>
          </Form.Item> */}
          <Form.Item {...formTailLayout}>
            <Button type="primary" htmlType="submit" block loading={loading}>
              Enregistrer
            </Button>
          </Form.Item>
          {/* <Button onClick={handleDelete}>DELETE</Button> */}
        </Form>
      )}
    </div>
  );
};
