import { CheckCircleOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Divider,
  Modal,
  Popover,
  Select,
  Space,
  Steps,
  Typography,
  Upload,
  notification,
} from "antd";
import { TransactionsImportModalParams } from "components/BankItem";
import { addHours } from "date-fns/esm";
import dayjs from "dayjs";
import Papa from "papaparse";
import { FC, useState } from "react";
import categoryService from "services/categoryService";
import importTransactionsMappingService from "services/importTransactionsMappingService";
import useSWR, { mutate } from "swr";
import { ImportTransactionsMapping } from "types";
import { applicationName } from "utils/constants";
import { CurrencyCode } from "utils/currency";
import { findTree, hasChildren } from "utils/tree";
import { ReadOnlyTransactionsTable } from "./ReadOnlyTransactionsTable";

const { Text } = Typography;
const fieldHash: ImportTransactionsMapping = {
  date: "Date (obligatoire)",
  description: "Libellé (obligatoire)",
  amount: "Montant TTC (obligatoire)",
  category: "Catégorie",
  type: "Type",
  memo: "Memo",
  externalId: "Identifiants des factures",
};
const requiredFields = ["date", "description", "amount"];

interface Props {
  importTransactions: (values: any) => Promise<void>;
  transactionsImportModalParams: TransactionsImportModalParams;
  setTransactionsImportModalParams: any;
}

const TransactionAddManyModal: FC<Props> = ({
  importTransactions,
  transactionsImportModalParams,
  setTransactionsImportModalParams,
}) => {
  const [loading, setLoading] = useState(false);
  const [uploadedTransactions, setUploadedTransactions] = useState<any[]>([]);
  const [transactions, setTransactions] = useState<any[]>([]);
  const [errorTitle, setErrorTitle] = useState("");
  const [error, setError] = useState("");
  const [csvFileName, setCsvFileName] = useState("");
  const { data: categories } = useSWR(
    "categoryService.getAllWithoutDefault",
    categoryService.getAllWithoutDefault
  );
  const [step, setStep] = useState(0);
  const [userNewMapping, setUserNewMapping] =
    useState<ImportTransactionsMapping>({});
  const [isNewMapping, setIsNewMapping] = useState(false);

  const { data: importTransactionsMappings } = useSWR(
    "importTransactionsMappingService.getAll",
    importTransactionsMappingService.getAll,
    {}
  );

  const openNotification = () => {
    notification.open({
      message: "Transactions bancaires importées avec succès",
      description: `Le fichier ${csvFileName} a été correctement importé`,
      icon: <CheckCircleOutlined style={{ color: "green" }} />,
    });
  };

  const handleOkModal = async () => {
    if (!importTransactionsMappings) {
      return;
    }
    setLoading(true);

    await importTransactions({
      transactions,
      fileName: csvFileName,
      accountId: transactionsImportModalParams.accountId,
    });

    if (isNewMapping) {
      if (importTransactionsMappings.length === 0) {
        await importTransactionsMappingService.create(userNewMapping);
        await mutate("importTransactionsMappingService.getAll");
      } else if (importTransactionsMappings.length >= 0) {
        await importTransactionsMappingService.update(
          // @ts-ignore
          importTransactionsMappings[0].id,
          userNewMapping
        );
        await mutate("importTransactionsMappingService.getAll");
      }
    }
    openNotification();
    setLoading(false);
    setTransactionsImportModalParams({ open: false, accountId: undefined });
    setError("");
    setErrorTitle("");
    setTransactions([]);
    setUserNewMapping({});
    setIsNewMapping(false);
    setStep(0);
  };

  const checkAndSetUserMapping = (userMappings: string[]) => {
    userMappings.forEach((userMapping) => {
      const transformedField = userMapping.trim().toLowerCase();
      if (
        importTransactionsMappings
          ?.map((item) => item.date)
          .includes(userMapping) ||
        transformedField === "date" ||
        transformedField.match(/date/)
      ) {
        setUserNewMapping((userNewMapping) =>
          userNewMapping
            ? {
                ...userNewMapping,
                date: userMapping,
              }
            : { date: userMapping }
        );
      } else if (
        importTransactionsMappings
          ?.map((item) => item.description)
          .includes(userMapping) ||
        transformedField === "libelle" ||
        transformedField === "libellé"
      ) {
        setUserNewMapping((userNewMapping) =>
          userNewMapping
            ? {
                ...userNewMapping,
                description: userMapping,
              }
            : { description: userMapping }
        );
      } else if (
        importTransactionsMappings
          ?.map((item) => item.amount)
          .includes(userMapping) ||
        transformedField === "montant ttc" ||
        transformedField.match(/ttc/)
      ) {
        setUserNewMapping((userNewMapping) =>
          userNewMapping
            ? {
                ...userNewMapping,
                amount: userMapping,
              }
            : { amount: userMapping }
        );
      } else if (
        importTransactionsMappings
          ?.map((item) => item.type)
          .includes(userMapping) ||
        transformedField === "type"
      ) {
        setUserNewMapping((userNewMapping) =>
          userNewMapping
            ? {
                ...userNewMapping,
                type: userMapping,
              }
            : { type: userMapping }
        );
      } else if (
        importTransactionsMappings
          ?.map((item) => item.category)
          .includes(userMapping) ||
        transformedField === "categorie"
      ) {
        setUserNewMapping((userNewMapping) =>
          userNewMapping
            ? {
                ...userNewMapping,
                category: userMapping,
              }
            : { category: userMapping }
        );
      } else if (
        importTransactionsMappings
          ?.map((item) => item.memo)
          .includes(userMapping) ||
        transformedField === "memo"
      ) {
        setUserNewMapping((userNewMapping) =>
          userNewMapping
            ? {
                ...userNewMapping,
                memo: userMapping,
              }
            : { memo: userMapping }
        );
      } else if (
        importTransactionsMappings
          ?.map((item) => item.externalId)
          .includes(userMapping) ||
        transformedField === "id"
      ) {
        setUserNewMapping((userNewMapping) =>
          userNewMapping
            ? {
                ...userNewMapping,
                externalId: userMapping,
              }
            : { externalId: userMapping }
        );
      } else {
        setIsNewMapping(true);
      }
    });
  };

  const handleValidateModal = async () => {
    if (!userNewMapping) {
      return;
    }

    const newTransactions = uploadedTransactions
      .map((transaction) => ({
        date: transaction[userNewMapping.date as string],
        description: transaction[userNewMapping.description as string],
        amount: transaction[userNewMapping.amount as string],
        category: transaction[userNewMapping.category as string],
        type: transaction[userNewMapping.type as string],
        memo: transaction[userNewMapping.memo as string],
        externalId: transaction[userNewMapping.externalId as string],
      }))
      .map((transaction, index) => {
        const date = transaction.date
          ? dayjs(
              transaction.date.trim(),
              ["DD/MM/YYYY", "DD-MM-YYYY"],
              true
            ).toDate()
          : new Date();

        let sign = 1;
        if (
          transaction.type === "decaissement" ||
          transaction.type === "décaissement"
        ) {
          sign = -1;
        }

        const amount = transaction.amount
          ? sign *
            parseFloat(transaction.amount.replace(",", ".").replace(/ /g, ""))
          : 0;

        const description = transaction.description.substring(0, 255);

        const note = transaction.memo?.substring(0, 255);

        const externalId = transaction.externalId;

        const categoryEntity = findTree({
          categories,
          categoryId: transaction.category,
          field: "name",
        });
        const categoryId =
          categoryEntity && !hasChildren(categoryEntity)
            ? categoryEntity.id
            : undefined;

        // eslint-disable-next-line no-self-compare
        if (date.getTime() !== date.getTime()) {
          setErrorTitle(
            `La date d'émission à la ligne ${index + 1} est invalide: ${
              transaction.date
            }`
          );
          setError(
            "Le format requis est DD/MM/YYYY, ex: 06 Août 1990 => 06/08/1992"
          );
        } else if (isNaN(amount)) {
          setErrorTitle(
            `Le montant à la ligne ${index + 1} est invalide: ${
              transaction.amount
            }`
          );
          setError("Exemple de nombre bien formaté: 100.01");
        } else if (!description) {
          setErrorTitle(`Le libellé à la ligne ${index + 1} est vide.`);
          setError("Toutes les transactions doivent avoir un libellé.");
        }
        return {
          date: addHours(date, 2),
          raw_description: description,
          note,
          externalId,
          amount,
          key: index,
          category: categoryId,
        };
      });
    setTransactions(newTransactions);
    setStep(2);
  };

  const handleCancelModal = () => {
    setTransactionsImportModalParams({ open: false, accountId: undefined });
    setTransactions([]);
    setUserNewMapping({});
    setError("");
    setErrorTitle("");
    setIsNewMapping(false);
    setStep(0);
  };

  const fileHandler = (file: File) => {
    setErrorTitle("");
    setError("");
    setCsvFileName(file.name);
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: ({ data, meta: { fields } }) => {
        setUploadedTransactions(data);
        if (file.name.split(".").pop() !== "csv") {
          setErrorTitle("Erreur de format");
          setError(
            "Le fichier que vous avez sélectionné ne se termine pas par '.csv'."
          );
        } else if (!fields || fields.length < 5) {
          setErrorTitle("Erreur de format");
          setError("Votre fichier doit contenir au minimum 4 colonnes.");
        } else {
          checkAndSetUserMapping(fields);
          setStep(1);
        }
      },
    });
    return false;
  };

  function handleChange(
    mappingValue: string | number,
    userMapping: keyof ImportTransactionsMapping
  ): void {
    setUserNewMapping((userNewMapping) =>
      userNewMapping
        ? {
            ...userNewMapping,
            [userMapping]: mappingValue,
          }
        : { [userMapping]: mappingValue }
    );
  }

  const handleDownloadExample = () => {
    const url = window.URL.createObjectURL(
      new Blob([
        `Date;Libelle;Montant TTC;Type;Categorie\n01/04/2021;Facture Exemple 1;100,01;encaissement;Catégorie n°1\n30/04/2021;Facture Exemple 2;120,01;decaissement;Catégorie n°2\n01/04/2021;Facture Exemple 3;100,01;encaissement;Catégorie n°1`,
      ])
    );
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `${applicationName} - Import de Transactions - Exemple.csv`
    );
    document.body.appendChild(link);
    link.click();
  };

  return (
    <Modal
      title="Importer des transactions bancaires"
      open={transactionsImportModalParams.open}
      onOk={handleOkModal}
      onCancel={handleCancelModal}
      forceRender
      destroyOnClose
      width="80%"
      footer={[
        <Button key="back" onClick={handleCancelModal}>
          Annuler
        </Button>,
        <>
          {step === 1 &&
            !error &&
            (requiredFields.every((field) => {
              return Object.keys(userNewMapping).includes(field);
            }) ? (
              <Button
                key="submit"
                type="primary"
                loading={loading}
                onClick={handleValidateModal}
              >
                Étape suivante
              </Button>
            ) : (
              <Popover
                content={
                  <>
                    <p>Date</p>
                    <p>Libellé</p>
                    <p>Montant TTC</p>
                  </>
                }
                title={"Les champs suivants sont requis: "}
              >
                <Button
                  key="submit"
                  type="primary"
                  loading={loading}
                  onClick={handleValidateModal}
                  disabled={true}
                >
                  Étape suivante
                </Button>
              </Popover>
            ))}
          {step === 2 && (
            <Button
              key="submit"
              type="primary"
              loading={loading}
              onClick={handleOkModal}
              disabled={error.length > 0}
            >
              Importer
            </Button>
          )}
          -
        </>,
      ]}
    >
      <Steps
        progressDot
        current={step}
        items={[
          { title: "Importation" },
          { title: "Vérification" },
          { title: "Finalisation" },
        ]}
      />
      <Space align="center" direction="vertical">
        {step === 0 && (
          <>
            <Alert
              message={
                errorTitle ||
                "Le fichier importé doit comporter un format et des en-têtes spécifiques"
              }
              description={error}
              type={error ? "error" : "warning"}
              action={
                <Space>
                  <Button onClick={handleDownloadExample} size="small">
                    Fichier exemple
                  </Button>
                </Space>
              }
            />
            <div
              style={{
                textAlign: "center",
                fontSize: "1.5em",
                marginBottom: 20,
              }}
            >
              <Upload
                name="file"
                accept="text/csv"
                maxCount={1}
                beforeUpload={fileHandler}
              >
                <Button>
                  <UploadOutlined /> Sélectionnez un fichier CSV à importer
                </Button>
              </Upload>
            </div>
            <ReadOnlyTransactionsTable
              transactions={[]}
              categories={categories || []}
            />
          </>
        )}
      </Space>
      {step === 1 && (
        <>
          <div style={{ marginBottom: 20 }}>
            <div
              style={{
                textAlign: "center",
                fontSize: "1.5em",
                marginBottom: 20,
              }}
            >
              <Text mark strong>
                Veuillez valider la correspondance de vos champs
              </Text>
            </div>
            <div style={{ display: "flex" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "50%",
                }}
              >
                <Typography.Text strong>Fichier source</Typography.Text>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "50%",
                }}
              >
                <Typography.Text strong>Correspondance</Typography.Text>
              </div>
            </div>
            {Object.keys(fieldHash).map((fieldName) => (
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "50%",
                  }}
                >
                  <Button
                    style={{ width: "40%", margin: "1%", display: "flex" }}
                  >
                    {
                      // @ts-ignore
                      fieldHash[fieldName]
                    }
                  </Button>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "50%",
                  }}
                >
                  <Select
                    defaultValue={
                      userNewMapping[
                        fieldName as keyof ImportTransactionsMapping
                      ]
                    }
                    style={{
                      width: "40%",
                      display: "flex",
                      margin: "1%",
                    }}
                    // @ts-ignore
                    onChange={(event) => handleChange(event, fieldName)}
                  >
                    {Object.keys(uploadedTransactions[0])
                      .filter((key) => {
                        if (!userNewMapping) {
                          return true;
                        }
                        const alreadyUsedColumns =
                          Object.values(userNewMapping);
                        return !alreadyUsedColumns.includes(key);
                      })
                      .map((key) => (
                        <Select.Option value={key} key={key}>
                          {key}
                        </Select.Option>
                      ))}
                  </Select>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
      {step === 2 && (
        <>
          {error && (
            <>
              <Alert message={errorTitle} description={error} type={"error"} />
              <Divider />
            </>
          )}
          <ReadOnlyTransactionsTable
            transactions={transactions.map((transaction) => ({
              ...transaction,
              currency_code: CurrencyCode.EUR,
            }))}
            categories={categories || []}
          />
        </>
      )}
    </Modal>
  );
};

export default TransactionAddManyModal;
