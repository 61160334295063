import * as Sentry from "@sentry/react";
import {
  ConfigProvider as AntdConfigProvider,
  message as antdMessage,
} from "antd";
import frFR from "antd/locale/fr_FR";
import AppRoutes from "components/AppRoutes";
import ErrorBoundary from "components/ErrorBoundary";
import { ErrorProvider } from "contexts/error";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import numeral from "numeral";
import "numeral/locales";
import { IntlProvider } from "react-intl";
import { SWRConfig } from "swr";
import messages_fr from "translations/fr.json";
import { handleError } from "utils/errorHandler";

declare global {
  interface Window {
    // Crisp.chat
    $crisp: any;
    CRISP_TOKEN_ID?: string;
    CRISP_WEBSITE_ID?: string;
    CRISP_READY_TRIGGER: () => void;

    // Segment.com
    analytics: any;
  }
}

antdMessage.config({
  top: 60,
  maxCount: 1,
});

const intlMessages = {
  fr: messages_fr,
};
// language without region code
// const language = navigator.language.split(/[-_]/)[0];
const language = "fr";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  ignoreErrors: ["ResizeObserver loop limit exceeded"],
});

dayjs.locale(language);
numeral.locale(language);

function App() {
  return (
    <AntdConfigProvider
      direction="ltr"
      locale={frFR}
      theme={{
        token: {
          fontFamily: "'Poppins', sans-serif",
          colorPrimary: "#EA6621",
          colorLink: "#EA6621",
          colorLinkHover: "#EA6621",
          colorLinkActive: "#EA6621",
        },
        components: {},
      }}
    >
      <IntlProvider locale={language} messages={intlMessages[language]}>
        <ErrorProvider>
          <SWRConfig
            value={{
              onError: handleError,
            }}
          >
            <ErrorBoundary>
              <div className="App">
                <AppRoutes />
              </div>
            </ErrorBoundary>
          </SWRConfig>
        </ErrorProvider>
      </IntlProvider>
    </AntdConfigProvider>
  );
}

export default App;
