import { CreateRightParams, Right } from "types";
import axios from "utils/setupAxios";

export async function getAll(): Promise<Right[]> {
  return axios.get(`/rights?_sort=id:DESC`).then((res) => res.data);
}

export async function create(values: CreateRightParams): Promise<Right> {
  return axios.post("/rights", values).then((res) => res.data);
}

export async function remove(rightId: string): Promise<Right> {
  return axios.delete(`/rights/${rightId}`).then((res) => res.data);
}

const rightService = {
  getAll,
  create,
  remove,
};
export default rightService;
