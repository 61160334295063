import {
  Alert,
  Button,
  Divider,
  Modal,
  Popover,
  Select,
  Space,
  Steps,
  Typography,
  Upload,
  notification,
} from "antd";
import { addHours } from "date-fns/esm";
import dayjs from "dayjs";
import Papa from "papaparse";
import { FC, useState } from "react";
import categoryService from "services/categoryService";
import importInvoicesMappingService from "services/importInvoicesMappingService";
import useSWR, { mutate } from "swr";
import { ImportInvoicesMapping } from "types";
import { applicationName } from "utils/constants";
import { CurrencyCode } from "utils/currency";
import { KindEnum } from "utils/kind";
import { cleanDescription } from "utils/string";
import { findTree, hasChildren } from "utils/tree";
import { ReadOnlyInvoicesTable } from "./ReadOnlyInvoicesTable";
import Icon from "@mdi/react";
import { mdiCheckCircleOutline, mdiTrayArrowUp } from "@mdi/js";

const { Text } = Typography;
const fieldHash: ImportInvoicesMapping = {
  transactionDate: "Date d'émission (obligatoire)",
  dueDate: "Date d'échéance (obligatoire)",
  description: "Libellé (obligatoire)",
  vat: "TVA",
  amount: "Montant TTC (obligatoire)",
  type: "Type (obligatoire)",
  category: "Catégorie",
  memo: "Mémo",
  externalId: "Identifiants des factures",
};
const requiredFields = [
  "transactionDate",
  "dueDate",
  "description",
  "amount",
  "type",
];

interface Props {
  importInvoices: any;
  open: boolean;
  setOpen: (open: boolean) => void;
}

const InvoiceAddManyModal: FC<Props> = ({ importInvoices, open, setOpen }) => {
  const [loading, setLoading] = useState(false);
  const [uploadedInvoices, setUploadedInvoices] = useState<any[]>([]);
  const [invoices, setInvoices] = useState<any[]>([]);
  const [errorTitle, setErrorTitle] = useState("");
  const [error, setError] = useState("");
  const [csvFileName, setCsvFileName] = useState("");
  const { data: categories } = useSWR(
    "categoryService.getAllWithoutDefault",
    categoryService.getAllWithoutDefault
  );
  const [step, setStep] = useState(0);
  const [userNewMapping, setUserNewMapping] = useState<ImportInvoicesMapping>(
    {}
  );
  const [isNewMapping, setIsNewMapping] = useState(false);

  const { data: importInvoicesMappings } = useSWR(
    "importInvoicesMappingService.getAll",
    importInvoicesMappingService.getAll,
    {}
  );

  const openNotification = () => {
    notification.open({
      message: "Factures engagées importées avec succès",
      description: `Le fichier ${csvFileName} a été correctement importé`,
      icon: (
        <Icon
          path={mdiCheckCircleOutline}
          size={1.2}
          style={{ color: "green" }}
        />
      ),
    });
  };

  const handleOkModal = async () => {
    if (!importInvoicesMappings) {
      return;
    }
    setLoading(true);

    await importInvoices({
      invoices,
      fileName: csvFileName,
    });

    if (isNewMapping) {
      if (importInvoicesMappings.length === 0) {
        await importInvoicesMappingService.create(userNewMapping);
        await mutate("importInvoicesMappingService.getAll");
      } else if (importInvoicesMappings.length >= 0) {
        await importInvoicesMappingService.update(
          // @ts-ignore
          importInvoicesMappings[0].id,
          userNewMapping
        );
        await mutate("importInvoicesMappingService.getAll");
      }
    }
    openNotification();
    setLoading(false);
    setOpen(false);
    setError("");
    setErrorTitle("");
    setInvoices([]);
    setUserNewMapping({});
    setIsNewMapping(false);
    setStep(0);
  };

  const checkAndSetUserMapping = (userMappings: string[]) => {
    userMappings.forEach((userMapping) => {
      const transformedField = userMapping.trim().toLowerCase();
      if (
        importInvoicesMappings
          ?.map((item) => item.transactionDate)
          .includes(userMapping) ||
        transformedField === "date emission" ||
        transformedField.match(/emission/)
      ) {
        setUserNewMapping((userNewMapping) =>
          userNewMapping
            ? {
                ...userNewMapping,
                transactionDate: userMapping,
              }
            : { transactionDate: userMapping }
        );
      } else if (
        importInvoicesMappings
          ?.map((item) => item.dueDate)
          .includes(userMapping) ||
        transformedField === "date echeance" ||
        transformedField.match(/echeance/)
      ) {
        setUserNewMapping((userNewMapping) =>
          userNewMapping
            ? {
                ...userNewMapping,
                dueDate: userMapping,
              }
            : { dueDate: userMapping }
        );
      } else if (
        importInvoicesMappings
          ?.map((item) => item.description)
          .includes(userMapping) ||
        transformedField === "libelle" ||
        transformedField === "libellé"
      ) {
        setUserNewMapping((userNewMapping) =>
          userNewMapping
            ? {
                ...userNewMapping,
                description: userMapping,
              }
            : { description: userMapping }
        );
      } else if (
        importInvoicesMappings?.map((item) => item.vat).includes(userMapping) ||
        transformedField === "tva"
      ) {
        setUserNewMapping((userNewMapping) =>
          userNewMapping
            ? {
                ...userNewMapping,
                vat: userMapping,
              }
            : { vat: userMapping }
        );
      } else if (
        importInvoicesMappings
          ?.map((item) => item.amount)
          .includes(userMapping) ||
        transformedField === "montant ttc" ||
        transformedField.match(/ttc/)
      ) {
        setUserNewMapping((userNewMapping) =>
          userNewMapping
            ? {
                ...userNewMapping,
                amount: userMapping,
              }
            : { amount: userMapping }
        );
      } else if (
        importInvoicesMappings
          ?.map((item) => item.type)
          .includes(userMapping) ||
        transformedField === "type"
      ) {
        setUserNewMapping((userNewMapping) =>
          userNewMapping
            ? {
                ...userNewMapping,
                type: userMapping,
              }
            : { type: userMapping }
        );
      } else if (
        importInvoicesMappings
          ?.map((item) => item.category)
          .includes(userMapping) ||
        transformedField === "categorie"
      ) {
        setUserNewMapping((userNewMapping) =>
          userNewMapping
            ? {
                ...userNewMapping,
                category: userMapping,
              }
            : { category: userMapping }
        );
      } else if (
        importInvoicesMappings
          ?.map((item) => item.memo)
          .includes(userMapping) ||
        transformedField === "memo"
      ) {
        setUserNewMapping((userNewMapping) =>
          userNewMapping
            ? {
                ...userNewMapping,
                memo: userMapping,
              }
            : { memo: userMapping }
        );
      } else if (
        importInvoicesMappings
          ?.map((item) => item.externalId)
          .includes(userMapping) ||
        transformedField === "id"
      ) {
        setUserNewMapping((userNewMapping) =>
          userNewMapping
            ? {
                ...userNewMapping,
                externalId: userMapping,
              }
            : { externalId: userMapping }
        );
      } else {
        setIsNewMapping(true);
      }
    });
  };

  const handleValidateModal = async () => {
    if (!userNewMapping) {
      return;
    }

    const newInvoices = uploadedInvoices
      .map((invoice) => ({
        transactionDate: invoice[userNewMapping.transactionDate as string],
        dueDate: invoice[userNewMapping.dueDate as string],
        description: invoice[userNewMapping.description as string],
        vat: invoice[userNewMapping.vat as string],
        amount: invoice[userNewMapping.amount as string],
        type: invoice[userNewMapping.type as string],
        category: invoice[userNewMapping.category as string],
        memo: invoice[userNewMapping.memo as string],
        externalId: invoice[userNewMapping.externalId as string],
      }))
      .map((invoice, index) => {
        const transactionDate = invoice.transactionDate
          ? dayjs(
              invoice.transactionDate.trim(),
              ["DD/MM/YYYY", "DD-MM-YYYY"],
              true
            ).toDate()
          : new Date();

        const dueDate = dayjs(
          invoice.dueDate?.trim(),
          ["DD/MM/YYYY", "DD-MM-YYYY"],
          true
        ).toDate();

        let kind = "";
        if (invoice.type.match(/encaissement/i)) {
          kind = "cashIn";
        } else if (invoice.type.match(/d[eé]caissement/i)) {
          kind = "cashOut";
        }

        const amount = invoice.amount
          ? parseFloat(invoice.amount.replace(",", ".").replace(/ /g, ""))
          : 0;

        const isCreditNote = amount < 0 && kind;

        const sign = kind === KindEnum.cashIn ? 1 : -1;
        const signedAmount = sign * amount;
        if (!isCreditNote) {
          kind = "";
        }

        const vat = invoice.vat
          ? parseFloat(invoice.vat.replace(",", ".").replace(" ", ""))
          : 0;

        const description = invoice.description.substring(0, 255);

        const note = invoice.memo?.substring(0, 255);

        const externalId = invoice.externalId;

        const categoryEntity = findTree({
          categories,
          categoryId: invoice.category,
          field: "name",
        });
        const categoryId =
          categoryEntity && !hasChildren(categoryEntity)
            ? categoryEntity.id
            : undefined;

        // eslint-disable-next-line no-self-compare
        if (transactionDate.getTime() !== transactionDate.getTime()) {
          setErrorTitle(
            `La date d'émission à la ligne ${index + 1} est invalide: ${
              invoice.transactionDate
            }`
          );
          setError(
            "Le format requis est DD/MM/YYYY, ex: 06 Août 1990 => 06/08/1992"
          );
          // eslint-disable-next-line no-self-compare
        } else if (dueDate.getTime() !== dueDate.getTime()) {
          setErrorTitle(
            `La date d'échéance à la ligne ${index + 1} est invalide: ${
              invoice.dueDate
            }`
          );
          setError(
            "Le format requis est DD/MM/YYYY, ex: 06 Août 1990 => 06/08/1992"
          );
        } else if (isNaN(amount)) {
          setErrorTitle(
            `Le montant à la ligne ${index + 1} est invalide: ${invoice.amount}`
          );
          setError("Exemple de nombre bien formaté: 100.01");
        } else if (!description) {
          setErrorTitle(`Le libellé à la ligne ${index + 1} est vide.`);
          setError("Toutes les factures doivent avoir un libellé.");
        }
        return {
          key: index,
          description,
          note,
          externalId,
          cleanDescription: cleanDescription(description),
          amount: signedAmount,
          currency_code: CurrencyCode.EUR,
          kind,
          vat,
          transactionDate: addHours(transactionDate, 2),
          dueDate: addHours(dueDate, 2),
          category: categoryId,
        };
      });
    setInvoices(newInvoices);
    setStep(2);
  };

  const handleCancelModal = () => {
    setOpen(false);
    setInvoices([]);
    setUserNewMapping({});
    setError("");
    setErrorTitle("");
    setIsNewMapping(false);
    setStep(0);
  };

  const fileHandler = (file: File) => {
    setErrorTitle("");
    setError("");
    setCsvFileName(file.name);
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: ({ data, meta: { fields } }) => {
        setUploadedInvoices(data);
        if (file.name.split(".").pop() !== "csv") {
          setErrorTitle("Erreur de format");
          setError(
            "Le fichier que vous avez sélectionné ne se termine pas par '.csv'."
          );
        } else if (fields) {
          checkAndSetUserMapping(fields);
          setStep(1);
        }
      },
    });
    return false;
  };

  function handleChange(
    mappingValue: string | number,
    userMapping: keyof ImportInvoicesMapping
  ): void {
    setUserNewMapping((userNewMapping) =>
      userNewMapping
        ? {
            ...userNewMapping,
            [userMapping]: mappingValue,
          }
        : { [userMapping]: mappingValue }
    );
  }

  const handleDownloadExample = () => {
    const url = window.URL.createObjectURL(
      new Blob([
        `Date emission;Date echeance;Libelle;TVA;Montant TTC;Type;Categorie;Memo\n01/04/2021;01/05/2021;Facture Exemple 1;20;100,01;encaissement;Catégorie n°1;Mémo n°1\n30/04/2021;01/05/2021;Facture Exemple 2;0;120,01;decaissement;Catégorie n°2;Mémo n°2\n01/04/2021;01/05/2021;Facture Exemple 3;20;100,01;encaissement;Catégorie n°1;Mémo n°3`,
      ])
    );
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `${applicationName} - Import de Factures engagées - Exemple.csv`
    );
    document.body.appendChild(link);
    link.click();
  };

  return (
    <Modal
      title="Importer un fichier CSV"
      open={open}
      onOk={handleOkModal}
      onCancel={handleCancelModal}
      forceRender
      destroyOnClose
      width="80%"
      footer={[
        <Button key="back" onClick={handleCancelModal}>
          Annuler
        </Button>,
        <>
          {step === 1 &&
            !error &&
            (requiredFields.every((field) => {
              return Object.keys(userNewMapping).includes(field);
            }) ? (
              <Button
                key="submit"
                type="primary"
                loading={loading}
                onClick={handleValidateModal}
              >
                Étape suivante
              </Button>
            ) : (
              <Popover
                content={
                  <>
                    <p>Date d'émission</p>
                    <p>Date d'échéance</p>
                    <p>Libellé</p>
                    <p>Montant TTC</p>
                    <p>Type</p>
                  </>
                }
                title={"Les champs suivants sont requis: "}
              >
                <Button
                  key="submit"
                  type="primary"
                  loading={loading}
                  onClick={handleValidateModal}
                  disabled={true}
                >
                  Étape suivante
                </Button>
              </Popover>
            ))}
          {step === 2 && (
            <Button
              key="submit"
              type="primary"
              loading={loading}
              onClick={handleOkModal}
              disabled={error.length > 0}
            >
              Importer
            </Button>
          )}
          -
        </>,
      ]}
    >
      <Steps
        progressDot
        current={step}
        items={[
          { title: "Importation" },
          { title: "Vérification" },
          { title: "Finalisation" },
        ]}
      />
      <Space align="center" direction="vertical">
        {step === 0 && (
          <>
            <Alert
              message={
                errorTitle ||
                "Le fichier importé doit comporter un format et des en-têtes spécifiques"
              }
              description={error}
              type={error ? "error" : "warning"}
              action={
                <Space>
                  <Button onClick={handleDownloadExample} size="small">
                    Fichier exemple
                  </Button>
                </Space>
              }
            />
            <div
              style={{
                textAlign: "center",
                fontSize: "1.5em",
                marginBottom: 20,
              }}
            >
              <Upload
                name="file"
                accept="text/csv"
                maxCount={1}
                beforeUpload={fileHandler}
              >
                <Button>
                  <Icon
                    path={mdiTrayArrowUp}
                    size={0.65}
                    style={{ verticalAlign: "-15%", marginRight: "8px" }}
                  />
                  Sélectionnez un fichier CSV à importer
                </Button>
              </Upload>
            </div>
            <ReadOnlyInvoicesTable
              invoices={invoices}
              categories={categories || []}
            />
          </>
        )}
      </Space>
      {step === 1 && (
        <>
          <div style={{ marginBottom: 20 }}>
            <div
              style={{
                textAlign: "center",
                fontSize: "1.5em",
                marginBottom: 20,
              }}
            >
              <Text mark strong>
                Veuillez valider la correspondance de vos champs
              </Text>
            </div>
            <div style={{ display: "flex" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "50%",
                }}
              >
                <Typography.Text strong>Fichier source</Typography.Text>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "50%",
                }}
              >
                <Typography.Text strong>Correspondance</Typography.Text>
              </div>
            </div>
            {Object.keys(fieldHash).map((fieldName) => (
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "50%",
                  }}
                >
                  <Button
                    style={{ width: "40%", margin: "1%", display: "flex" }}
                  >
                    {
                      // @ts-ignore
                      fieldHash[fieldName]
                    }
                  </Button>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "50%",
                  }}
                >
                  <Select
                    defaultValue={
                      userNewMapping[fieldName as keyof ImportInvoicesMapping]
                    }
                    style={{
                      width: "40%",
                      display: "flex",
                      margin: "1%",
                    }}
                    // @ts-ignore
                    onChange={(event) => handleChange(event, fieldName)}
                  >
                    {Object.keys(uploadedInvoices[0])
                      .filter((key) => {
                        if (!userNewMapping) {
                          return true;
                        }
                        const alreadyUsedColumns =
                          Object.values(userNewMapping);
                        return !alreadyUsedColumns.includes(key);
                      })
                      .map((key) => (
                        <Select.Option value={key} key={key}>
                          {key}
                        </Select.Option>
                      ))}
                  </Select>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
      {step === 2 && (
        <>
          {error && (
            <>
              <Alert message={errorTitle} description={error} type={"error"} />
              <Divider />
            </>
          )}
          <ReadOnlyInvoicesTable
            invoices={invoices}
            categories={categories || []}
          />
        </>
      )}
    </Modal>
  );
};

export default InvoiceAddManyModal;
