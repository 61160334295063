import { differenceInDays, parseISO, startOfDay } from "date-fns";
import { Company } from "types";

const getMessageTrialRemainingDays = (company: Company) => {
  const endDate = startOfDay(parseISO(company.trialEndDate));
  const remainingDays = differenceInDays(endDate, startOfDay(new Date()));
  let messageTrialRemainingDays = "";
  if (remainingDays > 1) {
    messageTrialRemainingDays = `Il vous reste ${remainingDays} jours de période d'essai`;
  } else {
    messageTrialRemainingDays = `C'est votre dernier jour de période d'essai`;
  }
  return messageTrialRemainingDays;
};

export default getMessageTrialRemainingDays;
