import { Statistic, Table } from "antd";
import { parseISO } from "date-fns";
import numeral from "numeral";
import goalService from "services/goalService";
import { Category } from "types";
import { isPastMonth, isPresentMonth } from "utils/date";
import { EditableCell } from "./EditableCell";
import "./EditableCell.css";

export const MainModalTable = ({
  dateString,
  category,
  goal,
  setGoal,
  note,
  setNote,
  sumOfTransactions,
  sumOfInvoices,
  withInvoices,
  scenarioId,
}: {
  dateString: string;
  category?: Category;
  goal: number | null;
  setGoal: (goal: number | null) => void;
  note: string;
  setNote: (note: string) => void;
  sumOfTransactions: number;
  sumOfInvoices: number;
  withInvoices: boolean;
  scenarioId?: number;
}) => {
  const isoDate = parseISO(dateString);
  const isPast = isPastMonth(isoDate);
  const isPresent = isPresentMonth(isoDate);
  const done = sumOfTransactions;
  const awaiting = sumOfInvoices;
  const planned = goal;

  const handleSave = ({ note, planned }: { note: string; planned: number }) => {
    if (category && scenarioId) {
      if (note !== undefined) {
        goalService.create({
          note,
          category: category.id,
          scenario: scenarioId,
          date: dateString,
        });
        setNote(note);
      } else if (planned !== undefined) {
        goalService.create({
          amount: planned,
          category: category.id,
          scenario: scenarioId,
          date: dateString,
        });
        setGoal(planned);
      }
    }
  };

  const columns = [
    {
      title: "Titre",
      dataIndex: "title",
    },
    {
      title: "Données",
      dataIndex: "data",
      render: (value: number | string, record: any) =>
        record.key === "note" ? (
          <div style={{ textAlign: "right" }}>{value || "-"}</div>
        ) : (
          <div style={{ textAlign: "right" }}>
            {value ? `${numeral(value).format("0,0.00", Math.floor)} €` : "-"}
          </div>
        ),
      onCell: (record: any) => ({
        record,
        // editable: col.editable,
        dataIndex: "data",
        handleSave,
      }),
    },
  ];

  let dataSource;
  if (category) {
    dataSource = [
      {
        key: "done",
        title: (
          <>
            Réalisé{" "}
            {planned !== null &&
              planned !== 0 &&
              Boolean(Math.round((done / planned) * 100)) &&
              `(${Math.round((done / planned) * 100)} %)`}
          </>
        ),
        data: done,
      },
      {
        key: "awaiting",
        title: (
          <>
            Engagé{" "}
            {planned !== null &&
              planned !== 0 &&
              Boolean(Math.round((awaiting / planned) * 100)) &&
              `(${Math.round((awaiting / planned) * 100)} %)`}
          </>
        ),
        data: awaiting,
      },
      {
        key: "planned",
        title: <b>Budget</b>,
        data: goal,
      },
      {
        key: "note",
        title: "Note",
        data: note,
      },
    ];

    if (!isPast && !isPresent) {
      dataSource = dataSource.filter((data) => data.key !== "done");
    }
    if (!withInvoices) {
      dataSource = dataSource.filter((data) => data.key !== "awaiting");
    }
    if (isPast) {
      dataSource = dataSource.filter(
        (data) => data.key !== "remaining" && data.key !== "awaiting"
      );
    }
  }

  let statistic = <></>;
  if (category) {
    statistic = goal ? (
      <Statistic
        title="Total du mois / Budget"
        value={sumOfTransactions + sumOfInvoices}
        suffix={` € / ${numeral(goal).format("0,0", Math.floor)}  €`}
        formatter={(value) => numeral(value).format("0,0", Math.floor)}
      />
    ) : (
      <Statistic
        title="Total du mois"
        suffix={` €`}
        value={sumOfTransactions + sumOfInvoices}
        formatter={(value) => numeral(value).format("0,0", Math.floor)}
      />
    );
  }

  const components = {
    body: {
      cell: EditableCell,
    },
  };

  return (
    <div style={{ textAlign: "center", margin: "0 auto", maxWidth: 500 }}>
      {statistic}
      {category && (
        <Table
          components={components}
          // @ts-ignore
          columns={columns}
          rowClassName={() => "editable-row"}
          dataSource={dataSource}
          showHeader={false}
          pagination={false}
        />
      )}
    </div>
  );
};
