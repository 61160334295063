import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Modal, Tabs } from "antd";
import ScenarioCreateForm from "components/ScenarioCreateForm";
import SubscriptionTooltip from "components/SubscriptionTooltip";
import { useEffect, useState } from "react";
import { Scenario } from "types";
import { plans } from "utils/constants";
import "./index.css";

function ScenarioTabs({
  scenarioId,
  scenarios,
  setScenario,
  createScenario,
  deleteScenario,
  canEdit,
  plan,
}: {
  scenarioId: number;
  scenarios: Scenario[];
  setScenario: (scenarioId: number) => Promise<void>;
  createScenario: (values: { name: string; color: string }) => Promise<void>;
  deleteScenario: (scenarioId: number) => Promise<void>;
  canEdit: boolean;
  plan?: string;
}) {
  const [open, setOpen] = useState(false);
  const [activeKey, setActiveKey] = useState(scenarioId.toString());

  useEffect(() => {
    setActiveKey(scenarioId.toString());
  }, [scenarioId]);

  const add = () => {
    setOpen(true);
  };

  const remove = (targetKey: any) => {
    const scenarioId = parseInt(targetKey, 10);
    Modal.confirm({
      okText: "Confirmer",
      cancelText: "Annuler",
      title: "Êtes-vous sûr de vouloir supprimer ce scénario ?",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        return deleteScenario(scenarioId);
      },
    });
  };

  const onEdit = (targetKey: any, action: any) => {
    if (action === "add") {
      add();
    } else if (action === "remove") {
      const scenarioId = parseInt(targetKey, 10);
      remove(scenarioId);

      const defaultScenario = scenarios.find(
        (scenario) => scenario.isDefaultScenario
      );

      if (!defaultScenario) {
        throw new Error("Default scenario not found");
      }

      setActiveKey(defaultScenario.id.toString());
    }
  };

  const onChange = (targetKey: any) => {
    const scenarioId = parseInt(targetKey, 10);
    setScenario(scenarioId);

    setActiveKey(targetKey);
  };

  return (
    <div className="ScenarioTabs">
      <ScenarioCreateForm
        createScenario={createScenario}
        open={open}
        setOpen={setOpen}
      />
      <SubscriptionTooltip plan={plan}>
        <Tabs
          type="editable-card"
          hideAdd={!canEdit}
          tabPosition="top"
          onChange={onChange}
          activeKey={activeKey}
          onEdit={onEdit}
          items={scenarios.map((scenario) => ({
            label: (
              <>
                <span
                  className="dot"
                  style={{
                    color: scenario.color,
                  }}
                >
                  &#x25cf;
                </span>{" "}
                <span>{scenario.name}</span>
              </>
            ),
            key: scenario.id.toString(),
            disabled:
              plan === plans.START_PLAN_ID && scenarioId !== scenario.id,

            closable: canEdit && !scenario.isDefaultScenario,
          }))}
        />
      </SubscriptionTooltip>
    </div>
  );
}

export default ScenarioTabs;
