import { Button, Checkbox, Col, DatePicker, Form, Modal, Row } from "antd";
import { AuthContext } from "contexts";
import dayjs from "dayjs";
import { FC, ReactElement, useContext, useEffect, useState } from "react";
import companyService from "services/companyService";
import dashboardService from "services/dashboardService";
import { applicationName } from "utils/constants";
import { getFormattedFullDate } from "utils/date";
import errorHandler from "utils/errorHandler";
import marketingTools from "utils/marketingTools";

interface Props {
  exportModalVisible: boolean;
  startingMonth: Date;
  currentScenarioId: number;
  handleCancelExportModal: () => void;
  setError: (errorMessage: ReactElement | undefined) => void;
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const rangeConfig = {
  rules: [
    {
      type: "array" as const,
      required: true,
      message: "Veuillez sélectionner une période",
    },
  ],
};
const checkboxConfig = {
  rules: [
    {
      type: "array" as const,
      required: true,
      message: "Veuillez sélectionner au moins un type de donnée",
    },
  ],
};

const ExportModal: FC<Props> = ({
  exportModalVisible,
  startingMonth,
  currentScenarioId,
  handleCancelExportModal,
  setError,
}) => {
  const { me, setMe } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (exportModalVisible === true) {
      const dataTypes = me?.company.isMultiCompany
        ? ["cashPlan", "plannedAndDone"]
        : [
            "cashPlan",
            "plannedAndDone",
            "planned",
            "transactions",
            "invoices",
            "daily",
          ];
      form.setFieldsValue({
        rangePicker: [
          dayjs(startingMonth),
          dayjs(startingMonth).add(11, "month").endOf("month"),
        ],
        dataTypes,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportModalVisible, startingMonth]);

  const handleDownloadXLSX = async () => {
    const values = await form.validateFields();
    setLoading(true);
    const { rangePicker, dataTypes } = values;

    const startingMonthString = rangePicker[0].format("YYYY-MM-DD");
    const endingMonthString = rangePicker[1].format("YYYY-MM-DD");

    dashboardService
      .downloadXLSX({
        startingDate: startingMonthString,
        endingDate: endingMonthString,
        dataTypes,
        scenarioId: currentScenarioId,
      })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${applicationName}- Export de ${
            me?.company.name
          } - ${getFormattedFullDate(new Date())}.xlsx`
        );
        document.body.appendChild(link);
        link.click();

        setLoading(false);
        handleCancelExportModal();
      })
      .catch(errorHandler(setError));

    marketingTools.hasExported();

    if (me?.company && !me?.company.hasExported) {
      companyService
        .update(me.company.id, { hasExported: true })
        .then((newCompany) => {
          const newMe = { ...me };
          newMe.company = newCompany;
          setMe(newMe);
        })
        .catch(errorHandler(setError));
    }
  };

  return (
    <Modal
      title={"Export Excel"}
      style={{ top: 20 }}
      open={exportModalVisible}
      onCancel={handleCancelExportModal}
      footer={[
        <Button key="back" onClick={handleCancelExportModal}>
          Annuler
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handleDownloadXLSX}
          loading={loading}
        >
          Lancer l'export
        </Button>,
      ]}
      width={"80%"}
      destroyOnClose
    >
      <Form
        form={form}
        name="time_related_controls"
        layout="vertical"
        {...formItemLayout}
      >
        <Form.Item
          name="rangePicker"
          label="Période de l'export"
          {...rangeConfig}
        >
          <DatePicker.RangePicker format="DD/MM/YYYY" allowClear={false} />
        </Form.Item>
        <Form.Item
          name="dataTypes"
          label="Choix des données"
          {...checkboxConfig}
        >
          <Checkbox.Group>
            {me?.company.isMultiCompany ? (
              <Row>
                <Col span={24}>
                  <Checkbox value="cashPlan" style={{ lineHeight: "32px" }}>
                    Plan de trésorerie
                  </Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox
                    value="plannedAndDone"
                    style={{ lineHeight: "32px" }}
                  >
                    Comparatif Budget et Réalisé
                  </Checkbox>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col span={12}>
                  <Checkbox value="cashPlan" style={{ lineHeight: "32px" }}>
                    Plan de trésorerie
                  </Checkbox>
                </Col>
                <Col span={12}>
                  <Checkbox
                    value="plannedAndDone"
                    style={{ lineHeight: "32px" }}
                  >
                    Comparatif Budget et Réalisé
                  </Checkbox>
                </Col>
                <Col span={12}>
                  <Checkbox value="planned" style={{ lineHeight: "32px" }}>
                    Prévisionnel saisi
                  </Checkbox>
                </Col>
                <Col span={12}>
                  <Checkbox value="transactions" style={{ lineHeight: "32px" }}>
                    Transactions bancaires
                  </Checkbox>
                </Col>
                {!!me?.company?.isInvoiceEnabled && (
                  <Col span={12}>
                    <Checkbox value="invoices" style={{ lineHeight: "32px" }}>
                      Factures engagées
                    </Checkbox>
                  </Col>
                )}
                <Col span={12}>
                  <Checkbox value="daily" style={{ lineHeight: "32px" }}>
                    Trésorerie jour par jour
                  </Checkbox>
                </Col>
              </Row>
            )}
          </Checkbox.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ExportModal;
