import { Button, Divider, Result, Space, Steps, Typography } from "antd";
import Emoji from "components/Emoji";
import Loader from "components/Loader";
import OnboardingLayout from "components/OnboardingLayout";
import { useNavigate } from "react-router-dom";
import itemService from "services/itemService";
import useSWR from "swr";
import "./index.css";

function OnboardingWaitingSyncPage() {
  const { data: items } = useSWR("itemService.getAll", itemService.getAll, {
    refreshInterval: 5000,
  });
  const navigate = useNavigate();

  const isLoadedAndFinished = items?.every(
    (item) =>
      item.status !== -2 &&
      item.status !== -3 &&
      item.toRefresh === false &&
      item.isRefreshing === false
  );

  return (
    <OnboardingLayout>
      <div className="OnboardingWaitingSyncPage">
        <Steps progressDot current={2}>
          <Steps.Step title="Inscription" />
          <Steps.Step title="Synchronisation" />
          <Steps.Step title="Finalisation" />
        </Steps>
        <Divider />
        <div className="animated">
          {isLoadedAndFinished ? (
            <Result
              style={{ padding: 0 }}
              icon={
                <img
                  style={{
                    height: "auto",
                    maxWidth: 300,
                  }}
                  className="CustomerLogo"
                  alt="museandmarlow"
                  src="/img/urban-lines-477.png"
                />
              }
              title="Synchronisation réussie !"
              subTitle="Vos données bancaires ont été synchronisées avec succès."
              status="success"
              extra={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 60,
                  }}
                >
                  <Button
                    className="FormButton"
                    type="primary"
                    onClick={() => navigate("/")}
                  >
                    <Space>
                      Commencer <Emoji symbol="👋" label="bienvenue" />
                    </Space>
                  </Button>
                </div>
              }
            />
          ) : (
            <div style={{ textAlign: "center" }}>
              <Loader />
              <Typography.Paragraph></Typography.Paragraph>
              <Typography.Paragraph>Un petit moment...</Typography.Paragraph>
              <Typography.Paragraph>
                Nous nous connectons à votre banque.
              </Typography.Paragraph>
              <Typography.Paragraph>
                Ce processus peut prendre plus d'une minute.
              </Typography.Paragraph>
            </div>
          )}
        </div>
      </div>
    </OnboardingLayout>
  );
}

export default OnboardingWaitingSyncPage;
