import { Button } from "antd";
import { useState } from "react";
import stripeService from "services/stripeService";
import { plans } from "utils/constants";

export const CheckoutButton = ({
  planId,
  isYearly,
  numberOfBanks,
}: {
  planId: string;
  isYearly: boolean;
  numberOfBanks: number;
}) => {
  const [loading, setLoading] = useState(false);
  const handleClick = async () => {
    setLoading(true);
    // Call your backend to create the Checkout session.
    const { url } = await stripeService.createCheckoutSessionSubscription({
      planId,
      isYearly,
      numberOfBanks,
    });
    window.location.href = url;
  };

  return (
    <Button
      style={{ margin: "25px 10px 5px" }}
      type={
        planId === plans.FIRST_PLAN_ID ||
        planId === plans.FIRST_MONTHLY_PLAN_ID ||
        planId === plans.RESOLD_PLAN_ID ||
        planId === plans.RESOLD_MONTHLY_PLAN_ID
          ? "primary"
          : "default"
      }
      loading={loading}
      onClick={handleClick}
    >
      Démarrer
    </Button>
  );
};
