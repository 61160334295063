import { Alert } from "antd";
import UnloggedLayout from "components/UnloggedLayout";
import { AuthContext } from "contexts";
import { ReactElement, useContext, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { RegisterForm } from "./RegisterForm";
import "./index.css";

function RegisterPage() {
  const [error, setError] = useState<ReactElement | undefined>(undefined);
  const [searchParams] = useSearchParams();
  const { logIn } = useContext(AuthContext);

  const ref = searchParams.get("ref") || undefined;

  return (
    <UnloggedLayout>
      <div className="form-container">
        <h1>Me connecter</h1>
        {error && <Alert type="error" message={error} />}

        <RegisterForm
          setError={setError}
          logIn={logIn}
          initialValues={{ ref }}
        />
      </div>
    </UnloggedLayout>
  );
}

export default RegisterPage;
