import { Alert, Button, Col, Divider, Row, Steps, Typography } from "antd";
import OnboardingLayout from "components/OnboardingLayout";
import { AuthContext } from "contexts";
import { ErrorContext } from "contexts/error";
import { FC, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import bridgeapiService from "services/bridgeapiService";
import companyService from "services/companyService";
import dashboardService from "services/dashboardService";
import itemService from "services/itemService";
import useSWR, { mutate } from "swr";
import { applicationName } from "utils/constants";
import errorHandler from "utils/errorHandler";
import { ReactComponent as ACPRLogo } from "./acpr-logo.svg";
import "./index.css";

const OnboardingSyncPage: FC = () => {
  const navigate = useNavigate();
  const { me, setMe } = useContext(AuthContext);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [nextLoading, setNextLoading] = useState(false);
  const { setError } = useContext(ErrorContext);
  const { data: items } = useSWR("itemService.getAll", itemService.getAll);

  const addItem = async () => {
    setButtonLoading(true);
    return bridgeapiService
      .getAddItemUrl()
      .then((res) => {
        window.location.href = res.data.redirect_url;
      })
      .catch(errorHandler(setError));
  };

  const generateDemoData = () => {
    setNextLoading(true);

    dashboardService.generateDemoData().then(() => {
      mutate("itemService.getAll");
      mutate("transactionService.getAllUncategorizedCount");
      mutate("transactionService.getAllPrecategorizedCount");
      setNextLoading(false);
    });

    if (!me || !me.company) {
      return;
    }

    if (me.company.isOnboarding) {
      if (process.env.NODE_ENV !== "development") {
        window.$crisp.push(["do", "trigger:run", ["has-onboarded"]]);
      }

      companyService
        .update(me.company.id, { isOnboarding: false })
        .then((newCompany) => {
          const newMe = { ...me };
          newMe.company = newCompany;
          setMe(newMe);
          setNextLoading(false);
        })
        .then(() => navigate("/"))

        .catch(errorHandler(setError));
    } else {
      navigate("/");
    }
  };

  useEffect(() => {
    if (
      me &&
      items &&
      ((!me?.company?.migrationAsked && items?.length >= 1) ||
        (me?.company?.migrationAsked && items?.length >= 2))
    ) {
      navigate("/register/your-banks");
    }
  }, [me, items, navigate]);

  return (
    <OnboardingLayout>
      <div className="OnboardingSyncPage">
        <Steps progressDot current={1}>
          <Steps.Step title="Inscription" />
          <Steps.Step title="Synchronisation" />
          <Steps.Step title="Finalisation" />
        </Steps>
        <Divider />
        <div className="animated">
          <h1>Synchroniser vos données bancaires</h1>
          {me?.company?.migrationAsked && (
            <Alert
              type="warning"
              message="Les données de votre ancien compte ont été importées avec succès par nos équipes."
            />
          )}
          <div style={{ marginTop: 30 }}>
            Liez vos comptes bancaires à votre compte {applicationName}. Vos
            banques nous transmettront en toute sécurité vos transactions pour
            permettre à {applicationName} de suivre automatiquement votre
            trésorerie.
          </div>
          <Row style={{ marginTop: 20 }}>
            <Col span={18}>
              <Typography.Paragraph>
                Synchronisation bancaire encadrée par{" "}
                <Typography.Text strong>
                  l'ACPR - Banque de France
                </Typography.Text>
                :
                <ul>
                  <li>Accès en lecture seule uniquement</li>
                  <li>Annulable en un clic, à tout moment</li>
                </ul>
              </Typography.Paragraph>
            </Col>
            <Col span={6}>
              <ACPRLogo />
            </Col>
          </Row>
          <Row style={{ marginTop: 20 }} gutter={[16, 16]}>
            {me?.company?.isDemoCompany ? (
              <Button
                className="FormButton"
                type="default"
                loading={nextLoading}
                onClick={generateDemoData}
                block
              >
                Générer données de démo
              </Button>
            ) : (
              <>
                <Col xs={24} md={12}>
                  <Button
                    className="FormButton"
                    type="default"
                    loading={nextLoading}
                    onClick={generateDemoData}
                    block
                  >
                    Générer données de démo
                  </Button>
                </Col>

                <Col xs={24} md={12}>
                  <Button
                    className="FormButton"
                    type="primary"
                    loading={buttonLoading}
                    onClick={addItem}
                    block
                  >
                    Synchroniser vos données bancaires
                  </Button>
                </Col>
              </>
            )}
          </Row>
        </div>
      </div>
    </OnboardingLayout>
  );
};

export default OnboardingSyncPage;
