import { Table, Tag } from "antd";
import { ColumnsType } from "antd/es/table";
import { Category, Invoice } from "types";
import { formatTransactionAmount } from "utils/amount";
import { getFormattedFullDate } from "utils/date";
import { findTree } from "utils/tree";

export const ReadOnlyInvoicesTable = ({
  invoices,
  categories,
}: {
  invoices: Invoice[];
  categories: Category[];
}) => {
  const columns: ColumnsType<Invoice> = [
    {
      title: "Date d'émission",
      dataIndex: "transactionDate",
      key: "transactionDate",
      width: 150,
      render: (transactionDate: Date) =>
        isNaN(transactionDate.getDate())
          ? "Date invalide"
          : getFormattedFullDate(transactionDate),
      sorter: (a, b) =>
        new Date(a.transactionDate).getTime() -
        new Date(b.transactionDate).getTime(),
    },
    {
      title: "Date d'échéance",
      dataIndex: "dueDate",
      key: "dueDate",
      width: 150,
      render: (dueDate: Date) =>
        isNaN(dueDate.getDate())
          ? "Date invalide"
          : getFormattedFullDate(dueDate),
      sorter: (a, b) =>
        new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime(),
    },
    {
      title: "Libellé",
      dataIndex: "description",
      key: "description",
      ellipsis: true,
      sorter: (a, b) => a.description.localeCompare(b.description),
    },
    {
      title: "Mémo",
      dataIndex: "note",
      key: "note",
      ellipsis: true,
      sorter: (a, b) => a.note.localeCompare(b.note),
    },
    {
      title: "Statut",
      dataIndex: "dueDate",
      key: "status",
      width: 100,
      render: (_: any, invoice: Invoice) => {
        if (invoice.paid) {
          return <Tag color="green">Payée</Tag>;
        } else if (new Date(invoice.dueDate).getTime() > new Date().getTime()) {
          return <Tag color="gold">En attente</Tag>;
        } else {
          return <Tag color="red">En retard</Tag>;
        }
      },
    },
    {
      title: "TVA",
      dataIndex: "vat",
      key: "vat",
      width: 100,
      render: (vat: number) => <Tag>{vat} %</Tag>,
    },
    {
      title: "Catégorie",
      dataIndex: "category",
      key: "category",
      width: "25%",
      sorter: true,
      render: (_: any, invoice: Invoice) => (
        <Tag>
          {findTree({
            categories: categories || [],
            categoryId: invoice.category,
          })?.name || "Pas de catégorie"}
        </Tag>
      ),
    },
    {
      title: "Montant TTC",
      dataIndex: "amount",
      key: "amount",
      width: 160,
      render: (_: any, invoice: Invoice) => formatTransactionAmount(invoice),
      align: "right",
      sorter: (a, b) => a.amount - b.amount,
    },
    {
      title: "Id",
      dataIndex: "externalId",
      key: "externalId",
      width: 160,
      align: "right",
    },
  ];

  return (
    <Table
      locale={{
        emptyText: (
          <div style={{ margin: 20 }}>
            Vous n'avez pas encore sélectionné de fichier
          </div>
        ),
      }}
      columns={columns}
      dataSource={invoices}
      size="small"
      showSorterTooltip={false}
    />
  );
};
