import AnalysisPage from "components/AnalysisPage";
import BankCallbackPage from "components/BankCallbackPage";
import BanksPage from "components/BanksPage";
import BillingPage from "components/BillingPage";
import CashPlanPage from "components/CashPlanPage";
import CategoriesPage from "components/CategoriesPage";
import ChangeCompanyPage from "components/ChangeCompanyPage";
import CompanyPage from "components/CompanyPage";
import ConnectCallbackPage from "components/ConnectCallbackPage";
import ForgotPage from "components/ForgotPage";
import IntegrationCallbackPage from "components/IntegrationCallbackPage";
import { IntegrationsPage } from "components/IntegrationsPage";
import InvoicesPage from "components/InvoicesPage";
import Loader from "components/Loader";
import LoginPage from "components/LoginPage";
import LogoutPage from "components/LogoutPage";
import OnboardingCreateCompanyPage from "components/OnboardingCreateCompanyPage";
import OnboardingCreateMultiCompanyPage from "components/OnboardingCreateMultiCompanyPage";
import OnboardingSyncPage from "components/OnboardingSyncPage";
import OnboardingWaitingSyncPage from "components/OnboardingWaitingSyncPage";
import OnboardingYourBanksPage from "components/OnboardingYourBanksPage";
import ProfilePage from "components/ProfilePage";
import RegisterAffiliatePage from "components/RegisterAffiliatePage";
import RegisterPage from "components/RegisterPage";
import RequireAuth from "components/RequireAuth";
import ResetPage from "components/ResetPage";
import TransactionsPage from "components/TransactionsPage";
import { AuthContext } from "contexts";
import { useContext, useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import userService from "services/userService";
import { rolesEnum } from "utils/constants";
import errorHandler from "utils/errorHandler";
import marketingTools from "utils/marketingTools";

const publicPages = [
  { url: "/login", component: <LoginPage /> },
  { url: "/connect/:provider/callback", component: <ConnectCallbackPage /> },
  { url: "/register", component: <RegisterPage /> },
  { url: "/inscription-avec-coupon", component: <RegisterAffiliatePage /> },
  { url: "/forgot", component: <ForgotPage /> },
  { url: "/reset/:code", component: <ResetPage /> },
];

const privatePages: Array<{
  url: string;
  component: JSX.Element;
  excludedPlans: (string | undefined)[];
  excludedRoles: (number | undefined)[];
}> = [
  {
    url: "/",
    component: <CashPlanPage />,
    excludedPlans: [],
    excludedRoles: [],
  },
  {
    url: "/analysis",
    component: <AnalysisPage />,
    excludedPlans: [],
    excludedRoles: [],
  },
  {
    url: "/transactions",
    component: <TransactionsPage />,
    excludedPlans: [],
    excludedRoles: [rolesEnum["READ"]],
  },
  {
    url: "/invoices",
    component: <InvoicesPage />,
    excludedPlans: [],
    excludedRoles: [rolesEnum["READ"]],
  },
  {
    url: "/categories",
    component: <CategoriesPage />,
    excludedPlans: [],
    excludedRoles: [rolesEnum["READ"]],
  },
  {
    url: "/profile/*",
    component: <ProfilePage />,
    excludedPlans: [],
    excludedRoles: [],
  },
  {
    url: "/company/*",
    component: <CompanyPage />,
    excludedPlans: [],
    excludedRoles: [rolesEnum["READ"]],
  },
  {
    url: "/integrations",
    component: <IntegrationsPage />,
    excludedPlans: [],
    excludedRoles: [rolesEnum["READ"]],
  },
  {
    url: "/banks",
    component: <BanksPage />,
    excludedPlans: [],
    excludedRoles: [rolesEnum["READ"]],
  },
  {
    url: "/bank/callback",
    component: <BankCallbackPage />,
    excludedPlans: [],
    excludedRoles: [rolesEnum["READ"]],
  },
  {
    url: "/billing",
    component: <BillingPage />,
    excludedPlans: [],
    excludedRoles: [rolesEnum["READ"], rolesEnum["READ_WRITE"]],
  },
  {
    url: "/integrations/:provider/callback",
    component: <IntegrationCallbackPage />,
    excludedPlans: [],
    excludedRoles: [],
  },
  {
    url: "/change-company",
    component: <ChangeCompanyPage />,
    excludedPlans: [],
    excludedRoles: [],
  },
  {
    url: "/register/create-company",
    component: <OnboardingCreateCompanyPage />,
    excludedPlans: [],
    excludedRoles: [],
  },
  {
    url: "/register/create-multi-company",
    component: <OnboardingCreateMultiCompanyPage />,
    excludedPlans: [],
    excludedRoles: [],
  },
  {
    url: "/register/sync",
    component: <OnboardingSyncPage />,
    excludedPlans: [],
    excludedRoles: [rolesEnum["READ"]],
  },
  {
    url: "/register/your-banks",
    component: <OnboardingYourBanksPage />,
    excludedPlans: [],
    excludedRoles: [rolesEnum["READ"]],
  },
  {
    url: "/register/finish",
    component: <OnboardingWaitingSyncPage />,
    excludedPlans: [],
    excludedRoles: [rolesEnum["READ"]],
  },
  {
    url: "/logout",
    component: <LogoutPage />,
    excludedPlans: [],
    excludedRoles: [],
  },
];

function usePageViews() {
  const location = useLocation();
  useEffect(() => {
    marketingTools.hasVisited(location.pathname);
  }, [location]);
}

function AppRoutes() {
  const { isAuthenticated, me, setMe } = useContext(AuthContext);
  const [loaded, setLoaded] = useState(false);
  const location = useLocation();
  const isMultiCompany = me?.company?.isMultiCompany;

  usePageViews();

  useEffect(() => {
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = process.env.REACT_APP_CRISP_WEBSITE_ID;
    (function () {
      var d = document;
      var s = d.createElement("script");
      s.src = "https://client.crisp.chat/l.js";
      s.async = true;
      d.getElementsByTagName("head")[0].appendChild(s);
    })();
    if (process.env.NODE_ENV === "production") {
      window.$crisp.push(["safe", true]);
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      userService
        .getMe()
        .then((me) => {
          if (me) {
            setMe(me);

            marketingTools.identifyUser(me);
          }
          setLoaded(true);
        })
        .catch(errorHandler());
    } else {
      setLoaded(true);
    }
  }, [isAuthenticated, setMe]);

  if (!loaded) {
    return <Loader />;
  }

  if (
    isAuthenticated &&
    me &&
    !me?.company &&
    [
      "/register/create-company",
      "/register/create-multi-company",
      "/change-company",
      "/logout",
    ].indexOf(location.pathname) === -1
  ) {
    return <Navigate to="/register/create-company" replace={true} />;
  }
  if (
    isAuthenticated &&
    me &&
    me?.company?.isOnboarding &&
    [
      "/register/sync",
      "/register/your-banks",
      "/bank/callback",
      "/register/create-company",
      "/change-company",
      "/logout",
    ].indexOf(location.pathname) === -1
  ) {
    return <Navigate to="/register/sync" replace={true} />;
  }
  if (
    isAuthenticated &&
    me &&
    me?.company &&
    ["/login", "/register", "/forgot"].indexOf(location.pathname) >= 0
  ) {
    return <Navigate to="/" replace={true} />;
  }

  return (
    <Routes>
      <Route
        index
        element={
          <RequireAuth>
            <CashPlanPage />
          </RequireAuth>
        }
      />
      {privatePages
        .filter(({ url }) => {
          if (!isMultiCompany) {
            return true;
          }
          const multiCompanyUrls = [
            "/",
            "/profile",
            "/company/*",
            "/register/create-company",
            "/register/create-multi-company",
            "/change-company",
          ];
          return multiCompanyUrls.indexOf(url) !== -1;
        })
        .filter(
          ({ excludedRoles, excludedPlans }) =>
            excludedRoles.indexOf(me?.role?.id) === -1 &&
            excludedPlans.indexOf(me?.company?.plan) === -1
        )
        .map(({ url, component }) => (
          <Route
            path={url}
            element={<RequireAuth>{component}</RequireAuth>}
            key={url}
          />
        ))}
      {publicPages.map(({ url, component }) => (
        <Route path={url} element={component} key={url} />
      ))}
      <Route path="*" element={<Navigate to="/" replace={true} />} />
    </Routes>
  );
}

export default AppRoutes;
