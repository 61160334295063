import { mdiRepeatVariant } from "@mdi/js";
import Icon from "@mdi/react";
import {
  Alert,
  Button,
  Checkbox,
  Divider,
  Input,
  Modal,
  PaginationProps,
  Space,
  Table,
  Tag,
  Typography,
} from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { ColumnsType } from "antd/es/table";

import { InvoiceReconciliationModalParams } from "components/InvoicesTable";
import ReconciliationTransactionsTable from "components/ReconciliationTransactionsTable";
import { FC, ReactElement, useEffect, useState } from "react";
import categoryService from "services/categoryService";
import invoiceService from "services/invoiceService";
import transactionService, {
  TransactionQuickFilterType,
} from "services/transactionService";
import useSWR, { mutate } from "swr";
import { Invoice, Transaction } from "types";
import { formatTransactionAmount, sumOfAmounts } from "utils/amount";
import { getFormattedFullDate } from "utils/date";
import errorHandler from "utils/errorHandler";
import { computeQuery } from "utils/strapi";
import { findTree } from "utils/tree";

interface Props {
  reconciliate: any;
  modalParams: InvoiceReconciliationModalParams;
  setModalParams: any;
  validatePrereconciliation: (
    invoice: Invoice,
    isValidated: boolean
  ) => Promise<void>;
}

const InvoiceReconciliationModal: FC<Props> = ({
  reconciliate,
  modalParams,
  setModalParams,
  validatePrereconciliation,
}) => {
  const {
    data: categories,
    // error
  } = useSWR(
    "categoryService.getAllWithoutDefault",
    categoryService.getAllWithoutDefault
  );
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [pagination, setPagination] = useState<PaginationProps>({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [selectedTransaction, setSelectedTransaction] = useState<Transaction>();
  const [error, setError] = useState<ReactElement | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [shouldCreateRemainingInvoice, setShouldCreateRemainingInvoice] =
    useState(true);

  const handleOkModal = async () => {
    const { reconciliatingInvoice } = modalParams;
    if (!reconciliatingInvoice || !selectedTransaction) {
      return;
    }
    setButtonLoading(true);

    if (reconciliatingInvoice.prereconciliated) {
      await invoiceService.update(reconciliatingInvoice.id, { paid: false });
    }
    await reconciliate(
      selectedTransaction.id,
      [reconciliatingInvoice.id],
      shouldCreateRemainingInvoice
    );

    setModalParams({ open: false });
    setSelectedTransaction(undefined);
    setButtonLoading(false);
  };

  const handleCancelModal = () => {
    setModalParams({ open: false });
    setSelectedTransaction(undefined);
  };

  const loadData = (
    quickFilter: TransactionQuickFilterType,
    newPagination?: any,
    filters?: any,
    sorter?: any
  ) => {
    const updatedPagination = {
      ...pagination,
      ...newPagination,
    };
    setPagination(updatedPagination);

    const updatedFilteredInfo = {
      ...filteredInfo,
      ...filters,
    };
    setFilteredInfo(updatedFilteredInfo);

    const updatedSortedInfo = {
      ...sortedInfo,
      ...sorter,
    };
    setSortedInfo(updatedSortedInfo);

    let query = computeQuery({
      pagination: updatedPagination,
      filteredInfo: updatedFilteredInfo,
      sortedInfo: updatedSortedInfo,
      defaultSortColum: "date",
      defaultSortOrder: "DESC",
    });

    if (!reconciliatingInvoice) {
      return;
    }
    if (
      (reconciliatingInvoice.amount >= 0 &&
        reconciliatingInvoice.kind !== "cashOut") ||
      reconciliatingInvoice.kind === "cashIn"
    ) {
      query += "&amount_gt=0";
    } else {
      query += "&amount_lt=0";
    }
    query += `&currency_code=${reconciliatingInvoice.currency_code}`;

    return Promise.all([
      transactionService.getAll({ query, quickFilter }),
      transactionService.getAllCount({ query, quickFilter }),
    ])
      .then(([transactions, transactionsCount]) => {
        setTransactions(transactions);
        setPagination((pagination) => ({
          ...pagination,
          total: transactionsCount,
        }));
        mutate("transactionService.getAllUncategorizedCount");
        // mutate("transactionService.getAllLateCount");
        setLoading(false);
      })
      .catch(errorHandler(setError));
  };

  useEffect(() => {
    loadData("all", { current: 1 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalParams, selectedTransaction]);

  const columnsInvoices: ColumnsType<Invoice> = [
    {
      title: "Date échéance",
      dataIndex: "dueDate",
      key: "dueDate",
      width: "12%",
      render: (date: string) => getFormattedFullDate(new Date(date)),
    },
    {
      title: "Libellé",
      dataIndex: "description",
      key: "description",
      width: "35%",
      ellipsis: true,
      render: (description: string) => (
        <>
          <Tag color="success">À rapprocher</Tag>
          {description}
        </>
      ),
    },
    {
      title: "Statut",
      dataIndex: "dueDate",
      key: "status",
      render: (_: any, invoice: Invoice) => {
        let status = <></>;
        if (new Date(invoice.dueDate).getTime() > new Date().getTime()) {
          status = <Tag color="gold">En attente</Tag>;
        } else {
          status = <Tag color="red">En retard</Tag>;
        }
        return status;
      },
    },
    {
      title: "Catégorie",
      dataIndex: "category",
      key: "category",
      render: (_: any, invoice: Invoice) => (
        <Tag>
          {findTree({
            categories: categories || [],
            categoryId: invoice.category,
          })?.name || "Pas de catégorie"}
        </Tag>
      ),
    },
    {
      title: "Montant TTC",
      dataIndex: "amount",
      key: "amount",
      width: "12%",
      render: (_: any, invoice: Invoice) => formatTransactionAmount(invoice),
      align: "right",
    },
  ];

  const columnsTransactions: ColumnsType<Transaction> = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: "12%",
      render: (date: string) => getFormattedFullDate(new Date(date)),
    },
    {
      title: "Libellé",
      dataIndex: "raw_description",
      key: "raw_description",
      width: "35%",
      ellipsis: true,
    },
    {
      title: "Catégorie",
      dataIndex: "category",
      key: "category",
      render: (_: any, transaction: Transaction) => (
        <Tag>
          {findTree({
            categories: categories || [],
            categoryId: transaction.category,
          })?.name || "Pas de catégorie"}
        </Tag>
      ),
    },
    {
      title: "Montant TTC",
      dataIndex: "amount",
      key: "amount",
      width: "12%",
      render: (_: any, transaction: Transaction) =>
        formatTransactionAmount(transaction),
      align: "right",
    },
  ];

  const changeParams = (pagination: any, filters: any, sorter: any) => {
    loadData("all", pagination, filters, sorter);
  };

  const handleSearch = (value: string) => {
    setSearchText(value);
    if (value) {
      loadData(
        "all",
        { ...pagination, current: 1 },
        {
          description: [value],
        }
      );
    } else {
      loadData(
        "all",
        { ...pagination, current: 1 },
        {
          description: undefined,
        }
      );
    }
  };

  const { open, reconciliatingInvoice } = modalParams;
  if (!reconciliatingInvoice) {
    return <></>;
  }

  const reconciliatingTransactionAmount = Math.abs(
    selectedTransaction?.amount || 0
  );
  const sumOfInvoices = Math.abs(sumOfAmounts([reconciliatingInvoice.amount]));
  const sumOfReconciliatedInvoices = Math.abs(
    sumOfAmounts(
      selectedTransaction?.invoices.map((invoice) => invoice.amount) || []
    )
  );
  // const selectedTransactionAmount = Math.abs(
  //   Math.abs(selectedTransaction?.amount || 0)
  // );
  const remainingAmountToReconciliate =
    (reconciliatingTransactionAmount * 100 -
      sumOfReconciliatedInvoices * 100 -
      sumOfInvoices * 100) /
    100;

  return (
    <>
      <Modal
        title={
          <Space>
            <Icon
              path={mdiRepeatVariant}
              size={0.7}
              style={{ verticalAlign: "-20%" }}
            />
            Rapprocher une transaction à
            <Typography.Text code>
              {reconciliatingInvoice?.description}
            </Typography.Text>
          </Space>
        }
        open={open}
        onOk={handleOkModal}
        onCancel={handleCancelModal}
        destroyOnClose
        footer={[
          <Button key="back" onClick={handleCancelModal}>
            Annuler
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={buttonLoading}
            onClick={handleOkModal}
            disabled={!selectedTransaction}
          >
            Rapprocher
          </Button>,
        ]}
        width="85%"
      >
        {error && <Alert type="error" message={error} />}
        {!selectedTransaction && (
          <>
            <div
              style={{
                marginBottom: 16,
                justifyContent: "space-between",
              }}
            >
              <Input.Search
                onSearch={handleSearch}
                allowClear
                style={{
                  width: 350,
                }}
              />
            </div>
            <ReconciliationTransactionsTable
              categories={categories || []}
              transactions={transactions}
              loading={loading}
              changeParams={changeParams}
              pagination={pagination}
              setSelectedTransaction={setSelectedTransaction}
              searchText={searchText}
            />
          </>
        )}
        {selectedTransaction && (
          <>
            <Typography.Title style={{ textAlign: "center" }} level={4}>
              Transaction sélectionnée
            </Typography.Title>
            <Table
              locale={{
                emptyText: "Aucune transaction à afficher",
              }}
              columns={columnsTransactions}
              dataSource={[selectedTransaction]}
              size="small"
              loading={loading}
              onChange={changeParams}
              pagination={pagination}
              rowKey="id"
              showSorterTooltip={false}
              rowClassName={(invoice) =>
                invoice.ignored ? "table-row-ignored" : ""
              }
            />
          </>
        )}
        {selectedTransaction && (
          <>
            <Divider />
            <Typography.Title style={{ textAlign: "center" }} level={4}>
              Factures rapprochées
            </Typography.Title>
            <Table
              locale={{
                emptyText: "Aucune facture à afficher",
              }}
              columns={columnsInvoices}
              dataSource={[
                ...(modalParams?.reconciliatingInvoice
                  ? [modalParams.reconciliatingInvoice]
                  : []),
                ...(selectedTransaction?.invoices
                  ? selectedTransaction.invoices
                  : []),
              ]}
              size="small"
              loading={loading}
              onChange={changeParams}
              pagination={pagination}
              rowKey="id"
              showSorterTooltip={false}
              rowClassName={(invoice) =>
                invoice.ignored ? "table-row-ignored" : ""
              }
            />
          </>
        )}
        <Divider />
        {selectedTransaction && remainingAmountToReconciliate < 0 && (
          <Typography style={{ textAlign: "right" }}>
            <Checkbox
              checked={shouldCreateRemainingInvoice}
              onChange={(e: CheckboxChangeEvent) =>
                setShouldCreateRemainingInvoice(e.target.checked)
              }
            >
              Une nouvelle facture de{" "}
              {formatTransactionAmount({
                amount:
                  Math.sign(reconciliatingInvoice.amount) *
                  Math.abs(remainingAmountToReconciliate),
                currency_code: reconciliatingInvoice.currency_code,
              })}{" "}
              sera créée (montant restant sur la facture initiale).
            </Checkbox>
          </Typography>
        )}
      </Modal>
    </>
  );
};

export default InvoiceReconciliationModal;
