import { Tooltip } from "antd";
import { ReactElement } from "react";
import { Link } from "react-router-dom";
import { plans } from "utils/constants";

function SubscriptionTooltip({
  plan,
  children,
}: {
  plan?: string;
  children: ReactElement;
}) {
  return plan === plans.START_PLAN_ID ? (
    <Tooltip
      title={
        <div>
          Vous devez passer au plan First pour accéder à cette fonctionnalité
          <br />
          <Link to="/billing">Me mettre à niveau.</Link>
        </div>
      }
    >
      {children}
    </Tooltip>
  ) : (
    children
  );
}

export default SubscriptionTooltip;
