import Loader from "components/Loader";
import { AuthContext } from "contexts/auth";
import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { cache } from "swr";

function LogoutPage() {
  const { logOut } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    logOut();
    navigate("/login", { replace: true });
    cache.clear();
  }, [logOut, navigate]);

  return <Loader />;
}

export default LogoutPage;
