import { Alert, Button, Divider, Form, Input, Select } from "antd";
import OnboardingLayout from "components/OnboardingLayout";
import { AuthContext } from "contexts";
import { FC, ReactElement, useContext, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import companyService from "services/companyService";
import userService from "services/userService";
import useSWR, { cache } from "swr";
import errorHandler from "utils/errorHandler";
import marketingTools from "utils/marketingTools";
import "./index.css";

const MultiCompanyForm = ({
  setError,
}: {
  setError: (errorMessage: ReactElement | undefined) => void;
}) => {
  const { me, setMe } = useContext(AuthContext);
  const [loadingForm, setLoadingForm] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const { data: companies } = useSWR(
    "companyService.getAll",
    companyService.getAll
  );

  const onMultiCompanyChange = (value: number[]) => {
    form.setFieldsValue({ toLinkCompanies: value });
  };

  const onFinish = (values: { name: string; toLinkCompanies: number[] }) => {
    if (!me) {
      return;
    }

    setLoadingForm(true);
    const { name, toLinkCompanies } = values;
    const data = {
      name: name.trim(),
      isMultiCompany: true,
      isOnboarding: false,
      toLinkCompanies,
    };
    companyService
      .create(data)
      .then((newCompany) => {
        window.$crisp.push(["set", "user:company", [name]]);
        return userService.setCompany({ company: newCompany.id });
      })
      .then(async (me) => {
        setMe(me);
        cache.clear();
        marketingTools.hasChangedCompany(me.company);
        navigate("/");
      })
      .catch((e) => {
        if (e?.response?.data?.message?.id) {
          setError(
            <FormattedMessage
              id={e?.response?.data?.message?.id || -1}
              defaultMessage="Un problème est survenu. Veuillez rafraîchir la page ou essayer ultérieurement."
              description="Server response to login"
            />
          );
          setLoadingForm(false);
        } else {
          throw e;
        }
      })
      .catch(errorHandler(setError));
  };
  return (
    <Form
      name="create-company"
      className="login-form"
      onFinish={onFinish}
      form={form}
      layout="vertical"
      hideRequiredMark
    >
      <Form.Item
        label="Nom de l'entreprise"
        name="name"
        rules={[{ required: true, message: "Requis" }]}
      >
        <Input autoComplete="organization" placeholder="Entreprise" />
      </Form.Item>
      <Form.Item
        label="Sociétés à consolider"
        name="toLinkCompanies"
        id="toLinkCompanies"
        rules={[{ required: true, message: "Requis" }]}
      >
        <Select
          mode="multiple"
          placeholder="Sélectionner les entreprises à consolider"
          onChange={onMultiCompanyChange}
          getPopupContainer={(trigger) => trigger.parentNode}
          loading={!companies}
        >
          {companies
            ?.filter((company) => !company.isMultiCompany)
            .map((toLinkCompany) => (
              <Select.Option key={toLinkCompany.id} value={toLinkCompany.id}>
                {toLinkCompany.name}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>
      <Button
        type="primary"
        htmlType="submit"
        className="FormButton"
        loading={loadingForm}
        block
        style={{ marginTop: 30 }}
      >
        Continuer
      </Button>
    </Form>
  );
};

const OnboardingCreateMultiCompanyPage: FC = () => {
  const [error, setError] = useState<ReactElement | undefined>(undefined);

  return (
    <OnboardingLayout>
      <div className="OnboardingCreateCompanyPage">
        <Divider />
        <div className="animated">
          <h1>Créer un plan de trésorerie consolidé (beta)</h1>
          {error && <Alert type="error" message={error} />}
          <div style={{ marginTop: 20 }}>
            <MultiCompanyForm setError={setError} />
          </div>
        </div>
      </div>
    </OnboardingLayout>
  );
};

export default OnboardingCreateMultiCompanyPage;
