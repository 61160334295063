import { FormInstance, Table } from "antd";
import React, { FC } from "react";
import { CategoryMonthData } from "utils/dashboardUtils";
import { EditableCell } from "./EditableCell";
import { EditableRow } from "./EditableRow";

export const EditableContext = React.createContext<FormInstance<any> | null>(
  null
);

export type Item = Record<string, CategoryMonthData> & {
  key: string;
  categoryId: number;
};

const EditableTable: FC<{
  columns: any[];
  dataSource: any[];
  loading: boolean;
  expandedRowKeys: string[];
  handleExpand: (expanded: boolean, key: string) => void;
  onRow: any;
}> = ({
  columns,
  dataSource,
  loading,
  expandedRowKeys,
  handleExpand,
  onRow,
}) => {
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      loading={loading}
      onRow={onRow}
      className="ant-table-fixed-header"
      bordered
      pagination={false}
      expandable={{
        expandedRowKeys: expandedRowKeys,
        onExpand: (expanded, record) => handleExpand(expanded, record.key),
      }}
      size={"middle"}
      indentSize={16}
      tableLayout="fixed"
      components={components}
    />
  );
};

export default EditableTable;
