import { mdiMenuDown, mdiMenuUp } from "@mdi/js";
import Icon from "@mdi/react";
import { Space, Typography } from "antd";
import { format } from "date-fns";
import numeral from "numeral";
import { FC } from "react";
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import { Category } from "types";
import { StatisticData } from "utils/dashboardUtils";
import { isPastMonth } from "utils/date";
import { KindEnum } from "utils/kind";

interface Props {
  data: StatisticData;
  categories: Category[];
  kind: string;
  pieChartMonth: Date;
  baseScenarioId: number;
}

const PieChartByCategory: FC<Props> = ({
  data,
  categories,
  kind,
  pieChartMonth,
  baseScenarioId,
}) => {
  const isCashIn = kind === KindEnum.cashIn;

  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

  const currentMonthString = format(pieChartMonth, "yyyy-MM-dd");
  const isPast = isPastMonth(pieChartMonth);

  const sumOfCurrentMonth = isPast
    ? data[isCashIn ? "cashInTotal" : "cashOutTotal"][currentMonthString]
        .sumOfTransactions
    : data[isCashIn ? "cashInTotal" : "cashOutTotal"][currentMonthString]
        .forecast[baseScenarioId];

  const pieChartData = categories
    .filter((category) => kind === category.kind)
    .map((category) => ({
      name: category.name,
      order: category.order,
      sum: Math.max(
        isPast
          ? data[category.id][currentMonthString].sumOfTransactions
          : data[category.id][currentMonthString].forecast[baseScenarioId],
        0
      ),
    }))
    .filter(
      ({ sum }) =>
        sumOfCurrentMonth !== 0 && (sum / sumOfCurrentMonth) * 100 >= 1
    );

  const CustomTooltip = (props: any) => {
    const { active, payload } = props;
    if (active) {
      return (
        <div
          style={{
            background: "white",
            padding: "9px 12px",
            border: "1px solid #ccc",
          }}
        >
          {payload.map((point: any) => (
            <div style={{ color: point.color }} key={point.dataKey}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ marginRight: 10 }}>{point.name}:</div>
                <div>{`${numeral(point.value).format(
                  "0,0",
                  Math.floor
                )} €`}</div>
              </div>
            </div>
          ))}
        </div>
      );
    }

    return null;
  };

  const CustomizedLabel = (props: any) => {
    const { name, cx, cy, midAngle, innerRadius, outerRadius, percent, value } =
      props;

    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5 + 80;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="black"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
        fontSize={10}
      >
        {`${name}: ${numeral(value).format("0,0", Math.floor)} € (${(
          percent * 100
        ).toFixed(0)}%)`}
      </text>
    );
  };

  const isEmpty = pieChartData.length === 0;

  return (
    <div style={{ width: "100%", height: 350 }}>
      <Typography.Title level={2} style={{ textAlign: "center", fontSize: 18 }}>
        Total
        <br />
        <Space
          style={{
            color: isCashIn ? "rgb(137, 199, 184)" : "rgb(226, 130, 127)",
          }}
        >
          {isCashIn ? (
            <Icon
              path={mdiMenuUp}
              size={1.2}
              style={{ verticalAlign: "middle" }}
            />
          ) : (
            <Icon path={mdiMenuDown} size={1.2} />
          )}
          {numeral(sumOfCurrentMonth).format("0,0", Math.floor)} €
        </Space>
      </Typography.Title>

      <ResponsiveContainer>
        <PieChart>
          <Pie
            data={
              isEmpty ? [{ name: "empty", order: 0, sum: 1 }] : pieChartData
            }
            dataKey="sum"
            label={!isEmpty && <CustomizedLabel />}
            isAnimationActive={false}
            outerRadius={"50%"}
          >
            {isEmpty ? (
              <Cell key={`cell-0`} fill={`grey`} />
            ) : (
              pieChartData.map((entry: any, index: any) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[entry.order % COLORS.length]}
                />
              ))
            )}
          </Pie>
          {!isEmpty && <Tooltip content={<CustomTooltip />} />}
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default PieChartByCategory;
