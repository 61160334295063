import { mdiChevronDown } from "@mdi/js";
import Icon from "@mdi/react";
import {
  Avatar,
  Button,
  Card,
  Dropdown,
  List,
  Menu,
  MenuProps,
  Space,
} from "antd";
import GeneralLayout from "components/GeneralLayout";
import Loader from "components/Loader";
import { MouseEvent, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import integrationService from "services/integrationService";
import useSWR, { mutate } from "swr";
import { Integration } from "types";
import { providerEnum, providerName } from "utils/constants";
import IntegrationAddModal from "./IntegrationAddModal";
import IntegrationItem from "./IntegrationItem";

export const oAuthIntegrations = ["intuit", "sellsy"];

export type IntegrationModalParams = {
  open: boolean;
  provider?: string;
  editingRecord?: Integration;
};

export function IntegrationsPage() {
  const [interval, setInterval] = useState(0);
  const location = useLocation();
  const { data: integrations } = useSWR(
    "integrationService.getAll",
    integrationService.getAll,
    { refreshInterval: interval }
  );
  const [modalParams, setModalParams] = useState<IntegrationModalParams>({
    open: false,
  });

  useEffect(() => {
    if (integrations?.some((integration) => integration.toRefresh)) {
      setInterval(5000);
    } else if (interval === 5000) {
      setInterval(0);
    }
  }, [integrations, interval]);

  const refreshIntegration = async (integrationId: number) => {
    if (!integrations) {
      return;
    }

    mutate(
      "integrationService.getAll",
      (integrations: Integration[]) => {
        const newIntegrations = [...integrations];
        const index = newIntegrations.findIndex(
          (integration) => integration.id === integrationId
        );
        newIntegrations.splice(index, 1, {
          ...newIntegrations[index],
          toRefresh: true,
        });

        return newIntegrations;
      },
      false
    );

    await integrationService.update(integrationId, { toRefresh: true });

    mutate("integrationService.getAll");
  };

  const deleteIntegration = async (integrationId: number) => {
    if (!integrations) {
      return;
    }

    mutate(
      "integrationService.getAll",
      (integrations: Integration[]) => {
        const newIntegrations = [...integrations];
        const index = newIntegrations.findIndex(
          (integration) => integration.id === integrationId
        );
        newIntegrations.splice(index, 1);

        return newIntegrations;
      },
      false
    );

    await integrationService.remove(integrationId);

    mutate("integrationService.getAll");
  };

  const remainingIntegrations = Object.values(providerEnum).filter(
    (providerKey) =>
      integrations?.findIndex(({ provider }) => provider === providerKey) === -1
  );

  const menuItems: MenuProps["items"] = remainingIntegrations.map((provider) =>
    oAuthIntegrations.indexOf(provider) === -1
      ? {
          key: provider,
          icon: (
            <Avatar shape="square" src={`/img/${provider}-logo.png`}>
              {provider}
            </Avatar>
          ),
          onClick: () => {
            setModalParams({ open: true, provider });
          },
          label: providerName[provider],
        }
      : {
          key: provider,
          icon: (
            <Avatar shape="square" src={`/img/${provider}-logo.png`}>
              {providerName[provider]}
            </Avatar>
          ),
          label: (
            <a href={`${process.env.REACT_APP_BASE_URL}connect/${provider}`}>
              {providerName[provider]}
            </a>
          ),
        }
  );
  const menu = { items: menuItems };

  return (
    <GeneralLayout
      title={
        <Menu
          selectedKeys={[location?.pathname]}
          mode="horizontal"
          items={[
            {
              key: "/banks",
              label: <Link to="/banks">Banques</Link>,
            },
            {
              key: "/integrations",
              label: <Link to="/integrations">Intégrations</Link>,
            },
          ]}
        />
      }
    >
      <div className="CompanyPage">
        <Card>
          <div style={{ marginTop: 15 }}>
            <IntegrationAddModal
              modalParams={modalParams}
              setModalParams={setModalParams}
            />
            {!integrations ? (
              <Loader />
            ) : (
              <List
                locale={{ emptyText: "Aucune intégration ajoutée" }}
                itemLayout="vertical"
                dataSource={integrations}
                header={
                  <div>
                    <h1>Liste de vos intégrations</h1>
                    {remainingIntegrations.length !== 0 && (
                      <Space>
                        <Dropdown
                          menu={menu}
                          getPopupContainer={(trigger) => trigger}
                        >
                          <Button
                            type="link"
                            onClick={(event: MouseEvent) =>
                              event.preventDefault()
                            }
                          >
                            Ajouter une intégration
                            <Icon
                              path={mdiChevronDown}
                              size={1}
                              style={{ verticalAlign: "middle" }}
                            />
                          </Button>
                        </Dropdown>
                      </Space>
                    )}
                  </div>
                }
                renderItem={(integration) => (
                  <IntegrationItem
                    integration={integration}
                    deleteIntegration={deleteIntegration}
                    refreshIntegration={refreshIntegration}
                    setModalParams={setModalParams}
                  />
                )}
              />
            )}
          </div>
        </Card>
      </div>
    </GeneralLayout>
  );
}
