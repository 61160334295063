import { Typography } from "antd";
import { FC } from "react";

const { Text } = Typography;

interface Props {
  parentName: string;
  goalCount: number;
  invoiceCount: number;
  transactionCount: number;
  subCategoriesBudgetsWillBeDeleted?: boolean;
}

const ConfirmAddSubCategoryContent: FC<Props> = ({
  parentName,
  goalCount,
  invoiceCount,
  transactionCount,
  subCategoriesBudgetsWillBeDeleted,
}) => {
  return (
    <>
      <Typography.Paragraph>
        Vous êtes sur le point d'ajouter des sous-catégories à:
      </Typography.Paragraph>
      <Typography.Paragraph>
        <Text mark>{parentName}</Text>
      </Typography.Paragraph>
      <Typography.Paragraph>
        Cela va entraîner les modifications suivantes:
      </Typography.Paragraph>
      <ul>
        {transactionCount !== 0 && (
          <li>
            <b>Transactions décatégorisées: {transactionCount}</b>
          </li>
        )}
        {invoiceCount !== 0 && (
          <li>
            <b>Factures engagées décatégorisées: {invoiceCount}</b>
          </li>
        )}
        {goalCount !== 0 && (
          <li>
            <b>Valeurs de budget supprimées: {goalCount}</b>
          </li>
        )}
        {goalCount !== 0 && subCategoriesBudgetsWillBeDeleted && (
          <li>
            <b>
              Les valeurs de budget des sous-catégories seront perdues (budget
              global)
            </b>
          </li>
        )}
        {goalCount === 0 && subCategoriesBudgetsWillBeDeleted && (
          <li>
            <b>
              Les valeurs de budget de la catégorie et de toutes ses
              sous-catégories seront perdues (budget global)
            </b>
          </li>
        )}
      </ul>
    </>
  );
};

export default ConfirmAddSubCategoryContent;
