import { mdiFileTreeOutline, mdiPlus } from "@mdi/js";
import Icon from "@mdi/react";
import { Alert, Badge, Dropdown, MenuProps, Table } from "antd";
import { AuthContext } from "contexts";
import { format } from "date-fns";
import { ReactElement, useContext, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link, useNavigate } from "react-router-dom";
import companyService from "services/companyService";
import userService from "services/userService";
import useSWR, { cache } from "swr";
import { Company } from "types";
import { plans } from "utils/constants";
import errorHandler from "utils/errorHandler";
import marketingTools from "utils/marketingTools";

const showPlan = (company: Company) => {
  const {
    isYearly,
    plan,
    numberOfBanks,
    trialEndDate,
    cancelAtPeriodEnd,
    isDemoCompany,
  } = company;
  if (isDemoCompany) {
    return "Démonstration";
  } else if (trialEndDate) {
    return `Essai jusqu'au ${format(
      new Date(company.trialEndDate),
      "dd/MM/yyyy"
    )}`;
  } else if (plan !== plans.START_PLAN_ID && cancelAtPeriodEnd) {
    return "Fin de l'abonnement programmée";
  } else if (plan === plans.START_PLAN_ID) {
    return `Gratuit`;
  } else if (
    plan === plans.FIRST_PLAN_ID ||
    plan === plans.RESOLD_PLAN_ID ||
    plan === plans.FIRST_MONTHLY_PLAN_ID ||
    plan === plans.RESOLD_MONTHLY_PLAN_ID
  ) {
    return `Abonnement ${
      isYearly ? "Annuel" : "Mensuel"
    } (${numberOfBanks} banque${numberOfBanks > 1 ? "s" : ""})`;
  } else if (plan === plans.START_PLAN_ID) {
    return "";
  } else if (plan === plans.PARTNER_PLAN_ID) {
    return `Compte partenaire`;
  } else {
    return "";
  }
};

export function ChangeCompanyForm() {
  const { data: companies } = useSWR(
    "companyService.getAll",
    companyService.getAll
  );
  const [error, setError] = useState<ReactElement | undefined>(undefined);
  const navigate = useNavigate();
  const { setMe } = useContext(AuthContext);

  const setCompany = (companyId: number) => {
    userService
      .setCompany({ company: companyId })
      .then((me) => {
        setMe(me);
        cache.clear();
        marketingTools.hasChangedCompany(me.company);
      })
      .then(() => {
        navigate("/");
      })
      .catch((e) => {
        if (e?.response?.data?.message?.[0]?.messages?.[0]?.id) {
          setError(
            <FormattedMessage
              id={e?.response?.data?.message?.[0]?.messages?.[0]?.id || -1}
              defaultMessage="Un problème est survenu. Veuillez rafraîchir la page ou essayer ultérieurement."
              description="Server response to login"
            />
          );
        } else {
          throw e;
        }
      })
      .catch(errorHandler(setError));
  };

  const columns = [
    {
      title: "Nom",
      dataIndex: "name",
      render: (_: any, company: Company) => (
        <Badge offset={[6, 0]} dot={company.isNewInvitation}>
          <Link onClick={() => setCompany(company.id)} to="#">
            {company.name}
          </Link>
        </Badge>
      ),
    },
    {
      title: "Plan",
      dataIndex: "plan",
      width: "50%",
      render: (_: any, company: Company) => showPlan(company),
    },
  ];

  const handleMenuClick = (e: any) => {
    if (e.key === "add") {
      navigate("/register/create-company");
    }
    if (e.key === "add-multi-company") {
      navigate("/register/create-multi-company");
    }
    if (e.key === "add-demo") {
      navigate("/register/create-company?isDemoCompany=true");
    }
  };

  const menuItems: MenuProps["items"] = [
    {
      key: "add",
      label: "Inscrire une nouvelle entreprise",
      icon: <Icon path={mdiPlus} size={0.65} />,
    },
    // {
    //   key: "add-demo",
    //   label: "Créer un compte de démo",
    //   icon: <Icon path={mdiTestTube} size={0.65} />,
    // },
    {
      key: "add-multi-company",
      label: "Créer un plan de trésorerie consolidé (beta)",
      icon: <Icon path={mdiFileTreeOutline} size={0.65} />,
      disabled: !companies || companies.length <= 1,
    },
  ];
  const menu = { items: menuItems, onClick: handleMenuClick };

  return (
    <div style={{ marginTop: 15, textAlign: "center" }}>
      {error && <Alert type="error" message={error} />}
      <Table
        loading={!companies}
        locale={{
          emptyText: "Aucune entreprise à afficher",
        }}
        dataSource={companies}
        bordered={true}
        size="small"
        columns={columns}
        rowKey="id"
        showHeader={false}
        pagination={false}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          marginTop: 30,
        }}
      >
        <Dropdown.Button
          className="FormButton"
          type="primary"
          onClick={() => navigate("/register/create-company")}
          menu={menu}
        >
          <Icon
            path={mdiPlus}
            size={0.8}
            style={{ verticalAlign: "-20%", marginRight: "5px" }}
          />
          Inscrire une nouvelle entreprise
        </Dropdown.Button>
      </div>
    </div>
  );
}
