import { CategorizationRule } from "types";
import axios from "utils/setupAxios";

export async function getAll(): Promise<CategorizationRule[]> {
  return axios.get(`/categorization-rules`).then((res) => res.data);
}

export async function create(values: any): Promise<CategorizationRule> {
  return axios.post("/categorization-rules", values).then((res) => res.data);
}

export async function remove(
  categorizationRuleId: number
): Promise<CategorizationRule> {
  return axios
    .delete(`/categorization-rules/${categorizationRuleId}`)
    .then((res) => res.data);
}

const categoryService = {
  getAll,
  create,
  remove,
};
export default categoryService;
