import Icon from "@mdi/react";
import { mdiPlus } from "@mdi/js";
import { Button, Divider, Space, TreeSelect, Typography } from "antd";
import { FC, useRef } from "react";
import Highlighter from "react-highlight-words";
import { Category, Invoice, Transaction } from "types";
import { isCashIn } from "utils/amount";
import { maxLevelDeepth } from "utils/constants";
import { KindEnum } from "utils/kind";
import { formatTree } from "utils/tree";
import "./index.css";

const { Text } = Typography;

interface Props {
  categories: Category[];
  transaction: Transaction | Invoice;
  handleCategorizeMany: (
    transactionIds: number[],
    categoryId: number
  ) => Promise<void>;
  disabled: boolean;
  showModal: (transaction: any, kind: KindEnum) => void;
  showSubCategoryModal: (
    transaction: any,
    kind: KindEnum,
    parent: Category
  ) => void;
  searchText?: string;
}

const CategorizeSelect: FC<Props> = ({
  categories,
  transaction,
  handleCategorizeMany,
  disabled,
  showModal,
  showSubCategoryModal,
  searchText = "",
}) => {
  const ref = useRef(null);

  const kind = isCashIn(transaction) ? KindEnum.cashIn : KindEnum.cashOut;
  const formatFunction = (category: Category, level: number) => ({
    title: (
      <Space>
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={category.name}
        />
        {level < maxLevelDeepth && (
          <div
            onClick={(event) => {
              event.stopPropagation();
              showSubCategoryModal(transaction, kind, category);
            }}
          >
            <Icon path={mdiPlus} size={0.9} className="AddSubCategory" />
          </div>
        )}
      </Space>
    ),
    searchValue: category.name,
    value: category.id.toString(),
    selectable: !category.children?.length,
    ...category,
  });

  const formattedCategories = formatTree({
    categories: categories.filter((category) => category.kind === kind),
    formatFunction,
  });

  const handleChange = (categoryId: string) => {
    handleCategorizeMany([transaction.id], parseInt(categoryId, 10));
  };

  return (
    <TreeSelect
      className="CategorizeSelect"
      ref={ref}
      value={transaction.category?.toString()}
      virtual={false}
      showSearch
      treeNodeFilterProp="searchValue"
      style={{ width: "80%" }}
      dropdownStyle={{ maxHeight: 400 }}
      placeholder={
        <Text mark strong>
          Sélectionnez une catégorie
        </Text>
      }
      treeDefaultExpandAll
      onChange={handleChange}
      getPopupContainer={(trigger) => {
        const modalContainer = document.getElementById("MainModalContent");

        return modalContainer || trigger.parentNode;
      }}
      treeData={formattedCategories}
      allowClear
      disabled={disabled}
      dropdownRender={(menu) => (
        <div>
          {menu}
          <Divider style={{ margin: "4px 0" }} />
          <div
            style={{
              display: "flex",
              flexWrap: "nowrap",
              padding: 8,
            }}
          >
            <Button type="link" onClick={() => showModal(transaction, kind)}>
              <b>+ Ajouter une catégorie</b>
            </Button>
          </div>
        </div>
      )}
    />
  );
};

export default CategorizeSelect;
