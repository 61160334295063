import { Table, Tag } from "antd";
import { ColumnsType } from "antd/es/table";
import { Category, Transaction } from "types";
import { formatTransactionAmount } from "utils/amount";
import { getFormattedFullDate } from "utils/date";
import { findTree } from "utils/tree";

export const ReadOnlyTransactionsTable = ({
  transactions,
  categories,
}: {
  transactions: Transaction[];
  categories: Category[];
}) => {
  const columns: ColumnsType<Transaction> = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: 150,
      render: (date: Date) =>
        isNaN(date.getTime()) ? "Date Invalide" : getFormattedFullDate(date),
      sorter: (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
    },
    {
      title: "Libellé",
      dataIndex: "raw_description",
      key: "raw_description",
      ellipsis: true,
      sorter: (a, b) => a.raw_description.localeCompare(b.raw_description),
    },
    {
      title: "Catégorie",
      dataIndex: "category",
      key: "category",
      width: "25%",
      sorter: true,
      render: (_: any, transaction: Transaction) => (
        <Tag>
          {findTree({
            categories: categories || [],
            categoryId: transaction.category,
          })?.name || "Pas de catégorie"}
        </Tag>
      ),
    },
    {
      title: "Montant TTC",
      dataIndex: "amount",
      key: "amount",
      width: 160,
      render: (_: any, transaction: Transaction) =>
        formatTransactionAmount(transaction),
      align: "right",
      sorter: (a, b) => a.amount - b.amount,
    },
    {
      title: "Id",
      dataIndex: "externalId",
      key: "externalId",
      width: 160,
      align: "right",
    },
  ];

  return (
    <Table
      locale={{
        emptyText: (
          <div style={{ margin: 20 }}>
            Vous n'avez pas encore sélectionné de fichier
          </div>
        ),
      }}
      columns={columns}
      dataSource={transactions}
      size="small"
      showSorterTooltip={false}
    />
  );
};
