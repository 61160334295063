import { PaginationProps } from "antd";
import { SorterResult } from "antd/es/table/interface";
import { startOfDay } from "date-fns";
import { KindEnum } from "./kind";

export const computeQuery = ({
  pagination,
  filteredInfo,
  sortedInfo,
  defaultSortColum,
  defaultSortOrder = "DESC",
  defaultPaginationCurrent = 1,
  defaultPaginationPageSize = 20,
}: {
  pagination: PaginationProps;
  filteredInfo: Record<string, string[]>;
  sortedInfo: SorterResult<string>;
  defaultSortColum: string;
  defaultSortOrder?: string;
  defaultPaginationCurrent?: number;
  defaultPaginationPageSize?: number;
}) => {
  const current = pagination.current || defaultPaginationCurrent;
  const pageSize = pagination.pageSize || defaultPaginationPageSize;
  const paginationParams = `&_start=${
    (current - 1) * pageSize
  }&_limit=${pageSize}`;

  const filtersParams = Object.entries(filteredInfo).reduce(
    (acc: string, [key, value]: [string, string[]], index: number) => {
      if (!value) {
        return acc;
      } else if (key === "raw_description" || key === "description") {
        return `${acc}&_or[0][${key}_contains]=${
          value[0]
        }&_or[1][category.name_contains]=${
          value[0]
        }&_or[2][amount_contains]=${value[0].replace(
          ",",
          "."
        )}&_or[3][note_contains]=${value[0]}
        `;
      } else if (key === "amount") {
        return value.length === 1
          ? `${acc}&${key}_${value[0] === KindEnum.cashIn ? "gte" : "lte"}=0`
          : acc;
      } else if (key === "status" && value.indexOf("not_paid") !== -1) {
        return `${acc}&paid=false`;
      } else if (key === "status" && value.indexOf("paid") !== -1) {
        return `${acc}&paid=true&paid_ne=false`;
      } else if (key === "status" && value.indexOf("late") !== -1) {
        return `${acc}&paid_ne=true&dueDate_lt=${startOfDay(
          new Date()
        ).toISOString()}`;
      } else if (key === "status" && value.indexOf("awaiting") !== -1) {
        return `${acc}&paid_ne=true&dueDate_gte=${startOfDay(
          new Date()
        ).toISOString()}`;
      } else if (key === "status" && value.indexOf("all") !== -1) {
        return `${acc}&paid=true`;
      } else {
        return `${acc}&_or[${index}][${key}_contains]=${value[0]}`;
      }
    },
    ""
  );

  const sortColumn = sortedInfo.column?.key || defaultSortColum;
  const sortOrder = sortedInfo.order
    ? sortedInfo.order === "ascend"
      ? "ASC"
      : "DESC"
    : defaultSortOrder;
  const sorterParams = `&_sort=${sortColumn}:${sortOrder}`;

  return `${paginationParams}${sorterParams}${filtersParams}`;
};
