import { AxiosResponse } from "axios";
import axios from "utils/setupAxios";

export async function getAddItemUrl(): Promise<AxiosResponse> {
  return axios.get("/connect/bridgeapi/items/add/url");
}

export async function connectCallback(): Promise<AxiosResponse> {
  return axios.post("/connect/bridgeApi/callback");
}

export async function getProValidationUrl(): Promise<AxiosResponse> {
  return axios.get(`/connect/bridgeApi/items/pro/confirmation/url`);
}

export async function getSCAUrl(itemId: number): Promise<AxiosResponse> {
  return axios.get(`/connect/bridgeApi/items/sync?itemId=${itemId}`);
}

export async function getEditItemUrl(itemId: number): Promise<AxiosResponse> {
  return axios.get(`/connect/bridgeApi/items/edit/url?itemId=${itemId}`);
}

const bridgeapiService = {
  getAddItemUrl,
  connectCallback,
  getProValidationUrl,
  getSCAUrl,
  getEditItemUrl,
};
export default bridgeapiService;
