import { Alert } from "antd";
import GeneralLayout from "components/GeneralLayout";
import StatisticsContainer from "components/StatisticContainer";
import React, { ReactElement, useState } from "react";
import "./index.css";

function AnalysisPage() {
  const [error, setError] = useState<ReactElement | undefined>(undefined);

  return (
    <GeneralLayout title="Analyses">
      <div className="AnalysisPage">
        {error && <Alert type="error" message={error} />}
        <StatisticsContainer setError={setError} />
      </div>
    </GeneralLayout>
  );
}

export default AnalysisPage;
