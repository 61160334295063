import { ArrowUpOutlined, FileExcelOutlined } from "@ant-design/icons";
import { mdiFileExcelOutline, mdiSquareEditOutline } from "@mdi/js";
import Icon from "@mdi/react";
import { Alert, Button, Card, Col, Radio, Row } from "antd";
import AccountSelect from "components/AccountSelect";
import ExportModal from "components/ExportModal";
import GeneralLayout from "components/GeneralLayout";
import Loader from "components/Loader";
import MainChartDatePicker from "components/MainChartDatePicker";
import MainModal from "components/MainModal";
import MainReChart from "components/MainReChart";
import MainScenarioCheck from "components/MainScenarioCheck";
import MainTable from "components/MainTable";
import ScenarioTabs from "components/ScenarioTabs";
import SubscriptionTooltip from "components/SubscriptionTooltip";
import { AuthContext } from "contexts";
import { addMonths, format, parseISO, startOfMonth, subMonths } from "date-fns";
import { ReactElement, useContext, useEffect, useState } from "react";
import categoryService from "services/categoryService";
import companyService from "services/companyService";
import dashboardService from "services/dashboardService";
import goalService from "services/goalService";
import itemService from "services/itemService";
import scenarioService from "services/scenarioService";
import useSWR from "swr";
import { Account, Category, Scenario } from "types";
import { formatConsolidatedBalance } from "utils/amount";
import { plans, rolesEnum } from "utils/constants";
import { defaultCurrencyCode } from "utils/currency";
import {
  SourceData,
  StatisticData,
  formatSourceData,
  formatStatisticData,
} from "utils/dashboardUtils";
import errorHandler from "utils/errorHandler";
import marketingTools from "utils/marketingTools";
import { findTree } from "utils/tree";
import "./index.css";

export type ModalParams = {
  category: Category | undefined;
  ignored: boolean;
  date: string;
  open: boolean;
};

export interface State {
  sourceData: SourceData | null;
  statisticData: StatisticData | null;
  // GENERAL SETTINGS
  startingMonth: Date;
  currentScenarioId?: number;
  currentSelectedAccount?: number;
  baseScenarioId?: number;
  loaded: boolean;
  // MODAL LIST TRANSACTIONS
  modalParams: ModalParams;
  exportModalVisible: boolean;
  // TABLE
  tableEditing: boolean;
  // DATA
  categories: Category[];
  scenarios: Scenario[];
  checkedScenarios: Record<number | string, boolean>;
}

function CashPlanPage() {
  const { data: items } = useSWR("itemService.getAll", itemService.getAll);
  const [error, setError] = useState<ReactElement | undefined>(undefined);
  const [graphicView, setGraphicView] = useState<string>("2");
  const [expandedRowKeys, setExpandedRowKeys] = useState(["cashIn", "cashOut"]);
  const { me, setMe } = useContext(AuthContext);
  const [state, setState] = useState<State>({
    sourceData: null,
    statisticData: null,
    // GENERAL SETTINGS
    startingMonth: startOfMonth(subMonths(new Date(), 3)),
    currentScenarioId: undefined, // not loaded if null
    currentSelectedAccount: undefined,
    baseScenarioId: undefined, // not loaded if null
    loaded: false,
    // MODAL LIST TRANSACTIONS
    modalParams: {
      category: undefined,
      ignored: false,
      date: format(startOfMonth(subMonths(new Date(), 3)), "yyyy-MM-dd"),
      open: false,
    },
    // MODAL LIST TRANSACTIONS
    exportModalVisible: false,
    // TABLE
    tableEditing: false,
    // DATA
    categories: [],
    scenarios: [],
    checkedScenarios: {},
  });
  const [selectedMonth, setSelectedMonth] = useState<Date | undefined>(
    startOfMonth(new Date())
  );

  const loadData = async (
    startingMonth: Date,
    scenarioId: number | undefined,
    checkedScenarios: Record<number | string, boolean>,
    selectedAccount?: number
  ) => {
    const startingMonthString = format(startingMonth, "yyyy-MM-dd");

    return Promise.all([
      dashboardService.get(startingMonthString, true),
      categoryService.getAll(),
    ])
      .then(([res, categories]) => {
        let { transactions, invoices, scenarios } = res.data;
        if (selectedAccount) {
          transactions = res.data.transactions.filter(
            (transaction: any) => transaction.account === selectedAccount
          );
          invoices = [];
          scenarios = scenarios.map((scenario: any) => {
            return { ...scenario, goals: [] };
          });
        }
        let currentScenarioId = scenarioId;
        const baseScenario = scenarios.find(
          (scenario: Scenario) => scenario.isDefaultScenario
        );
        const baseScenarioId = baseScenario.id;
        if (!currentScenarioId) {
          currentScenarioId = baseScenarioId;
        }

        const sourceData = formatSourceData({
          // Display data
          startingMonth,
          numberOfMonth: 12,
          // Real world data
          transactions,
          invoices,
          // Structure of the table
          categories,
          // Scenarios
          scenarios,
          defaultCurrencyCode,
        });

        const statisticData = formatStatisticData({
          sourceData,
          startingMonth,
          numberOfMonth: 12,
          scenarios,
          categories,
          baseScenarioId,
          isSimpleForecastModeEnabled:
            me?.company?.isSimpleForecastModeEnabled || false,
        });

        setState((state) => ({
          ...state,
          sourceData,
          statisticData,
          startingMonth,
          currentScenarioId,
          currentSelectedAccount: selectedAccount,
          baseScenarioId,
          categories,
          scenarios,
          loaded: true,
          checkedScenarios,
        }));
      })
      .catch(errorHandler(setError));
  };

  // GOALS
  const saveGoal = async (
    categoryId: number,
    date: string,
    newGoalAmount: number | null
  ) => {
    const newSourceData: SourceData = { ...state.sourceData };
    const { currentScenarioId } = state;

    if (!currentScenarioId || !baseScenarioId) {
      throw Error("No current scenario or base scenario");
    }

    const values = {
      category: categoryId,
      date,
      amount: newGoalAmount,
      scenario: currentScenarioId,
    };

    return goalService
      .create(values)
      .then((goal) => {
        newSourceData[categoryId][date].goal[currentScenarioId] = goal.amount;

        const { startingMonth, scenarios, categories } = state;
        const statisticData = formatStatisticData({
          sourceData: newSourceData,
          startingMonth,
          numberOfMonth: 12,
          scenarios,
          categories,
          baseScenarioId,
          isSimpleForecastModeEnabled:
            me?.company?.isSimpleForecastModeEnabled || false,
        });

        setState((state) => ({
          ...state,
          sourceData: newSourceData,
          statisticData,
        }));
      })
      .catch(errorHandler(setError));
  };

  const duplicateGoal = async ({
    categoryId,
    date,
    newGoalAmount,
    interval,
    numberOfMonth,
  }: {
    categoryId: number;
    date: string;
    newGoalAmount: number | null;
    interval: number;
    numberOfMonth: number;
  }) => {
    const newSourceData: SourceData = { ...state.sourceData };
    const { currentScenarioId } = state;

    if (!currentScenarioId || !baseScenarioId) {
      throw Error("No current scenario or base scenario");
    }

    const values = {
      category: categoryId,
      date,
      amount: newGoalAmount,
      scenario: currentScenarioId,
      interval,
      numberOfMonth,
    };

    return goalService
      .duplicate(values)
      .then((goals) => {
        goals.forEach((goal) => {
          if (newSourceData[categoryId][goal.date]) {
            newSourceData[categoryId][goal.date].goal[currentScenarioId] =
              goal.amount;
          }
        });

        const { startingMonth, scenarios, categories } = state;
        const statisticData = formatStatisticData({
          sourceData: newSourceData,
          startingMonth,
          numberOfMonth: 12,
          scenarios,
          categories,
          baseScenarioId,
          isSimpleForecastModeEnabled:
            me?.company?.isSimpleForecastModeEnabled || false,
        });

        setState((state) => ({
          ...state,
          sourceData: newSourceData,
          statisticData,
        }));
        return newSourceData[categoryId];
      })
      .catch(errorHandler(setError))
      .then((value) => value || {});
  };

  const computeVATGoal = async (categoryId: number) => {
    const newSourceData: SourceData = { ...state.sourceData };
    const { startingMonth, currentScenarioId } = state;

    if (!currentScenarioId || !baseScenarioId) {
      throw Error("No current scenario or base scenario");
    }

    const values = {
      endingMonth: format(addMonths(startingMonth, 12), "yyyy-MM-dd"),
      scenario: currentScenarioId,
      category: categoryId,
    };

    return goalService
      .computeVAT(values)
      .then((goals) => {
        goals.forEach((goal) => {
          if (newSourceData[categoryId][goal.date]) {
            newSourceData[categoryId][goal.date].goal[currentScenarioId] =
              goal.amount;
          }
        });

        const { startingMonth, scenarios, categories } = state;
        const statisticData = formatStatisticData({
          sourceData: newSourceData,
          startingMonth,
          numberOfMonth: 12,
          scenarios,
          categories,
          baseScenarioId,
          isSimpleForecastModeEnabled:
            me?.company?.isSimpleForecastModeEnabled || false,
        });

        setState((state) => ({
          ...state,
          sourceData: newSourceData,
          statisticData,
        }));
        return newSourceData[categoryId];
      })
      .catch(errorHandler(setError))
      .then((value) => value || {});
  };

  // SCENARIOS
  const setScenario = async (scenarioId: number) => {
    const { startingMonth, currentSelectedAccount } = state;
    loadData(startingMonth, scenarioId, {}, currentSelectedAccount);
  };

  const handleSwitch = (scenarioId: number) => (checked: boolean) => {
    const { checkedScenarios } = state;

    const newCheckedScenarios = { ...checkedScenarios };
    newCheckedScenarios[scenarioId] = checked;

    setState((state) => ({ ...state, checkedScenarios: newCheckedScenarios }));
  };

  const createScenario = async (values: { color: string; name: string }) =>
    scenarioService
      .create(values)
      .then((newScenario) => {
        const { startingMonth, currentSelectedAccount } = state;

        const newScenarioId = newScenario.id;
        const newCheckedScenarios = {};

        loadData(
          startingMonth,
          newScenarioId,
          newCheckedScenarios,
          currentSelectedAccount
        );
      })
      .catch(errorHandler(setError));

  const deleteScenario = async (scenarioId: number) =>
    scenarioService
      .remove(scenarioId)
      .then((res) => {
        const { data: deletedScenario } = res;

        const newScenarios = [...state.scenarios];
        const index = newScenarios.findIndex(
          (scenario) => deletedScenario.id === scenario.id
        );
        newScenarios.splice(index, 1);

        const {
          startingMonth,
          currentScenarioId,
          checkedScenarios,
          scenarios,
          currentSelectedAccount,
        } = state;

        let newScenarioId = currentScenarioId;
        if (deletedScenario.id === currentScenarioId) {
          const defaultScenario = scenarios.find(
            (scenario) => scenario.isDefaultScenario
          );

          if (!defaultScenario) {
            throw new Error("Default scenario not found");
          }

          newScenarioId = defaultScenario.id;
        }

        const newCheckedScenarios = { ...checkedScenarios };
        newCheckedScenarios[deletedScenario.id] = false;

        loadData(
          startingMonth,
          newScenarioId,
          newCheckedScenarios,
          currentSelectedAccount
        );
      })
      .catch(errorHandler(setError));

  // START MONTH
  const incrementStartingMonth = async (numberOfMonth: number) => {
    if (!me) {
      return;
    }
    const {
      startingMonth,
      currentScenarioId,
      checkedScenarios,
      currentSelectedAccount,
    } = state;

    const newstartingMonth = addMonths(startingMonth, numberOfMonth);
    setMe({
      ...me,
      company: {
        ...me.company,
        startingMonth: format(newstartingMonth, "yyyy-MM-dd"),
      },
    });

    return loadData(
      newstartingMonth,
      currentScenarioId,
      checkedScenarios,
      currentSelectedAccount
    );
  };

  const setStartingMonth = async (month: Date) => {
    if (!me) {
      return;
    }
    const { currentScenarioId, checkedScenarios, currentSelectedAccount } =
      state;

    const newstartingMonth = month;
    setMe({
      ...me,
      company: {
        ...me.company,
        startingMonth: format(newstartingMonth, "yyyy-MM-dd"),
      },
    });

    return loadData(
      newstartingMonth,
      currentScenarioId,
      checkedScenarios,
      currentSelectedAccount
    );
  };

  useEffect(() => {
    const {
      currentScenarioId,
      loaded,
      checkedScenarios,
      currentSelectedAccount,
    } = state;

    if (me && !loaded) {
      const startingMonth = me.company.startingMonth
        ? parseISO(me.company.startingMonth)
        : startOfMonth(subMonths(new Date(), 3));
      setGraphicView(me.company.graphicView || "2");
      setExpandedRowKeys(me?.company?.expandedRowKeys || ["cashIn", "cashOut"]);
      loadData(
        startingMonth,
        currentScenarioId,
        checkedScenarios,
        currentSelectedAccount
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me]);

  const handleEditing = () => {
    const { tableEditing } = state;
    setState((state) => ({ ...state, tableEditing: !tableEditing }));

    marketingTools.hasBudgetized();

    if (me?.company && !me?.company.hasBudgetized) {
      companyService
        .update(me.company.id, { hasBudgetized: true })
        .then((newCompany) => {
          const newMe = { ...me };
          newMe.company = newCompany;
          setMe(newMe);
        })
        .catch(errorHandler(setError));
    }
  };

  const showModal = ({
    categoryId,
    ignored,
    date,
  }: {
    categoryId?: number;
    ignored: boolean;
    date: string;
  }) => {
    const { modalParams } = state;
    const category = findTree({ categories, categoryId });
    const newModalParams = {
      ...modalParams,
      category,
      ignored,
      date,
      open: true,
    };
    setState((state) => ({ ...state, modalParams: newModalParams }));
  };

  const handleCancel = () => {
    const { modalParams, currentSelectedAccount } = state;
    const newModalParams = {
      ...modalParams,
      open: false,
    };
    setState((state) => ({ ...state, modalParams: newModalParams }));
    loadData(
      startingMonth,
      currentScenarioId,
      checkedScenarios,
      currentSelectedAccount
    );
  };

  const handleCancelExportModal = () => {
    setState((state) => ({ ...state, exportModalVisible: false }));
  };

  const handleModeChange = (e: any) => {
    if (!me) {
      return;
    }

    const graphicView = e.target.value;
    companyService.update(me.company.id, { graphicView });
    setMe({ ...me, company: { ...me.company, graphicView } });
    setGraphicView(graphicView);
  };

  const allParentKeys = (acc: string[], category: Category): string[] => {
    if (category.children && Boolean(category.children.length)) {
      acc.push(category.id.toString());
      return category.children?.reduce(allParentKeys, acc);
    }
    return acc;
  };

  const expandAll = (expand: boolean) => {
    if (!me) {
      return;
    }

    const newExpandedRowKeys = expand
      ? ["cashIn", "cashOut", ...categories.reduce(allParentKeys, [])]
      : [];

    companyService.update(me.company.id, {
      expandedRowKeys: newExpandedRowKeys,
    });
    setMe({
      ...me,
      company: {
        ...me.company,
        expandedRowKeys: newExpandedRowKeys,
      },
    });
    setExpandedRowKeys(newExpandedRowKeys);
  };

  const handleExpand = (expanded: boolean, key: string) => {
    if (!me) {
      return;
    }

    const newExpandedRowKeys = expanded
      ? expandedRowKeys.concat(key)
      : expandedRowKeys.filter((k: string) => k !== key);

    companyService.update(me.company.id, {
      expandedRowKeys: newExpandedRowKeys,
    });
    setMe({
      ...me,
      company: {
        ...me.company,
        expandedRowKeys: newExpandedRowKeys,
      },
    });
    setExpandedRowKeys(newExpandedRowKeys);
  };

  const handleChangeSelectedAccount = (selectedAccount: number) => {
    const { startingMonth, currentScenarioId, checkedScenarios } = state;
    loadData(
      startingMonth,
      currentScenarioId,
      checkedScenarios,
      selectedAccount
    );
  };

  const {
    sourceData,
    statisticData,
    categories,
    startingMonth,
    currentScenarioId,
    baseScenarioId,
    scenarios,
    checkedScenarios,
    loaded,
    modalParams,
    exportModalVisible,
    tableEditing,
  } = state;

  const accounts = items
    ?.reduce((acc: Account[], { accounts }) => [...acc, ...accounts], [])
    ?.filter((account) => account.active);

  const canEdit = me?.role?.id !== rolesEnum["READ"];
  const currentPlan = me?.company?.plan;

  return (
    <GeneralLayout
      title={"Plan de trésorerie"}
      action={
        !me?.company?.isMultiCompany ? (
          <SubscriptionTooltip plan={currentPlan}>
            <Button
              shape="round"
              onClick={() =>
                setState((state) => ({ ...state, exportModalVisible: true }))
              }
              type="default"
              style={{ margin: 16 }}
              icon={
                <Icon
                  path={mdiFileExcelOutline}
                  size={0.7}
                  style={{ verticalAlign: "-15%", marginRight: "5px" }}
                />
              }
              disabled={!currentPlan || currentPlan === plans.START_PLAN_ID}
            >
              Export Excel
            </Button>
          </SubscriptionTooltip>
        ) : (
          <Button
            shape="round"
            onClick={() =>
              setState((state) => ({ ...state, exportModalVisible: true }))
            }
            type="default"
            style={{ margin: 16 }}
            icon={<FileExcelOutlined />}
          >
            Export Excel
          </Button>
        )
      }
    >
      <div className="CashPlanPage">
        {error && <Alert type="error" message={error} />}
        <MainModal
          modalParams={modalParams}
          scenarioId={currentScenarioId}
          handleCancel={handleCancel}
        />
        {currentScenarioId && (
          <ExportModal
            exportModalVisible={exportModalVisible}
            startingMonth={startingMonth}
            currentScenarioId={currentScenarioId}
            handleCancelExportModal={handleCancelExportModal}
            setError={setError}
          />
        )}
        {!loaded || !sourceData || !statisticData ? (
          <Loader />
        ) : (
          <>
            <Row>
              <Col
                span={24}
                style={{
                  position: "sticky",
                  top: 0,
                  zIndex: 2,
                }}
              >
                <Card
                  bodyStyle={{
                    display: "flex",
                    alignItems: "center",
                    flexShrink: 0,
                    width: "100%",
                  }}
                >
                  {canEdit && !me?.company?.isMultiCompany && (
                    <SubscriptionTooltip plan={currentPlan}>
                      {!tableEditing ? (
                        <Button
                          onClick={handleEditing}
                          type="primary"
                          icon={
                            <Icon
                              path={mdiSquareEditOutline}
                              size={0.7}
                              style={{
                                verticalAlign: "-15%",
                                marginRight: "5",
                              }}
                            />
                          }
                          disabled={currentPlan === plans.START_PLAN_ID}
                          style={{ width: 200 }}
                        >
                          Éditer les budgets
                        </Button>
                      ) : (
                        <Button
                          onClick={handleEditing}
                          type="default"
                          icon={<ArrowUpOutlined />}
                          disabled={currentPlan === plans.START_PLAN_ID}
                          style={{ width: 200 }}
                        >
                          Ne plus éditer
                        </Button>
                      )}
                    </SubscriptionTooltip>
                  )}
                  {!me?.company?.isMultiCompany ? (
                    <>
                      <ScenarioTabs
                        scenarioId={currentScenarioId!}
                        scenarios={scenarios}
                        setScenario={setScenario}
                        createScenario={createScenario}
                        deleteScenario={deleteScenario}
                        canEdit={canEdit}
                        plan={currentPlan}
                      />
                      <div style={{ flexGrow: 1 }}></div>
                      <AccountSelect
                        items={items || []}
                        handleChangeSelectedAccount={
                          handleChangeSelectedAccount
                        }
                      />
                    </>
                  ) : (
                    <div style={{ flexGrow: 1 }}></div>
                  )}
                  <MainChartDatePicker
                    date={startingMonth}
                    setDate={setStartingMonth}
                    incrementStartingMonth={incrementStartingMonth}
                    monthInterval={11}
                  />
                </Card>
              </Col>
              {/* <OnboardingSteps /> */}
              <Col
                id="MainColCashPlanPage"
                style={{
                  marginTop: 10,
                }}
                span={24}
              >
                <Card bodyStyle={{ padding: 0 }}>
                  <Radio.Group
                    onChange={handleModeChange}
                    value={graphicView}
                    style={{ margin: 24 }}
                  >
                    <Radio.Button value="0">Trésorerie</Radio.Button>
                    <Radio.Button value="1">Évolution du budget</Radio.Button>
                    <Radio.Button value="2">Mixte</Radio.Button>
                  </Radio.Group>
                  <MainScenarioCheck
                    scenarioId={currentScenarioId!}
                    scenarios={scenarios}
                    checkedScenarios={checkedScenarios}
                    handleSwitch={handleSwitch}
                    graphicView={graphicView}
                    startingMonth={startingMonth}
                  />
                  <Row wrap={false}>
                    <Col flex="150px">
                      <Row
                        style={{
                          marginLeft: 24,
                          marginBottom: 24,
                        }}
                      >
                        <Col>{formatConsolidatedBalance(accounts || [])}</Col>
                        <Col style={{ marginTop: 20 }}></Col>
                      </Row>
                    </Col>
                    <Col flex="auto">
                      <MainReChart
                        data={statisticData}
                        startingMonth={startingMonth}
                        loaded={loaded}
                        checkedScenarios={checkedScenarios}
                        scenarioId={currentScenarioId!}
                        scenarios={scenarios}
                        overdraft={me?.company?.overdraft}
                        graphicView={graphicView}
                      />
                    </Col>
                  </Row>

                  <MainTable
                    data={statisticData}
                    sourceData={sourceData}
                    scenarioId={currentScenarioId!}
                    baseScenarioId={baseScenarioId!}
                    duplicateGoal={duplicateGoal}
                    saveGoal={saveGoal}
                    computeVATGoal={computeVATGoal}
                    tableEditing={tableEditing}
                    startingMonth={startingMonth}
                    categories={categories}
                    showModal={showModal}
                    loaded={loaded}
                    selectedMonth={selectedMonth}
                    setSelectedMonth={setSelectedMonth}
                    withInvoices={!!me?.company?.isInvoiceEnabled}
                    expandedRowKeys={expandedRowKeys}
                    handleExpand={handleExpand}
                    expandAll={expandAll}
                  />
                </Card>
              </Col>
            </Row>
          </>
        )}
      </div>
    </GeneralLayout>
  );
}

export default CashPlanPage;
