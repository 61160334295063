import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";
import { mdiRepeatVariant } from "@mdi/js";
import Icon from "@mdi/react";
import {
  Alert,
  Button,
  Checkbox,
  Divider,
  Input,
  Modal,
  PaginationProps,
  Progress,
  Space,
  Table,
  Tag,
  Typography,
} from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import ReconciliationInvoicesTable from "components/ReconciliationInvoicesTable";
import { TransactionReconciliationModalParams } from "components/TransactionsTable";
import numeral from "numeral";
import { FC, ReactElement, useEffect, useState } from "react";
import categoryService from "services/categoryService";
import invoiceService, {
  InvoiceQuickFilterType,
} from "services/invoiceService";
import useSWR, { mutate } from "swr";
import { Invoice, Transaction } from "types";
import { formatTransactionAmount, sumOfAmounts } from "utils/amount";
import { getFormattedFullDate } from "utils/date";
import errorHandler from "utils/errorHandler";
import { computeQuery } from "utils/strapi";
import { findTree } from "utils/tree";

interface Props {
  reconciliateTransaction: any;
  modalParams: TransactionReconciliationModalParams;
  setModalParams: any;
  validatePrereconciliation: (
    invoice: Invoice,
    isValidated: boolean
  ) => Promise<void>;
}

const TransactionReconciliationModal: FC<Props> = ({
  reconciliateTransaction,
  modalParams,
  setModalParams,
  validatePrereconciliation,
}) => {
  const {
    data: categories,
    // error
  } = useSWR(
    "categoryService.getAllWithoutDefault",
    categoryService.getAllWithoutDefault
  );
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [pagination, setPagination] = useState<PaginationProps>({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [selectedInvoices, setSelectedInvoices] = useState<Invoice[]>([]);
  const [error, setError] = useState<ReactElement | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [shouldCreateRemainingInvoice, setShouldCreateRemainingInvoice] =
    useState(true);

  const handleOkModal = async () => {
    const { reconciliatingTransaction } = modalParams;
    if (!reconciliatingTransaction) {
      return;
    }
    setButtonLoading(true);
    const invoiceIds = selectedInvoices.map((invoice) => invoice.id);
    await reconciliateTransaction(
      reconciliatingTransaction.id,
      invoiceIds,
      shouldCreateRemainingInvoice
    );

    setModalParams({ open: false });
    setSelectedInvoices([]);
    setButtonLoading(false);
  };

  const handleCancelModal = () => {
    setModalParams({ open: false });
    setSelectedInvoices([]);
  };

  const loadData = (
    quickFilter: InvoiceQuickFilterType,
    newPagination?: any,
    filters?: any,
    sorter?: any
  ) => {
    const updatedPagination = {
      ...pagination,
      ...newPagination,
    };
    setPagination(updatedPagination);

    const updatedFilteredInfo = {
      ...filteredInfo,
      ...filters,
    };
    setFilteredInfo(updatedFilteredInfo);

    const updatedSortedInfo = {
      ...sortedInfo,
      ...sorter,
    };
    setSortedInfo(updatedSortedInfo);

    let query = computeQuery({
      pagination: updatedPagination,
      filteredInfo: updatedFilteredInfo,
      sortedInfo: updatedSortedInfo,
      defaultSortColum: "dueDate",
      defaultSortOrder: "ASC",
    });

    if (!reconciliatingTransaction) {
      return;
    }
    if (reconciliatingTransaction.amount >= 0) {
      query +=
        "&_where[0][_or][0][0][amount_gt]=0&_where[0][_or][0][1][kind_ne]=cashOut&_where[0][_or][1][kind]=cashIn";
    } else {
      query +=
        "&_where[0][_or][0][0][amount_lt]=0&_where[0][_or][0][1][kind_ne]=cashIn&_where[0][_or][1][kind]=cashOut";
    }
    query += `&currency_code=${reconciliatingTransaction.currency_code}&_where[1][_or][0][provider_null]=true&_where[1][_or][1][provider]=gesco`;

    const reconciliatingTransactionAmount = Math.abs(
      reconciliatingTransaction.amount
    );
    const reconciliatingTransactionSign = Math.sign(
      reconciliatingTransaction.amount
    );
    const sumOfInvoices =
      reconciliatingTransactionSign *
      sumOfAmounts(selectedInvoices.map((invoice) => invoice.amount));
    const sumOfReconciliatedInvoices =
      reconciliatingTransactionSign *
      sumOfAmounts(
        reconciliatingTransaction.invoices.map((invoice) => invoice.amount)
      );

    const remainingAmountToReconciliate =
      (reconciliatingTransactionAmount * 100 -
        sumOfReconciliatedInvoices * 100 -
        sumOfInvoices * 100) /
      100;

    if (selectedInvoices.length > 0) {
      // credit notes (kind not null) always satisfy this condition
      if (reconciliatingTransaction.amount > 0) {
        query += `&amount_lte=${Math.max(remainingAmountToReconciliate, 0)}`;
      } else {
        query += `&amount_gte=${-Math.max(remainingAmountToReconciliate, 0)}`;
      }
      selectedInvoices.forEach((invoice) => {
        query += `&id_nin=${invoice.id}`;
      });
    }

    return Promise.all([
      invoiceService.getAll({ query, quickFilter }),
      invoiceService.getAllCount({ query, quickFilter }),
    ])
      .then(([invoices, invoicesCount]) => {
        setInvoices(invoices);
        setPagination((pagination) => ({
          ...pagination,
          total: invoicesCount,
        }));
        mutate("invoiceService.getAllUncategorizedCount");
        mutate("invoiceService.getAllLateCount");
        mutate("invoiceService.getAllPrereconciliatedCount");
        setLoading(false);
      })
      .catch(errorHandler(setError));
  };

  useEffect(() => {
    loadData("all", { current: 1 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalParams, selectedInvoices]);

  const validatePrereconciliationLocal = (
    invoice: Invoice,
    isValidated: boolean,
    reconciliatingTransaction?: Transaction
  ) => {
    const reconciliatedInvoices = isValidated
      ? reconciliatingTransaction?.invoices.map((currentInvoice) =>
          currentInvoice.id === invoice.id
            ? { ...invoice, prereconciliated: false, paid: true }
            : invoice
        )
      : reconciliatingTransaction?.invoices.filter(
          (currentInvoice) => currentInvoice.id !== invoice.id
        );

    setModalParams({
      ...modalParams,
      reconciliatingTransaction: {
        ...reconciliatingTransaction,
        invoices: reconciliatedInvoices,
      },
    });
  };

  const columnsInvoices = [
    {
      title: "Date échéance",
      dataIndex: "dueDate",
      key: "dueDate",
      width: "12%",
      render: (date: string) => getFormattedFullDate(new Date(date)),
    },
    {
      title: "Libellé",
      dataIndex: "description",
      key: "description",
      width: "35%",
      ellipsis: true,
    },
    {
      title: "Statut",
      dataIndex: "dueDate",
      key: "status",
      render: (_: any, invoice: Invoice) => {
        let status = <></>;
        if (invoice.paid) {
          status = <Tag color="green">Payée</Tag>;
        } else if (new Date(invoice.dueDate).getTime() > new Date().getTime()) {
          status = <Tag color="gold">En attente</Tag>;
        } else {
          status = <Tag color="red">En retard</Tag>;
        }
        return (
          <>
            {status}
            {invoice.prereconciliated && (
              <>
                <Button
                  type="link"
                  onClick={async () => {
                    await validatePrereconciliation(invoice, true);
                    validatePrereconciliationLocal(
                      invoice,
                      true,
                      modalParams.reconciliatingTransaction
                    );
                  }}
                >
                  <CheckCircleTwoTone
                    twoToneColor="#52c41a"
                    style={{ fontSize: 25 }}
                  />
                </Button>
                <Button
                  type="link"
                  onClick={async () => {
                    await validatePrereconciliation(invoice, false);
                    validatePrereconciliationLocal(
                      invoice,
                      false,
                      modalParams.reconciliatingTransaction
                    );
                  }}
                >
                  <CloseCircleTwoTone
                    twoToneColor="#dc143c"
                    style={{ fontSize: 25 }}
                  />
                </Button>
              </>
            )}
          </>
        );
      },
    },
    {
      title: "Catégorie",
      dataIndex: "category",
      key: "category",
      render: (_: any, invoice: Invoice) => (
        <Tag>
          {findTree({
            categories: categories || [],
            categoryId: invoice.category,
          })?.name || "Pas de catégorie"}
        </Tag>
      ),
    },
    {
      title: "Montant TTC",
      dataIndex: "amount",
      key: "amount",
      width: "12%",
      render: (_: any, invoice: Invoice) => formatTransactionAmount(invoice),
      align: "right",
    },
  ];

  const changeParams = (pagination: any, filters: any, sorter: any) => {
    loadData("all", pagination, filters, sorter);
  };

  const handleSearch = (value: string) => {
    setSearchText(value);
    if (value) {
      loadData(
        "all",
        { ...pagination, current: 1 },
        {
          description: [value],
        }
      );
    } else {
      loadData(
        "all",
        { ...pagination, current: 1 },
        {
          description: undefined,
        }
      );
    }
  };

  const { open, reconciliatingTransaction } = modalParams;
  if (!reconciliatingTransaction) {
    return <></>;
  }

  const reconciliatingTransactionAmount = Math.abs(
    reconciliatingTransaction.amount
  );
  const reconciliatingTransactionSign = Math.sign(
    reconciliatingTransaction.amount
  );
  const sumOfInvoices =
    reconciliatingTransactionSign *
    sumOfAmounts(selectedInvoices.map((invoice) => invoice.amount));
  const sumOfReconciliatedInvoices =
    reconciliatingTransactionSign *
    sumOfAmounts(
      reconciliatingTransaction.invoices.map((invoice) => invoice.amount)
    );

  const remainingAmountToReconciliate =
    (reconciliatingTransactionAmount * 100 -
      sumOfReconciliatedInvoices * 100 -
      sumOfInvoices * 100) /
    100;

  return (
    <>
      <Modal
        title={
          <Space>
            <Icon
              path={mdiRepeatVariant}
              size={0.7}
              style={{ verticalAlign: "-20%" }}
            />
            Rapprocher des factures à
            <Typography.Text code>
              {reconciliatingTransaction?.description}
            </Typography.Text>
          </Space>
        }
        open={open}
        onOk={handleOkModal}
        onCancel={handleCancelModal}
        destroyOnClose
        footer={[
          <Button key="back" onClick={handleCancelModal}>
            Annuler
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={buttonLoading}
            onClick={handleOkModal}
            disabled={selectedInvoices.length === 0}
          >
            Rapprocher
          </Button>,
        ]}
        width="85%"
      >
        {error && <Alert type="error" message={error} />}
        {remainingAmountToReconciliate > 0 && (
          <>
            <div
              style={{
                marginBottom: 16,
                justifyContent: "space-between",
              }}
            >
              <Input.Search
                onSearch={handleSearch}
                allowClear
                style={{
                  width: 350,
                }}
              />
            </div>
            <ReconciliationInvoicesTable
              categories={categories || []}
              invoices={invoices}
              loading={loading}
              changeParams={changeParams}
              pagination={pagination}
              selectedInvoices={selectedInvoices}
              setSelectedInvoices={setSelectedInvoices}
              searchText={searchText}
            />
          </>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: 10,
          }}
        >
          <Space align="center" direction="vertical">
            <Progress
              width={80}
              type="circle"
              percent={Number(
                (
                  sumOfReconciliatedInvoices +
                  (sumOfInvoices / reconciliatingTransaction.amount) * 100
                ).toFixed(2)
              )}
            />
            <Typography.Text strong>
              {`${numeral(sumOfReconciliatedInvoices + sumOfInvoices).format(
                "0,0.00"
              )} €`}{" "}
              / {formatTransactionAmount(reconciliatingTransaction)}
            </Typography.Text>
          </Space>
        </div>
        {(selectedInvoices?.length !== 0 ||
          reconciliatingTransaction.invoices.length !== 0) && (
          <>
            <Divider />
            <Typography.Title style={{ textAlign: "center" }} level={4}>
              Factures rapprochées
            </Typography.Title>
            <Table
              locale={{
                emptyText: "Aucune facture à afficher",
              }}
              // @ts-ignore
              columns={columnsInvoices}
              dataSource={[
                ...reconciliatingTransaction.invoices,
                ...selectedInvoices,
              ]}
              size="small"
              loading={loading}
              onChange={changeParams}
              pagination={pagination}
              rowKey="id"
              showSorterTooltip={false}
              rowClassName={(invoice) =>
                invoice.ignored ? "table-row-ignored" : ""
              }
            />
          </>
        )}
        <Divider />
        {remainingAmountToReconciliate < 0 && (
          <Typography style={{ textAlign: "right" }}>
            <Checkbox
              checked={shouldCreateRemainingInvoice}
              onChange={(e: CheckboxChangeEvent) =>
                setShouldCreateRemainingInvoice(e.target.checked)
              }
            >
              Une nouvelle facture de{" "}
              {formatTransactionAmount({
                amount:
                  Math.sign(reconciliatingTransaction.amount) *
                  Math.abs(remainingAmountToReconciliate),
                currency_code: reconciliatingTransaction.currency_code,
              })}{" "}
              sera créée (montant restant sur la facture initiale).
            </Checkbox>
          </Typography>
        )}
      </Modal>
    </>
  );
};

export default TransactionReconciliationModal;
