import { Alert, Card, Col, Menu, Row } from "antd";
import GeneralLayout from "components/GeneralLayout";
import { ReactElement, useState } from "react";
import { Link, Route, Routes, useLocation } from "react-router-dom";
import { ProfileForm } from "./ProfileForm";

function ProfilePage() {
  const [error, setError] = useState<ReactElement | undefined>(undefined);
  const location = useLocation();

  const menu = (
    <Menu
      selectedKeys={[location?.pathname]}
      mode="horizontal"
      items={[
        { key: "/profile", label: <Link to="/profile">Infos</Link> },
        // {
        //   key: "/profile/refer-a-friend",
        //   label: (
        //     <Link to="/profile/refer-a-friend">Gagnez 100€ par parrainage</Link>
        //   ),
        // },
      ]}
    />
  );

  return (
    <GeneralLayout title="Mon Profil">
      <div className="ProfilePage">
        {error && <Alert type="error" message={error} />}
        <Card>
          <Row>
            <Col span={24}>
              {menu}
              <Routes>
                <Route index element={<ProfileForm setError={setError} />} />
                {/* <Route
                  path="/refer-a-friend"
                  element={<AffiliateForm setError={setError} />}
                /> */}
              </Routes>
            </Col>
          </Row>
        </Card>
      </div>
    </GeneralLayout>
  );
}

export default ProfilePage;
