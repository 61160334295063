import { Alert, Card, Col, Menu, Row } from "antd";
import GeneralLayout from "components/GeneralLayout";
import { AuthContext } from "contexts";
import { ReactElement, useContext, useState } from "react";
import { Link, Navigate, Route, Routes, useLocation } from "react-router-dom";
import { rolesEnum } from "utils/constants";
import CompanyCategorizationRulesForm from "./CompanyCategorizationRulesForm";
import CompanyImportHistory from "./CompanyImportHistory";
import { CompanyInfoForm } from "./CompanyInfoForm";
import { CompanyUsersForm } from "./CompanyUsersForm";
import { MultiCompanyInfoForm } from "./MultiCompanyInfoForm";

function CompanyPage() {
  const [error, setError] = useState<ReactElement | undefined>(undefined);
  const { me } = useContext(AuthContext);
  const location = useLocation();

  const menu = (
    <Menu
      selectedKeys={[location?.pathname]}
      mode="horizontal"
      items={[
        { key: "/company", label: <Link to="/company">Infos</Link> },
        ...(!me?.company?.isMultiCompany
          ? [
              {
                key: "/company/rules",
                label: (
                  <Link to="/company/rules">Règles de catégorisation</Link>
                ),
              },
            ]
          : []),
        ...(me?.role?.id === rolesEnum["ADMIN"]
          ? [
              {
                key: "/company/users",
                label: <Link to="/company/users">Utilisateurs</Link>,
              },
            ]
          : []),
        ...(!me?.company?.isMultiCompany
          ? [
              {
                key: "/company/import-history",
                label: (
                  <Link to="/company/import-history">Historique d'imports</Link>
                ),
              },
            ]
          : []),
      ]}
    />
  );

  return (
    <GeneralLayout title={"Paramètres de l'entreprise"}>
      <div className="CompanyPage">
        {error && <Alert type="error" message={error} />}

        <Card>
          <Row>
            <Col span={24}>
              {menu}
              <Routes>
                <Route
                  index
                  element={
                    !me?.company?.isMultiCompany ? (
                      <CompanyInfoForm setError={setError} />
                    ) : (
                      <MultiCompanyInfoForm setError={setError} />
                    )
                  }
                />
                <Route
                  path="/rules"
                  element={<CompanyCategorizationRulesForm />}
                />
                {me?.role?.id === rolesEnum["ADMIN"] && (
                  <Route path="/users" element={<CompanyUsersForm />} />
                )}
                <Route
                  path="/import-history"
                  element={<CompanyImportHistory />}
                />
                <Route
                  path="*"
                  element={<Navigate to="/company" replace={true} />}
                />
              </Routes>
            </Col>
          </Row>
        </Card>
      </div>
    </GeneralLayout>
  );
}

export default CompanyPage;
