import {
  CreditCardOutlined,
  DeleteOutlined,
  FormOutlined,
  ImportOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  List,
  Popconfirm,
  Space,
  Switch,
  Typography,
} from "antd";
import TransactionAddManyModal from "components/TransactionAddManyModal";
import UpdateAccountModal from "components/UpdateAccountModal";
import { FC, ReactElement, useState } from "react";
import { Account, ImportTransactions, Item } from "types";
import { formatAccountBalance } from "utils/amount";
import { getFormattedDateTime } from "utils/date";

export type TransactionsImportModalParams = {
  open: boolean;
  accountId?: number;
};
export type UpdateAccountModalParams = {
  open: boolean;
} & Partial<Account>;

const ItemStatus = ({
  status,
  statusDescription,
  proValidation,
  retryConnection,
  sca,
  automaticRefresh,
}: {
  status: number;
  statusDescription: string;
  proValidation: () => void;
  retryConnection: () => void;
  sca: () => void;
  automaticRefresh: boolean;
}) => {
  let message: string = "";
  let action: ReactElement | undefined;
  if (status === -2) {
    message = "Récemment ajouté, synchronisation en cours";
  } else if (status === -3) {
    message = "Récemment modifié, synchronisation en cours";
  } else if (status === 402) {
    message =
      "Identifiants invalides, êtes-vous sûr d'utiliser le bon mot de passe ?";
    action = (
      <Button type="link" onClick={retryConnection} style={{ padding: 0 }}>
        Cliquez ici pour rafraîchir la connexion
      </Button>
    );
  } else if (status === 429) {
    message =
      "Connectez-vous via le site de votre banque, une action est requise";
  } else if (status === 430) {
    message =
      "Connectez-vous via le site de votre banque, votre mot de passe doit être changé.";
  } else if (status === 1003) {
    message = "Synchronisation impossible pour le moment";
  } else if (status === 1005) {
    message = "Ce type de compte n'est pas supporté";
  } else if (status === 1007) {
    message = "Synchronisation temporairement désactivée";
  } else if (status === 1010) {
    message =
      "Votre banque demande une authentification forte (code à usage unique)";
    action = (
      <Button type="link" onClick={sca} style={{ padding: 0 }}>
        Cliquez ici pour renseigner votre code
      </Button>
    );
  } else if (status === 1099) {
    message = "Ce compte est en cours de migration vers une autre banque";
  } else if (status === 1100) {
    message = "Compte professionnel, vous devez valider votre compte";
    action = (
      <Button type="link" onClick={proValidation} style={{ padding: 0 }}>
        Cliquez ici pour valider votre compte
      </Button>
    );
  } else if (!automaticRefresh) {
    message =
      "Votre banque nécessite une authentification forte à chaque synchronisation";
    action = (
      <Button type="link" onClick={sca} style={{ padding: 0 }}>
        Cliquez ici pour rafraîchir la connexion
      </Button>
    );
  }
  return (
    <Typography.Text type="warning">
      {(statusDescription || message) && (
        <>
          {statusDescription || message}
          <br />
          {action}
        </>
      )}
    </Typography.Text>
  );
};

interface Props {
  item: Item;
  deleteItem: (itemId: number) => Promise<void>;
  setActive: (
    itemId: number,
    accountId: number,
    checked: boolean
  ) => Promise<void>;
  retryConnection: (itemId: number) => Promise<void>;
  sca: (itemId: number) => Promise<void>;
  proValidation: () => Promise<void>;
  isRegister: boolean;
  isDemoCompany: boolean;
  importTransactions: (values: ImportTransactions) => Promise<void>;
  updateAccount: (
    accountId: number,
    {
      balance,
      customName,
      hide,
    }: { balance: number; customName: string; hide: boolean }
  ) => Promise<void>;
  refreshItem: (itemId: number) => Promise<void>;
  isRefreshing: boolean;
}

const BankItem: FC<Props> = ({
  item,
  deleteItem,
  setActive,
  sca,
  proValidation,
  retryConnection,
  isRegister,
  isDemoCompany,
  importTransactions,
  updateAccount,
  refreshItem,
  isRefreshing,
}) => {
  const [transactionsImportModalParams, setTransactionsImportModalParams] =
    useState<TransactionsImportModalParams>({ open: false });
  const [updateAccountModalParams, setUpdateAccountModalParams] =
    useState<UpdateAccountModalParams>({ open: false });

  const handleRefresh = (itemId: number) => () => {
    refreshItem(itemId);
  };

  const handleDelete = (itemId: number) => () => {
    deleteItem(itemId);
  };

  const handleSetActive =
    (itemId: number, accountId: number) => (checked: boolean) => {
      setActive(itemId, accountId, checked);
    };

  const handleProValidation = () => {
    proValidation();
  };

  const handleRetryConnection = (itemId: number) => () => {
    retryConnection(itemId);
  };

  const handleSca = (itemId: number) => () => {
    sca(itemId);
  };

  return (
    <>
      <UpdateAccountModal
        updateAccount={updateAccount}
        updateAccountModalParams={updateAccountModalParams}
        setUpdateAccountModalParams={setUpdateAccountModalParams}
      />
      <TransactionAddManyModal
        importTransactions={importTransactions}
        transactionsImportModalParams={transactionsImportModalParams}
        setTransactionsImportModalParams={setTransactionsImportModalParams}
      />
      <List.Item>
        <List.Item.Meta
          avatar={
            <Avatar
              style={{ width: 64 }}
              shape="square"
              src={item.bank.logo_url}
            >
              {item.bank.name.charAt(0)}
            </Avatar>
          }
          title={
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Space>
                {item.bank.name}
                {!(
                  item.isManualData ||
                  item.isDemoData ||
                  item.isMigrationData
                ) && (
                  <Button
                    type="link"
                    onClick={handleRefresh(item.id)}
                    disabled={isRefreshing}
                    icon={<SyncOutlined spin={isRefreshing} />}
                  />
                )}
                {item.isDemoData && !isRegister && !isDemoCompany && (
                  <Typography.Text code>
                    Données de démo, prêt à passer sur des données réelles ?{" "}
                    <Button type={"link"} onClick={handleDelete(item.id)}>
                      Cliquez ici pour commencer
                    </Button>
                  </Typography.Text>
                )}
              </Space>

              <div>
                {!(
                  item.isManualData ||
                  item.isDemoData ||
                  item.isMigrationData
                ) && (
                  <Button
                    type={"link"}
                    onClick={handleRetryConnection(item.id)}
                    icon={<FormOutlined />}
                  />
                )}
                <Popconfirm
                  title={
                    <Space direction="vertical">
                      Êtes-vous sûr de vouloir supprimer cette connexion
                      bancaire ?
                      <Typography.Text type="danger">
                        Toutes vos transactions catégorisées seront perdues.
                      </Typography.Text>
                    </Space>
                  }
                  okButtonProps={{ danger: true }}
                  onConfirm={handleDelete(item.id)}
                  okText="Confirmer"
                  placement="topLeft"
                >
                  <Button type={"link"} icon={<DeleteOutlined />} />
                </Popconfirm>
              </div>
            </div>
          }
          description={
            <ItemStatus
              status={item.status}
              statusDescription={item.status_code_description}
              proValidation={handleProValidation}
              retryConnection={handleRetryConnection(item.id)}
              sca={handleSca(item.id)}
              automaticRefresh={item.bank.automatic_refresh}
            />
          }
        />
        <List
          bordered
          dataSource={item.accounts.filter((account) => !account.hide)}
          renderItem={(account: Account) => (
            <List.Item>
              <div
                style={{
                  display: "flex",
                  width: "66%",
                  alignItems: "center",
                }}
              >
                <Switch
                  checked={account.active}
                  disabled={account.disabled}
                  onChange={handleSetActive(item.id, account.id)}
                />{" "}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: 10,
                  }}
                >
                  <Space>
                    <Typography.Link
                      onClick={() =>
                        setUpdateAccountModalParams({
                          open: true,
                          ...account,
                        })
                      }
                    >
                      {account.customName || account.name}
                    </Typography.Link>
                    {account.type === "card" && <CreditCardOutlined />}
                    {account.isManualData && (
                      <Button
                        type="link"
                        onClick={() =>
                          setTransactionsImportModalParams({
                            open: true,
                            accountId: account.id,
                          })
                        }
                        icon={<ImportOutlined />}
                      />
                    )}
                  </Space>
                  <Typography.Text type="secondary" style={{ fontSize: 12 }}>
                    {getFormattedDateTime(new Date(account.bridgeUpdatedAt))}{" "}
                  </Typography.Text>
                </div>
              </div>
              <div>{formatAccountBalance(account)}</div>
            </List.Item>
          )}
        />
      </List.Item>
    </>
  );
};

export default BankItem;
