import { ImportInvoicesMapping } from "types";
import axios from "utils/setupAxios";

export async function getAll(): Promise<ImportInvoicesMapping[]> {
  return axios.get(`/import-invoices-mappings`).then((res) => res.data);
}

export async function create(values: ImportInvoicesMapping): Promise<ImportInvoicesMapping> {
  return axios.post("/import-invoices-mappings", values).then((res) => res.data);
}

export async function update(
  importInvoicesMappingId: number,
  values: ImportInvoicesMapping
): Promise<ImportInvoicesMapping> {
  return axios
    .put(`/import-invoices-mappings/${importInvoicesMappingId}`, values)
    .then((res) => res.data);
}

const importInvoicesMappingService = { getAll, create, update };
export default importInvoicesMappingService;
