import { ImportHistory } from "types";
import axios from "utils/setupAxios";

export async function getAll(): Promise<ImportHistory[]> {
    return axios.get("/import-histories").then((res)=>res.data);
}

export async function remove(importHistoryId: number): Promise<ImportHistory> {
    return axios.delete(`/import-histories/${importHistoryId}`).then((res) => res.data);
}

const importHistoryService = { getAll, remove };
export default importHistoryService;