import { Breadcrumb, Modal } from "antd";
import { ModalParams } from "components/CashPlanPage";
import MainModalContent from "components/MainModalContent";
import { FC } from "react";
import { KindEnum } from "utils/kind";

interface Props {
  scenarioId: number | undefined;
  modalParams: ModalParams;
  handleCancel: () => void;
}

const MainModal: FC<Props> = ({ scenarioId, modalParams, handleCancel }) => (
  <Modal
    title={
      <Breadcrumb>
        {modalParams.category ? (
          <>
            <Breadcrumb.Item>
              {modalParams.category?.kind === KindEnum.cashIn
                ? "Encaissements"
                : "Décaissements"}
            </Breadcrumb.Item>
            <Breadcrumb.Item>{modalParams.category?.name}</Breadcrumb.Item>
          </>
        ) : (
          <Breadcrumb.Item>Non catégorisés</Breadcrumb.Item>
        )}
      </Breadcrumb>
    }
    style={{ top: 20 }}
    open={modalParams.open}
    onCancel={handleCancel}
    footer={null}
    width={"80%"}
    destroyOnClose
  >
    <MainModalContent modalParams={modalParams} scenarioId={scenarioId} />
  </Modal>
);

export default MainModal;
