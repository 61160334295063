import { mdiDeleteOutline } from "@mdi/js";
import Icon from "@mdi/react";
import { Button, Popconfirm, Space, Table, Tag, Typography } from "antd";
import importHistoryService from "services/importHistoryService";
import useSWR, { mutate } from "swr";
import { ImportHistory } from "types";
import { getFormattedFullDate } from "utils/date";

function CompanyImportHistory() {
  const { data: importHistories } = useSWR(
    "importHistoryService.getAll",
    importHistoryService.getAll,
    {}
  );
  const columns = [
    {
      title: "Nom du fichier",
      dataIndex: "fileName",
      key: "fileName",
    },
    {
      title: "Date de l'import",
      dataIndex: "importDate",
      key: "importDate",
      render: (date: Date) => getFormattedFullDate(new Date(date)),
    },
    {
      title: "Type de l'import",
      dataIndex: "importType",
      key: "importType",
      render: (importType: string) => (
        <Tag color={importType === "invoices" ? "green" : "volcano"}>
          {importType === "invoices"
            ? "Factures engagées"
            : "Transactions bancaires"}
        </Tag>
      ),
    },
    {
      title: "",
      key: "delete",
      render: (record: ImportHistory) => (
        <Popconfirm
          title={
            <Space direction="vertical">
              <Typography.Text>
                Êtes-vous sûr de vouloir supprimer cet import ?
              </Typography.Text>
              {record.importType === "invoices" ? (
                <Typography.Text type="danger">
                  Toutes les factures importées seront définitivement
                  supprimées.
                </Typography.Text>
              ) : (
                <Typography.Text type="danger">
                  Toutes les transactions importées seront définitivement
                  supprimées.
                </Typography.Text>
              )}
            </Space>
          }
          okButtonProps={{ danger: true }}
          onConfirm={() => handleDelete(record)}
          okText="Confirmer"
          placement="topLeft"
        >
          <Button
            type="link"
            icon={<Icon path={mdiDeleteOutline} size={0.9} />}
          />
        </Popconfirm>
      ),
    },
  ];

  const handleDelete = async (record: ImportHistory) => {
    await importHistoryService.remove(record.id);
    mutate("importHistoryService.getAll");
  };

  return (
    <Table
      columns={columns}
      dataSource={importHistories}
      locale={{
        emptyText: "Vous n'avez encore réalisé aucun import",
      }}
    />
  );
}

export default CompanyImportHistory;
