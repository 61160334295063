import { Button, FormInstance, Table, Tag, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import { default as React, FC, useEffect, useState } from "react";
import categoryService from "services/categoryService";
import useSWR from "swr";
import { Transaction } from "types";
import { formatTransactionAmountText } from "utils/amount";
import { KindEnum } from "utils/kind";
import { InputRow } from ".";
import { EditableCell } from "./EditableCell";
import { EditableRow } from "./EditableRow";
import "./index.css";
import SplittingCategorySelect from "./SplittingCategorySelect";

export const EditableContext = React.createContext<FormInstance<any> | null>(
  null
);

export const SplittingTransactionEditableTable: FC<{
  splittingTransaction: Transaction;
  inputRows: InputRow[];
  setInputRows: (inputRows: InputRow[]) => void;
}> = ({ splittingTransaction, inputRows, setInputRows }) => {
  let { data: categories } = useSWR(
    "categoryService.getAllWithoutDefault",
    categoryService.getAllWithoutDefault
  );
  const [count, setCount] = useState(1);

  useEffect(() => {
    const halfAmount = Math.floor(splittingTransaction.amount) / 2;
    setInputRows([
      {
        key: 0,
        amount: halfAmount,
        currency_code: splittingTransaction.currency_code,
      },
      {
        key: 1,
        amount: splittingTransaction.amount - halfAmount,
        currency_code: splittingTransaction.currency_code,
      },
    ]);
    setCount(2);
  }, [splittingTransaction, setInputRows]);

  const remainingAmount =
    (splittingTransaction.amount * 100 -
      inputRows.reduce((sum, { amount }) => sum + amount * 100, 0)) /
    100;
  const overAllocated =
    Math.sign(remainingAmount) !== Math.sign(splittingTransaction.amount);

  const handleAdd = () => {
    const newInputRow: InputRow = {
      key: count,
      amount: remainingAmount,
      currency_code: splittingTransaction.currency_code,
    };
    setInputRows([...inputRows, newInputRow]);
    setCount(count + 1);
  };

  const handleChange = (inputRow: InputRow) => {
    const newInputRow = [...inputRows];
    const index = newInputRow.findIndex((item) => inputRow.key === item.key);
    if (!inputRow.amount) {
      setInputRows([...inputRows.filter((item) => item.key !== inputRow.key)]);
    } else {
      const item = newInputRow[index];
      newInputRow.splice(index, 1, {
        ...item,
        ...inputRow,
      });
      setInputRows(newInputRow);
    }
  };

  const columns: ColumnsType<InputRow> = [
    {
      title: (
        <Button onClick={handleAdd} type="primary" disabled={overAllocated}>
          Ajouter une transaction
        </Button>
      ),
      dataIndex: "categoryId",
      key: "categoryId",
      width: "70%",
      ellipsis: true,
      render: (_: any, record: InputRow) => (
        <SplittingCategorySelect
          categories={categories || []}
          kind={
            splittingTransaction.amount > 0 ? KindEnum.cashIn : KindEnum.cashOut
          }
          handleChange={(categoryId: number) =>
            handleChange({
              ...record,
              categoryId,
            })
          }
        />
      ),
    },
    {
      title: "Montant TTC",
      dataIndex: "amount",
      key: "amount",
      width: "30%",
      onCell: (record: InputRow) => ({
        record,
        editable: true,
        title: "Montant TTC",
        dataIndex: "amount",
        handleChange: handleChange,
        splittingTransaction,
      }),
      render: (_: any, record: InputRow) => formatTransactionAmountText(record),
      align: "right",
    },
  ];

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  return (
    <Table
      components={components}
      rowClassName={() => "editable-row"}
      bordered
      dataSource={inputRows}
      columns={columns}
      pagination={false}
      className="TransactionsSplittingModal"
      summary={(pageData: readonly InputRow[]) => {
        let sumOfAmounts = 0;

        pageData.forEach(({ amount }) => {
          sumOfAmounts += amount * 100;
        });
        sumOfAmounts /= 100;

        return (
          <>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} align="right">
                <Typography.Text strong>Total</Typography.Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1} colSpan={2} align="right">
                <div
                  className="summary-cell-value-wrap"
                  style={{ paddingRight: 24 }}
                >
                  <Tag
                    color={
                      sumOfAmounts !== splittingTransaction.amount
                        ? "error"
                        : undefined
                    }
                  >
                    {formatTransactionAmountText({
                      amount: sumOfAmounts,
                      currency_code: splittingTransaction.currency_code,
                    })}
                  </Tag>
                </div>
              </Table.Summary.Cell>
            </Table.Summary.Row>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} align="right">
                <Typography.Text strong>
                  Montant total à répartir
                </Typography.Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1} colSpan={2} align="right">
                <div
                  className="summary-cell-value-wrap"
                  style={{ paddingRight: 24 }}
                >
                  <Tag>{formatTransactionAmountText(splittingTransaction)}</Tag>
                </div>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </>
        );
      }}
    />
  );
};
