import { Form, TreeSelect, Typography } from "antd";
import { FC } from "react";
import Highlighter from "react-highlight-words";
import { Category } from "types";
import { KindEnum } from "utils/kind";
import { findTree, formatTree } from "utils/tree";

const { Text } = Typography;

interface Props {
  categories: Category[];
  kind?: KindEnum;
  disabled?: boolean;
  searchText?: string;
  setVat?: (vat?: number) => void;
  required?: boolean;
}

const CategorySelect: FC<Props> = ({
  categories,
  kind,
  disabled = false,
  searchText = "",
  setVat,
  required = true,
}) => {
  const cashInCategories = categories.filter(
    (category) => category.kind === KindEnum.cashIn
  );
  const cashOutCategories = categories.filter(
    (category) => category.kind === KindEnum.cashOut
  );

  const formatFunction = (category: Category) => ({
    title: (
      <Highlighter
        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={category.name}
      />
    ),
    searchValue: category.name,
    value: category.id,
    disabled: Boolean(kind) && category.kind !== kind,
    selectable: !category.children?.length,
    ...category,
  });

  const formattedCategories = formatTree({
    categories: [
      {
        name: "Encaissements",
        id: "cashIn",
        kind: KindEnum.cashIn,
        children: cashInCategories,
      },
      {
        name: "Décaissements",
        id: "cashOut",
        kind: KindEnum.cashOut,
        children: cashOutCategories,
      },
    ],
    formatFunction,
  });

  return (
    <Form.Item
      name="category"
      label="Catégorie"
      rules={required ? [{ required: true, message: "Requis" }] : []}
    >
      <TreeSelect
        virtual={false}
        showSearch
        treeNodeFilterProp="searchValue"
        style={{ width: "80%" }}
        dropdownStyle={{ maxHeight: 400 }}
        placeholder={
          <Text mark strong>
            Sélectionnez une catégorie
          </Text>
        }
        onChange={(value: number) => {
          const currentCategory = findTree({
            categories,
            categoryId: value,
          });
          if (setVat) {
            setVat(currentCategory?.vat);
          }
        }}
        treeDefaultExpandAll
        getPopupContainer={(trigger) => {
          const modalContainer = document.getElementById("MainModalContent");

          return modalContainer || trigger.parentNode;
        }}
        treeData={formattedCategories}
        disabled={disabled}
        // dropdownRender={(menu) => (
        //   <div>
        //     {menu}
        //     <Divider style={{ margin: "4px 0" }} />
        //     <div
        //       style={{
        //         display: "flex",
        //         flexWrap: "nowrap",
        //         padding: 8,
        //       }}
        //     >
        //       <Button type="link" onClick={() => showModal(transaction, kind)}>
        //         <b>+ Ajouter une catégorie</b>
        //       </Button>
        //     </div>
        //   </div>
        // )}
      />
    </Form.Item>
  );
};

export default CategorySelect;
