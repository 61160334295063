import { mdiPlus } from "@mdi/js";
import Icon from "@mdi/react";
import { Button, Divider, Form, InputNumber, Select } from "antd";
import React, { FC, useState } from "react";
import { delayList } from "utils/constants";

interface Props {
  handleDelayChange: any;
}

export const DelaySelect: FC<Props> = ({ handleDelayChange }) => {
  const [customDelay, setCustomDelay] = useState(undefined);

  return (
    <Form.Item
      noStyle
      shouldUpdate={(prevValues, currentValues) =>
        prevValues.delay !== currentValues.delay
      }
    >
      {({ getFieldValue, setFieldsValue }) => {
        const currentDelay = getFieldValue("delay");

        const delays =
          delayList.indexOf(currentDelay) === -1
            ? [...delayList, currentDelay]
            : delayList;

        const handleCustomRate = () => {
          if (customDelay !== 0 && !customDelay) {
            return;
          }
          setFieldsValue({ delay: customDelay });
          handleDelayChange();
          setCustomDelay(undefined);
        };

        return (
          <Form.Item name="delay" label="Délai de paiement" initialValue={30}>
            <Select
              onDropdownVisibleChange={() => {
                setCustomDelay(undefined);
              }}
              style={{ width: "100%" }}
              getPopupContainer={(trigger) => trigger.parentNode}
              onChange={handleDelayChange}
              dropdownRender={(menu) => (
                <div>
                  {menu}
                  <Divider style={{ margin: "4px 0" }} />
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "nowrap",
                      padding: 8,
                    }}
                  >
                    <InputNumber
                      min={0}
                      step={1}
                      precision={0}
                      value={customDelay}
                      // @ts-ignore
                      onChange={(value) => setCustomDelay(value)}
                      onPressEnter={handleCustomRate}
                    />
                    <Button type="link" onClick={handleCustomRate}>
                      <Icon
                        path={mdiPlus}
                        size={0.8}
                        style={{ verticalAlign: "-22%" }}
                      />
                      Ajouter
                    </Button>
                  </div>
                </div>
              )}
              options={delays
                .sort((a, b) => a - b)
                .map((delay) => {
                  let label;
                  if (delay === 0) {
                    label = "Immédiat";
                  } else {
                    label = `${delay} ${delay === 1 ? "jour" : "jours"}`;
                  }
                  return {
                    label,
                    value: delay,
                    key: delay,
                  };
                })}
            />
          </Form.Item>
        );
      }}
    </Form.Item>
  );
};
