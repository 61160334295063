import { AxiosResponse } from "axios";
import { Category } from "types";
import axios from "utils/setupAxios";
import { getCategoryChildren } from "utils/tree";

export interface CreateCategoryValues {
  name: string;
  note?: string;
  vat?: number;
}

export async function getAll(): Promise<Category[]> {
  const res = await axios.get(
    `/categories?_sort=isDefaultCategory:ASC,order:ASC`
  );
  const categories: Category[] = res.data;
  const output = getCategoryChildren({ categories, parentId: null });
  return output;
}

export async function getAllWithoutDefault(): Promise<Category[]> {
  const res = await axios.get(
    `/categories?isDefaultCategory=false&_sort=order:ASC`
  );
  const categories: Category[] = res.data;
  const output = getCategoryChildren({ categories, parentId: null });
  return output;
}

export async function create(values: CreateCategoryValues): Promise<Category> {
  return axios.post("/categories", values).then((res) => res.data);
}

export async function update(
  categoryId: number,
  values: any
): Promise<Category> {
  return axios.put(`/categories/${categoryId}`, values).then((res) => res.data);
}

export async function remove(categoryId: number): Promise<Category> {
  return axios.delete(`/categories/${categoryId}`).then((res) => res.data);
}

export async function dropInsert(
  categoryId: number,
  targetCategoryId: number,
  position: string
): Promise<AxiosResponse> {
  return axios.put(`/categories/${categoryId}/drop-insert`, {
    target: targetCategoryId,
    position,
  });
}

export async function isDroppableOver(categoryId: number): Promise<{
  transactionCount: number;
  invoiceCount: number;
  goalCount: number;
}> {
  return axios
    .get(`/categories/${categoryId}/is-droppable-over`)
    .then((res) => res.data);
}

const categoryService = {
  getAll,
  getAllWithoutDefault,
  create,
  update,
  remove,
  dropInsert,
  isDroppableOver,
};
export default categoryService;
