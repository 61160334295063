import GeneralLayout from "components/GeneralLayout";
import categoryService from "services/categoryService";
import useSWR, { mutate } from "swr";
import { KindEnum } from "utils/kind";
import { CategoriesTree } from "./CategoriesTree";

function CategoriesPage() {
  const { data: categories } = useSWR(
    "categoryService.getAllWithoutDefault",
    categoryService.getAllWithoutDefault
  );

  const addCategory = async (values: {
    name: string;
    note: string;
    kind: KindEnum;
    vat: number;
    parent?: number;
  }) => {
    await categoryService.create(values);

    mutate("categoryService.getAllWithoutDefault");
  };

  const updateCategory = async (
    id: number,
    values: { name: string; vat: number }
  ) => {
    await categoryService.update(id, values);

    mutate("categoryService.getAllWithoutDefault");
  };

  const deleteCategory = async (id: number) => {
    await categoryService.remove(id);

    mutate("categoryService.getAllWithoutDefault");
  };

  const setCategoryAsGrouper = async (id: number, grouper: boolean) => {
    await categoryService.update(id, { grouper });

    mutate("categoryService.getAllWithoutDefault");
  };

  const insertDrop = async (
    dragCategoryId: number,
    dropCategoryId: number,
    type: string
  ) => {
    await categoryService.dropInsert(dragCategoryId, dropCategoryId, type);

    mutate("categoryService.getAllWithoutDefault");
  };

  const loaded = !!categories;
  const cashInCategories =
    categories?.filter((category) => category.kind === KindEnum.cashIn) || [];
  const cashOutCategories =
    categories?.filter((category) => category.kind === KindEnum.cashOut) || [];

  return (
    <GeneralLayout title="Catégories">
      <div className="CategoriesPage">
        <CategoriesTree
          categories={cashInCategories}
          kind={KindEnum.cashIn}
          addCategory={addCategory}
          updateCategory={updateCategory}
          deleteCategory={deleteCategory}
          setCategoryAsGrouper={setCategoryAsGrouper}
          insertDrop={insertDrop}
          loaded={loaded}
        />
        <CategoriesTree
          categories={cashOutCategories}
          kind={KindEnum.cashOut}
          addCategory={addCategory}
          updateCategory={updateCategory}
          deleteCategory={deleteCategory}
          setCategoryAsGrouper={setCategoryAsGrouper}
          insertDrop={insertDrop}
          loaded={loaded}
        />
      </div>
    </GeneralLayout>
  );
}

export default CategoriesPage;
