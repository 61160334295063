import { Space } from "antd";
import LogoZenfirst from "components/LogoZenfirst";
import { AuthContext } from "contexts";
import { FC, MouseEvent, ReactElement, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./index.css";

const OnboardingLayout: FC<{ children: ReactElement }> = ({ children }) => {
  const navigate = useNavigate();
  const { me } = useContext(AuthContext);

  const handleLogout = (event: MouseEvent) => {
    event.preventDefault();

    navigate("/logout");
  };

  return (
    <div className="OnboardingLayout">
      <div className="OnboardingLayoutHeader">
        <Link to="/">
          <LogoZenfirst />
        </Link>
        <Space>
          <Link to="/change-company">
            Changer d'entreprise ({me?.company?.name})
          </Link>
          {"/"}
          <Link onClick={handleLogout} to="#">
            Déconnexion
          </Link>
        </Space>
      </div>
      <div className="OnboardingLayoutContent">
        <div className="OnboardingLayoutFlex">{children}</div>
      </div>
    </div>
  );
};

export default OnboardingLayout;
