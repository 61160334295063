import { Alert, Button, Form, Input } from "antd";
import UnloggedLayout from "components/UnloggedLayout";
import { FC, ReactElement, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import authService from "services/authService";
import errorHandler from "utils/errorHandler";

interface Props {
  setError: (errorMessage: ReactElement | undefined) => void;
}

const ForgotForm: FC<Props> = ({ setError }) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onFinish = (values: any) => {
    setLoading(true);

    const { email } = values;
    const creds = {
      email: email.trim(),
    };
    authService
      .forgot(creds)
      .then(() => {
        navigate("/login?forgotPassword=true");
      })
      .catch((e) => {
        if (e?.response?.data?.message?.[0]?.messages?.[0]?.id) {
          setError(
            <FormattedMessage
              id={e?.response?.data?.message?.[0]?.messages?.[0]?.id || -1}
              defaultMessage="Un problème est survenu. Veuillez rafraîchir la page ou essayer ultérieurement."
              description="Server response to forgot"
            />
          );
          setLoading(false);
        } else {
          throw e;
        }
      })
      .catch(errorHandler(setError));
  };

  return (
    <Form
      name="forgot"
      className="login-form"
      onFinish={onFinish}
      layout="vertical"
      requiredMark={false}
    >
      <Form.Item
        label="Votre email"
        id="email"
        name="email"
        rules={[
          { required: true, message: "Requis" },
          { type: "email", message: "Email invalide" },
        ]}
      >
        <Input
          type="email"
          autoComplete="email"
          placeholder="Saisissez votre adresse e-mail"
        />
      </Form.Item>
      <Button
        type="primary"
        htmlType="submit"
        className="FormButton"
        loading={loading}
        block
      >
        Réinitialiser le mot de passe
      </Button>
    </Form>
  );
};

function ForgotPage() {
  const [error, setError] = useState<ReactElement | undefined>(undefined);

  return (
    <UnloggedLayout>
      <div className="form-container">
        <h1>Mot de passe oublié ?</h1>
        <p>
          Entrez le mail associé à votre compte, nous vous enverrons un mail
          avec les instructions pour changer votre mot de passe
        </p>
        {error && <Alert type="error" message={error} />}
        <ForgotForm setError={setError} />
      </div>
    </UnloggedLayout>
  );
}

export default ForgotPage;
