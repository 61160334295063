import {
  Button,
  Checkbox,
  Form,
  Input,
  Popconfirm,
  Space,
  Typography,
} from "antd";
import { Store } from "antd/es/form/interface";
import Loader from "components/Loader";
import { AuthContext } from "contexts";
import { FC, ReactElement, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import companyService from "services/companyService";
import { rolesEnum } from "utils/constants";
import errorHandler from "utils/errorHandler";

interface Props {
  setError: (errorMessage: ReactElement | undefined) => void;
}

export const MultiCompanyInfoForm: FC<Props> = ({ setError }) => {
  const { me, setMe } = useContext(AuthContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 8 },
  };
  const formTailLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 8, offset: 4 },
  };

  const handleDelete = () => async () => {
    if (!me || !me.company) {
      return;
    }
    await companyService.remove(me?.company.id);
    const newMe = { ...me, company: undefined };
    //@ts-ignore
    setMe(newMe);
    navigate("/change-company");
  };

  const onFinish = (values: Store) => {
    if (!me) {
      return;
    }

    const { name, isSimpleForecastModeEnabled } = values;

    setLoading(true);
    companyService
      .update(me.company.id, {
        name,
        isSimpleForecastModeEnabled,
      })
      .then((newCompany) => {
        const newMe = { ...me };
        newMe.company = newCompany;
        setMe(newMe);
        setLoading(false);
      })
      .catch(errorHandler(setError));
  };

  return (
    <div style={{ marginTop: 15 }}>
      {!me ? (
        <Loader />
      ) : (
        <Form
          name="update-user"
          {...formItemLayout}
          onFinish={onFinish}
          hideRequiredMark
          initialValues={{
            name: me?.company?.name,
            isSimpleForecastModeEnabled:
              me?.company?.isSimpleForecastModeEnabled,
          }}
          colon={false}
        >
          <Form.Item
            label="Entreprise"
            name="name"
            rules={[
              {
                required: true,
                message: "Requis",
              },
            ]}
          >
            <Input
              autoComplete="organization"
              placeholder="Nom de votre organisation"
            />
          </Form.Item>
          <Form.Item
            label="Calcul des prévisions"
            name="isSimpleForecastModeEnabled"
            valuePropName="checked"
            tooltip={{
              title: (
                <>
                  Le mode simple vous permet d'avoir un budget total qui est
                  égal à la somme de tous les budgets. Ainsi, tout dépassement
                  de budget compense les autres budgets non atteints. Plus
                  d'infos sur{" "}
                  <a
                    href="https://help.zenfirst.fr/fr/article/mode-de-calcul-des-previsions-oh4z8g/?bust=1653493471165"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    notre centre d'aide
                  </a>
                  .
                </>
              ),
            }}
          >
            <Checkbox>Activer le mode simple</Checkbox>
          </Form.Item>
          <Form.Item {...formTailLayout}>
            <Button type="primary" htmlType="submit" block loading={loading}>
              Enregistrer
            </Button>
          </Form.Item>
          {me && me.role.id === rolesEnum["ADMIN"] && (
            <Form.Item {...formTailLayout}>
              <Popconfirm
                title={
                  <Space direction="vertical">
                    <Typography.Text>
                      Êtes-vous sûr de vouloir supprimer cette entreprise ?
                    </Typography.Text>
                    <Typography.Text type="danger">
                      Tous les utilisateurs perdront l'accès.
                    </Typography.Text>
                    <Typography.Text type="danger">
                      Cette action est irréversible.
                    </Typography.Text>
                  </Space>
                }
                okButtonProps={{ danger: true }}
                onConfirm={handleDelete()}
                okText="Confirmer"
                placement="topLeft"
              >
                <Button type="text" danger>
                  Supprimer cette entreprise
                </Button>
              </Popconfirm>
            </Form.Item>
          )}
        </Form>
      )}
    </div>
  );
};
