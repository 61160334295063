import {
  Button,
  Checkbox,
  Form,
  Input,
  InputNumber,
  Modal,
  Typography,
} from "antd";
import { UpdateAccountModalParams } from "components/BankItem";
import { FC, useEffect, useState } from "react";

interface Props {
  updateAccount: (
    accountId: number,
    values: { balance: number; customName: string; hide: boolean }
  ) => Promise<void>;
  updateAccountModalParams: UpdateAccountModalParams;
  setUpdateAccountModalParams: any;
}

const UpdateAccountModalModal: FC<Props> = ({
  updateAccount,
  updateAccountModalParams,
  setUpdateAccountModalParams,
}) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (updateAccountModalParams.open === true) {
      form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateAccountModalParams.open]);

  const handleOkModal = async () => {
    if (!updateAccountModalParams.id) {
      return;
    }

    const values = await form.validateFields();
    const { balance, customName, hide } = values;

    setLoading(true);
    await updateAccount(updateAccountModalParams.id, {
      balance,
      customName,
      hide,
    });
    setLoading(false);
    setUpdateAccountModalParams({ open: false });
  };

  const handleCancelModal = () => {
    setUpdateAccountModalParams({ open: false });
  };

  return (
    <Modal
      title={`Mettre à jour ${updateAccountModalParams.name}`}
      open={updateAccountModalParams.open}
      onOk={handleOkModal}
      onCancel={handleCancelModal}
      forceRender
      destroyOnClose
      footer={[
        <Button key="back" onClick={handleCancelModal}>
          Annuler
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={handleOkModal}
        >
          Mettre à jour
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        name={`add-invoice-form`}
        initialValues={{
          balance: updateAccountModalParams.balance,
          customName: updateAccountModalParams.customName,
          hide: updateAccountModalParams.hide,
        }}
        style={{ paddingTop: 12 }}
      >
        <Form.Item name="name" label="Nom du compte">
          <Typography.Text code>
            {updateAccountModalParams.name}
          </Typography.Text>
        </Form.Item>
        <Form.Item name="customName" label="Nom du compte paramétrable">
          <Input
            onPressEnter={handleOkModal}
            style={{
              width: "100%",
            }}
          />
        </Form.Item>
        {!updateAccountModalParams.isManualData &&
          !updateAccountModalParams.isDemoData &&
          !updateAccountModalParams.isMigrationData && (
            <Form.Item name="hide" valuePropName="checked">
              <Checkbox style={{ color: "red", borderColor: "red" }}>
                Supprimer et cesser la synchronisation de ce compte
                (irréversible)
              </Checkbox>
            </Form.Item>
          )}
        {updateAccountModalParams.isManualData && (
          <Form.Item
            name="balance"
            label="Solde actuel"
            rules={[{ required: true, message: "Requis" }]}
          >
            <InputNumber
              precision={2}
              onPressEnter={handleOkModal}
              style={{
                width: "100%",
              }}
            />
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

export default UpdateAccountModalModal;
