import { Alert, Input, message, PaginationProps } from "antd";
import BatchAction from "components/BatchAction";
import Emoji from "components/Emoji";
import InvoicesTable from "components/InvoicesTable";
import { FC, ReactElement, ReactNode, useEffect, useState } from "react";
import categoryService, {
  CreateCategoryValues,
} from "services/categoryService";
import invoiceService, {
  InvoiceQuickFilterType,
} from "services/invoiceService";
import transactionService from "services/transactionService";
import { mutate } from "swr";
import { Category, Invoice } from "types";
import { CurrencyCode } from "utils/currency";
import errorHandler from "utils/errorHandler";
import { computeQuery } from "utils/strapi";

interface Props {
  categories: Category[];
  quickFilter: InvoiceQuickFilterType;
  withRowSelection?: boolean;
  refresh?: boolean;
}

const InvoicesTableContainer: FC<Props> = ({
  categories,
  quickFilter,
  withRowSelection = true,
  refresh,
}) => {
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState<ReactElement | undefined>(undefined);
  const [pagination, setPagination] = useState<PaginationProps>({
    current: 1,
    pageSize: 100,
    total: 0,
  });
  const [filteredInfo, setFilteredInfo] = useState({ status: ["not_paid"] });
  const [sortedInfo, setSortedInfo] = useState({});
  const [searchText, setSearchText] = useState("");

  const handleCategorizeMany = async (
    invoiceIds: number[],
    categoryId: number
  ) => {
    const updatedInvoices = await invoiceService.categorizeMany(
      invoiceIds,
      categoryId
    );

    if (updatedInvoices > invoiceIds.length) {
      const precategorizedTransactions = updatedInvoices - invoiceIds.length;
      message.success(
        precategorizedTransactions === 1
          ? "Une facture similaire a été précatégorisée dans la même catégorie."
          : `${precategorizedTransactions} factures similaires ont été précatégorisées dans la même catégorie.`
      );
    }

    return loadData(quickFilter);
  };

  const handleUpdateManyCategory = async (
    invoiceIds: number[],
    params: any
  ) => {
    await invoiceService.updateMany(invoiceIds, params);

    return loadData(quickFilter);
  };

  const handleUpdate = async (invoiceId: number, params: any) => {
    await invoiceService.update(invoiceId, params);

    return loadData(quickFilter);
  };

  const handleDelete = async (invoiceId: number) => {
    await invoiceService.remove(invoiceId);

    return loadData(quickFilter);
  };

  const handleDeleteMany = async (invoiceIds: number[]) => {
    await invoiceService.removeMany(invoiceIds);

    return loadData(quickFilter);
  };

  const handleDuplicate = async (values: {
    description: string;
    amount: number;
    transactionDate: string;
    dueDate: string;
    currency_code: CurrencyCode;
    category?: number;
    vat: number;
  }) => {
    await invoiceService.create(values);

    return loadData(quickFilter);
  };

  const handleValidateCategory = async (invoice: Invoice) => {
    await invoiceService.update(invoice.id, { category: invoice.category });

    return loadData(quickFilter);
  };

  const handleValidatePrereconciliation = (
    invoice: Invoice,
    isValidated: boolean
  ) => {
    return invoiceService
      .update(invoice.id, { paid: isValidated })
      .then(() => {
        return loadData(quickFilter);
      })
      .catch(errorHandler(setError));
  };

  const handleReconciliate = (
    transactionId: number,
    invoiceIds: number[],
    shouldCreateRemainingInvoice: boolean
  ) => {
    return transactionService
      .reconciliate(transactionId, invoiceIds, shouldCreateRemainingInvoice)
      .then(() => {
        return loadData(quickFilter);
      })
      .catch(errorHandler(setError));
  };

  const handleAddCategoryAndCategorize = async (
    values: CreateCategoryValues,
    kind: string,
    invoiceIds: number[],
    parent?: number
  ) => {
    if (!categories) {
      return;
    }
    const newCategory = { ...values, kind, parent };
    const category = await categoryService.create(newCategory);

    mutate(
      "categoryService.getAllWithoutDefault",
      [...categories, { ...category }],
      false
    );
    mutate("categoryService.getAllWithoutDefault");
    return handleCategorizeMany(invoiceIds, category.id);
  };

  const loadData = async (
    quickFilter: InvoiceQuickFilterType,
    newPagination?: any,
    filters?: any,
    sorter?: any
  ) => {
    setSelectedRowKeys([]);

    const updatedFilteredInfo = {
      ...filteredInfo,
      ...filters,
    };

    const updatedSortedInfo = {
      ...sortedInfo,
      ...sorter,
    };

    const countQuery = computeQuery({
      pagination: { current: 1 },
      filteredInfo: updatedFilteredInfo,
      sortedInfo: updatedSortedInfo,
      defaultSortColum: "dueDate",
      defaultSortOrder: "ASC",
    });

    const invoicesCount = await invoiceService.getAllCount({
      query: countQuery,
      quickFilter,
    });

    const current = pagination.current || 1;
    const pageSize = pagination.pageSize || 1;
    const maxPage = Math.floor(invoicesCount / pageSize) + 1;

    const updatedPagination = {
      ...pagination,
      current: Math.min(current, maxPage),
      ...newPagination,
      total: invoicesCount,
    };

    const dataQuery = computeQuery({
      pagination: updatedPagination,
      filteredInfo: updatedFilteredInfo,
      sortedInfo: updatedSortedInfo,
      defaultSortColum: "dueDate",
      defaultSortOrder: "ASC",
    });

    return invoiceService
      .getAll({ query: dataQuery, quickFilter })
      .then((invoices) => {
        setLoaded(true);
        setInvoices(invoices);
        setPagination(updatedPagination);
        setFilteredInfo(updatedFilteredInfo);
        setSortedInfo(updatedSortedInfo);
        mutate("invoiceService.getAllUncategorizedCount");
        mutate("invoiceService.getAllPrecategorizedCount");
        mutate("invoiceService.getAllLateCount");
        mutate("invoiceService.getAllPrereconciliatedCount");
      })
      .catch(errorHandler(setError));
  };

  const changeParams = (pagination: any, filters: any, sorter: any) => {
    loadData(quickFilter, pagination, filters, sorter);
  };

  useEffect(() => {
    loadData(quickFilter, pagination, filteredInfo, sortedInfo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quickFilter, refresh]);

  let emptyText: string | ReactNode;
  if (quickFilter === "precategorized") {
    emptyText = (
      <div style={{ marginTop: 20, marginBottom: 20 }}>
        <Emoji symbol="✨" label="félicitations" /> Toutes les
        précatégorisations ont été validées !
      </div>
    );
  } else if (quickFilter === "uncategorized") {
    emptyText = (
      <div style={{ marginTop: 20, marginBottom: 20 }}>
        <Emoji symbol="✨" label="félicitations" /> Toutes vos factures sont
        catégorisées !
      </div>
    );
  } else if (quickFilter === "late") {
    emptyText = (
      <div style={{ marginTop: 20, marginBottom: 20 }}>
        <Emoji symbol="✨" label="félicitations" /> Vous n'avez aucune facture
        en retard !
      </div>
    );
  } else if (quickFilter === "prereconciliated") {
    emptyText = (
      <div style={{ marginTop: 20, marginBottom: 20 }}>
        <Emoji symbol="✨" label="félicitations" /> Tous les prérapprochements
        ont été validés !
      </div>
    );
  }

  const handleSearch = (value: string) => {
    setSearchText(value);
    if (value) {
      loadData(
        quickFilter,
        { ...pagination, current: 1 },
        {
          description: [value],
        }
      );
    } else {
      loadData(
        quickFilter,
        { ...pagination, current: 1 },
        {
          description: undefined,
        }
      );
    }
  };

  return (
    <>
      {error && (
        <Alert style={{ marginBottom: 10 }} type="error" message={error} />
      )}
      {Boolean(selectedRowKeys.length) && (
        <BatchAction
          categories={categories || []}
          transactions={invoices}
          handleCategorizeMany={handleCategorizeMany}
          handleUpdateManyCategory={handleUpdateManyCategory}
          handleDeleteMany={handleDeleteMany}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
          mainAction={
            quickFilter === "precategorized" ? "validate" : "categorize"
          }
        />
      )}
      <div
        style={{
          marginBottom: 16,
          display: Boolean(selectedRowKeys.length) ? "none" : "flex",
          justifyContent: "space-between",
        }}
      >
        <Input.Search
          onSearch={handleSearch}
          allowClear
          style={{
            width: 350,
          }}
        />
      </div>
      <InvoicesTable
        invoices={invoices}
        categories={categories || []}
        handleCategorizeMany={handleCategorizeMany}
        handleValidateCategory={handleValidateCategory}
        handleAddCategoryAndCategorize={handleAddCategoryAndCategorize}
        handleValidatePrereconciliation={handleValidatePrereconciliation}
        handleReconciliate={handleReconciliate}
        handleUpdate={handleUpdate}
        handleDelete={handleDelete}
        handleDuplicate={handleDuplicate}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
        loading={!loaded}
        changeParams={changeParams}
        pagination={pagination}
        emptyText={emptyText}
        searchText={searchText}
        withRowSelection={true}
        sameSignSelection={quickFilter !== "precategorized"}
        quickFilter={quickFilter}
      />
    </>
  );
};

export default InvoicesTableContainer;
