import { Company } from "types";
import axios from "utils/setupAxios";

export async function getAll(): Promise<Company[]> {
  return axios.get("/companies").then((res) => res.data);
}

export async function create(values: any): Promise<Company> {
  return axios.post("/companies", values).then((res) => res.data);
}

export async function update(companyId: number, values: any): Promise<Company> {
  return axios.put(`/companies/${companyId}`, values).then((res) => res.data);
}

export async function getCurrent(): Promise<Company> {
  return axios.get("/companies/current").then((res) => res.data);
}

export async function remove(companyId: number): Promise<Company> {
  return axios.delete(`/companies/${companyId}`).then((res) => res.data);
}

const companyService = { getAll, create, update, getCurrent, remove };
export default companyService;
