import { addMonths, format, parseISO, startOfMonth } from "date-fns";
import { Category, Invoice, Scenario, Transaction } from "types";
import { convert, CurrencyCode } from "./currency";
import { isPastMonth } from "./date";
import { KindEnum } from "./kind";
import { applyOnAllNodesChildrenFirst } from "./tree";

// 7.1.0
const getPlannedValue = (
  currentValue: SourceCategoryMonthData,
  scenarioId: number,
  baseScenarioId: number
) => {
  let planned = null;
  if (currentValue.goal?.[scenarioId] !== null) {
    planned = currentValue.goal?.[scenarioId];
  } else if (currentValue.goal?.[baseScenarioId] !== null) {
    planned = currentValue.goal?.[baseScenarioId];
  }

  return planned;
};

const computePlannedValue = (
  currentValue: SourceCategoryMonthData,
  scenarioId: number,
  baseScenarioId: number
) => {
  const planned = getPlannedValue(currentValue, scenarioId, baseScenarioId);

  return planned || 0;
};

const isAlternativeScenarioPlannedValue = (
  currentValue: SourceCategoryMonthData,
  scenarioId: number,
  baseScenarioId: number
) =>
  baseScenarioId !== scenarioId &&
  currentValue.goal?.[scenarioId] !== null &&
  currentValue.goal?.[scenarioId] !== currentValue.goal?.[baseScenarioId];

const getMonthArray = (startingMonth: Date, numberOfMonth: number) =>
  Array.from(Array(numberOfMonth).keys())
    .map((n) => addMonths(startingMonth, n))
    .map((month) => ({ month, monthString: format(month, "yyyy-MM-dd") }));

enum CashTotalRowEnum {
  cashInTotal = "cashInTotal",
  cashOutTotal = "cashOutTotal",
  cashVariation = "cashVariation",
}

enum ExtraRowEnum {
  cashAtBeginning = "cashAtBeginning",
  cashAtEnd = "cashAtEnd",
  ignoredTotal = "ignoredTotal",
}

export type SourceCategoryMonthData = Record<
  | "sumOfTransactions"
  | "sumOfIgnoredTransactions"
  | "sumOfInvoices"
  | "sumOfIgnoredInvoices",
  number
> &
  Record<"goal", Record<number, number | null>>;

export type CategoryMonthData = Record<
  | "sumOfTransactions"
  | "sumOfIgnoredTransactions"
  | "sumOfInvoices"
  | "sumOfIgnoredInvoices",
  number
> &
  Record<"goal", Record<number, number>> &
  Record<"isAlternativeScenarioPlannedValue", Record<number, boolean>> &
  Record<"forecast", Record<number, number>>;

export type CashTotalRowData = Record<
  | "sumOfTransactions"
  | "sumOfIgnoredTransactions"
  | "sumOfInvoices"
  | "sumOfIgnoredInvoices",
  number
> &
  Record<"goal", Record<number, number>> &
  Record<"isAlternativeScenarioPlannedValue", Record<number, boolean>> &
  Record<"forecast", Record<number, number>>;

export type ExtraRowData = Record<number, number>;

export type StatisticData = Record<number, Record<string, CategoryMonthData>> &
  Record<CashTotalRowEnum, Record<string, CashTotalRowData>> &
  Record<ExtraRowEnum, Record<string, ExtraRowData>>;

export type SourceData = Record<
  number,
  Record<string, SourceCategoryMonthData>
>;

const formatSourceData = ({
  // Display data
  startingMonth,
  numberOfMonth,
  // Real world data
  transactions,
  invoices,
  // Structure of the table
  categories,
  // Scenarios
  scenarios,
  defaultCurrencyCode,
}: {
  startingMonth: Date;
  numberOfMonth: number;
  transactions: (Transaction & { category: number })[];
  invoices: (Invoice & { category: number })[];
  categories: Category[];
  scenarios: Scenario[];
  defaultCurrencyCode: CurrencyCode;
}) => {
  const sourceData: SourceData = {};
  const monthDateArray = getMonthArray(startingMonth, numberOfMonth);

  applyOnAllNodesChildrenFirst({
    categories,
    fn: (category) => {
      const categoryId = category.id;

      // Initialize data for the given category
      sourceData[categoryId] = {};
      monthDateArray.forEach(({ monthString }) => {
        sourceData[categoryId][monthString] = {
          sumOfTransactions: 0,
          sumOfIgnoredTransactions: 0,
          sumOfInvoices: 0,
          sumOfIgnoredInvoices: 0,
          goal: {},
        };
        scenarios.forEach(({ id }) => {
          sourceData[categoryId][monthString].goal[id] = null;
        });
      });
    },
  });

  transactions.forEach((transaction) => {
    const transactionDate = parseISO(transaction.date);
    const transactionMonth = startOfMonth(transactionDate);
    const transactionMonthString = format(transactionMonth, "yyyy-MM-dd");
    const convertedAmount =
      transaction.currency_code === defaultCurrencyCode
        ? transaction.amount
        : convert(transaction.amount, {
            to: defaultCurrencyCode,
            from: transaction.currency_code,
          });
    if (transaction.ignored) {
      sourceData[transaction.category][
        transactionMonthString
      ].sumOfIgnoredTransactions += convertedAmount;
    } else {
      sourceData[transaction.category][
        transactionMonthString
      ].sumOfTransactions += convertedAmount;
    }
  });

  invoices.forEach((invoice) => {
    const invoiceDueDate = parseISO(invoice.dueDate);
    const invoiceMonth = startOfMonth(invoiceDueDate);
    const invoiceMonthString = format(invoiceMonth, "yyyy-MM-dd");
    const convertedAmount =
      invoice.currency_code === defaultCurrencyCode
        ? invoice.amount
        : convert(invoice.amount, {
            to: defaultCurrencyCode,
            from: invoice.currency_code,
          });
    if (invoice.ignored) {
      sourceData[invoice.category][invoiceMonthString].sumOfIgnoredInvoices +=
        convertedAmount;
    } else {
      sourceData[invoice.category][invoiceMonthString].sumOfInvoices +=
        convertedAmount;
    }
  });

  scenarios.forEach(({ id, goals }) => {
    goals.forEach((goal) => {
      const goalCategory = goal.category;
      const goalDate = parseISO(goal.date);
      const goalMonth = startOfMonth(goalDate);
      const goalMonthString = format(goalMonth, "yyyy-MM-dd");

      sourceData[goalCategory][goalMonthString].goal[id] = goal.amount;
    });
  });

  return sourceData;
};

const formatStatisticData = ({
  sourceData,
  startingMonth,
  numberOfMonth,
  scenarios,
  categories,
  baseScenarioId,
  isSimpleForecastModeEnabled,
}: {
  sourceData: SourceData;
  startingMonth: Date;
  numberOfMonth: number;
  scenarios: Scenario[];
  categories: Category[];
  baseScenarioId: number;
  isSimpleForecastModeEnabled: boolean;
}) => {
  const statisticData: StatisticData = {
    cashAtBeginning: {},
    cashAtEnd: {},
    cashInTotal: {},
    cashOutTotal: {},
    cashVariation: {},
    ignoredTotal: {},
  };
  const monthDateArray = getMonthArray(startingMonth, numberOfMonth);

  Object.values(CashTotalRowEnum).forEach((key) => {
    monthDateArray.forEach(({ monthString }) => {
      statisticData[key][monthString] = {
        sumOfTransactions: 0,
        sumOfIgnoredTransactions: 0,
        sumOfInvoices: 0,
        sumOfIgnoredInvoices: 0,
        goal: {},
        isAlternativeScenarioPlannedValue: {},
        forecast: {},
      };
      scenarios.forEach((scenario) => {
        statisticData[key][monthString].goal[scenario.id] = 0;
        statisticData[key][monthString].isAlternativeScenarioPlannedValue[
          scenario.id
        ] = false;
        statisticData[key][monthString].forecast[scenario.id] = 0;
      });
    });
  });
  Object.values(ExtraRowEnum).forEach((key) => {
    monthDateArray.forEach(({ monthString }) => {
      statisticData[key][monthString] = {};
      scenarios.forEach((scenario) => {
        statisticData[key][monthString][scenario.id] = 0;
      });
    });
  });

  applyOnAllNodesChildrenFirst({
    categories,
    fn: (category) => {
      statisticData[category.id] = {};
      monthDateArray.forEach(({ month, monthString }) => {
        const dataForCategoryForMonth: CategoryMonthData = {
          sumOfTransactions: 0,
          sumOfIgnoredTransactions: 0,
          sumOfInvoices: 0,
          sumOfIgnoredInvoices: 0,
          goal: {},
          isAlternativeScenarioPlannedValue: {},
          forecast: {},
        };

        if (!category.children?.length) {
          dataForCategoryForMonth.sumOfTransactions =
            sourceData[category.id][monthString].sumOfTransactions;
          dataForCategoryForMonth.sumOfIgnoredTransactions =
            sourceData[category.id][monthString].sumOfIgnoredTransactions;
          dataForCategoryForMonth.sumOfInvoices =
            sourceData[category.id][monthString].sumOfInvoices;
          dataForCategoryForMonth.sumOfIgnoredInvoices =
            sourceData[category.id][monthString].sumOfIgnoredInvoices;

          if (category.grouped) {
            scenarios.forEach((scenario) => {
              dataForCategoryForMonth.goal[scenario.id] = 0;
              dataForCategoryForMonth.forecast[scenario.id] =
                sourceData[category.id][monthString].sumOfTransactions +
                sourceData[category.id][monthString].sumOfInvoices;
            });
          } else {
            scenarios.forEach((scenario) => {
              dataForCategoryForMonth.goal[scenario.id] = computePlannedValue(
                sourceData[category.id][monthString],
                scenario.id,
                baseScenarioId
              );
              dataForCategoryForMonth.isAlternativeScenarioPlannedValue[
                scenario.id
              ] = isAlternativeScenarioPlannedValue(
                sourceData[category.id][monthString],
                scenario.id,
                baseScenarioId
              );
              if (isSimpleForecastModeEnabled) {
                dataForCategoryForMonth.forecast[scenario.id] =
                  dataForCategoryForMonth.goal[scenario.id];
              } else {
                dataForCategoryForMonth.forecast[scenario.id] = Math.max(
                  sourceData[category.id][monthString].sumOfTransactions +
                    sourceData[category.id][monthString].sumOfInvoices,
                  computePlannedValue(
                    sourceData[category.id][monthString],
                    scenario.id,
                    baseScenarioId
                  )
                );
              }
            });
          }
        } else {
          if (!category.grouper) {
            scenarios.forEach((scenario) => {
              dataForCategoryForMonth.goal[scenario.id] = 0;
              dataForCategoryForMonth.forecast[scenario.id] = 0;
            });

            category.children.forEach((childCategory) => {
              dataForCategoryForMonth.sumOfTransactions +=
                statisticData[childCategory.id][monthString].sumOfTransactions;
              dataForCategoryForMonth.sumOfIgnoredTransactions +=
                statisticData[childCategory.id][
                  monthString
                ].sumOfIgnoredTransactions;
              dataForCategoryForMonth.sumOfInvoices +=
                statisticData[childCategory.id][monthString].sumOfInvoices;
              dataForCategoryForMonth.sumOfIgnoredInvoices +=
                statisticData[childCategory.id][
                  monthString
                ].sumOfIgnoredInvoices;

              scenarios.forEach((scenario) => {
                dataForCategoryForMonth.goal[scenario.id] +=
                  statisticData[childCategory.id][monthString].goal[
                    scenario.id
                  ];

                dataForCategoryForMonth.forecast[scenario.id] +=
                  statisticData[childCategory.id][monthString].forecast[
                    scenario.id
                  ];
              });
            });
          } else {
            category.children.forEach((childCategory) => {
              dataForCategoryForMonth.sumOfTransactions +=
                statisticData[childCategory.id][monthString].sumOfTransactions;
              dataForCategoryForMonth.sumOfIgnoredTransactions +=
                statisticData[childCategory.id][
                  monthString
                ].sumOfIgnoredTransactions;
              dataForCategoryForMonth.sumOfInvoices +=
                statisticData[childCategory.id][monthString].sumOfInvoices;
              dataForCategoryForMonth.sumOfIgnoredInvoices +=
                statisticData[childCategory.id][
                  monthString
                ].sumOfIgnoredInvoices;

              scenarios.forEach((scenario) => {
                dataForCategoryForMonth.goal[scenario.id] +=
                  statisticData[childCategory.id][monthString].goal[
                    scenario.id
                  ];

                dataForCategoryForMonth.forecast[scenario.id] +=
                  statisticData[childCategory.id][monthString].forecast[
                    scenario.id
                  ];
              });
            });

            scenarios.forEach((scenario) => {
              dataForCategoryForMonth.goal[scenario.id] = computePlannedValue(
                sourceData[category.id][monthString],
                scenario.id,
                baseScenarioId
              );
              dataForCategoryForMonth.isAlternativeScenarioPlannedValue[
                scenario.id
              ] = isAlternativeScenarioPlannedValue(
                sourceData[category.id][monthString],
                scenario.id,
                baseScenarioId
              );
              if (isSimpleForecastModeEnabled) {
                dataForCategoryForMonth.forecast[scenario.id] =
                  dataForCategoryForMonth.goal[scenario.id];
              } else {
                dataForCategoryForMonth.forecast[scenario.id] = Math.max(
                  dataForCategoryForMonth.sumOfTransactions +
                    dataForCategoryForMonth.sumOfInvoices,
                  computePlannedValue(
                    sourceData[category.id][monthString],
                    scenario.id,
                    baseScenarioId
                  )
                );
              }
            });
          }
        }
        statisticData[category.id][monthString] = dataForCategoryForMonth;
      });
    },
  });

  const cashInRows = categories
    .filter((category) => category.kind === KindEnum.cashIn)
    .map((category) => statisticData[category.id]);

  const cashOutRows = categories
    .filter((category) => category.kind === KindEnum.cashOut)
    .map((category) => statisticData[category.id]);

  monthDateArray.forEach(({ monthString }) => {
    cashInRows.forEach((row) => {
      statisticData["cashInTotal"][monthString].sumOfTransactions +=
        row[monthString].sumOfTransactions;
      statisticData["cashInTotal"][monthString].sumOfIgnoredTransactions +=
        row[monthString].sumOfIgnoredTransactions;
      statisticData["cashInTotal"][monthString].sumOfInvoices +=
        row[monthString].sumOfInvoices;
      statisticData["cashInTotal"][monthString].sumOfIgnoredInvoices +=
        row[monthString].sumOfIgnoredInvoices;
    });
    cashOutRows.forEach((row) => {
      statisticData["cashOutTotal"][monthString].sumOfTransactions +=
        row[monthString].sumOfTransactions;
      statisticData["cashOutTotal"][monthString].sumOfIgnoredTransactions +=
        row[monthString].sumOfIgnoredTransactions;
      statisticData["cashOutTotal"][monthString].sumOfInvoices +=
        row[monthString].sumOfInvoices;
      statisticData["cashOutTotal"][monthString].sumOfIgnoredInvoices +=
        row[monthString].sumOfIgnoredInvoices;
    });
  });

  monthDateArray.forEach(({ monthString }) => {
    statisticData["cashVariation"][monthString].sumOfTransactions =
      statisticData["cashInTotal"][monthString].sumOfTransactions -
      statisticData["cashOutTotal"][monthString].sumOfTransactions;

    statisticData["cashVariation"][monthString].sumOfIgnoredTransactions =
      statisticData["cashInTotal"][monthString].sumOfIgnoredTransactions -
      statisticData["cashOutTotal"][monthString].sumOfIgnoredTransactions;

    statisticData["cashVariation"][monthString].sumOfInvoices =
      statisticData["cashInTotal"][monthString].sumOfInvoices -
      statisticData["cashOutTotal"][monthString].sumOfInvoices;

    statisticData["cashVariation"][monthString].sumOfIgnoredInvoices =
      statisticData["cashInTotal"][monthString].sumOfIgnoredInvoices -
      statisticData["cashOutTotal"][monthString].sumOfIgnoredInvoices;
  });

  scenarios.forEach((scenario) => {
    let currentBalance = scenario.startingBalance;

    monthDateArray.forEach(({ month, monthString }) => {
      cashInRows.forEach((row) => {
        statisticData["cashInTotal"][monthString].goal[scenario.id] +=
          row[monthString].goal[scenario.id];

        statisticData["cashInTotal"][monthString].forecast[scenario.id] +=
          row[monthString].forecast[scenario.id];
      });

      cashOutRows.forEach((row) => {
        statisticData["cashOutTotal"][monthString].goal[scenario.id] +=
          row[monthString].goal[scenario.id];

        statisticData["cashOutTotal"][monthString].forecast[scenario.id] +=
          row[monthString].forecast[scenario.id];
      });

      if (isSimpleForecastModeEnabled) {
        statisticData["cashInTotal"][monthString].forecast[scenario.id] =
          Math.max(
            statisticData["cashInTotal"][monthString].sumOfTransactions +
              statisticData["cashInTotal"][monthString].sumOfInvoices,
            statisticData["cashInTotal"][monthString].goal[scenario.id]
          );
        statisticData["cashOutTotal"][monthString].forecast[scenario.id] =
          Math.max(
            statisticData["cashOutTotal"][monthString].sumOfTransactions +
              statisticData["cashOutTotal"][monthString].sumOfInvoices,
            statisticData["cashOutTotal"][monthString].goal[scenario.id]
          );
      }

      statisticData["ignoredTotal"][monthString][scenario.id] =
        statisticData["cashInTotal"][monthString].sumOfIgnoredTransactions -
        statisticData["cashOutTotal"][monthString].sumOfIgnoredTransactions;

      statisticData["cashAtBeginning"][monthString][scenario.id] =
        currentBalance;

      statisticData["cashVariation"][monthString].goal[scenario.id] =
        statisticData["cashInTotal"][monthString].goal[scenario.id] -
        statisticData["cashOutTotal"][monthString].goal[scenario.id];

      statisticData["cashVariation"][monthString].forecast[scenario.id] =
        statisticData["cashInTotal"][monthString].forecast[scenario.id] -
        statisticData["cashOutTotal"][monthString].forecast[scenario.id];

      statisticData["cashAtEnd"][monthString][scenario.id] =
        currentBalance +
        statisticData["ignoredTotal"][monthString][scenario.id] +
        (isPastMonth(month)
          ? statisticData["cashVariation"][monthString].sumOfTransactions
          : statisticData["cashVariation"][monthString].forecast[scenario.id]);

      currentBalance = statisticData["cashAtEnd"][monthString][scenario.id];
    });
  });
  return statisticData;
};

export {
  formatSourceData,
  formatStatisticData,
  getPlannedValue,
  computePlannedValue,
  isAlternativeScenarioPlannedValue,
  getMonthArray,
};
