import { LinkOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Switch,
} from "antd";
import CategorySelect from "components/CategorySelect";
import { DelaySelect } from "components/DelaySelect";
import { VatSelect } from "components/VatSelect";
import { AuthContext } from "contexts";
import dayjs from "dayjs";
import { FC, useContext, useEffect, useState } from "react";
import categoryService from "services/categoryService";
import useSWR from "swr";
import { CurrencyCode } from "utils/currency";
import { KindEnum } from "utils/kind";
import { cleanDescription } from "utils/string";
import { findTree } from "utils/tree";
interface Props {
  addInvoice: (params: any) => Promise<void>;
  open: boolean;
  setOpen: (open: boolean) => void;
}

const dateDisplayFormat = "DD/MM/YYYY";
const dateAPIFormat = "YYYY-MM-DD";

const AddInvoiceModal: FC<Props> = ({ addInvoice, open, setOpen }) => {
  const {
    data: categories,
    // error
  } = useSWR(
    "categoryService.getAllWithoutDefault",
    categoryService.getAllWithoutDefault
  );
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { me } = useContext(AuthContext);

  const isDocumentEnabled = me?.company?.isDocumentEnabled;

  useEffect(() => {
    if (open === true) {
      form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleOkModal = async () => {
    if (!categories) {
      return;
    }

    const values = await form.validateFields();
    const {
      category,
      description,
      note,
      amount,
      vat,
      transactionDate: dayjsTransactionDate,
      dueDate: dayjsDueDate,
      hasDocument,
      document,
    } = values;

    const currentCategory = findTree({
      categories,
      categoryId: parseInt(category),
    });

    if (!currentCategory) {
      return;
    }

    const kind = amount < 0 ? currentCategory.kind : "";
    const sign = currentCategory.kind === KindEnum.cashIn ? 1 : -1;
    const signedAmount = sign * amount;
    const transactionDate = dayjsTransactionDate.format(dateAPIFormat);
    const dueDate = dayjsDueDate.format(dateAPIFormat);
    const currency_code = CurrencyCode.EUR;

    setLoading(true);
    await addInvoice({
      category,
      description,
      note,
      cleanDescription: cleanDescription(description),
      amount: signedAmount,
      currency_code,
      kind,
      vat,
      transactionDate,
      dueDate,
      hasDocument,
      document,
    });
    setLoading(false);
    setOpen(false);
  };

  const handleCancelModal = () => {
    setOpen(false);
  };

  const handleDelayOrTransactionDateChange = () => {
    const transactionDate = form.getFieldValue("transactionDate");
    const delay = form.getFieldValue("delay");

    if (!transactionDate || (delay !== 0 && !delay)) {
      return;
    }

    const transactionDateClone = transactionDate.clone();
    const dueDate = transactionDateClone.add(parseInt(delay, 10), "days");
    form.setFieldsValue({ dueDate });
  };

  const handleDueDateChange = () => {
    const dueDate = form.getFieldValue("dueDate");
    const transactionDate = form.getFieldValue("transactionDate");

    if (!dueDate || !transactionDate) {
      return;
    }

    const delay = dueDate.diff(transactionDate, "days");
    form.setFieldsValue({ delay });
  };

  return (
    <Modal
      title="Ajouter une facture"
      open={open}
      onOk={handleOkModal}
      onCancel={handleCancelModal}
      forceRender
      destroyOnClose
      footer={[
        <Button key="back" onClick={handleCancelModal}>
          Annuler
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={handleOkModal}
        >
          Créer
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical" name={`add-invoice-form`}>
        <CategorySelect
          categories={categories || []}
          setVat={(vat?: number) => form.setFieldsValue({ vat })}
        />
        <Form.Item
          name="description"
          label="Libellé"
          rules={[{ required: true, message: "Requis" }]}
        >
          <Input onPressEnter={handleOkModal} />
        </Form.Item>
        <Form.Item name="note" label="Mémo">
          <Input onPressEnter={handleOkModal} />
        </Form.Item>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="amount"
              label="Saisissez le montant TTC (€)"
              rules={[{ required: true, message: "Requis" }]}
            >
              <InputNumber
                precision={2}
                parser={(value) => `${value}`.replace(",", ".")}
                onPressEnter={handleOkModal}
                style={{
                  width: "100%",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <VatSelect />
          </Col>

          <Col span={12}>
            <Form.Item
              name="transactionDate"
              label="Date d'émission"
              initialValue={dayjs()}
            >
              <DatePicker
                format={dateDisplayFormat}
                allowClear={false}
                onChange={handleDelayOrTransactionDateChange}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <DelaySelect
              handleDelayChange={handleDelayOrTransactionDateChange}
            />
          </Col>
          <Col span={12}></Col>
          <Col span={12}>
            <Form.Item
              name="dueDate"
              label="Date échéance"
              initialValue={dayjs().add(30, "days")}
            >
              <DatePicker
                format={dateDisplayFormat}
                allowClear={false}
                onChange={handleDueDateChange}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>
        {isDocumentEnabled && (
          <Row>
            <Col span={4}>
              <Form.Item
                name="hasDocument"
                valuePropName="checked"
                label={<LinkOutlined />}
              >
                <Switch />
              </Form.Item>
            </Col>
            <Col span={20}>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.hasDocument !== currentValues.hasDocument
                }
              >
                {({ getFieldValue }) => {
                  const hasDocument = getFieldValue("hasDocument");

                  return (
                    hasDocument && (
                      <Form.Item name="document" label="URL du document">
                        <Input placeholder="Entrez l'URL" />
                      </Form.Item>
                    )
                  );
                }}
              </Form.Item>
            </Col>
          </Row>
        )}
      </Form>
    </Modal>
  );
};

export default AddInvoiceModal;
