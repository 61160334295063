import {
  Alert,
  Button,
  Form,
  Input,
  Popconfirm,
  Select,
  Table,
  Typography,
} from "antd";
import { FC, ReactElement, useState } from "react";
import { FormattedMessage } from "react-intl";
import rightService from "services/rightService";
import useSWR, { mutate } from "swr";
import { CreateRightParams, User } from "types";
import { roles, rolesEnum } from "utils/constants";
import errorHandler from "utils/errorHandler";
import { DeleteOutlined } from "@ant-design/icons";

const { Option } = Select;
const { Text } = Typography;

const rolesDescription: any = {
  READ: "Lecture",
  READ_WRITE: "Lecture / Ecriture",
  ADMIN: "Administrateur",
};

interface Props {}

export const CompanyUsersForm: FC<Props> = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ReactElement | undefined>(undefined);
  const [form] = Form.useForm();

  const { data: rights } = useSWR("rightService.getAll", rightService.getAll);

  const ejectUser = async (rightId: string) => {
    await rightService.remove(rightId).catch(errorHandler(setError));

    mutate("rightService.getAll");
  };

  const onFinish = async (values: CreateRightParams) => {
    setLoading(true);

    const { firstName, lastName, email, role } = values;

    await rightService
      .create({
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        email: email.trim().toLowerCase(),
        role,
      })
      .catch((e) => {
        if (e?.response?.data?.message?.[0]?.messages?.[0]?.id) {
          setError(
            <FormattedMessage
              id={e?.response?.data?.message?.[0]?.messages?.[0]?.id || -1}
              defaultMessage="Un problème est survenu. Veuillez rafraîchir la page ou essayer ultérieurement."
              description="Server response to login"
            />
          );
          setLoading(false);
        } else {
          throw e;
        }
      })
      .catch(errorHandler(setError));

    mutate("rightService.getAll");
    form.resetFields();
    setError(undefined);
    setLoading(false);
  };

  const columns = [
    {
      title: "Utilisateur",
      dataIndex: "user",
      render: (user: User) => user.email,
    },
    {
      title: "Role",
      dataIndex: "role",
      render: (role: string) => rolesDescription[roles[role]],
    },
    {
      title: "Action",
      render: (_: any, record: any) =>
        record.role !== rolesEnum["ADMIN"] && (
          <Popconfirm
            onConfirm={() => ejectUser(record.id)}
            okText="Confirmer"
            title={
              <>
                Êtes-vous sûr de vouloir supprimer l'utilisateur associé à
                l'adresse email:{" "}
                <Text mark strong>
                  {record.user.email}
                </Text>{" "}
                ?
              </>
            }
            placement="top"
          >
            <span>
              <Button
                type="link"
                icon={
                  <DeleteOutlined /> /*<Icon
                    path={mdiDeleteOutline}
                    size={0.9}
                  />*/ // en commentaire, comment tester, l'invitation ne marche pas en local ?
                }
              />
            </span>
          </Popconfirm>
        ),
    },
  ];

  return (
    <>
      <div>{error && <Alert type="error" message={error} />}</div>
      <div style={{ marginTop: 15 }}>
        <Form
          name="invite-user"
          // @ts-ignore
          onFinish={onFinish}
          requiredMark={false}
          layout="inline"
          form={form}
        >
          <Form.Item
            name="email"
            rules={[
              { required: true, message: "Requis" },
              { type: "email", message: "Email invalide" },
            ]}
          >
            <Input
              type="email"
              autoComplete="username email"
              placeholder="Email"
            />
          </Form.Item>
          <Form.Item
            name="firstName"
            rules={[{ required: true, message: "Requis" }]}
          >
            <Input autoComplete="given-name" placeholder="Prénom" />
          </Form.Item>

          <Form.Item
            name="lastName"
            rules={[{ required: true, message: "Requis" }]}
          >
            <Input autoComplete="family-name" placeholder="Nom" />
          </Form.Item>
          <Form.Item
            name="role"
            rules={[{ required: true, message: "Requis" }]}
          >
            <Select
              placeholder="Droits"
              style={{ width: 200 }}
              getPopupContainer={(trigger) => trigger.parentNode}
            >
              {Object.keys(roles).map((key) => {
                return (
                  <Option
                    value={key}
                    key={key}
                    disabled={roles[key] === "ADMIN"}
                  >
                    {rolesDescription[roles[key]]}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" block loading={loading}>
              Envoyer une invitation
            </Button>
          </Form.Item>
        </Form>
      </div>

      <div style={{ marginTop: 15 }}>
        <Table
          loading={!rights}
          locale={{
            emptyText: "Aucune transaction à afficher",
          }}
          dataSource={rights}
          bordered={true}
          size="small"
          columns={columns}
          rowKey="id"
        />
      </div>
    </>
  );
};
