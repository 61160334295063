import { Button, Checkbox, Col, Form, Input, Row } from "antd";
import { FC, ReactElement, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate, useSearchParams } from "react-router-dom";
import authService from "services/authService";
import { Me } from "types";
import { getCookie } from "utils/attribution";
import { applicationName } from "utils/constants";
import errorHandler from "utils/errorHandler";
import marketingTools from "utils/marketingTools";

interface Props {
  setError: (errorMessage: ReactElement | undefined) => void;
  logIn: (token: string, me: Me) => void;
}

export const RegisterAffiliateForm: FC<Props> = ({ setError, logIn }) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const coupon = searchParams.get("fpr");

  const onFinish = (values: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    password: string;
  }) => {
    setLoading(true);

    const referrer = getCookie("referrer");
    const utm_campaign = getCookie("utm_campaign");
    const utm_medium = getCookie("utm_medium");
    const utm_source = getCookie("utm_source");
    const app_domain = process.env.REACT_APP_DOMAIN || "";

    const { firstName, lastName, email, phone, password } = values;

    authService
      .register({
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        email: email.trim(),
        phone: phone,
        password: password,
        referrer,
        utm_campaign,
        utm_medium,
        utm_source,
        app_domain,
        promotionCode: coupon || undefined,
      })
      .then(({ jwt, user }) => {
        navigate("/register/create-company", { replace: true });

        marketingTools.hasRegistered(user);

        logIn(jwt, user);
      })
      .catch((e) => {
        if (e?.response?.data?.message?.[0]?.messages?.[0]?.id) {
          setError(
            <FormattedMessage
              id={e?.response?.data?.message?.[0]?.messages?.[0]?.id || -1}
              defaultMessage="Un problème est survenu. Veuillez rafraîchir la page ou essayer ultérieurement."
              description="Server response to login"
            />
          );
          setLoading(false);
        } else {
          throw e;
        }
      })
      .catch(errorHandler(setError));
  };

  if (!coupon) {
    return (
      <Form
        name="coupon"
        onFinish={({ coupon }) => {
          window.location.href = `https://${process.env.REACT_APP_DOMAIN}/inscription-avec-coupon?fpr=${coupon}`;
        }}
        className="login-form animated"
        layout="vertical"
        requiredMark={false}
      >
        <Form.Item
          label="Votre coupon"
          name="coupon"
          hasFeedback
          rules={[
            {
              validator: (_, value) => {
                if (!value) {
                  return Promise.reject("Coupon requis");
                }
                const coupons = [
                  "blueomingmak",
                  "lorraineinside",
                  "estangels",
                  "incubateurlorrain",
                  "gclegr50",
                ];
                return coupons.indexOf(value.toLowerCase()) !== -1 ||
                  value.match(/^07\d{4}R[0-3]0$/)
                  ? Promise.resolve()
                  : Promise.reject("Coupon invalide");
              },
            },
          ]}
        >
          <Input placeholder="Coupon" />
        </Form.Item>
        <Button type="primary" htmlType="submit" loading={loading} block>
          Créer un compte avec coupon
        </Button>
      </Form>
    );
  }

  return (
    <Form
      name="register"
      onFinish={onFinish}
      className="login-form animated"
      layout="vertical"
      requiredMark={false}
    >
      <Form.Item
        label="Entrez votre email"
        name="email"
        rules={[{ required: true, message: "Requis" }]}
      >
        <Input autoComplete="username email" placeholder="nom@entreprise.com" />
      </Form.Item>
      <Form.Item
        label="Choisissez votre mot de passe"
        name="password"
        rules={[{ required: true, message: "Requis" }]}
      >
        <Input.Password
          autoComplete="new-password"
          visibilityToggle={false}
          placeholder="••••••••"
        />
      </Form.Item>
      <Row gutter={8}>
        <Col span="12">
          <Form.Item
            label="Votre prénom"
            name="firstName"
            rules={[{ required: true, message: "Requis" }]}
          >
            <Input autoComplete="given-name" placeholder="Prénom" />
          </Form.Item>
        </Col>
        <Col span="12">
          <Form.Item
            label="Votre nom"
            name="lastName"
            rules={[{ required: true, message: "Requis" }]}
          >
            <Input autoComplete="family-name" placeholder="Nom" />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        label="Entrez votre téléphone"
        name="phone"
        rules={[{ required: true, message: "Requis" }]}
      >
        <Input autoComplete="tel" type="tel" placeholder="06XXXXXXXX" />
      </Form.Item>

      <Form.Item
        name="cgu"
        valuePropName="checked"
        rules={[
          {
            validator: (_, value) =>
              value
                ? Promise.resolve()
                : Promise.reject(
                    "Veuillez lire et valider nos conditions d'utilisation"
                  ),
          },
        ]}
      >
        <Checkbox>
          J'accepte les{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.ingeneo.eu/conditions-generales-dutilisation/"
          >
            Conditions Générales d'Utilisation des Services
          </a>{" "}
          {applicationName}.
        </Checkbox>
      </Form.Item>
      <Button type="primary" htmlType="submit" loading={loading} block>
        Créer un compte
      </Button>
    </Form>
  );
};
