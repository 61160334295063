// 1.1.0
export enum CurrencyCode {
  USD = "USD",
  CAD = "CAD",
  EUR = "EUR",
  AED = "AED",
  AFN = "AFN",
  ALL = "ALL",
  AMD = "AMD",
  ARS = "ARS",
  AUD = "AUD",
  AZN = "AZN",
  BAM = "BAM",
  BDT = "BDT",
  BGN = "BGN",
  BHD = "BHD",
  BIF = "BIF",
  BND = "BND",
  BOB = "BOB",
  BRL = "BRL",
  BWP = "BWP",
  BYN = "BYN",
  BZD = "BZD",
  CDF = "CDF",
  CHF = "CHF",
  CLP = "CLP",
  CNY = "CNY",
  COP = "COP",
  CRC = "CRC",
  CVE = "CVE",
  CZK = "CZK",
  DJF = "DJF",
  DKK = "DKK",
  DOP = "DOP",
  DZD = "DZD",
  EEK = "EEK",
  EGP = "EGP",
  ERN = "ERN",
  ETB = "ETB",
  GBP = "GBP",
  GEL = "GEL",
  GHS = "GHS",
  GNF = "GNF",
  GTQ = "GTQ",
  HKD = "HKD",
  HNL = "HNL",
  HRK = "HRK",
  HUF = "HUF",
  IDR = "IDR",
  ILS = "ILS",
  INR = "INR",
  IQD = "IQD",
  IRR = "IRR",
  ISK = "ISK",
  JMD = "JMD",
  JOD = "JOD",
  JPY = "JPY",
  KES = "KES",
  KHR = "KHR",
  KMF = "KMF",
  KRW = "KRW",
  KWD = "KWD",
  KZT = "KZT",
  LBP = "LBP",
  LKR = "LKR",
  LTL = "LTL",
  LVL = "LVL",
  LYD = "LYD",
  MAD = "MAD",
  MDL = "MDL",
  MGA = "MGA",
  MKD = "MKD",
  MMK = "MMK",
  MOP = "MOP",
  MUR = "MUR",
  MXN = "MXN",
  MYR = "MYR",
  MZN = "MZN",
  NAD = "NAD",
  NGN = "NGN",
  NIO = "NIO",
  NOK = "NOK",
  NPR = "NPR",
  NZD = "NZD",
  OMR = "OMR",
  PAB = "PAB",
  PEN = "PEN",
  PHP = "PHP",
  PKR = "PKR",
  PLN = "PLN",
  PYG = "PYG",
  QAR = "QAR",
  RON = "RON",
  RSD = "RSD",
  RUB = "RUB",
  RWF = "RWF",
  SAR = "SAR",
  SDG = "SDG",
  SEK = "SEK",
  SGD = "SGD",
  SOS = "SOS",
  SYP = "SYP",
  THB = "THB",
  TND = "TND",
  TOP = "TOP",
  TRY = "TRY",
  TTD = "TTD",
  TWD = "TWD",
  TZS = "TZS",
  UAH = "UAH",
  UGX = "UGX",
  UYU = "UYU",
  UZS = "UZS",
  VEF = "VEF",
  VND = "VND",
  XAF = "XAF",
  XOF = "XOF",
  YER = "YER",
  ZAR = "ZAR",
}

export const currencySymbols: Record<CurrencyCode, string> = {
  [CurrencyCode.AED]: "د.إ",
  [CurrencyCode.AFN]: "؋",
  [CurrencyCode.ALL]: "L",
  [CurrencyCode.AMD]: "֏",
  [CurrencyCode.ARS]: "$",
  [CurrencyCode.AUD]: "$",
  [CurrencyCode.AZN]: "₼",
  [CurrencyCode.BAM]: "KM",
  [CurrencyCode.BDT]: "৳",
  [CurrencyCode.BGN]: "лв",
  [CurrencyCode.BHD]: ".د.ب",
  [CurrencyCode.BIF]: "FBu",
  [CurrencyCode.BND]: "$",
  [CurrencyCode.BOB]: "$b",
  [CurrencyCode.BRL]: "R$",
  [CurrencyCode.BWP]: "P",
  [CurrencyCode.BYN]: "Br",
  [CurrencyCode.BZD]: "BZ$",
  [CurrencyCode.CAD]: "$",
  [CurrencyCode.CDF]: "FC",
  [CurrencyCode.CHF]: "CHF",
  [CurrencyCode.CLP]: "$",
  [CurrencyCode.CNY]: "¥",
  [CurrencyCode.COP]: "$",
  [CurrencyCode.CRC]: "₡",
  [CurrencyCode.CVE]: "$",
  [CurrencyCode.CZK]: "Kč",
  [CurrencyCode.DJF]: "Fdj",
  [CurrencyCode.DKK]: "kr",
  [CurrencyCode.DOP]: "RD$",
  [CurrencyCode.DZD]: "دج",
  [CurrencyCode.EEK]: "kr",
  [CurrencyCode.EGP]: "£",
  [CurrencyCode.ERN]: "Nfk",
  [CurrencyCode.ETB]: "Br",
  [CurrencyCode.EUR]: "€",
  [CurrencyCode.GBP]: "£",
  [CurrencyCode.GEL]: "₾",
  [CurrencyCode.GHS]: "GH₵",
  [CurrencyCode.GNF]: "FG",
  [CurrencyCode.GTQ]: "Q",
  [CurrencyCode.HKD]: "$",
  [CurrencyCode.HNL]: "L",
  [CurrencyCode.HRK]: "kn",
  [CurrencyCode.HUF]: "Ft",
  [CurrencyCode.IDR]: "Rp",
  [CurrencyCode.ILS]: "₪",
  [CurrencyCode.INR]: "₹",
  [CurrencyCode.IQD]: "ع.د",
  [CurrencyCode.IRR]: "﷼",
  [CurrencyCode.ISK]: "kr",
  [CurrencyCode.JMD]: "J$",
  [CurrencyCode.JOD]: "JD",
  [CurrencyCode.JPY]: "¥",
  [CurrencyCode.KES]: "KSh",
  [CurrencyCode.KHR]: "៛",
  [CurrencyCode.KMF]: "CF",
  [CurrencyCode.KRW]: "₩",
  [CurrencyCode.KWD]: "KD",
  [CurrencyCode.KZT]: "лв",
  [CurrencyCode.LBP]: "£",
  [CurrencyCode.LKR]: "₨",
  [CurrencyCode.LTL]: "Lt",
  [CurrencyCode.LVL]: "Ls",
  [CurrencyCode.LYD]: "LD",
  [CurrencyCode.MAD]: "MAD",
  [CurrencyCode.MDL]: "lei",
  [CurrencyCode.MGA]: "Ar",
  [CurrencyCode.MKD]: "ден",
  [CurrencyCode.MMK]: "K",
  [CurrencyCode.MOP]: "MOP$",
  [CurrencyCode.MUR]: "₨",
  [CurrencyCode.MXN]: "$",
  [CurrencyCode.MYR]: "RM",
  [CurrencyCode.MZN]: "MT",
  [CurrencyCode.NAD]: "$",
  [CurrencyCode.NGN]: "₦",
  [CurrencyCode.NIO]: "C$",
  [CurrencyCode.NOK]: "kr",
  [CurrencyCode.NPR]: "₨",
  [CurrencyCode.NZD]: "$",
  [CurrencyCode.OMR]: "﷼",
  [CurrencyCode.PAB]: "B/.",
  [CurrencyCode.PEN]: "S/.",
  [CurrencyCode.PHP]: "₱",
  [CurrencyCode.PKR]: "₨",
  [CurrencyCode.PLN]: "zł",
  [CurrencyCode.PYG]: "Gs",
  [CurrencyCode.QAR]: "﷼",
  [CurrencyCode.RON]: "lei",
  [CurrencyCode.RSD]: "Дин.",
  [CurrencyCode.RUB]: "₽",
  [CurrencyCode.RWF]: "R₣",
  [CurrencyCode.SAR]: "﷼",
  [CurrencyCode.SDG]: "ج.س.",
  [CurrencyCode.SEK]: "kr",
  [CurrencyCode.SGD]: "$",
  [CurrencyCode.SOS]: "S",
  [CurrencyCode.SYP]: "£",
  [CurrencyCode.THB]: "฿",
  [CurrencyCode.TND]: "د.ت",
  [CurrencyCode.TOP]: "T$",
  [CurrencyCode.TRY]: "₺",
  [CurrencyCode.TTD]: "TT$",
  [CurrencyCode.TWD]: "NT$",
  [CurrencyCode.TZS]: "TSh",
  [CurrencyCode.UAH]: "₴",
  [CurrencyCode.UGX]: "USh",
  [CurrencyCode.USD]: "$",
  [CurrencyCode.UYU]: "$U",
  [CurrencyCode.UZS]: "лв",
  [CurrencyCode.VEF]: "Bs",
  [CurrencyCode.VND]: "₫",
  [CurrencyCode.XAF]: "FCFA",
  [CurrencyCode.XOF]: "CFA",
  [CurrencyCode.YER]: "﷼",
  [CurrencyCode.ZAR]: "R",
};

// TEMP
export const defaultCurrencyCode = CurrencyCode.EUR;
const privateProperties: { base: string; rates: any; date: string } = {
  rates: {
    CAD: 1.5621,
    HKD: 9.533,
    ISK: 156.1,
    PHP: 59.058,
    DKK: 7.4379,
    HUF: 361.32,
    CZK: 26.141,
    AUD: 1.5928,
    RON: 4.8713,
    SEK: 10.0895,
    IDR: 17062.67,
    INR: 89.789,
    BRL: 6.3241,
    RUB: 90.342,
    HRK: 7.5565,
    JPY: 126.62,
    THB: 36.728,
    CHF: 1.0811,
    SGD: 1.6198,
    PLN: 4.5475,
    BGN: 1.9558,
    TRY: 9.0579,
    CNY: 7.9484,
    NOK: 10.444,
    NZD: 1.7065,
    ZAR: 17.9214,
    USD: 1.2296,
    MXN: 24.3031,
    ILS: 3.943,
    GBP: 0.9016,
    KRW: 1332.03,
    MYR: 4.9264,
    AED: 4.45,
  },
  base: "EUR",
  date: "2021-01-04",
};
// TEMP

export const convert = function (
  amount: number,
  opts: { to: string; from: string }
) {
  return amount * getRate(opts.to, opts.from);
};

export const getRate = function (to: string, from: string) {
  var rates = privateProperties.rates;

  rates[privateProperties.base] = 1;

  if (!rates[to] || !rates[from]) {
    throw new Error("Exchange rate not found");
  }

  if (from === privateProperties.base) {
    return rates[to];
  }

  if (to === privateProperties.base) {
    return 1 / rates[from];
  }

  return rates[to] * (1 / rates[from]);
};
