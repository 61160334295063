import { ExclamationCircleOutlined, LinkOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Switch,
  Typography,
} from "antd";
import CategorySelect from "components/CategorySelect";
import { DelaySelect } from "components/DelaySelect";
import { InvoiceEditModalParams } from "components/InvoicesPage";
import { VatSelect } from "components/VatSelect";
import { AuthContext } from "contexts";
import dayjs from "dayjs";
import { FC, useContext, useEffect, useState } from "react";
import categoryService from "services/categoryService";
import useSWR from "swr";
import { CurrencyCode } from "utils/currency";
import { KindEnum } from "utils/kind";
import { findTree } from "utils/tree";

const confirmProps = {
  okText: "Confirmer",
  cancelText: "Annuler",
  title: "Êtes-vous sûr ?",
  icon: <ExclamationCircleOutlined />,
};

const confirmDeleteInvoiceContent = ({
  description,
}: {
  description: string;
}) => (
  <>
    <Typography.Paragraph>
      Vous êtes sur le point de supprimer :
    </Typography.Paragraph>
    <Typography.Paragraph>
      <Typography.Text mark>{description}</Typography.Text>
    </Typography.Paragraph>
    <Typography.Paragraph>
      La suppression d'une facture engagée est irréversible.
    </Typography.Paragraph>
  </>
);

interface Props {
  updateInvoice: (
    id: number,
    values: {
      category: number;
      description: string;
      amount: number;
      currency_code: CurrencyCode;
      vat: number;
      transactionDate: Date;
      dueDate: Date;
      hasDocument: boolean;
      document: string;
    }
  ) => Promise<void>;
  deleteInvoice: (invoiceId: number) => Promise<void>;
  modalParams: InvoiceEditModalParams;
  setModalParams: any;
}

const dateDisplayFormat = "DD/MM/YYYY";
const dateAPIFormat = "YYYY-MM-DD";

const InvoiceEditModal: FC<Props> = ({
  updateInvoice,
  deleteInvoice,
  modalParams,
  setModalParams,
}) => {
  const {
    data: categories,
    // error
  } = useSWR(
    "categoryService.getAllWithoutDefault",
    categoryService.getAllWithoutDefault
  );
  const [loading, setLoading] = useState(false);
  const [kind, setKind] = useState<KindEnum>();
  const [form] = Form.useForm();
  const { me } = useContext(AuthContext);

  const isDocumentEnabled = me?.company?.isDocumentEnabled;

  useEffect(() => {
    if (modalParams.open === true) {
      const {
        category,
        description,
        amount,
        vat,
        transactionDate,
        dueDate,
        hasDocument,
        document,
      } = modalParams.editingInvoice;
      setKind(amount >= 0 ? KindEnum.cashIn : KindEnum.cashOut);
      const absoluteAmount = Math.abs(amount);
      const delay = dayjs(dueDate).diff(dayjs(transactionDate), "days");

      form.setFieldsValue({
        category,
        description,
        amount: absoluteAmount,
        vat,
        transactionDate: dayjs(transactionDate),
        dueDate: dayjs(dueDate),
        delay,
        hasDocument,
        document,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalParams]);

  const handleOkModal = async () => {
    if (!categories) {
      return;
    }

    const values = await form.validateFields();
    const {
      category,
      description,
      amount: absoluteAmount,
      vat,
      transactionDate: dayjsTransactionDate,
      dueDate: dayjsDueDate,
      hasDocument,
      document,
    } = values;

    const currentCategory = findTree({
      categories,
      categoryId: parseInt(category),
    });

    if (!currentCategory) {
      return;
    }

    const { kind } = currentCategory;
    const sign = kind === KindEnum.cashIn ? 1 : -1;
    const amount = sign * absoluteAmount;
    const transactionDate = dayjsTransactionDate.format(dateAPIFormat);
    const dueDate = dayjsDueDate.format(dateAPIFormat);
    const currency_code = CurrencyCode.EUR;

    setLoading(true);
    await updateInvoice(modalParams.editingInvoice.id, {
      category,
      description,
      amount,
      currency_code,
      vat,
      transactionDate,
      dueDate,
      hasDocument,
      document,
    });
    setLoading(false);
    setModalParams({ open: false });
  };

  const handleDelete = () => {
    const { id, description } = modalParams.editingInvoice;

    setModalParams({ open: false });
    Modal.confirm({
      ...confirmProps,
      onOk() {
        return deleteInvoice(id);
      },
      content: confirmDeleteInvoiceContent({ description }),
    });
  };

  const handleCancelModal = () => {
    setModalParams({ open: false });
  };

  const handleDelayOrTransactionDateChange = () => {
    const transactionDate = form.getFieldValue("transactionDate");
    const delay = form.getFieldValue("delay");

    if (!transactionDate || (delay !== 0 && !delay)) {
      return;
    }

    const transactionDateClone = transactionDate.clone();
    const dueDate = transactionDateClone.add(parseInt(delay, 10), "days");
    form.setFieldsValue({ dueDate });
  };

  const handleDueDateChange = () => {
    const dueDate = form.getFieldValue("dueDate");
    const transactionDate = form.getFieldValue("transactionDate");

    if (!dueDate || !transactionDate) {
      return;
    }

    const delay = dueDate.diff(transactionDate, "days");
    form.setFieldsValue({ delay });
  };

  return (
    <>
      <Modal
        title="Modifier une facture"
        open={modalParams.open}
        onOk={handleOkModal}
        onCancel={handleCancelModal}
        forceRender
        destroyOnClose
        footer={
          <Row>
            <Col span={12} style={{ textAlign: "left" }}>
              <Button type="link" danger onClick={handleDelete}>
                Supprimer
              </Button>
            </Col>
            <Col span={12} style={{ textAlign: "right" }}>
              <Button key="back" onClick={handleCancelModal}>
                Annuler
              </Button>
              <Button
                key="submit"
                type="primary"
                loading={loading}
                onClick={handleOkModal}
              >
                Enregistrer
              </Button>
            </Col>
          </Row>
        }
      >
        <Form form={form} layout="vertical" name={`edit-invoice-form`}>
          <CategorySelect
            categories={categories || []}
            kind={kind}
            setVat={(vat?: number) => form.setFieldsValue({ vat })}
            required={false}
          />
          <Form.Item
            name="description"
            label="Libellé"
            rules={[{ required: true, message: "Requis" }]}
          >
            <Input onPressEnter={handleOkModal} />
          </Form.Item>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                name="amount"
                label="Saisissez le montant TTC (€)"
                rules={[{ required: true, message: "Requis" }]}
              >
                <InputNumber
                  min={0}
                  precision={2}
                  onPressEnter={handleOkModal}
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <VatSelect />
            </Col>
            <Col span={12}>
              <Form.Item
                name="transactionDate"
                label="Date d'émission"
                initialValue={dayjs()}
              >
                <DatePicker
                  format={dateDisplayFormat}
                  allowClear={false}
                  onChange={handleDelayOrTransactionDateChange}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <DelaySelect
                handleDelayChange={handleDelayOrTransactionDateChange}
              />
            </Col>
            <Col span={12}></Col>
            <Col span={12}>
              <Form.Item
                name="dueDate"
                label="Date échéance"
                initialValue={dayjs().add(30, "days")}
              >
                <DatePicker
                  format={dateDisplayFormat}
                  allowClear={false}
                  onChange={handleDueDateChange}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>
          {isDocumentEnabled && (
            <Row>
              <Col span={4}>
                <Form.Item
                  name="hasDocument"
                  valuePropName="checked"
                  label={<LinkOutlined />}
                >
                  <Switch />
                </Form.Item>
              </Col>
              <Col span={20}>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues.hasDocument !== currentValues.hasDocument
                  }
                >
                  {({ getFieldValue }) => {
                    const hasDocument = getFieldValue("hasDocument");

                    return (
                      hasDocument && (
                        <Form.Item name="document" label="URL du document">
                          <Input placeholder="Entrez l'URL" />
                        </Form.Item>
                      )
                    );
                  }}
                </Form.Item>
              </Col>
            </Row>
          )}
        </Form>
      </Modal>
    </>
  );
};

export default InvoiceEditModal;
