import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  DashOutlined,
  EditTwoTone,
  ExclamationCircleOutlined,
  LinkOutlined,
} from "@ant-design/icons";
import {
  mdiContentDuplicate,
  mdiCurrencyEur,
  mdiDeleteOutline,
  mdiEyeOffOutline,
  mdiNoteMultipleOutline,
  mdiRepeatVariant,
  mdiRestore,
  mdiSquareEditOutline,
} from "@mdi/js";
import Icon from "@mdi/react";
import {
  Button,
  Dropdown,
  Form,
  Input,
  InputRef,
  Modal,
  PaginationProps,
  Popover,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { ColumnsType } from "antd/es/table";
import CategorizeSelect from "components/CategorizeSelect";
import ConfirmAddSubCategoryContent from "components/ConfirmAddSubCategoryContent";
import InvoiceEditModal from "components/InvoiceEditModal";
import InvoiceReconciliationModal from "components/InvoiceReconciliationModal";
import TransactionNoteModal from "components/TransactionNoteModal";
import { TransactionNoteModalParams } from "components/TransactionsTable";
import { FC, ReactNode, useEffect, useRef, useState } from "react";
import Highlighter from "react-highlight-words";
import categoryService, {
  CreateCategoryValues,
} from "services/categoryService";
import { Category, Invoice, Transaction } from "types";
import { formatTransactionAmount } from "utils/amount";
import { providerEnum } from "utils/constants";
import { CurrencyCode } from "utils/currency";
import { getFormattedFullDate } from "utils/date";
import { KindEnum } from "utils/kind";

const confirmProps = {
  okText: "Confirmer",
  cancelText: "Annuler",
  title: "Êtes-vous sûr ?",
  icon: <ExclamationCircleOutlined />,
};

const { confirm } = Modal;

const confirmDeleteInvoiceContent = ({
  description,
}: {
  description: string;
}) => (
  <>
    <p>Vous êtes sur le point de supprimer :</p>
    <p>
      <Typography.Text mark>{description}</Typography.Text>
    </p>
    <p>La suppression d'une facture engagée est irréversible.</p>
  </>
);

export type InvoiceEditModalParams = {
  open: boolean;
  editingInvoice?: Invoice;
};

export type InvoiceReconciliationModalParams = {
  open: boolean;
  reconciliatingInvoice?: Invoice;
};

interface Props {
  invoices: Invoice[];
  categories: Category[];
  handleCategorizeMany: (
    invoiceIds: number[],
    categoryId: number
  ) => Promise<void>;
  handleValidateCategory: (invoice: Invoice) => Promise<void>;
  loading: boolean;
  handleAddCategoryAndCategorize: (
    values: CreateCategoryValues,
    kind: string,
    transactionIds: number[],
    parent?: number
  ) => Promise<void>;
  handleUpdate: (invoiceId: number, params: any) => Promise<void>;
  handleDelete: (invoiceId: number) => Promise<void>;
  handleDuplicate: (values: {
    description: string;
    amount: number;
    transactionDate: string;
    dueDate: string;
    currency_code: CurrencyCode;
    category?: number;
    vat: number;
  }) => Promise<void>;
  handleValidatePrereconciliation: (
    invoice: Invoice,
    isValidated: boolean
  ) => Promise<void>;
  handleReconciliate: (
    transactionId: number,
    invoiceIds: number[],
    shouldCreateRemainingInvoice: boolean
  ) => Promise<void>;
  changeParams: (
    pagination: PaginationProps,
    filters: any,
    sorter: any
  ) => void;
  pagination: PaginationProps;
  selectedRowKeys?: number[];
  setSelectedRowKeys?: (selectedRowKeys: number[]) => void;
  withRowSelection?: boolean;
  sameSignSelection?: boolean;
  emptyText?: string | ReactNode;
  searchText?: string;
  quickFilter?: string;
}

const InvoicesTable: FC<Props> = ({
  invoices,
  categories,
  handleCategorizeMany,
  handleValidateCategory,
  loading,
  handleAddCategoryAndCategorize,
  handleUpdate,
  handleDelete,
  handleDuplicate,
  handleValidatePrereconciliation,
  handleReconciliate,
  changeParams,
  pagination,
  withRowSelection = true,
  selectedRowKeys = [],
  setSelectedRowKeys = () => {},
  emptyText = <div style={{ margin: 20 }}>Aucune facture à afficher.</div>,
  searchText = "",
  sameSignSelection = false,
  quickFilter,
}) => {
  const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([]);
  const [form] = Form.useForm<CreateCategoryValues>();
  const [modalLoading, setModalLoading] = useState(false);
  const [modalSubCategoryLoading, setModalSubCategoryLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openSubCategory, setOpenSubCategory] = useState(false);
  const [currentCategory, setCurrentCategory] = useState<Category>();
  const [modalEditParams, setModalEditParams] =
    useState<InvoiceEditModalParams>({
      open: false,
    });
  const [modalNoteParams, setModalNoteParams] =
    useState<TransactionNoteModalParams>({
      open: false,
    });
  const [modalReconciliationParams, setModalReconciliationParams] =
    useState<InvoiceReconciliationModalParams>({
      open: false,
    });
  const [kind, setKind] = useState<KindEnum>(KindEnum.cashIn);
  const [currentTransaction, setCurrentTransaction] = useState<
    Transaction | undefined
  >(undefined);
  const ref = useRef<InputRef>(null);

  const hasSelected = selectedRowKeys.length > 0;

  const showModal = (transaction: Transaction, kind: KindEnum) => {
    setCurrentTransaction(transaction);
    setKind(kind);
    setOpen(true);
  };

  const showSubCategoryModal = (
    transaction: Transaction,
    kind: KindEnum,
    parent: Category
  ) => {
    setCurrentTransaction(transaction);
    setKind(kind);
    setCurrentCategory(parent);
    setOpenSubCategory(true);
  };

  useEffect(() => {
    if (open === true) {
      setTimeout(() => ref.current!.focus());
    }
  }, [open]);

  const handleOkModal = async () => {
    if (!currentTransaction) {
      return;
    }
    const values = await form.validateFields();

    const transactionIds = [currentTransaction.id];
    setModalLoading(true);

    await handleAddCategoryAndCategorize(values, kind, transactionIds);

    setModalLoading(false);
    setOpen(false);
    form.resetFields();
  };

  const handleSubCategoryOkModal = async () => {
    if (!currentCategory || !currentTransaction) {
      return;
    }
    const values = await form.validateFields();

    const transactionIds = [currentTransaction.id];
    setModalSubCategoryLoading(true);

    const { transactionCount, invoiceCount, goalCount } =
      await categoryService.isDroppableOver(currentCategory.id);

    if (transactionCount === 0 && invoiceCount === 0 && goalCount === 0) {
      handleAddCategoryAndCategorize(
        { ...values, vat: currentCategory?.vat || 0 },
        kind,
        transactionIds,
        currentCategory.id
      ).then(() => {
        setModalSubCategoryLoading(false);
        setOpenSubCategory(false);
        form.resetFields();
      });
    } else {
      confirm({
        ...confirmProps,
        onOk() {
          handleAddCategoryAndCategorize(
            { ...values, vat: currentCategory?.vat || 0 },
            kind,
            transactionIds,
            currentCategory.id
          ).then(() => {
            setModalSubCategoryLoading(false);
            setOpenSubCategory(false);
            form.resetFields();
          });
        },
        onCancel() {
          setModalSubCategoryLoading(false);
          setOpenSubCategory(false);
          form.resetFields();
        },
        content: (
          <ConfirmAddSubCategoryContent
            parentName={currentCategory.name}
            transactionCount={transactionCount}
            invoiceCount={invoiceCount}
            goalCount={goalCount}
          />
        ),
      });
    }
  };

  const handleCancelModal = () => {
    setOpen(false);
    form.resetFields();
  };

  const handleCancelSubCategoryModal = () => {
    setOpenSubCategory(false);
    form.resetFields();
  };

  const handleMenuClick = (invoice: Invoice) => (e: any) => {
    if (e.key === "edit") {
      setModalEditParams({ open: true, editingInvoice: invoice });
    } else if (e.key === "delete") {
      Modal.confirm({
        ...confirmProps,
        onOk() {
          return handleDelete(invoice.id);
        },
        content: confirmDeleteInvoiceContent({
          description: invoice.description,
        }),
      });
    } else if (e.key === "note") {
      setModalNoteParams({
        open: true,
        transaction: invoice,
      });
    } else if (e.key === "setAsPaid") {
      handleUpdate(invoice.id, { paid: !invoice.paid });
    } else if (e.key === "ignore") {
      handleUpdate(invoice.id, { ignored: !invoice.ignored });
    } else if (e.key === "changeKind") {
      const opposedKind =
        invoice.amount > 0 ? KindEnum.cashOut : KindEnum.cashIn;
      handleUpdate(invoice.id, {
        kind: invoice.kind ? null : opposedKind,
      });
    } else if (e.key === "reconciliate") {
      setModalReconciliationParams({
        open: true,
        reconciliatingInvoice: invoice,
      });
    } else if (e.key === "duplicate") {
      const {
        description,
        amount,
        transactionDate,
        dueDate,
        currency_code,
        category,
        vat,
      } = invoice;

      handleDuplicate({
        description: `Copie de ${description}`,
        amount,
        transactionDate,
        dueDate,
        currency_code,
        category,
        vat,
      });
    }
  };

  const handleRowExpand = (rowKey: number) => {
    const rowIndex = expandedRowKeys.indexOf(rowKey);
    if (rowIndex === -1) {
      setExpandedRowKeys([...expandedRowKeys, rowKey]);
    } else {
      const newExpandedRowKeys = [...expandedRowKeys];
      newExpandedRowKeys.splice(rowIndex, 1);
      setExpandedRowKeys(newExpandedRowKeys);
    }
  };

  const columns: ColumnsType<Invoice> = [
    {
      title: "Date échéance",
      dataIndex: "dueDate",
      key: "dueDate",
      width: 140,
      sorter: true,
      render: (date: string) => getFormattedFullDate(new Date(date)),
    },
    {
      title: "Libellé",
      dataIndex: "description",
      key: "description",
      ellipsis: true,
      sorter: true,
      render: (_: any, invoice: Invoice) => (
        <Space direction="vertical">
          <Popover
            mouseEnterDelay={1}
            placement="topLeft"
            content={
              <div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span>
                    <Tag>
                      {providerEnum[invoice.provider] || "Ajout manuel"}
                    </Tag>
                  </span>
                  <span>
                    <Highlighter
                      highlightStyle={{
                        backgroundColor: "#ffc069",
                        padding: 0,
                      }}
                      searchWords={[searchText]}
                      autoEscape
                      textToHighlight={
                        invoice.note
                          ? `${invoice.description} • ${invoice.note}`
                          : invoice.description
                      }
                    />
                  </span>
                </div>
              </div>
            }
          >
            <Highlighter
              highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={
                invoice.note
                  ? `${invoice.description} • ${invoice.note}`
                  : invoice.description
              }
            />
          </Popover>
          {Boolean(invoice.transaction?.id) &&
            quickFilter !== "precategorized" &&
            quickFilter !== "uncategorized" && (
              <span>
                <Icon
                  path={mdiRepeatVariant}
                  size={0.8}
                  style={{ verticalAlign: "middle" }}
                />{" "}
                {invoice.transaction && (
                  <Popover
                    mouseEnterDelay={1}
                    placement="topLeft"
                    content={
                      <Tag>
                        {getFormattedFullDate(
                          new Date(invoice.transaction.date)
                        )}{" "}
                        -{" "}
                        {invoice.transaction.note
                          ? `${invoice.transaction.raw_description} • ${invoice.transaction.note}`
                          : invoice.transaction.raw_description}
                      </Tag>
                    }
                  >
                    <Tag>
                      {getFormattedFullDate(new Date(invoice.transaction.date))}{" "}
                      -{" "}
                      {invoice.transaction.note
                        ? `${invoice.transaction.raw_description} • ${invoice.transaction.note}`
                        : invoice.transaction.raw_description}
                    </Tag>
                  </Popover>
                )}
              </span>
            )}
          {invoice.transaction &&
            invoice.prereconciliated &&
            quickFilter !== "precategorized" &&
            quickFilter !== "uncategorized" && (
              <div>
                <Tooltip
                  placement="topLeft"
                  title={"Valider le prérapprochement"}
                >
                  <Button
                    type="link"
                    size="small"
                    onClick={() => {
                      handleValidatePrereconciliation(invoice, true);
                    }}
                  >
                    <CheckCircleTwoTone
                      twoToneColor="#52c41a"
                      style={{ fontSize: 25 }}
                    />
                  </Button>
                </Tooltip>
                <Tooltip
                  placement="topLeft"
                  title={"Annuler le prérapprochement"}
                >
                  <Button
                    type="link"
                    size="small"
                    onClick={() => {
                      handleValidatePrereconciliation(invoice, false);
                    }}
                  >
                    <CloseCircleTwoTone
                      twoToneColor="#ff4d4f"
                      style={{ fontSize: 25 }}
                    />
                  </Button>
                </Tooltip>
                <Tooltip
                  placement="topLeft"
                  title={"Annuler et modifier le prérapprochement"}
                >
                  <Button
                    type="link"
                    size="small"
                    onClick={() => {
                      setModalReconciliationParams({
                        open: true,
                        reconciliatingInvoice: invoice,
                      });
                    }}
                  >
                    <EditTwoTone style={{ fontSize: 25 }} />
                  </Button>
                </Tooltip>
              </div>
            )}
        </Space>
      ),
    },
    {
      title: "Statut",
      dataIndex: "dueDate",
      key: "status",
      width: 100,
      render: (_: any, invoice: Invoice) => {
        if (invoice.paid) {
          return <Tag color="green">Payée</Tag>;
        } else if (new Date(invoice.dueDate).getTime() > new Date().getTime()) {
          return <Tag color="gold">En attente</Tag>;
        } else {
          return <Tag color="red">En retard</Tag>;
        }
      },
      filters: [
        { text: "Non payée", value: "not_paid" },
        { text: "Payée", value: "paid" },
        { text: "En attente", value: "awaiting" },
        { text: "En retard", value: "late" },
        { text: "Tout", value: "all" },
      ],
      filterMultiple: false,
      defaultFilteredValue: ["not_paid"],
      filterResetToDefaultFilteredValue: true,
    },
    {
      title: "Catégorie",
      dataIndex: "category",
      key: "category",
      width: 360,
      sorter: true,
      render: (_: any, invoice: Invoice) => (
        <>
          <CategorizeSelect
            categories={categories}
            handleCategorizeMany={handleCategorizeMany}
            transaction={invoice}
            disabled={hasSelected}
            showModal={showModal}
            showSubCategoryModal={showSubCategoryModal}
            searchText={searchText}
          />
          {invoice.category && invoice.precategorized && (
            <Button
              style={{ position: "absolute" }}
              type="link"
              onClick={() => {
                handleValidateCategory(invoice);
              }}
            >
              <CheckCircleTwoTone
                twoToneColor="#52c41a"
                style={{ fontSize: 25 }}
              />
            </Button>
          )}
        </>
      ),
    },
    {
      title: "Montant TTC",
      dataIndex: "amount",
      key: "amount",
      width: 160,
      render: (_: any, invoice: Invoice) =>
        formatTransactionAmount(invoice, searchText),
      sorter: true,
      align: "right",
      filters: [
        { text: "Encaissements", value: KindEnum.cashIn },
        { text: "Décaissements", value: KindEnum.cashOut },
      ],
      filterMultiple: false,
    },
    {
      title: "",
      key: "action",
      dataIndex: "action",
      width: 120,
      render: (_: any, invoice: Invoice) => (
        <Space>
          <Dropdown
            trigger={["click"]}
            menu={{
              onClick: handleMenuClick(invoice),
              selectedKeys: [
                invoice.paid ? "setAsPaid" : "",
                invoice.kind ? "changeKind" : "",
                invoice.ignored ? "ignore" : "",
              ].filter((key) => Boolean(key)),
              items: [
                {
                  icon: <Icon path={mdiNoteMultipleOutline} size={0.62} />,
                  key: "note",
                  label: "Mémo",
                },
                ...(invoice.provider === "gesco"
                  ? !invoice.paid
                    ? [
                        {
                          icon: <Icon path={mdiRepeatVariant} size={0.62} />,
                          key: "reconciliate",
                          label: "Rapprocher",
                        },
                      ]
                    : [
                        {
                          icon: <Icon path={mdiRepeatVariant} size={0.62} />,
                          key: "setAsPaid",
                          label: "Annuler le rapprochement",
                        },
                      ]
                  : []),
                ...(!invoice.provider && !invoice.paid
                  ? [
                      {
                        icon: <Icon path={mdiRepeatVariant} size={0.62} />,
                        key: "reconciliate",
                        label: "Rapprocher",
                      },
                    ]
                  : []),
                ...(!invoice.provider
                  ? [
                      {
                        icon: <Icon path={mdiSquareEditOutline} size={0.62} />,
                        key: "edit",
                        label: "Modifier",
                      },
                      {
                        icon: <Icon path={mdiDeleteOutline} size={0.62} />,
                        key: "delete",
                        label: "Supprimer",
                      },
                      {
                        icon: <Icon path={mdiContentDuplicate} size={0.62} />,
                        key: "duplicate",
                        label: "Dupliquer",
                      },
                      {
                        icon: <Icon path={mdiCurrencyEur} size={0.62} />,
                        key: "setAsPaid",
                        label: invoice.paid
                          ? "Ne plus marquer comme payé"
                          : "Marquer comme payé",
                      },
                    ]
                  : []),
                {
                  icon: <Icon path={mdiEyeOffOutline} size={0.62} />,
                  key: "ignore",
                  label: invoice.ignored
                    ? "Ne plus masquer"
                    : "Masquer la facture (des totaux et statistiques)",
                },
                {
                  icon: <Icon path={mdiRestore} size={0.62} rotate={-30} />,
                  key: "changeKind",
                  label: invoice.kind
                    ? "Ne plus utiliser les catégories opposées"
                    : "Utiliser les catégories opposées",
                },
              ],
            }}
            placement="topRight"
          >
            <Button size="small">
              <DashOutlined />
            </Button>
          </Dropdown>
          {Boolean(invoice.hasDocument) && Boolean(invoice.document) && (
            <LinkOutlined />
          )}
          {Boolean(invoice.transaction?.id) && (
            <Button
              type="link"
              size="small"
              onClick={() => {
                handleRowExpand(invoice.id);
              }}
            >
              <Icon
                path={mdiRepeatVariant}
                size={0.8}
                style={{ verticalAlign: "middle" }}
              />
            </Button>
          )}
        </Space>
      ),
    },
  ];

  const onSelectChange = (selectedRowKeys: number[]) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const title = kind === KindEnum.cashIn ? "Encaissements" : "Décaissements";

  const expandedRowRender = (invoice: Invoice) => {
    const transactionColumns: ColumnsType<Transaction> = [
      {
        title: "Date",
        dataIndex: "date",
        key: "date",
        width: "12%",
        render: (date: string) => getFormattedFullDate(new Date(date)),
      },
      {
        title: "Libellé",
        dataIndex: "raw_description",
        key: "raw_description",
        width: "35%",
        ellipsis: true,
        render: (_: any, transaction: Transaction) =>
          transaction.note
            ? `${transaction.description} • ${transaction.note}`
            : transaction.description,
      },
      {
        title: "Montant TTC",
        dataIndex: "amount",
        key: "amount",
        width: "12%",
        render: (_: any, transaction: Transaction) =>
          formatTransactionAmount(transaction),
        align: "right",
      },
      {
        title: "",
        key: "space",
        dataIndex: "space",
      },
    ];

    return (
      <>
        <Typography.Title level={4} style={{ textAlign: "center" }}>
          <Space>Transaction rapprochée</Space>
        </Typography.Title>
        <Table
          columns={transactionColumns}
          dataSource={[invoice.transaction]}
          rowKey="id"
          pagination={false}
        />
      </>
    );
  };

  return (
    <>
      <Modal
        title={
          <Space>
            Catégoriser
            <Typography.Text code>
              {currentTransaction?.description}
            </Typography.Text>
          </Space>
        }
        open={open}
        confirmLoading={modalLoading}
        okText="Créer et catégoriser"
        onOk={handleOkModal}
        onCancel={handleCancelModal}
        forceRender
        destroyOnClose
      >
        <p>
          Ajout d'une catégorie en : <b>{title}</b>
        </p>
        <Form form={form} layout="vertical">
          <Form.Item
            name="name"
            label="Nom de la catégorie"
            rules={[{ required: true, message: "Requis" }]}
          >
            <Input ref={ref} onPressEnter={handleOkModal} />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title={
          <Space>
            Catégoriser
            <Typography.Text code>
              {currentTransaction?.description}
            </Typography.Text>
          </Space>
        }
        open={openSubCategory}
        confirmLoading={modalSubCategoryLoading}
        okText="Créer et catégoriser la transaction"
        onOk={handleSubCategoryOkModal}
        onCancel={handleCancelSubCategoryModal}
        forceRender
        destroyOnClose
      >
        <div style={{ marginBottom: 15 }}>
          Ajout d'une sous-catégorie à : <b>{currentCategory?.name}</b>
        </div>
        <Form form={form} layout="vertical">
          <Form.Item
            name="name"
            label="Nom de la sous-catégorie"
            rules={[{ required: true, message: "Requis" }]}
          >
            <Input ref={ref} onPressEnter={handleSubCategoryOkModal} />
          </Form.Item>
        </Form>
      </Modal>
      <InvoiceEditModal
        updateInvoice={handleUpdate}
        deleteInvoice={handleDelete}
        modalParams={modalEditParams}
        setModalParams={setModalEditParams}
      />
      <TransactionNoteModal
        addNote={(transactionId: number, note: string) =>
          handleUpdate(transactionId, { note })
        }
        modalParams={modalNoteParams}
        setModalParams={setModalNoteParams}
      />
      <InvoiceReconciliationModal
        reconciliate={handleReconciliate}
        modalParams={modalReconciliationParams}
        setModalParams={setModalReconciliationParams}
        validatePrereconciliation={handleValidatePrereconciliation}
      />
      <Table
        locale={{
          emptyText,
        }}
        columns={columns.filter(
          (column) =>
            quickFilter !== "prereconciliated" || column.key !== "category"
        )}
        dataSource={invoices}
        size="small"
        loading={loading}
        onChange={changeParams}
        pagination={{
          showSizeChanger: true,
          position: ["bottomCenter"],
          ...pagination,
        }}
        rowKey="id"
        showSorterTooltip={false}
        // @ts-ignore
        rowClassName={(invoice) => invoice.ignored && "table-row-ignored"}
        expandable={{
          expandIconColumnIndex: -1,
          expandedRowRender,
          expandedRowKeys,
        }}
        {...(withRowSelection && {
          rowSelection: {
            onChange: onSelectChange,
            selectedRowKeys,
          },
        })}
      />
    </>
  );
};

export default InvoicesTable;
