import * as Sentry from "@sentry/react";
import { Company, Me } from "types";

const identifyUser = (me: Me) => {
  // Sentry
  Sentry.configureScope(function (scope) {
    scope.setUser({ email: me.email });
  });

  // Crisp
  window.CRISP_TOKEN_ID = me.crispToken;
  window.$crisp.push(["do", "session:reset"]);
  if (process.env.NODE_ENV === "production") {
    window.$crisp.push(["safe", true]);
  }
  window.$crisp.push(["set", "user:email", [me.email]]);
  window.$crisp.push([
    "set",
    "user:nickname",
    [`${me.firstName} ${me.lastName}`],
  ]);

  // Segment
  window.analytics.identify(me.id, {
    email: me.email,
    firstName: me.firstName,
    lastName: me.lastName,
    phone: me.phone,
  });
  if (me.company) {
    const { company } = me;
    window.analytics.group(company.id, {
      name: company.name,
      industry: company.businessKind,
      plan: company.plan,
    });
  }
};

const unidentifyUser = () => {
  // Crisp
  window.CRISP_TOKEN_ID = "";
  window.$crisp.push(["do", "session:reset"]);
  if (process.env.NODE_ENV === "production") {
    window.$crisp.push(["safe", true]);
  }
};

const hasRegistered = (me: Me) => {};

const hasVisited = (pathname: string) => {
  // Segment
  window.analytics.page(pathname);
};

const hasLogin = () => {};

const hasCategorized = () => {
  // Segment
  window.analytics.track("Transaction Categorized");
};

const hasBudgetized = () => {
  // Segment
  window.analytics.track("Budgets Edited");
};

const hasExported = () => {
  // Segment
  window.analytics.track("CashPlan Exported");
};

const hasOpenedPricing = () => {
  window.$crisp.push(["set", "session:event", ["user:pricing_opened"]]);

  // Segment
  window.analytics.track("Pricing Opened");
};

const hasChangedCompany = (company: Company) => {
  window.analytics.group(company.id, {
    name: company.name,
    industry: company.businessKind,
    plan: company.plan,
  });
};

const marketingTools = {
  identifyUser,
  unidentifyUser,
  hasRegistered,
  hasVisited,
  hasLogin,
  hasCategorized,
  hasBudgetized,
  hasExported,
  hasOpenedPricing,
  hasChangedCompany,
};
export default marketingTools;
