import Loader from "components/Loader";
import { AuthContext } from "contexts";
import { ReactElement, useContext, useEffect, useState } from "react";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import authService from "services/authService";
import errorHandler from "utils/errorHandler";
import marketingTools from "utils/marketingTools";

function ConnectCallbackPage() {
  const navigate = useNavigate();
  const [, setError] = useState<ReactElement | undefined>(undefined);
  const [loaded, setLoaded] = useState(false);
  const { logIn } = useContext(AuthContext);
  const location = useLocation();
  const params = useParams();

  const from = ((location.state as any)?.from.pathname as string) || "/";

  useEffect(() => {
    const provider = params.provider;
    if (provider) {
      const search = location.search;

      authService
        .loginProvider({ provider, search })
        .then(({ jwt, user }) => {
          logIn(jwt, user);
          if (!user.company) {
            marketingTools.hasRegistered(user);
          }
          setLoaded(true);
        })
        .catch((e) => {
          if (
            e?.response?.data?.message?.[0]?.messages?.[0]?.id ===
            "Auth.form.error.email.taken"
          ) {
            navigate("/login?emailAlreadyTaken=true");
          } else if (
            e?.response?.data?.message?.[0]?.messages?.[0]?.id ===
            "Auth.form.error.confirmed"
          ) {
            navigate("/login?emailNotConfirmed=true");
          } else {
            setLoaded(true);
            throw e;
          }
        })
        .catch(errorHandler(setError));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!loaded) {
    return <Loader />;
  }

  return <Navigate to={from} replace={true} />;
}

export default ConnectCallbackPage;
