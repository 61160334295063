import { addMonths, format, getMonth } from "date-fns";
import numeral from "numeral";
import { FC } from "react";
import {
  CartesianGrid,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Category } from "types";
import { StatisticData } from "utils/dashboardUtils";
import { isPastMonth, monthFullStrings, monthStrings } from "utils/date";

interface CustomTooltipProps {
  active: boolean;
  label: number;
  payload: { value: number; color: string; name: string; dataKey: number }[];
}

const formatChartData = ({
  startingMonth,
  categories,
  data,
  kind,
  baseScenarioId,
}: {
  startingMonth: Date;
  categories: Category[];
  data: StatisticData;
  kind: string;
  baseScenarioId: number;
}) => {
  const startingMonthIndex = getMonth(startingMonth);

  // LINE CHART
  const dataLineChart = Array.from(Array(12).keys()).map((n) => {
    const monthId = startingMonthIndex + n;
    const output: any = { date: monthId };

    const currentMonth = addMonths(startingMonth, n);
    const currentMonthString = format(currentMonth, "yyyy-MM-dd");
    const isPast = isPastMonth(currentMonth);

    categories
      .filter((category) => category.kind === kind)
      .forEach((category) => {
        output[category.id] = isPast
          ? data[category.id][currentMonthString].sumOfTransactions
          : data[category.id][currentMonthString].forecast[baseScenarioId];
      });
    return output;
  });

  return {
    dataLineChart,
  };
};

interface Props {
  data: StatisticData;
  categories: Category[];
  startingMonth: Date;
  loaded: boolean;
  kind: string;
  baseScenarioId: number;
}

const LineChartByCategory: FC<Props> = ({
  data,
  categories,
  startingMonth,
  loaded,
  kind,
  baseScenarioId,
}) => {
  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

  const { dataLineChart } = loaded
    ? formatChartData({
        startingMonth,
        data,
        categories,
        kind,
        baseScenarioId,
      })
    : {
        dataLineChart: [],
      };

  const CustomTooltip = ({ active, payload, label }: CustomTooltipProps) => {
    if (active) {
      const filteredPayload = payload?.filter((point) => point.value !== 0);
      return (
        <div
          style={{
            background: "white",
            padding: "9px 12px",
            border: "1px solid #ccc",
          }}
        >
          {payload?.length && (
            <>
              <strong>{monthFullStrings[label % 12]}</strong>
              <br />
              <br />
            </>
          )}
          {filteredPayload?.length ? (
            filteredPayload
              .sort((pointA, pointB) => pointB.value - pointA.value)
              .map((point) => (
                <div style={{ color: point.color }} key={point.dataKey}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ marginRight: 10 }}>{point.name}:</div>
                    <div>{`${numeral(point.value).format(
                      "0,0",
                      Math.floor
                    )} €`}</div>
                  </div>
                </div>
              ))
          ) : (
            <div>Pas de transactions ce mois-ci</div>
          )}
        </div>
      );
    }

    return null;
  };

  const CustomizedAxisTick = ({
    x,
    y,
    payload,
    width,
    visibleTicksCount,
  }: any) => {
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="end" fill="#666">
          {monthStrings[payload.value % 12]}
        </text>
      </g>
    );
  };

  const formatter = (value: number) =>
    Math.abs(value) >= 1000 ? `${value / 1000} k` : `${value}`;

  return (
    <div style={{ height: 300 }}>
      <ResponsiveContainer>
        <LineChart width={500} height={300} data={dataLineChart}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            orientation="bottom"
            dataKey="date"
            tick={<CustomizedAxisTick />}
          />
          <YAxis width={60} tickFormatter={formatter} />
          <Tooltip
            content={
              // @ts-ignore
              <CustomTooltip />
            }
          />
          <ReferenceLine y={0} stroke="#000" ifOverflow="extendDomain" />
          {/* <Legend /> */}
          {categories
            .filter((category) => category.kind === kind)
            .map((category) => (
              <Line
                type="monotone"
                key={category.id}
                name={category.name}
                dataKey={category.id}
                stroke={COLORS[category.order % COLORS.length]}
                strokeWidth={2}
              />
            ))}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default LineChartByCategory;
