import { ArrowRightOutlined, FormatPainterOutlined } from "@ant-design/icons";
import { Col, Form, InputNumber, Popover, Row, Tooltip } from "antd";
import numeral from "numeral";
import React, { useContext, useRef } from "react";
import {
  getPlannedValue,
  isAlternativeScenarioPlannedValue,
  SourceCategoryMonthData,
} from "utils/dashboardUtils";
import { EditableContext, Item } from "./index";

export interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  toHide: boolean;
  children: React.ReactNode;
  dataIndex: keyof Item;
  record: Item;
  sourceData: Item;
  saveGoal: (
    categoryId: number,
    date: string,
    newGoalAmount: number | null
  ) => Promise<void>;
  duplicateGoal: ({
    categoryId,
    date,
    newGoalAmount,
    interval,
    numberOfMonth,
  }: {
    categoryId: number;
    date: string;
    newGoalAmount: number | null;
    interval: number;
    numberOfMonth: number;
  }) => Promise<Record<string, SourceCategoryMonthData>>;
  scenarioId: number;
  baseScenarioId: number;
  className: string;
  onClick: () => Promise<void>;
  computeVATGoal: (
    categoryId: number
  ) => Promise<Record<string, SourceCategoryMonthData>>;
  showComputeVAT: boolean;
  duplicateGoalsParams: {
    interval: number;
    numberOfMonth: number;
  };
  setDuplicateGoalsParams: ({
    interval,
    numberOfMonth,
  }: {
    interval: number;
    numberOfMonth: number;
  }) => void;
}

export const EditableCell = ({
  title,
  editable,
  toHide,
  showComputeVAT,
  dataIndex,
  record,
  sourceData,
  saveGoal,
  duplicateGoal,
  computeVATGoal,
  scenarioId,
  baseScenarioId,
  children,
  duplicateGoalsParams,
  setDuplicateGoalsParams,
  ...restProps
}: EditableCellProps) => {
  const form = useContext(EditableContext)!;
  const ref = useRef(null);

  const handleEnter = () => {
    // @ts-ignore
    ref.current.blur();
  };

  const handleSave = () => {
    const values = form.getFieldsValue();

    if (
      values[dataIndex] ===
      getPlannedValue(sourceData[dataIndex], scenarioId, baseScenarioId)
    ) {
      return;
    }
    if (values[dataIndex] === null && scenarioId !== baseScenarioId) {
      form.setFieldsValue({
        [dataIndex]: sourceData[dataIndex].goal[baseScenarioId],
      });
    }

    const value =
      values[dataIndex] === null ? null : parseFloat(values[dataIndex]);

    saveGoal(record.categoryId, dataIndex, value);
  };

  const handleDuplicate = async ({
    interval,
    numberOfMonth,
  }: {
    interval: number;
    numberOfMonth: number;
  }) => {
    const values = form.getFieldsValue();

    const value =
      values[dataIndex] === null ? null : parseFloat(values[dataIndex]);
    duplicateGoal({
      categoryId: record.categoryId,
      date: dataIndex,
      newGoalAmount: value,
      interval,
      numberOfMonth,
    }).then((newDataForTheCategory) => {
      const newRecord: Record<string, number | null> = {};
      Object.keys(newDataForTheCategory).forEach((key) => {
        if (
          newDataForTheCategory[key].goal[scenarioId] === null &&
          scenarioId !== baseScenarioId
        ) {
          newRecord[key] = sourceData[key].goal[baseScenarioId];
        } else {
          newRecord[key] = newDataForTheCategory[key].goal[scenarioId];
        }
      });
      form.setFieldsValue(newRecord);
    });
  };

  const handleComputeVAT = async () => {
    computeVATGoal(record.categoryId).then((newDataForTheCategory) => {
      const newRecord: Record<string, number | null> = {};
      Object.keys(newDataForTheCategory).forEach((key) => {
        newRecord[key] = newDataForTheCategory[key].goal[scenarioId];
      });
      form.setFieldsValue(newRecord);
    });
  };

  const alternativeScenarioPlannedValue =
    sourceData &&
    sourceData[dataIndex] &&
    isAlternativeScenarioPlannedValue(
      sourceData[dataIndex],
      scenarioId,
      baseScenarioId
    );
  let className = "";
  if (alternativeScenarioPlannedValue) {
    className = "alternative-scenario-value ";
  }

  let childNode = children;
  if (editable) {
    childNode = (
      <div
        className="input-number"
        onClick={(event: any) => {
          event.stopPropagation();
        }}
      >
        <Form.Item name={dataIndex} className="input-number-budget">
          <InputNumber
            ref={ref}
            id={`${record.key}/${dataIndex}`}
            min={0}
            formatter={(value) => (value ? numeral(value).format("0,0") : "")}
            onPressEnter={handleEnter}
            onBlur={handleSave}
            className={className}
          />
        </Form.Item>
        <Popover
          placement="bottom"
          overlayStyle={{ width: 220 }}
          title="Étendre la valeur"
          content={
            <>
              <InputNumber
                min={1}
                max={12}
                addonBefore="Tous les"
                addonAfter="mois"
                value={duplicateGoalsParams.interval}
                onChange={(value) =>
                  setDuplicateGoalsParams({
                    interval: value || 1,
                    numberOfMonth: duplicateGoalsParams.numberOfMonth,
                  })
                }
              />
              <InputNumber
                min={1}
                max={36}
                addonBefore="Pendant"
                addonAfter="mois"
                value={duplicateGoalsParams.numberOfMonth}
                onChange={(value) =>
                  setDuplicateGoalsParams({
                    interval: duplicateGoalsParams.interval,
                    numberOfMonth: value || 1,
                  })
                }
              />
            </>
          }
          getPopupContainer={(trigger) => trigger}
          trigger="hover"
        >
          <FormatPainterOutlined
            className="duplicate-icon"
            onClick={() =>
              handleDuplicate({
                interval: duplicateGoalsParams.interval,
                numberOfMonth: duplicateGoalsParams.numberOfMonth,
              })
            }
          />
        </Popover>
      </div>
    );
  } else if (toHide) {
    return <td {...restProps}></td>;
  } else if (showComputeVAT) {
    childNode = (
      <Row justify="space-between">
        <Col span={4}>{children}</Col>
        <Col span={2}>
          <Tooltip
            title="Calculer la TVA jusqu'au dernier mois visible"
            mouseEnterDelay={1}
          >
            <ArrowRightOutlined
              className="vat-icon"
              onClick={handleComputeVAT}
            />
          </Tooltip>
        </Col>
      </Row>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};
