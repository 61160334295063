import { Alert, Avatar, Typography } from "antd";
import LogoZenfirst from "components/LogoZenfirst";
import { AuthContext } from "contexts";
import { ReactElement, useContext, useState } from "react";
import { Link } from "react-router-dom";
import { RegisterAffiliateForm } from "./RegisterAffiliateForm";
import { ReactComponent as QuoteIcon } from "./quote.svg";

function RegisterAffiliatePage() {
  const { logIn } = useContext(AuthContext);
  const [error, setError] = useState<ReactElement | undefined>(undefined);

  return (
    <div className="RegisterPage">
      <div className="LeftPanel">
        <div className="CustomerFeedbacks">
          <div className="CustomerFeedbacksTop">
            <div className="QuoteContainer">
              <QuoteIcon />
              <div className="QuoteText">
                <div>
                  Une prise en main facile, une solution efficace pour suivre et
                  anticiper sa trésorerie.
                </div>
              </div>
              <div className="QuoteAuthor">
                <div className="QuoteAuthorPicture">
                  <Avatar
                    src="/img/frederic-lardeau.jpeg"
                    size="large"
                  ></Avatar>
                </div>
                <div className="QuoteAuthorDescription">
                  <Typography.Text strong>Frédéric Lardeau</Typography.Text>,
                  Expert Gestion @ GCL
                </div>
              </div>
            </div>
            <div className="CustomerLogos">Ils nous font déjà confiance</div>
            <div className="CustomerLogosContainer">
              <img
                className="CustomerLogo"
                alt="logo flair"
                src="/img/logo-flair.png"
              />
              <img
                className="CustomerLogo"
                alt="museandmarlow"
                src="/img/logo-muse-and-marlow.png"
              />
              <img
                className="CustomerLogo"
                alt="logo olea"
                src="/img/logo-olea.png"
              />
              <img
                className="CustomerLogo"
                alt="logo les trois coups"
                src="/img/logo-trois-coups.png"
              />
            </div>
          </div>
          <div className="AppPreviewContainer">
            <img alt="app-preview" src="/img/tableaudebord.png" />
          </div>
        </div>
      </div>
      <div className="MainPanel">
        <div className="FormHeader">
          <div className="LogoContainer">
            <LogoZenfirst />
          </div>
          <h1 className="CatchPhrase">Créez votre compte</h1>
          <div className="Description">Inscription en quelques minutes.</div>
        </div>
        <div className="FormContainer">
          {error && <Alert type="error" message={error} />}

          <RegisterAffiliateForm setError={setError} logIn={logIn} />
        </div>
        <div className="FormFooter">
          <div className="AlreadyRegisteredContainer">
            <div className="AlreadyRegisteredText">
              Vous avez déjà un compte ?
            </div>
            <Link className="AlreadyRegisteredAction" to="/login">
              Connexion
            </Link>
          </div>
        </div>
        <div className="FormFooterMobile">
          <div className="CustomerLogos">Ils nous font déjà confiance</div>
          <div className="CustomerLogosContainer">
            <img
              className="CustomerLogo"
              alt="flair"
              src="/img/logo-flair.png"
            />
            <img
              className="CustomerLogo"
              alt="museandmarlow"
              src="/img/logo-muse-and-marlow.png"
            />
            <img className="CustomerLogo" alt="olea" src="/img/logo-olea.png" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default RegisterAffiliatePage;
