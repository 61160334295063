import Loader from "components/Loader";
import { AuthContext } from "contexts/auth";
import { useContext } from "react";
import { Navigate, useLocation, useSearchParams } from "react-router-dom";

function RequireAuth({ children }: { children: JSX.Element }) {
  const { isAuthenticated } = useContext(AuthContext);
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const context = searchParams.get("context");

  if (context === "neoapibankcallback") {
    window.location.href = `https://treso.neoapi.eu/bank/callback`;
    return <Loader />;
  }

  if (!isAuthenticated) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

export default RequireAuth;
