import { DownOutlined } from "@ant-design/icons";
import { Button, Dropdown, MenuProps } from "antd";
import { getMonth } from "date-fns";
import { FC, useState } from "react";
import { Scenario } from "types";
import { monthFullStrings } from "utils/date";

interface Props {
  scenarioId: number;
  scenarios: Scenario[];
  checkedScenarios: Record<number | string, boolean>;
  handleSwitch: (scenarioId: number) => (checked: boolean) => void;
  graphicView: string;
  startingMonth: Date;
}

const MainScenarioCheck: FC<Props> = ({
  scenarioId,
  scenarios,
  checkedScenarios,
  handleSwitch,
  graphicView,
  startingMonth,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const startingMonthIndex = getMonth(startingMonth);

  const handleMenuClick = (e: any) => {
    if (e.key === `${scenarioId}`) {
      return;
    }
    handleSwitch(e.key)(!checkedScenarios[e.key]);
  };

  const handleVisibleChange = (flag: boolean) => {
    setOpen(flag);
  };

  const menuItems: MenuProps["items"] = [
    {
      id: "compareWithPastGoals",
      name: `En suivant les objectifs depuis le 1er ${
        monthFullStrings[startingMonthIndex % 12]
      }`,
    },
    ...scenarios,
  ].map((scenario) => ({
    disabled: scenario.id === scenarioId,
    key: scenario.id,
    label: scenario.name,
  }));
  const menu = {
    items: menuItems,
    onClick: handleMenuClick,
    selectedKeys: [
      ...Object.keys(checkedScenarios).filter((key) => checkedScenarios[key]),
      `${scenarioId}`,
    ],
  };

  if (graphicView === "1") return <></>;

  return (
    <Dropdown
      menu={menu}
      open={open}
      getPopupContainer={(trigger) => trigger}
      onOpenChange={handleVisibleChange}
    >
      <Button>
        Scénarios affichés <DownOutlined />
      </Button>
    </Dropdown>
  );
};
export default MainScenarioCheck;
