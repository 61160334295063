import { Button, Modal, Space, Typography } from "antd";
import { TransactionSplittingModalParams } from "components/TransactionsTable";
import { default as React, FC, useState } from "react";
import { CategoriesWithAmounts } from "types";
import { CurrencyCode } from "utils/currency";
import "./index.css";
import { SplittingTransactionEditableTable } from "./SplittingTransactionEditableTable";
import Icon from "@mdi/react";
import { mdiSourceFork } from "@mdi/js";

export interface InputRow {
  key: React.Key;
  amount: number;
  currency_code: CurrencyCode;
  categoryId?: number;
}

interface Props {
  splitTransaction: (
    transactionId: number,
    categoriesWithAmounts: CategoriesWithAmounts[]
  ) => Promise<void>;
  modalParams: TransactionSplittingModalParams;
  setModalParams: any;
}

const TransactionSplittingModal: FC<Props> = ({
  splitTransaction,
  modalParams,
  setModalParams,
}) => {
  const [buttonLoading, setButtonLoading] = useState(false);
  const [inputRows, setInputRows] = useState<InputRow[]>([]);

  const { splittingTransaction, open } = modalParams;

  const handleOkModal = async () => {
    const { splittingTransaction } = modalParams;
    if (!splittingTransaction) {
      return;
    }
    setButtonLoading(true);
    const categoriesWithAmounts = inputRows.map(({ amount, categoryId }) => ({
      categoryId,
      amount,
    }));
    await splitTransaction(splittingTransaction.id, categoriesWithAmounts);

    setModalParams({ open: false });
    setButtonLoading(false);
  };

  const handleCancelModal = () => {
    setModalParams({ open: false });
  };

  let sumOfAmounts = 0;
  let numberOfAmount = 0;

  inputRows.forEach(({ amount }) => {
    numberOfAmount += 1;
    sumOfAmounts += amount * 100;
  });
  sumOfAmounts /= 100;

  return (
    <>
      <Modal
        title={
          <Space>
            <Icon
              path={mdiSourceFork}
              size={0.65}
              style={{ verticalAlign: "-15%" }}
            />
            Diviser en plusieurs
            <Typography.Text code>
              {splittingTransaction?.description}
            </Typography.Text>
          </Space>
        }
        open={open}
        onOk={handleOkModal}
        onCancel={handleCancelModal}
        destroyOnClose
        footer={[
          <Button key="back" onClick={handleCancelModal}>
            Annuler
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={buttonLoading}
            onClick={handleOkModal}
            disabled={
              numberOfAmount <= 1 ||
              sumOfAmounts !== splittingTransaction?.amount
            }
          >
            Diviser
          </Button>,
        ]}
        width="85%"
      >
        {splittingTransaction && (
          <div style={{ textAlign: "center" }}>
            <div style={{ width: 600, margin: "0 auto" }}>
              <SplittingTransactionEditableTable
                splittingTransaction={splittingTransaction}
                inputRows={inputRows}
                setInputRows={setInputRows}
              />
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};

export default TransactionSplittingModal;
