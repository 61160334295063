import { AxiosResponse } from "axios";
import axios from "utils/setupAxios";

export async function get(
  startingMonth: string,
  saveStartingMonth?: boolean
): Promise<AxiosResponse> {
  return axios.get(
    `/dashboard?startingMonth=${startingMonth}${
      saveStartingMonth ? "&saveStartingMonth=true" : ""
    }`
  );
}

export async function downloadXLSX({
  startingDate,
  endingDate,
  dataTypes,
  scenarioId,
}: {
  startingDate: string;
  endingDate: string;
  dataTypes: string[];
  scenarioId?: number;
}): Promise<AxiosResponse> {
  return axios.get(
    `/dashboard/export?startingDate=${startingDate}${
      scenarioId ? `&scenario=${scenarioId}` : ""
    }&endingDate=${endingDate}${dataTypes
      .map((dataType, index) => `&dataTypes[${index}]=${dataType}`)
      .join("")}`,
    { responseType: "blob" }
  );
}

export async function generateDemoData(): Promise<AxiosResponse> {
  return axios.post(`/dashboard/generate-demo-data`);
}

const dashboardService = { get, downloadXLSX, generateDemoData };
export default dashboardService;
