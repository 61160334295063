import { Button, Progress, Tooltip } from "antd";
import EditableTable from "components/EditableTable";
import { AuthContext } from "contexts";
import CSS from "csstype";
import { addMonths, format, getMonth, getYear, startOfMonth } from "date-fns";
import numeral from "numeral";
import React, { FC, useContext, useEffect, useState } from "react";
import { Category } from "types";
import {
  CashTotalRowData,
  CategoryMonthData,
  ExtraRowData,
  SourceCategoryMonthData,
  SourceData,
  StatisticData,
} from "utils/dashboardUtils";
import { isPastMonth, isPresentMonth, monthStrings } from "utils/date";
import { KindEnum } from "utils/kind";
import { formatTree } from "utils/tree";
import "./index.css";
import {
  mdiMinusBoxOutline,
  mdiPlusBoxOutline,
  mdiSkipBackward,
} from "@mdi/js";
import Icon from "@mdi/react";

interface Props {
  data: StatisticData;
  sourceData: SourceData;
  scenarioId: number;
  baseScenarioId: number;
  saveGoal: (
    categoryId: number,
    date: string,
    newGoalAmount: number | null
  ) => Promise<void>;
  duplicateGoal: ({
    categoryId,
    date,
    newGoalAmount,
    interval,
    numberOfMonth,
  }: {
    categoryId: number;
    date: string;
    newGoalAmount: number | null;
    interval: number;
    numberOfMonth: number;
  }) => Promise<Record<string, SourceCategoryMonthData>>;
  computeVATGoal: (
    categoryId: number
  ) => Promise<Record<string, SourceCategoryMonthData>>;
  tableEditing: boolean;
  startingMonth: Date;
  categories: Category[];
  showModal: any;
  loaded: boolean;
  selectedMonth?: Date;
  setSelectedMonth: React.Dispatch<React.SetStateAction<Date | undefined>>;
  withInvoices: boolean;
  expandedRowKeys: string[];
  handleExpand: (expanded: boolean, key: string) => void;
  expandAll: (expand: boolean) => void;
}

const MainTable: FC<Props> = ({
  data,
  sourceData,
  scenarioId,
  baseScenarioId,
  duplicateGoal,
  saveGoal,
  computeVATGoal,
  tableEditing,
  startingMonth,
  categories,
  showModal,
  loaded,
  selectedMonth,
  setSelectedMonth,
  withInvoices,
  expandedRowKeys,
  handleExpand,
  expandAll,
}) => {
  const { me } = useContext(AuthContext);
  const [isEditingPast, setIsEditingPast] = useState<boolean>(false);
  const [duplicateGoalsParams, setDuplicateGoalsParams] = useState({
    interval: 1,
    numberOfMonth: 12,
  });

  useEffect(() => {
    if (tableEditing === false && isEditingPast === true) {
      setIsEditingPast(false);
    }
  }, [tableEditing, isEditingPast]);

  const monthsArray = Array.from(Array(12).keys()).map((n) => {
    const currentMonth = addMonths(startingMonth, n);
    const monthShortString = `${monthStrings[getMonth(currentMonth)]} ${
      getYear(currentMonth) % 100
    }`;
    const currentMonthString = format(currentMonth, "yyyy-MM-dd");
    return {
      currentMonth,
      monthShortString,
      currentMonthString,
    };
  });

  const cashInCategories = categories.filter(
    (category) => category.kind === KindEnum.cashIn
  );
  const cashOutCategories = categories.filter(
    (category) => category.kind === KindEnum.cashOut
  );

  const formatFunction = (category: Category) => ({
    key: category.id.toString(),
    category: (
      <Tooltip
        title={
          category.note ? `${category.name} • ${category.note}` : category.name
        }
        mouseEnterDelay={1}
      >
        {category.name}
      </Tooltip>
    ),
    categoryId: category.id,
    kind: category.kind,
    grouper: category.grouper,
    grouped: category.grouped,
    isVAT: category.isVAT || false,
    ...data[category.id],
    sourceData: sourceData[category.id],
  });

  const cashInRows = formatTree({
    categories: cashInCategories,
    formatFunction,
  });
  const cashOutRows = formatTree({
    categories: cashOutCategories,
    formatFunction,
  });

  const dataSource = [
    {
      category: <b>En début de mois</b>,
      key: "cashAtBeginning",
      ...data["cashAtBeginning"],
    },
    {
      category: "",
      type: "separator",
      key: "separator-1",
    },
    {
      category: <b>Encaissements</b>,
      key: "cashIn",
      kind: KindEnum.cashIn,
      ...data["cashInTotal"],
      children: cashInRows,
    },
    {
      category: "",
      type: "separator",
      key: "separator-2",
    },
    {
      category: <b>Décaissements</b>,
      key: "cashOut",
      kind: KindEnum.cashOut,
      ...data["cashOutTotal"],
      children: cashOutRows,
    },
    {
      category: "",
      type: "separator",
      key: "separator-3",
    },
    {
      category: <b>Masqué</b>,
      key: "ignoredTotal",
      ...data["ignoredTotal"],
      // children: [
      //   {
      //     category: "Encaissements",
      //     key: "ignoredCashIn",
      //     ...data["ignoredCashIn"],
      //   },
      //   {
      //     category: "Décaissements",
      //     key: "ignoredCashOut",
      //     ...data["ignoredCashOut"],
      //   },
      // ],
    },
    {
      category: <b>Variation</b>,
      key: "cashVariation",
      ...data["cashVariation"],
    },
    {
      category: <b>En fin de mois</b>,
      key: "cashAtEnd",
      ...data["cashAtEnd"],
    },
  ];

  // Data for table header
  const columns = [
    {
      title: (
        <>
          <Tooltip
            placement="topLeft"
            title={
              expandedRowKeys.length !== 0
                ? "Déplier toutes les catégories"
                : "Replier toutes les catégories"
            }
          >
            <Button
              type="link"
              style={{ color: "black" }}
              onClick={(e) => {
                expandAll(expandedRowKeys.length !== 0 ? false : true);
              }}
              icon={
                expandedRowKeys.length !== 0 ? (
                  <Icon
                    path={mdiMinusBoxOutline}
                    size={0.85}
                    style={{ verticalAlign: "middle" }}
                  />
                ) : (
                  <Icon
                    path={mdiPlusBoxOutline}
                    size={0.85}
                    style={{ verticalAlign: "middle" }}
                  />
                )
              }
            ></Button>
          </Tooltip>
          {tableEditing && (
            <Tooltip
              placement="topLeft"
              title={
                !isEditingPast ? "Éditer le passé" : "Ne plus éditer le passé"
              }
            >
              <Button
                type="link"
                style={!isEditingPast ? { color: "black" } : {}}
                onClick={() => {
                  setIsEditingPast(!isEditingPast);
                }}
              >
                <Icon
                  path={mdiSkipBackward}
                  size={0.75}
                  style={{ verticalAlign: "middle" }}
                />
              </Button>
            </Tooltip>
          )}
        </>
      ),
      dataIndex: "category",
      width: 210,
      ellipsis: true,
      onCell: (record: any) => {
        return {
          record,
          showComputeVAT: me?.company?.isVATEnabled && record.isVAT,
          computeVATGoal,
          scenarioId,
        };
      },
      render: (text: string) => text,
    },
    ...monthsArray.map(
      ({ currentMonth, monthShortString, currentMonthString }) => {
        const isPast = isPastMonth(currentMonth);
        const isPresent = isPresentMonth(currentMonth);
        const isSelected = !tableEditing
          ? startOfMonth(currentMonth).getTime() === selectedMonth?.getTime()
          : false;
        return {
          title: monthShortString,
          ...(isSelected && { width: 150 }),
          dataIndex: currentMonthString,
          onHeaderCell: () => {
            return {
              className: `${isPast ? "cell-is-past" : ""} ${
                isSelected ? "cell-is-selected" : ""
              } ${isPresent ? "cell-is-present" : ""}`,
              onClick: () => {
                if (selectedMonth?.getTime() === currentMonth?.getTime()) {
                  setSelectedMonth(undefined);
                } else {
                  setSelectedMonth(currentMonth);
                }
              },
            };
          },
          onCell: (record: any) => {
            const isExpanded = expandedRowKeys.indexOf(record.key) !== -1;
            const isEditableRow =
              (!record.children || record.grouper) &&
              !record.grouped &&
              record.kind;
            return {
              title: monthShortString,
              editable:
                tableEditing && (!isPast || isEditingPast) && isEditableRow,
              toHide:
                tableEditing &&
                (!isPast || isEditingPast) &&
                !((!record.children || record.grouper) && !record.grouped) &&
                record.kind,
              dataIndex: currentMonthString,
              record,
              sourceData: record.sourceData,
              saveGoal,
              duplicateGoal,
              scenarioId,
              baseScenarioId,
              className: `${isPast ? "cell-is-past" : ""} ${
                isSelected ? "cell-is-selected" : ""
              } ${isPresent ? "cell-is-present" : ""} ${
                record.children && isExpanded ? "is-parent-expanded" : ""
              } ${
                record.children && !isExpanded ? "is-parent-not-expanded" : ""
              }`,
              onClick: () => {
                if (
                  ((!record.children && record.kind) ||
                    record.key === "ignoredTotal") &&
                  !tableEditing
                ) {
                  if (record.key === "ignoredTotal") {
                    showModal({
                      ignored: true,
                      date: currentMonthString,
                    });
                  } else {
                    showModal({
                      categoryId: record.categoryId,
                      date: currentMonthString,
                    });
                  }
                } else if (record.children) {
                  handleExpand(!isExpanded, record.key);
                }
              },
              duplicateGoalsParams,
              setDuplicateGoalsParams,
            };
          },
          render: (
            cell: CategoryMonthData | CashTotalRowData | ExtraRowData,
            row: any
          ) => {
            if (row.type === "separator") {
              return <></>;
            }
            if (
              row.key === "cashAtBeginning" ||
              row.key === "cashAtEnd" ||
              row.key === "ignoredTotal"
            ) {
              const currentValue = cell as ExtraRowData;
              return (
                <span>{`${
                  currentValue[scenarioId]
                    ? numeral(currentValue[scenarioId]).format(
                        "0,0",
                        Math.floor
                      )
                    : "-"
                }`}</span>
              );
            }

            const currentValue = cell as CategoryMonthData | CashTotalRowData;

            const done = currentValue.sumOfTransactions;
            const awaiting = currentValue.sumOfInvoices;
            const doneAndAwaiting = done + awaiting;
            const planned = currentValue.goal[scenarioId];
            const forecast = currentValue.forecast[scenarioId];
            const alternativeScenarioPlannedValue =
              currentValue.isAlternativeScenarioPlannedValue[scenarioId];
            const isEditableRow =
              (!row.children || row.grouper) && !row.grouped && row.kind;

            const style: CSS.Properties = {};
            if (
              currentMonth.getTime() >= startOfMonth(new Date()).getTime() &&
              alternativeScenarioPlannedValue
            ) {
              style.textDecoration = "underline";
            }
            const format = row.key === "cashVariation" ? "+0,0" : "0,0";

            const formatValue = (value: number) =>
              value ? `${numeral(value).format("0,0", Math.floor)} €` : "-";

            let dataSource = [
              {
                key: "done",
                title: (
                  <span>
                    Réalisé{" "}
                    {Boolean(planned) &&
                      Boolean(Math.round((done / forecast) * 100)) &&
                      `(${Math.round((done / forecast) * 100)} %)`}
                  </span>
                ),
                value: done,
              },
              {
                key: "awaiting",
                title: (
                  <span>
                    Engagé{" "}
                    {Boolean(planned) &&
                      Boolean(Math.round((awaiting / forecast) * 100)) &&
                      `(${Math.round((awaiting / forecast) * 100)} %)`}
                  </span>
                ),
                value: awaiting,
              },
              {
                key: "planned",
                title: <span>Prévisionnel</span>,
                value: planned,
              },
              {
                key: "forecast",
                title: <b>Prévu</b>,
                value: forecast,
              },
            ];

            if (!isEditableRow) {
              dataSource = dataSource.filter((data) => data.key !== "planned");
            }
            if (isPast) {
              dataSource = dataSource.filter(
                (data) => data.key !== "remaining" && data.key !== "awaiting"
              );
            }
            if (!isPast && !isPresent) {
              dataSource = dataSource.filter((data) => data.key !== "done");
            }
            if (!withInvoices) {
              dataSource = dataSource.filter((data) => data.key !== "awaiting");
            }

            const popoverContent = (
              <div>
                {dataSource.map((e) => (
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                    key={e.key}
                  >
                    <span style={{ marginRight: 30 }}>{e.title}</span>
                    <span>{formatValue(e.value)}</span>
                  </div>
                ))}
              </div>
            );

            if (!isSelected) {
              let className = "";
              if (row.key === "cashVariation") {
                className =
                  forecast > 0 ? "amount-positive" : "amount-negative";
              }
              const value =
                currentMonth.getTime() >= startOfMonth(new Date()).getTime()
                  ? forecast
                  : done;
              const formattedValue = value
                ? numeral(value).format(format, Math.floor)
                : "-";
              return (
                <Tooltip
                  title={popoverContent}
                  mouseEnterDelay={1}
                  placement="left"
                  getPopupContainer={(trigger) => trigger}
                  overlayStyle={{ zIndex: 1 }}
                >
                  <span className={className} style={style}>
                    {formattedValue}
                  </span>
                </Tooltip>
              );
            }

            style["fontSize"] = "11px";

            const doneAndAwaitingDisplay = doneAndAwaiting
              ? numeral(doneAndAwaiting).format(format, Math.floor)
              : "-";
            const plannedDisplay = planned
              ? ` / ${numeral(
                  isEditableRow || me?.company?.isSimpleForecastModeEnabled
                    ? planned
                    : forecast
                ).format(format, Math.floor)}`
              : " / -";

            let doneAndAwaitingPercent: number;
            let donePercent: number;
            if (planned === 0 || doneAndAwaiting >= planned) {
              doneAndAwaitingPercent = 100;
              donePercent = (done / doneAndAwaiting) * 100;
            } else {
              doneAndAwaitingPercent = Math.round(
                (doneAndAwaiting /
                  (isEditableRow || me?.company?.isSimpleForecastModeEnabled
                    ? planned
                    : forecast)) *
                  100
              );
              donePercent = Math.round(
                (done /
                  (isEditableRow || me?.company?.isSimpleForecastModeEnabled
                    ? planned
                    : forecast)) *
                  100
              );
            }

            const status =
              row.kind === KindEnum.cashIn ? "success" : "exception";
            const color =
              row.kind === KindEnum.cashIn
                ? "rgba(48, 206, 143, 0.4)"
                : "rgba(255, 91, 54, 0.4)";

            return (
              <Tooltip
                title={popoverContent}
                mouseEnterDelay={1}
                placement="left"
                getPopupContainer={(trigger) => trigger}
                overlayStyle={{ zIndex: 1 }}
              >
                <span
                  style={style}
                >{`${doneAndAwaitingDisplay}${plannedDisplay}`}</span>
                {Boolean(doneAndAwaiting || planned) &&
                  row.key !== "cashVariation" && (
                    <Progress
                      strokeWidth={3}
                      percent={donePercent}
                      success={{
                        percent: doneAndAwaitingPercent,
                        strokeColor: color,
                      }}
                      status={status}
                      showInfo={false}
                    />
                  )}
              </Tooltip>
            );
          },
        };
      }
    ),
  ];

  return (
    <div
      style={{ border: "1px solid #d6d6d6" }}
      className={`${tableEditing ? "editable" : ""}`}
      id="MainTable"
    >
      <EditableTable
        columns={columns}
        expandedRowKeys={expandedRowKeys}
        handleExpand={handleExpand}
        onRow={(record: any) => ({
          record,
          sourceData: record.sourceData,
          scenarioId,
          baseScenarioId,
          className: `${
            record.key === "ignoredTotal" ? "row-is-ignored" : ""
          } ${record.type === "separator" ? "row-is-separator" : ""} ${
            record.children ? "row-is-parent" : ""
          }  ${record.key === "cashIn" ? "row-is-cashIn" : ""} ${
            record.key === "cashOut" ? "row-is-cashOut" : ""
          } ${record.key === "cashVariation" ? "row-is-variation" : ""} ${
            record.key === "cashAtBeginning" || record.key === "cashAtEnd"
              ? "row-is-balance"
              : ""
          }`,
        })}
        dataSource={dataSource}
        loading={!loaded}
      />
    </div>
  );
};

export default MainTable;
