import { AxiosResponse } from "axios";
import { Scenario } from "types";
import axios from "utils/setupAxios";

export async function find({ query }: { query?: string }): Promise<Scenario[]> {
  let options = "";

  return axios
    .get(`/scenarios?${options}${query || ""}`)
    .then((res) => res.data);
}

export async function create(values: {
  color: string;
  name: string;
}): Promise<Scenario> {
  return axios.post(`/scenarios`, values).then((res) => res.data);
}

export async function duplicate(
  scenarioId: number,
  values: any
): Promise<AxiosResponse> {
  return axios.post(`/scenarios/${scenarioId}/duplicate`, values);
}

export async function update(
  scenarioId: number,
  values: any
): Promise<AxiosResponse> {
  return axios.put(`/scenarios/${scenarioId}`, values);
}

export async function remove(scenarioId: number): Promise<AxiosResponse> {
  return axios.delete(`/scenarios/${scenarioId}`);
}

const scenarioService = { find, create, update, remove };
export default scenarioService;
