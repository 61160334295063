import { Me } from "types";
import axios from "utils/setupAxios";

interface AuthResponse {
  user: Me;
  jwt: string;
}

export async function login(values: {
  identifier: string;
  password: string;
}): Promise<AuthResponse> {
  return axios.post("/auth/local", values).then((res) => res.data);
}

export async function loginProvider({
  provider,
  search,
}: {
  provider: string;
  search: string;
}): Promise<AuthResponse> {
  return axios
    .get(`/auth/${provider}/callback${search}`)
    .then((res) => res.data);
}

export async function register(values: {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  password: string;
  referrer: string;
  utm_campaign: string;
  utm_medium: string;
  utm_source: string;
  app_domain: string;
  promotionCode?: string;
  ref?: string;
}): Promise<AuthResponse> {
  return axios.post("/auth/local/register", values).then((res) => res.data);
}

export async function forgot(values: {
  email: string;
}): Promise<{ ok: boolean }> {
  return axios.post("/auth/forgot-password", values).then((res) => res.data);
}

export async function reset(values: {
  code: string;
  password: string;
  passwordConfirmation: string;
}): Promise<AuthResponse> {
  return axios.post("/auth/reset-password", values).then((res) => res.data);
}

const authService = { login, loginProvider, forgot, reset, register };
export default authService;
