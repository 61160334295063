import Icon from "@mdi/react";
import {
  mdiContentSaveOutline,
  mdiGroup,
  mdiUngroup,
  mdiViewGridPlusOutline,
} from "@mdi/js";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  InputRef,
  Modal,
  Row,
  Space,
  Tag,
  Tooltip,
  Tree,
  Typography,
} from "antd";
import ConfirmAddSubCategoryContent from "components/ConfirmAddSubCategoryContent";
import { VatSelect } from "components/VatSelect";
import { AuthContext } from "contexts";
import React, { FC, useContext, useEffect, useRef, useState } from "react";
import categoryService from "services/categoryService";
import { Category } from "types";
import { maxLevelDeepth } from "utils/constants";
import { KindEnum } from "utils/kind";
import { findTree, formatTree, hasChildren } from "utils/tree";

const { confirm } = Modal;
const { Text } = Typography;

const confirmProps = {
  okText: "Confirmer",
  cancelText: "Annuler",
  title: "Êtes-vous sûr ?",
  icon: <ExclamationCircleOutlined />,
};

const confirmSetCategoryAsGrouper = ({
  parentName,
  grouper,
}: {
  parentName: string;
  grouper: boolean;
}) => (
  <>
    <Typography.Paragraph>
      Vous êtes sur le point de modifier le maillage des catégories pour :
    </Typography.Paragraph>
    <Typography.Paragraph>
      <Text mark>{parentName}</Text>
    </Typography.Paragraph>
    <Typography.Paragraph>
      Cela va entraîner les modifications suivantes:
    </Typography.Paragraph>
    {grouper ? (
      <ul>
        <li>
          <b>Les budgets pourront être saisis dans cette catégorie</b>
        </li>
        <li>
          <b>Tous les budgets des sous-catégories seront perdus</b>
        </li>
      </ul>
    ) : (
      <ul>
        <li>
          <b>Les budgets seront saisis dans les sous-catégories</b>
        </li>
        <li>
          <b>Tous les budgets de cette catégorie seront perdus</b>
        </li>
      </ul>
    )}
  </>
);

const confirmDeleteCategoryContent = ({ name }: { name: string }) => (
  <>
    <Typography.Paragraph>
      Vous êtes sur le point de supprimer la catégorie :
    </Typography.Paragraph>
    <Typography.Paragraph>
      <Text mark>{name}</Text>
    </Typography.Paragraph>
    <Typography.Paragraph>
      La suppression d'une catégorie est irréversible et entraîne la
      décatégorisation de toutes ses transactions bancaires.
    </Typography.Paragraph>
  </>
);

interface Props {
  categories: Category[];
  kind: KindEnum;
  addCategory: (values: {
    name: string;
    note: string;
    kind: KindEnum;
    vat: number;
    parent?: number;
  }) => Promise<void>;
  updateCategory: (
    id: number,
    values: { name: string; note: string; vat: number }
  ) => Promise<void>;
  deleteCategory: (id: number) => Promise<void>;
  setCategoryAsGrouper: (id: number, grouper: boolean) => Promise<void>;
  insertDrop: (
    dragCategoryId: number,
    dropCategoryId: number,
    type: string
  ) => void;
  loaded: boolean;
}

type ModalParams = {
  open: boolean;
  type: "addCategory" | "addSubCategory" | "editCategory";
  editingRecord?: Category;
};

export const CategoriesTree: FC<Props> = ({
  categories,
  kind,
  addCategory,
  updateCategory,
  deleteCategory,
  setCategoryAsGrouper,
  insertDrop,
  loaded,
}) => {
  const [form] = Form.useForm();
  const [okLoading, setOkLoading] = useState(false);
  const [modalParams, setModalParams] = useState<ModalParams>({
    open: false,
    type: "addCategory",
  });
  const [selectedKeys, setSelectedKeys] = useState<Array<React.Key>>([]);
  const { me } = useContext(AuthContext);
  const ref = useRef<InputRef>(null);

  const title = kind === KindEnum.cashIn ? "Encaissements" : "Décaissements";

  const showModal = (
    type: "addCategory" | "addSubCategory" | "editCategory",
    editingRecord?: Category
  ) => {
    const newModalParams: ModalParams = {
      ...modalParams,
      open: true,
      type,
    };
    if (type === "editCategory") {
      const { name, note, vat } = editingRecord!;
      form.setFieldsValue({
        name,
        note,
        vat,
      });
      newModalParams.editingRecord = editingRecord;
    } else if (type === "addSubCategory") {
      form.resetFields();
      form.setFieldValue("vat", modalParams.editingRecord?.vat || 0);
    }
    setModalParams(newModalParams);
  };

  useEffect(() => {
    if (modalParams.open === true) {
      setTimeout(() => ref.current!.focus());
    }
  }, [modalParams]);

  const handleOkModal = async () => {
    const { name, note, vat } = await form.validateFields();

    setOkLoading(true);
    const { type, editingRecord } = modalParams;
    if (type === "addSubCategory") {
      if (!editingRecord) {
        throw new Error(
          "CategoriesTree addSubCategory: editingRecord is undefined"
        );
      }
      setModalParams({
        type: "addCategory",
        open: false,
        editingRecord: undefined,
      });
      form.resetFields();
      setOkLoading(false);
      const { transactionCount, invoiceCount, goalCount } =
        await categoryService.isDroppableOver(editingRecord.id);

      if (transactionCount === 0 && invoiceCount === 0 && goalCount === 0) {
        addCategory({
          name,
          note,
          kind,
          vat,
          parent: editingRecord.id,
        });
      } else {
        confirm({
          ...confirmProps,
          onOk() {
            addCategory({
              name,
              note,
              kind,
              vat,
              parent: editingRecord.id,
            });
            setSelectedKeys([]);
          },
          onCancel() {
            setSelectedKeys([]);
          },
          content: (
            <ConfirmAddSubCategoryContent
              parentName={editingRecord.name}
              transactionCount={transactionCount}
              invoiceCount={invoiceCount}
              goalCount={goalCount}
            />
          ),
        });
      }
    } else {
      let action;

      if (type === "addCategory") {
        action = addCategory({
          name,
          note,
          vat,
          kind,
        });
      } else {
        if (!editingRecord) {
          throw new Error(
            "CategoriesTree editCategory: editingRecord is undefined"
          );
        }
        action = updateCategory(editingRecord.id, { name, note, vat });
      }
      await action;

      setModalParams({
        type: "addCategory",
        open: false,
        editingRecord: undefined,
      });
      form.resetFields();
      setSelectedKeys([]);
      setOkLoading(false);
    }
  };

  const handleSetCategoryAsGrouper = (grouper: boolean) => async () => {
    const { editingRecord } = modalParams;
    if (!editingRecord) {
      throw new Error(
        "CategoriesTree handleSetCategoryAsGrouper: editingRecord is undefined"
      );
    }
    setModalParams({
      type: "addCategory",
      open: false,
      editingRecord: undefined,
    });

    confirm({
      ...confirmProps,
      onOk() {
        setCategoryAsGrouper(editingRecord.id, grouper);
        setSelectedKeys([]);
      },
      onCancel() {
        setSelectedKeys([]);
      },
      content: confirmSetCategoryAsGrouper({
        parentName: editingRecord.name,
        grouper,
      }),
    });
  };

  const handleCancelModal = () => {
    setModalParams({
      type: "addCategory",
      open: false,
      editingRecord: undefined,
    });
    form.resetFields();
    setSelectedKeys([]);
  };

  const handleDelete = ({ id, name }: { id: number; name: string }) => {
    setModalParams({
      type: "addCategory",
      open: false,
      editingRecord: undefined,
    });
    form.resetFields();
    confirm({
      ...confirmProps,
      onOk() {
        setSelectedKeys([]);
        return deleteCategory(id);
      },
      onCancel() {
        setSelectedKeys([]);
      },
      content: confirmDeleteCategoryContent({ name }),
    });
  };

  const allowDrop = ({ dropNode, dropPosition }: any) => {
    if (dropPosition === 0 && dropNode.isVAT) {
      return false;
    }

    return true;
  };

  const onDrop = async (info: any) => {
    const dropCategory = info.node;
    const dragCategory = info.dragNode;

    const dropPos = info.node.pos.split("-");
    const dropPosition =
      info.dropPosition - Number(dropPos[dropPos.length - 1]);

    const targetLevel = dropCategory.pos.split("-").length - 2;
    let categoryDeepth = 0;

    if (hasChildren(dragCategory)) {
      categoryDeepth += 1;
      const isNotAllLeaves = dragCategory.children.some((category: Category) =>
        hasChildren(category)
      );
      if (isNotAllLeaves) {
        categoryDeepth += 1;
      }
    }

    let type: "top" | "bottom" | "over";
    if (!info.dropToGap) {
      type = "over";
    } else if (dropPosition === -1) {
      type = "top";
    } else {
      type = "bottom";
    }

    if (type === "top" || type === "bottom") {
      if (targetLevel + categoryDeepth > maxLevelDeepth) {
        return;
      }
    } else if (type === "over") {
      if (
        targetLevel + 1 + categoryDeepth > maxLevelDeepth ||
        dropCategory.isVAT
      ) {
        return;
      }
    }

    let transactionCount = 0;
    let invoiceCount = 0;
    let goalCount = 0;
    let subCategoriesBudgetsWillBeDeleted = false;

    if (type === "over" && !hasChildren(dropCategory)) {
      ({ transactionCount, invoiceCount, goalCount } =
        await categoryService.isDroppableOver(info.node.id));
    }

    const dropCategoryParent = findTree({
      categories,
      categoryId: dropCategory.parent,
    });
    if (
      (type === "top" || type === "bottom") &&
      (dropCategoryParent?.grouper || dropCategoryParent?.grouped)
    ) {
      subCategoriesBudgetsWillBeDeleted = true;
    } else if (
      type === "over" &&
      (dropCategory?.grouper || dropCategory?.grouped)
    ) {
      subCategoriesBudgetsWillBeDeleted = true;
    }

    if (
      transactionCount === 0 &&
      invoiceCount === 0 &&
      goalCount === 0 &&
      subCategoriesBudgetsWillBeDeleted === false
    ) {
      insertDrop(dragCategory.id, dropCategory.id, type);
    } else {
      confirm({
        ...confirmProps,
        onOk() {
          insertDrop(dragCategory.id, dropCategory.id, type);
        },
        content: (
          <ConfirmAddSubCategoryContent
            parentName={info.node.name}
            transactionCount={transactionCount}
            invoiceCount={invoiceCount}
            goalCount={goalCount}
            subCategoriesBudgetsWillBeDeleted={
              subCategoriesBudgetsWillBeDeleted
            }
          />
        ),
      });
    }
  };

  let modalTitle;
  let modalValidationText;
  if (modalParams.type === "addCategory") {
    modalTitle = `${title}`;
    modalValidationText = "Ajouter une catégorie";
  } else if (modalParams.type === "addSubCategory") {
    modalTitle = `${title} / ${modalParams.editingRecord!.name}`;
    modalValidationText = "Ajouter une sous-categorie";
  } else {
    modalTitle = `${title} / ${modalParams.editingRecord!.name}`;
    modalValidationText = "Enregistrer";
  }

  const formatFunction = (category: Category) => ({
    title: (
      <Space>
        {category.name}
        {category.isVAT && (
          <Tooltip
            placement="topLeft"
            title="Catégorie par défaut pour vos paiements de TVA."
          >
            <ExclamationCircleOutlined />
          </Tooltip>
        )}
        {category.grouper && <Tag>Budget global</Tag>}
        {me?.company?.isVATEnabled &&
          (!hasChildren(category) || category.grouper) &&
          category.vat !== 0 && <Tag>{category.vat} %</Tag>}
      </Space>
    ),
    key: category.id,
    ...category,
  });

  const formattedCategories = formatTree({
    categories,
    formatFunction,
  });

  return (
    <div className="CategoriesTree">
      <Modal
        title={modalTitle}
        open={modalParams.open}
        onOk={handleOkModal}
        onCancel={handleCancelModal}
        forceRender
        footer={null}
        destroyOnClose
      >
        <Form form={form} layout="vertical" name={`modal-form-${kind}`}>
          <Form.Item
            name="name"
            label={
              modalParams.type === "addSubCategory"
                ? "Nom de la sous-catégorie"
                : "Nom de la catégorie"
            }
            rules={[{ required: true, message: "Requis" }]}
          >
            <Input ref={ref} onPressEnter={handleOkModal} />
          </Form.Item>
          <Form.Item name="note" label="Mémo (optionnel)">
            <Input onPressEnter={handleOkModal} />
          </Form.Item>
          {me?.company?.isVATEnabled &&
            (modalParams.type === "addCategory" ||
              modalParams.type === "addSubCategory" ||
              (modalParams.type === "editCategory" &&
                (!modalParams.editingRecord?.children?.length ||
                  modalParams.editingRecord?.grouper))) && <VatSelect />}
        </Form>

        <Row>
          <Col span={12} style={{ textAlign: "left" }}>
            {modalParams.type === "editCategory" && (
              <Tooltip
                overlay={
                  Boolean(modalParams.editingRecord!.children?.length) &&
                  "Supprimez les catégories enfants avant de supprimer cette catégorie."
                }
              >
                <Button
                  type="link"
                  danger
                  onClick={() =>
                    handleDelete({
                      id: modalParams.editingRecord!.id,
                      name: modalParams.editingRecord!.name,
                    })
                  }
                  disabled={Boolean(
                    modalParams.editingRecord!.children?.length
                  )}
                >
                  Supprimer
                </Button>
              </Tooltip>
            )}
          </Col>
          <Col span={12} style={{ textAlign: "right" }}>
            <Button
              icon={
                modalParams.type === "editCategory" ? (
                  <Icon
                    path={mdiContentSaveOutline}
                    size={0.7}
                    style={{ verticalAlign: "-15%", marginRight: "6px" }}
                  />
                ) : (
                  <Icon
                    path={mdiViewGridPlusOutline}
                    size={0.65}
                    style={{ verticalAlign: "-15%", marginRight: "6px" }}
                  />
                )
              }
              loading={okLoading}
              onClick={handleOkModal}
              type="primary"
            >
              {modalValidationText}
            </Button>
          </Col>
        </Row>
        {modalParams.type === "editCategory" &&
          // @ts-ignore
          modalParams.editingRecord!.pos.split("-").length - 2 <
            maxLevelDeepth && (
            <>
              <Divider />
              <Row>
                <Col span={24} style={{ textAlign: "right" }}>
                  <Button
                    icon={
                      <Icon
                        path={mdiViewGridPlusOutline}
                        size={0.65}
                        style={{ verticalAlign: "-15%", marginRight: "6px" }}
                      />
                    }
                    onClick={() => showModal("addSubCategory")}
                  >
                    Ajouter une sous-catégorie
                  </Button>
                </Col>
              </Row>
            </>
          )}
        {modalParams.type === "editCategory" &&
          modalParams.editingRecord &&
          !modalParams.editingRecord.grouped &&
          modalParams.editingRecord.children?.length && (
            <>
              <Divider />
              <Row>
                <Col span={24} style={{ textAlign: "right" }}>
                  {!modalParams.editingRecord.grouper ? (
                    <Button
                      icon={
                        <Icon
                          path={mdiGroup}
                          size={0.6}
                          style={{ verticalAlign: "-12%", marginRight: "6px" }}
                        />
                      }
                      onClick={handleSetCategoryAsGrouper(true)}
                    >
                      Saisir le budget globalement
                    </Button>
                  ) : (
                    <Button
                      icon={
                        <Icon
                          path={mdiUngroup}
                          size={0.6}
                          style={{ verticalAlign: "-12%", marginRight: "6px" }}
                        />
                      }
                      onClick={handleSetCategoryAsGrouper(false)}
                    >
                      Ne plus saisir le budget globalement
                    </Button>
                  )}
                </Col>
              </Row>
            </>
          )}
      </Modal>
      <Card
        loading={!loaded}
        title={title}
        extra={
          <Button
            onClick={() => {
              showModal("addCategory");
            }}
          >
            Ajouter une catégorie
          </Button>
        }
      >
        <Tree
          onSelect={(selectedKeys, { node }) => {
            // @ts-ignore
            if (node.isVAT) {
              return;
            }
            setSelectedKeys(selectedKeys);
            // @ts-ignore
            showModal("editCategory", node);
          }}
          selectedKeys={selectedKeys}
          draggable
          blockNode
          onDrop={onDrop}
          allowDrop={allowDrop}
          treeData={formattedCategories}
          defaultExpandAll
        />
      </Card>
    </div>
  );
};
