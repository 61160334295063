import { mdiChevronDown, mdiPlus, mdiSync, mdiTrayArrowUp } from "@mdi/js";
import Icon from "@mdi/react";
import { Badge, Button, Card, Col, Dropdown, MenuProps, Row, Tabs } from "antd";
import GeneralLayout from "components/GeneralLayout";
import InvoiceAddManyModal from "components/InvoiceAddManyModal";
import AddInvoiceModal from "components/InvoiceAddModal";
import InvoicesTableContainer from "components/InvoicesTableContainer";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import categoryService from "services/categoryService";
import invoiceService, {
  InvoiceQuickFilterType,
} from "services/invoiceService";
import useSWR from "swr";
import { ImportInvoices } from "types";

export type InvoiceEditModalParams = {
  open: boolean;
  editingInvoice?: any;
};

export type InvoiceImportModalParams = {
  open: boolean;
};

function InvoicesPage() {
  const {
    data: categories,
    // error
  } = useSWR(
    "categoryService.getAllWithoutDefault",
    categoryService.getAllWithoutDefault
  );
  const { data: uncategorizedCount } = useSWR(
    "invoiceService.getAllUncategorizedCount",
    invoiceService.getAllUncategorizedCount
  );
  const { data: precategorizedCount } = useSWR(
    "invoiceService.getAllPrecategorizedCount",
    invoiceService.getAllPrecategorizedCount
  );
  const { data: lateCount } = useSWR(
    "invoiceService.getAllLateCount",
    invoiceService.getAllLateCount
  );
  const { data: prereconciliatedCount } = useSWR(
    "invoiceService.getAllPrereconciliatedCount",
    invoiceService.getAllPrereconciliatedCount
  );
  const [key, setKey] = useState<InvoiceQuickFilterType>("all");
  const [refresh, setRefresh] = useState(false);
  const [addVisible, setAddVisible] = useState(false);
  const [addManyVisible, setAddManyVisible] = useState(false);

  const navigate = useNavigate();

  const addInvoice = async (values: any) => {
    await invoiceService.create(values);

    setRefresh(!refresh);
  };

  const importInvoices = async (values: ImportInvoices) => {
    await invoiceService.createMany(values);

    setRefresh(!refresh);
  };

  const onChangeTab = (key: InvoiceQuickFilterType) => {
    setKey(key);
  };

  const handleMenuClick = (e: any) => {
    if (e.key === "form") {
      setAddVisible(true);
    } else if (e.key === "import") {
      setAddManyVisible(true);
    } else if (e.key === "integrations") {
      navigate("/integrations");
    }
  };

  const menuItems: MenuProps["items"] = [
    {
      icon: <Icon path={mdiPlus} size={0.65} />,
      key: "form",
      label: "Créer une facture",
    },
    {
      icon: <Icon path={mdiTrayArrowUp} size={0.65} />,
      key: "import",
      label: "Importer un fichier CSV",
    },
    {
      icon: <Icon path={mdiSync} size={0.65} rotate={-45} />,
      key: "integrations",
      label: "Gérer mes intégrations",
    },
  ];
  const menu = { items: menuItems, onClick: handleMenuClick };

  return (
    <GeneralLayout
      title={"Factures engagées"}
      action={
        <Dropdown
          trigger={["click"]}
          getPopupContainer={(trigger) => trigger}
          menu={menu}
          placement="bottomRight"
        >
          <Button type="primary">
            Ajouter des factures engagées
            <Icon
              path={mdiChevronDown}
              size={1}
              style={{ verticalAlign: "-35%", marginLeft: "4px" }}
            />
          </Button>
        </Dropdown>
      }
    >
      <div className="InvoicesPage">
        <InvoiceAddManyModal
          importInvoices={importInvoices}
          open={addManyVisible}
          setOpen={setAddManyVisible}
        />
        <AddInvoiceModal
          addInvoice={addInvoice}
          open={addVisible}
          setOpen={setAddVisible}
        />
        <Card>
          <Row>
            <Col span={24}>
              <Tabs
                activeKey={key}
                animated={false}
                // @ts-ignore
                onChange={onChangeTab}
                items={[
                  { label: "Toutes les factures", key: "all" },
                  {
                    label: (
                      <Badge
                        offset={[12, -5]}
                        count={precategorizedCount}
                        overflowCount={10000}
                      >
                        <span>Précatégorisés</span>
                      </Badge>
                    ),
                    key: "precategorized",
                  },
                  {
                    label: (
                      <Badge
                        offset={[12, -5]}
                        count={prereconciliatedCount}
                        overflowCount={10000}
                      >
                        <span>Prérapprochées</span>
                      </Badge>
                    ),
                    key: "prereconciliated",
                  },
                  {
                    label: (
                      <Badge
                        offset={[12, -5]}
                        count={uncategorizedCount}
                        overflowCount={10000}
                      >
                        <span>À catégoriser</span>
                      </Badge>
                    ),
                    key: "uncategorized",
                  },
                  {
                    label: (
                      <Badge
                        offset={[12, -5]}
                        count={lateCount}
                        overflowCount={10000}
                      >
                        <span>En retard</span>
                      </Badge>
                    ),
                    key: "late",
                  },
                ]}
              />
              <InvoicesTableContainer
                categories={categories || []}
                quickFilter={key}
                withRowSelection={false}
                refresh={refresh}
              />
            </Col>
          </Row>
        </Card>
      </div>
    </GeneralLayout>
  );
}

export default InvoicesPage;
