import { Form, InputNumber } from "antd";
import {
  default as React,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Transaction } from "types";
import { InputRow } from ".";
import { EditableContext } from "./SplittingTransactionEditableTable";

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof InputRow;
  record: InputRow;
  handleChange: (inputRow: InputRow) => void;
  splittingTransaction: Transaction;
}
export const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleChange,
  splittingTransaction,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (editing) {
      // @ts-ignore
      inputRef.current!.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: Math.abs(record.amount) });
  };

  const save = async () => {
    const values = await form.validateFields();

    toggleEdit();
    handleChange({
      ...record,
      amount: Math.sign(splittingTransaction.amount) * values.amount,
    });
  };

  let childNode = children;
  if (editable) {
    childNode = editing ? (
      <Form.Item style={{ margin: 0 }} name={dataIndex}>
        <InputNumber
          min={0}
          precision={2}
          ref={inputRef}
          onPressEnter={save}
          onBlur={save}
          style={{ width: "100%" }}
        />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};
