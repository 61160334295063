import { AxiosResponse } from "axios";
import { Integration } from "types";
import axios from "utils/setupAxios";

export async function getAll(): Promise<Integration[]> {
  return axios
    .get(`/integrations?_sort=provider&provider_ne=bridgeapi`)
    .then((res) => res.data);
}

export async function update(
  integrationId: number,
  values: { toRefresh: boolean }
): Promise<Integration> {
  return axios
    .put(`/integrations/${integrationId}`, values)
    .then((res) => res.data);
}

export async function remove(integrationId: number): Promise<Integration> {
  return axios.delete(`/integrations/${integrationId}`).then((res) => res.data);
}

export async function connectCallback({
  provider,
  refreshToken,
  groupId,
  access_token,
  access_secret,
}: {
  provider: string;
  refreshToken?: string;
  groupId?: string;
  access_token?: string;
  access_secret?: string;
}): Promise<AxiosResponse> {
  return axios.post(`/integrations/${provider}/callback`, {
    refreshToken,
    groupId,
    access_token,
    access_secret,
  });
}

const integrationService = {
  connectCallback,
  getAll,
  remove,
  update,
};

export default integrationService;
