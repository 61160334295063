import { mdiMinus, mdiPlus } from "@mdi/js";
import Icon from "@mdi/react";
import { Button, DatePicker } from "antd";
import { addMonths, getMonth, getYear } from "date-fns";
import dayjs, { Dayjs } from "dayjs";
import { FC } from "react";
import { monthStrings } from "utils/date";

const getMonthYearString = (date: Date) =>
  `${monthStrings[getMonth(date)]} ${getYear(date)}`;

interface Props {
  date: Date;
  setDate: (date: Date) => void;
  incrementStartingMonth: (numberOfMonth: number) => void;
  monthInterval?: number;
}

const MainChartDatePicker: FC<Props> = ({
  date,
  setDate,
  incrementStartingMonth,
  monthInterval = 0,
}) => {
  const onChange = async (dayjsDate: Dayjs | null) => {
    if (!dayjsDate) {
      return;
    }
    const date = dayjsDate.toDate();

    setDate(date);
  };

  const pickerFormat = (dayjsDate: Dayjs) => {
    const date = dayjsDate.toDate();
    const endingMonth = monthInterval ? addMonths(date, monthInterval) : null;

    return `${
      endingMonth
        ? `${getMonthYearString(date)} - ${getMonthYearString(endingMonth)}`
        : getMonthYearString(date)
    }`;
  };

  return (
    <Button.Group>
      <Button onClick={() => incrementStartingMonth(-1)}>
        <Icon path={mdiMinus} size={0.8} style={{ verticalAlign: "-25%" }} />
      </Button>
      <DatePicker.MonthPicker
        value={dayjs(date)}
        allowClear={false}
        onChange={onChange}
        format={pickerFormat}
      />
      <Button
        style={{ marginRight: 20 }}
        onClick={() => incrementStartingMonth(1)}
      >
        <Icon path={mdiPlus} size={0.8} style={{ verticalAlign: "middle" }} />
      </Button>
    </Button.Group>
  );
};

export default MainChartDatePicker;
