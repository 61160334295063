import { Button } from "antd";
import { AuthContext } from "contexts";
import { useContext, useState } from "react";
import stripeService from "services/stripeService";

export const CancelButton = ({
  setOpen,
}: {
  setOpen: (open: boolean) => void;
}) => {
  const [loading, setLoading] = useState(false);
  const { me, setMe } = useContext(AuthContext);

  const handleClick = async () => {
    if (!me || !me.company) {
      return;
    }

    setLoading(true);

    const newCompany = await stripeService.cancelSubscription();

    const newMe = { ...me };
    newMe.company = newCompany;
    setMe(newMe);

    setLoading(false);
    setOpen(false);
  };

  return (
    <Button
      style={{ margin: "25px 10px 5px" }}
      loading={loading}
      onClick={handleClick}
    >
      Annuler l'abonnement
    </Button>
  );
};
