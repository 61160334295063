import { Button } from "antd";
import { AuthContext } from "contexts";
import { useContext, useState } from "react";
import stripeService from "services/stripeService";
import { plans } from "utils/constants";

export const SubscribeButton = ({
  planId,
  isYearly,
  numberOfBanks,
  setOpen,
}: {
  planId: string;
  isYearly: boolean;
  numberOfBanks: number;
  setOpen: (open: boolean) => void;
}) => {
  const [loading, setLoading] = useState(false);
  const { me, setMe } = useContext(AuthContext);

  const handleClick = async () => {
    if (!me || !me.company) {
      return;
    }

    setLoading(true);

    const newCompany = await stripeService.createSubscription({
      planId,
      isYearly,
      numberOfBanks,
    });

    const newMe = { ...me };
    newMe.company = newCompany;
    setMe(newMe);

    setLoading(false);
    setOpen(false);
  };

  return (
    <Button
      style={{ margin: "25px 10px 5px" }}
      type={
        planId === plans.FIRST_PLAN_ID ||
        planId === plans.FIRST_MONTHLY_PLAN_ID ||
        planId === plans.RESOLD_PLAN_ID ||
        planId === plans.RESOLD_MONTHLY_PLAN_ID
          ? "primary"
          : "default"
      }
      loading={loading}
      onClick={handleClick}
    >
      Démarrer
    </Button>
  );
};
