import {
  Button,
  Checkbox,
  Form,
  Input,
  Popconfirm,
  Select,
  Space,
  Typography,
} from "antd";
import { Store } from "antd/es/form/interface";
import Loader from "components/Loader";
import { AuthContext } from "contexts";
import { FC, ReactElement, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import companyService from "services/companyService";
import { rolesEnum } from "utils/constants";
import errorHandler from "utils/errorHandler";

const { Option } = Select;

interface Props {
  setError: (errorMessage: ReactElement | undefined) => void;
}

export const CompanyInfoForm: FC<Props> = ({ setError }) => {
  const { me, setMe } = useContext(AuthContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  // const history = useHistory();

  const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 8 },
  };
  const formTailLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 8, offset: 4 },
  };

  const handleDelete = () => async () => {
    if (!me || !me.company) {
      return;
    }
    await companyService.remove(me?.company.id);
    const newMe = { ...me, company: undefined };
    //@ts-ignore
    setMe(newMe);
    navigate("/change-company");
  };

  const onFinish = (values: Store) => {
    if (!me) {
      return;
    }

    const {
      name,
      overdraft: overdraftString,
      VATRegime,
      isVATEnabled,
      isInvoiceEnabled,
      isSimpleForecastModeEnabled,
      keepRefundedTransaction,
      keepCrossAccountTransaction,
    } = values;
    const overdraft = parseInt(overdraftString, 10);

    setLoading(true);
    companyService
      .update(me.company.id, {
        name,
        overdraft,
        VATRegime,
        isVATEnabled,
        isInvoiceEnabled,
        isSimpleForecastModeEnabled,
        keepRefundedTransaction,
        keepCrossAccountTransaction,
      })
      .then((newCompany) => {
        const newMe = { ...me };
        newMe.company = newCompany;
        setMe(newMe);
        setLoading(false);
      })
      .catch(errorHandler(setError));
  };

  return (
    <div style={{ marginTop: 15 }}>
      {!me ? (
        <Loader />
      ) : (
        <Form
          name="update-user"
          {...formItemLayout}
          onFinish={onFinish}
          requiredMark={false}
          initialValues={{
            name: me?.company?.name,
            overdraft: me?.company?.overdraft,
            VATRegime: me?.company?.VATRegime,
            isVATEnabled: me?.company?.isVATEnabled,
            isInvoiceEnabled: me?.company?.isInvoiceEnabled,
            isSimpleForecastModeEnabled:
              me?.company?.isSimpleForecastModeEnabled,
            keepRefundedTransaction: me?.company?.keepRefundedTransaction,
            keepCrossAccountTransaction:
              me?.company?.keepCrossAccountTransaction,
          }}
          colon={false}
        >
          <Form.Item
            label="Entreprise"
            name="name"
            rules={[
              {
                required: true,
                message: "Requis",
              },
            ]}
          >
            <Input
              autoComplete="organization"
              placeholder="Nom de votre organisation"
            />
          </Form.Item>
          <Form.Item
            label="Seuil de trésorerie minimum"
            name="overdraft"
            tooltip={{
              title:
                "Utile pour afficher son découvert autorisé ou se fixer un objectif de trésorerie minimale",
            }}
          >
            <Input
              type="number"
              suffix="€"
              step="1"
              onKeyPress={(event) => {
                if (!/[-0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              style={{
                width: "calc(50% - 8px)",
              }}
            />
          </Form.Item>
          <Form.Item
            label="Fonctionnalité TVA"
            tooltip={{
              title: (
                <>
                  Cette fonctionnalité vous permet d'estimer automatiquement les
                  échéances de TVA à payer à partir de vos flux. Seulement utile
                  dans certains cas, plus d'infos sur{" "}
                  <a
                    href="https://help.zenfirst.fr/fr/article/calculez-automatiquement-vos-echeances-de-tva-1ny40it/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    notre centre d'aide
                  </a>
                  .
                </>
              ),
            }}
            style={{ marginBottom: 0 }}
          >
            <Form.Item
              name="isVATEnabled"
              valuePropName="checked"
              style={{
                display: "inline-block",
                width: "calc(50% - 8px)",
              }}
            >
              <Checkbox>Calculer les échéances</Checkbox>
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.isVATEnabled !== currentValues.isVATEnabled
              }
            >
              {({ getFieldValue }) => {
                return (
                  <Form.Item
                    name="VATRegime"
                    style={{
                      display: "inline-block",
                      width: "calc(50% - 8px)",
                      margin: "0 8px",
                    }}
                  >
                    <Select
                      placeholder="TVA"
                      style={{ width: 200 }}
                      getPopupContainer={(trigger) => trigger.parentNode}
                      disabled={!getFieldValue("isVATEnabled")}
                    >
                      <Option value={"monthly"} key={"monthly"}>
                        Régime mensuel
                      </Option>
                      <Option value={"quarterly"} key={"quarterly"}>
                        Régime trimestriel
                      </Option>
                    </Select>
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Form.Item>
          <Form.Item
            label="Factures engagées"
            name="isInvoiceEnabled"
            valuePropName="checked"
          >
            <Checkbox>Gestion des factures engagées</Checkbox>
          </Form.Item>
          <Form.Item
            label="Calcul des prévisions"
            name="isSimpleForecastModeEnabled"
            valuePropName="checked"
            tooltip={{
              title: (
                <>
                  Le mode simple vous permet d'avoir un budget total qui est
                  égal à la somme de tous les budgets. Ainsi, tout dépassement
                  de budget compense les autres budgets non atteints. Plus
                  d'infos sur{" "}
                  <a
                    href="https://help.zenfirst.fr/fr/article/mode-de-calcul-des-previsions-oh4z8g/?bust=1653493471165"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    notre centre d'aide
                  </a>
                  .
                </>
              ),
            }}
          >
            <Checkbox>Activer le mode simple</Checkbox>
          </Form.Item>
          <Form.Item
            label="Masquage des transactions"
            name="keepRefundedTransaction"
            valuePropName="checked"
          >
            <Checkbox>
              Masquage automatique des transactions remboursées
            </Checkbox>
          </Form.Item>
          <Form.Item
            label=" "
            name="keepCrossAccountTransaction"
            valuePropName="checked"
          >
            <Checkbox>
              Masquage automatique des transactions intercomptes
            </Checkbox>
          </Form.Item>
          <Form.Item {...formTailLayout}>
            <Button type="primary" htmlType="submit" block loading={loading}>
              Enregistrer
            </Button>
          </Form.Item>
          {me && me.role.id === rolesEnum["ADMIN"] && (
            <Form.Item {...formTailLayout}>
              <Popconfirm
                title={
                  <Space direction="vertical">
                    <Typography.Text>
                      Êtes-vous sûr de vouloir supprimer cette entreprise ?
                    </Typography.Text>
                    <Typography.Text type="danger">
                      Tous les utilisateurs perdront l'accès.
                    </Typography.Text>
                    <Typography.Text type="danger">
                      Cette action est irréversible.
                    </Typography.Text>
                  </Space>
                }
                okButtonProps={{ danger: true }}
                onConfirm={handleDelete()}
                okText="Confirmer"
                placement="topLeft"
              >
                <Button type="text" danger>
                  Supprimer cette entreprise
                </Button>
              </Popconfirm>
            </Form.Item>
          )}
        </Form>
      )}
    </div>
  );
};
