import React from "react";

function Emoji({ label, symbol }: { label: string; symbol: string }) {
  return (
    <span
      className="emoji"
      role="img"
      aria-label={label ? label : ""}
      aria-hidden={label ? "false" : "true"}
    >
      {symbol}
    </span>
  );
}
export default Emoji;
