import { Alert, Divider, Steps } from "antd";
import BankConnector from "components/BankConnector";
import OnboardingLayout from "components/OnboardingLayout";
import React, { FC } from "react";
import { useSearchParams } from "react-router-dom";
import "./index.css";

const YourBanksPage: FC = () => {
  const [searchParams] = useSearchParams();

  const hasFailed = searchParams.get("hasFailed") === "true";

  return (
    <OnboardingLayout>
      <div className="YourBanksPage">
        <Steps progressDot current={1}>
          <Steps.Step title="Inscription" />
          <Steps.Step title="Synchronisation" />
          <Steps.Step title="Finalisation" />
        </Steps>
        <Divider />
        {hasFailed && (
          <Alert
            message="La connexion bancaire ne s'est pas terminée avec succès."
            type="error"
          />
        )}
        <div className="animated">
          <BankConnector isRegister={true} />
        </div>
      </div>
    </OnboardingLayout>
  );
};

export default YourBanksPage;
