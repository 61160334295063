import { Alert, Col, Input, message, PaginationProps, Row } from "antd";
import AccountSelect from "components/AccountSelect";
import BatchAction from "components/BatchAction";
import Emoji from "components/Emoji";
import TransactionsTable from "components/TransactionsTable";
import { AuthContext } from "contexts";
import {
  FC,
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import categoryService, {
  CreateCategoryValues,
} from "services/categoryService";
import companyService from "services/companyService";
import invoiceService from "services/invoiceService";
import itemService from "services/itemService";
import transactionService, {
  TransactionQuickFilterType,
} from "services/transactionService";
import useSWR, { mutate } from "swr";
import { CategoriesWithAmounts, Category, Invoice, Transaction } from "types";
import errorHandler from "utils/errorHandler";
import marketingTools from "utils/marketingTools";
import { computeQuery } from "utils/strapi";

interface Props {
  categories: Category[];
  quickFilter: TransactionQuickFilterType;
}

const TransactionsTableContainer: FC<Props> = ({ categories, quickFilter }) => {
  const { data: items } = useSWR("itemService.getAll", itemService.getAll);
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);

  const [loaded, setLoaded] = useState(true);
  const [error, setError] = useState<ReactElement | undefined>(undefined);
  const [pagination, setPagination] = useState<PaginationProps>({
    current: 1,
    pageSize: 100,
    total: 0,
  });
  const [filteredInfo, setFilteredInfo] = useState<Record<string, string[]>>(
    {}
  );
  const [sortedInfo, setSortedInfo] = useState({});
  const [searchText, setSearchText] = useState("");
  const [selectedAccount, setSelectedAccount] = useState<number>(0);
  const { me, setMe } = useContext(AuthContext);

  const handleCategorizeMany = (
    transactionIds: number[],
    categoryId: number
  ) => {
    marketingTools.hasCategorized();

    if (me?.company && !me?.company.hasCategorized) {
      companyService
        .update(me.company.id, { hasCategorized: true })
        .then((newCompany) => {
          const newMe = { ...me };
          newMe.company = newCompany;
          setMe(newMe);
        })
        .catch(errorHandler(setError));
    }

    return transactionService
      .categorizeMany(transactionIds, categoryId)
      .then((updatedTransactions) => {
        if (updatedTransactions > transactionIds.length) {
          const precategorizedTransactions =
            updatedTransactions - transactionIds.length;
          message.success(
            precategorizedTransactions === 1
              ? "Une transaction similaire a été précatégorisée dans la même catégorie."
              : `${precategorizedTransactions} transactions similaires ont été précatégorisées dans la même catégorie.`
          );
        }
        return loadData(quickFilter);
      })
      .catch(errorHandler(setError));
  };

  const handleReconciliateTransaction = (
    transactionId: number,
    invoiceIds: number[],
    shouldCreateRemainingInvoice: boolean
  ) => {
    return transactionService
      .reconciliate(transactionId, invoiceIds, shouldCreateRemainingInvoice)
      .then(() => {
        return loadData(quickFilter);
      })
      .catch(errorHandler(setError));
  };

  const handleUpdateManyCategory = (transactionIds: number[], params: any) => {
    return transactionService
      .updateMany(transactionIds, params)
      .then(() => {
        return loadData(quickFilter);
      })
      .catch(errorHandler(setError));
  };

  const handleUpdate = (transactionId: number, params: any) => {
    return transactionService
      .update(transactionId, params)
      .then(() => {
        return loadData(quickFilter);
      })
      .catch(errorHandler(setError));
  };

  const handleSplit = (
    transactionId: number,
    categoriesWithAmounts: CategoriesWithAmounts[]
  ) => {
    return transactionService
      .split(transactionId, categoriesWithAmounts)
      .then(() => {
        return loadData(quickFilter);
      })
      .catch(errorHandler(setError));
  };

  const handleUnsplit = (transactionId: number) => {
    return transactionService
      .unsplit(transactionId)
      .then(() => {
        return loadData(quickFilter);
      })
      .catch(errorHandler(setError));
  };

  const handleChangeDate = (transactionId: number, newDate: Date) => {
    return transactionService
      .changeDate(transactionId, newDate)
      .then(() => {
        return loadData(quickFilter);
      })
      .catch(errorHandler(setError));
  };

  const handleUnchangeDate = (transactionId: number) => {
    return transactionService
      .unchangeDate(transactionId)
      .then(() => {
        return loadData(quickFilter);
      })
      .catch(errorHandler(setError));
  };

  const handleValidateCategory = (transaction: Transaction) => {
    return transactionService
      .update(transaction.id, { precategorized: false })
      .then(() => {
        return loadData(quickFilter);
      })
      .catch(errorHandler(setError));
  };

  const handleValidatePrereconciliationTransaction = (
    invoice: Invoice,
    isValidated: boolean
  ) => {
    return invoiceService
      .update(invoice.id, { paid: isValidated })
      .then(() => {
        return loadData(quickFilter);
      })
      .catch(errorHandler(setError));
  };

  const handleAddCategoryAndCategorize = async (
    values: CreateCategoryValues,
    kind: string,
    transactionIds: number[],
    parent?: number
  ) => {
    if (!categories) {
      return;
    }
    const newCategory = { ...values, kind, parent };
    const category = await categoryService.create(newCategory);

    mutate(
      "categoryService.getAllWithoutDefault",
      [...categories, { ...category }],
      false
    );
    mutate("categoryService.getAllWithoutDefault");
    return handleCategorizeMany(transactionIds, category.id);
  };

  const handleDeleteMany = async (transactionIds: number[]) => {
    await transactionService.removeMany(transactionIds);

    return loadData(quickFilter);
  };

  const loadData = async (
    quickFilter: "all" | "precategorized" | "uncategorized",
    newPagination?: any,
    filters?: any,
    sorter?: any,
    account?: number
  ) => {
    setSelectedRowKeys([]);

    const updatedFilteredInfo = {
      ...filteredInfo,
      ...filters,
    };

    const updatedSortedInfo = {
      ...sortedInfo,
      ...sorter,
    };

    const newSelectedAccount =
      account !== undefined ? account : selectedAccount;

    const countQuery = computeQuery({
      pagination: { current: 1 },
      filteredInfo: updatedFilteredInfo,
      sortedInfo: updatedSortedInfo,
      defaultSortColum: "date",
    });

    const transactionsCount = await transactionService.getAllCount({
      query: countQuery,
      quickFilter,
      account: newSelectedAccount,
    });

    const current = pagination.current || 1;
    const pageSize = pagination.pageSize || 1;
    const maxPage = Math.floor(transactionsCount / pageSize) + 1;

    const updatedPagination = {
      ...pagination,
      current: Math.min(current, maxPage),
      ...newPagination,
      total: transactionsCount,
    };

    const dataQuery = computeQuery({
      pagination: updatedPagination,
      filteredInfo: updatedFilteredInfo,
      sortedInfo: updatedSortedInfo,
      defaultSortColum: "date",
    });

    return transactionService
      .getAll({
        query: dataQuery,
        quickFilter,
        account: newSelectedAccount,
      })
      .then((transactions) => {
        setLoaded(true);
        setTransactions(transactions);
        setPagination(updatedPagination);
        setFilteredInfo(updatedFilteredInfo);
        setSortedInfo(updatedSortedInfo);
        setSelectedAccount(newSelectedAccount);
        mutate("transactionService.getAllUncategorizedCount");
        mutate("transactionService.getAllPrecategorizedCount");
      })
      .catch(errorHandler(setError));
  };

  const changeParams = (pagination: any, filters: any, sorter: any) => {
    loadData(quickFilter, pagination, filters, sorter);
  };

  useEffect(() => {
    loadData(quickFilter, pagination, filteredInfo, sortedInfo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quickFilter]);

  let emptyText: string | ReactNode;
  if (items && items.length === 0) {
    emptyText = (
      <Row style={{ marginTop: 20, marginBottom: 20 }} gutter={[8, 16]}>
        <Col span={24} style={{ textAlign: "center" }}>
          Vous n'avez ajouté aucun compte bancaire.
        </Col>
        {/* <Col span={24}>
          <Button type="primary" onClick={() => navigate("/banks")}>
            Ajouter une banque
          </Button>
        </Col> */}
      </Row>
    );
  } else if (!filteredInfo.raw_description) {
    if (quickFilter === "precategorized") {
      emptyText = (
        <div style={{ marginTop: 20, marginBottom: 20 }}>
          <Emoji symbol="✨" label="félicitations" /> Toutes les
          précatégorisations ont été validées !
        </div>
      );
    } else if (quickFilter === "uncategorized") {
      emptyText = (
        <div style={{ marginTop: 20, marginBottom: 20 }}>
          <Emoji symbol="✨" label="félicitations" /> Toutes vos transactions
          sont catégorisées !
        </div>
      );
    }
  }

  const handleSearch = (value: string) => {
    setSearchText(value);
    if (value) {
      loadData(
        quickFilter,
        { ...pagination, current: 1 },
        {
          raw_description: [value],
        }
      );
    } else {
      loadData(
        quickFilter,
        { ...pagination, current: 1 },
        {
          raw_description: undefined,
        }
      );
    }
  };

  const handleChangeSelectedAccount = (selectedAccount: number) => {
    loadData(
      quickFilter,
      { ...pagination, current: 1 },
      filteredInfo,
      sortedInfo,
      selectedAccount
    );
  };

  return (
    <>
      {error && (
        <Alert style={{ marginBottom: 10 }} type="error" message={error} />
      )}
      {Boolean(selectedRowKeys.length) && (
        <BatchAction
          categories={categories || []}
          transactions={transactions}
          handleCategorizeMany={handleCategorizeMany}
          handleUpdateManyCategory={handleUpdateManyCategory}
          handleDeleteMany={handleDeleteMany}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
          mainAction={
            quickFilter === "precategorized" ? "validate" : "categorize"
          }
        />
      )}
      <div
        style={{
          marginBottom: 16,
          display: Boolean(selectedRowKeys.length) ? "none" : "flex",
          justifyContent: "space-between",
        }}
      >
        <Input.Search
          onSearch={handleSearch}
          allowClear
          style={{
            width: 350,
          }}
        />
        <AccountSelect
          items={items || []}
          selectedAccount={selectedAccount}
          handleChangeSelectedAccount={handleChangeSelectedAccount}
          hasAmount={true}
        />
      </div>
      <TransactionsTable
        transactions={transactions}
        categories={categories || []}
        handleCategorizeMany={handleCategorizeMany}
        handleValidateCategory={handleValidateCategory}
        handleValidatePrereconciliation={
          handleValidatePrereconciliationTransaction
        }
        handleAddCategoryAndCategorize={handleAddCategoryAndCategorize}
        handleUpdate={handleUpdate}
        handleReconciliate={handleReconciliateTransaction}
        handleSplit={handleSplit}
        handleUnsplit={handleUnsplit}
        handleChangeDate={handleChangeDate}
        handleUnchangeDate={handleUnchangeDate}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
        loading={!loaded}
        changeParams={changeParams}
        pagination={pagination}
        emptyText={emptyText}
        searchText={searchText}
        sameSignSelection={quickFilter !== "precategorized"}
      />
    </>
  );
};

export default TransactionsTableContainer;
