import { Form, Input } from "antd";
import React, { useEffect, useRef, useState } from "react";

interface EditableCellProps {
  children: React.ReactNode;
  record: any;
  handleSave: (record: any) => void;
}

export const EditableCell: React.FC<EditableCellProps> = ({
  children,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const [form] = Form.useForm();

  useEffect(() => {
    if (editing) {
      // @ts-ignore
      inputRef.current!.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    if (record?.key === "note") {
      form.setFieldsValue({ [record.key]: record.data });
    }
  };

  const save = async () => {
    const values = await form.validateFields();

    toggleEdit();
    handleSave({ ...record, ...values });
  };

  let childNode = children;

  if (record?.key === "note") {
    childNode = editing ? (
      <Form form={form}>
        <Form.Item style={{ margin: 0 }} name={record?.key}>
          <Input
            style={{ textAlign: "right" }}
            ref={inputRef}
            onPressEnter={save}
            onBlur={save}
          />
        </Form.Item>
      </Form>
    ) : (
      <div className="editable-cell-value-wrap" onClick={toggleEdit}>
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};
