import { addMonths, parseISO } from "date-fns";
import { CategoriesWithAmounts, Category, ImportTransactions, Transaction } from "types";
import { KindEnum } from "utils/kind";
import axios from "utils/setupAxios";

export type TransactionQuickFilterType =
  | "all"
  | "precategorized"
  | "uncategorized";

export async function find({
  date,
  category,
  ignored,
  query,
  account,
}: {
  date?: string;
  category?: Category;
  ignored?: boolean;
  query?: string;
  account?: number;
}): Promise<Transaction[]> {
  let options = "?";
  if (date) {
    const startDate = parseISO(date);
    const endDate = addMonths(startDate, 1);
    const startDateISO = startDate.toISOString();
    const endDateISO = endDate.toISOString();
    options += `&date_gte=${startDateISO}&date_lt=${endDateISO}`;
  }
  if (category) {
    if (category.isDefaultCategory) {
      if (category.kind === KindEnum.cashIn) {
        options += "&amount_gt=0&category_null=true";
      } else if (category.kind === KindEnum.cashOut) {
        options += "&amount_lt=0&category_null=true";
      }
    } else {
      options += `&category=${category.id}`;
    }
  }
  if (ignored) {
    options += "&ignored=true";
  }
  if (account) {
    options += `&account=${account}`;
  }

  return axios
    .get(`/transactions${options}${query || ""}`)
    .then((res) => res.data);
}

export async function count({
  date,
  category,
  ignored,
  query,
  account,
}: {
  date?: string;
  category?: Category;
  ignored?: boolean;
  query?: string;
  account?: number;
}): Promise<number> {
  let options = "?";
  if (date) {
    const startDate = parseISO(date);
    const endDate = addMonths(startDate, 1);
    const startDateISO = startDate.toISOString();
    const endDateISO = endDate.toISOString();
    options += `&date_gte=${startDateISO}&date_lt=${endDateISO}`;
  }
  if (category) {
    if (category.isDefaultCategory) {
      if (category.kind === KindEnum.cashIn) {
        options += "&amount_gt=0&category_null=true";
      } else if (category.kind === KindEnum.cashOut) {
        options += "&amount_lt=0&category_null=true";
      }
    } else {
      options += `&category=${category.id}`;
    }
  }
  if (ignored) {
    options += "&ignored=true";
  }
  if (account) {
    options += `&account=${account}`;
  }

  return axios
    .get(`/transactions/count${options}${query || ""}`)
    .then((res) => res.data);
}

export async function getAll({
  query,
  quickFilter,
  account,
}: {
  query?: string;
  quickFilter?: TransactionQuickFilterType;
  account?: number;
}): Promise<Transaction[]> {
  let options = "?";

  if (quickFilter === "precategorized") {
    options += "&category_null=false&precategorized=true";
  } else if (quickFilter === "uncategorized") {
    options += "&category_null=true";
  }
  if (account) {
    options += `&account=${account}`;
  }

  return axios
    .get(`/transactions${options}${query || ""}`)
    .then((res) => res.data);
}

export async function getAllCount({
  query,
  quickFilter,
  account,
}: {
  query?: string;
  quickFilter?: TransactionQuickFilterType;
  account?: number;
}): Promise<number> {
  let options = "?";

  if (quickFilter === "precategorized") {
    options += "&category_null=false&precategorized=true";
  } else if (quickFilter === "uncategorized") {
    options += "&category_null=true";
  }
  if (account) {
    options += `&account=${account}`;
  }

  return axios
    .get(`/transactions/count${options}${query || ""}`)
    .then((res) => res.data);
}

export async function getAllUncategorizedCount() {
  return getAllCount({ quickFilter: "uncategorized" });
}

export async function getAllPrecategorizedCount() {
  return getAllCount({ quickFilter: "precategorized" });
}

export async function update(
  transactionId: number,
  values: any
): Promise<Transaction> {
  return axios
    .put(`/transactions/${transactionId}`, values)
    .then((res) => res.data);
}

export async function updateMany(
  transactionIds: number[],
  values: any
): Promise<number> {
  return axios
    .post(`/transactions/update-many`, {
      ids: transactionIds,
      ...values,
    })
    .then((res) => res.data);
}

export async function categorizeMany(
  transactionIds: number[],
  categoryId: number
): Promise<number> {
  return axios
    .post(`/transactions/categorize-many`, {
      ids: transactionIds,
      category: categoryId,
    })
    .then((res) => res.data);
}

export async function reconciliate(
  transactionId: number,
  invoiceIds: number[],
  shouldCreateRemainingInvoice: boolean
): Promise<{ ok: boolean }> {
  return axios
    .put(`/transactions/${transactionId}/reconciliate`, {
      ids: invoiceIds,
      shouldCreateRemainingInvoice,
    })
    .then((res) => res.data);
}

export async function split(
  transactionId: number,
  categoriesWithAmounts: CategoriesWithAmounts[]
): Promise<{ ok: boolean }> {
  return axios
    .put(`/transactions/${transactionId}/split`, {
      categoriesWithAmounts: categoriesWithAmounts,
    })
    .then((res) => res.data);
}

export async function unsplit(transactionId: number): Promise<{ ok: boolean }> {
  return axios
    .put(`/transactions/${transactionId}/unsplit`)
    .then((res) => res.data);
}

export async function changeDate(
  transactionId: number,
  date: Date
): Promise<{ ok: boolean }> {
  return axios
    .put(`/transactions/${transactionId}/change-date`, {
      date,
    })
    .then((res) => res.data);
}

export async function unchangeDate(
  transactionId: number
): Promise<{ ok: boolean }> {
  return axios
    .put(`/transactions/${transactionId}/unchange-date`)
    .then((res) => res.data);
}

export async function createMany(values: any): Promise<Transaction[]> {
  return axios
    .post("/transactions/create-many", values)
    .then((res) => res.data);
}

export async function importTransactions(values: ImportTransactions): Promise<Transaction[]> {
  return axios
    .post("/transactions/import-transactions", values)
    .then((res) => res.data);
}

export async function removeMany(transactionIds: number[]): Promise<number> {
  return axios
    .post("/transactions/delete-many", { ids: transactionIds })
    .then((res) => res.data);
}

const transactionService = {
  find,
  count,
  getAll,
  getAllCount,
  getAllUncategorizedCount,
  getAllPrecategorizedCount,
  update,
  updateMany,
  categorizeMany,
  reconciliate,
  split,
  unsplit,
  changeDate,
  unchangeDate,
  createMany,
  importTransactions,
  removeMany,
};
export default transactionService;
