import { AxiosResponse } from "axios";
import { Goal } from "types";
import axios from "utils/setupAxios";

export async function find({
  scenarioId,
  categoryId,
  date,
}: {
  scenarioId?: number;
  categoryId?: number;
  date?: string;
}): Promise<AxiosResponse> {
  let options = "";
  if (scenarioId) {
    options += `&scenario=${scenarioId}`;
  }
  if (categoryId) {
    options += `&category=${categoryId}`;
  }
  if (date) {
    options += `&date=${date}`;
  }
  return axios.get(`/goals?${options}`);
}

export async function create(values: any): Promise<Goal> {
  return axios.post(`/goals`, values).then((res) => res.data);
}

export async function duplicate(values: {
  scenario: number;
  category: number;
  date: string;
  amount: number | null;
}): Promise<Goal[]> {
  return axios.post(`/goals/duplicate`, values).then((res) => res.data);
}

export async function computeVAT(values: {
  endingMonth: string;
  scenario: number;
  category: number;
}): Promise<Goal[]> {
  return axios.post(`/goals/compute-vat`, values).then((res) => res.data);
}

const goalService = { find, create, duplicate, computeVAT };
export default goalService;
