import { Button } from "antd";
import { useState } from "react";
import stripeService from "services/stripeService";

export const CheckoutSessionSetupIntentButton = () => {
  const [loading, setLoading] = useState(false);
  const handleClick = async () => {
    setLoading(true);
    // Call your backend to create the Checkout session.
    const { url } = await stripeService.createCheckoutSessionSetupIntent();
    window.location.href = url;
  };

  return (
    <Button
      style={{ margin: "25px 10px 5px" }}
      type="primary"
      loading={loading}
      onClick={handleClick}
    >
      Mettre à jour mes informations de paiement
    </Button>
  );
};
