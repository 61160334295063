import React, { FC, ReactElement } from "react";
import "./index.css";
import LogoZenfirstTreso from "components/LogoZenfirstTreso";

const UnloggedLayout: FC<{ children: ReactElement }> = ({ children }) => {
  return (
    <div className="UnloggedLayout">
      <div className="UnloggedInner">
        <div className="UnloggedHeader">
          <LogoZenfirstTreso />
        </div>
        <div className="UnloggedForm">{children}</div>
      </div>
    </div>
  );
};

export default UnloggedLayout;
