import {
  Button,
  Card,
  Col,
  Divider,
  InputNumber,
  Modal,
  Row,
  Space,
  Statistic,
  Switch,
  Tooltip,
  Typography,
} from "antd";
import LogoZenfirst from "components/LogoZenfirst";
import { AuthContext } from "contexts";
import numeral from "numeral";
import { MouseEvent, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import itemService from "services/itemService";
import useSWR from "swr";
import { Company, Plan, Plans } from "types";
import { plans } from "utils/constants";
import { CancelButton } from "./CancelButton";
import { CheckoutButton } from "./CheckoutButton";
import { PortalButton } from "./PortalButton";
import { SubscribeButton } from "./SubscribeButton";
import "./index.css";

const BillingModal = ({
  open,
  setOpen,
  plansData,
  company,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  plansData: Plans;
  company: Company | undefined;
}) => {
  const [modalLoading, setModalLoading] = useState(false);
  const [yearly, setYearly] = useState(true);
  const { me } = useContext(AuthContext);
  const { data: items } = useSWR("itemService.getAll", itemService.getAll, {});
  const [numberOfBanks, setNumberOfBanks] = useState(1);

  useEffect(() => {
    if (items) {
      setNumberOfBanks(
        items?.filter((item) => !item.isMigrationData && !item.isManualData)
          .length || 1
      );
    }
  }, [items]);

  const handleChat = (event: MouseEvent) => {
    event.preventDefault();
    window.$crisp.push(["do", "chat:toggle"]);
  };

  const handleOkModal = () => {
    setOpen(false);
    setModalLoading(true);
  };

  const handleCancelModal = () => {
    setOpen(false);
  };

  const generatePriceSection = (planData: Plan, yearly: boolean) => (
    <Statistic
      value={planData.amount + (numberOfBanks - 1) * (yearly ? 10 : 12)}
      suffix="€ / mois (HT)"
      formatter={(value) => numeral(value).format("0,0.00")}
    />
  );
  const generateCtaSection = (planId: string) => (
    <>
      {company?.plan !== plans.START_PLAN_ID && !company?.trialEndDate ? (
        me?.stripeId ? (
          <CancelButton setOpen={setOpen} />
        ) : (
          <PortalButton customText="Annuler l'abonnement" />
        )
      ) : me?.stripeId ? (
        <SubscribeButton
          planId={planId}
          numberOfBanks={numberOfBanks}
          isYearly={
            planId === plans.FIRST_PLAN_ID || planId === plans.RESOLD_PLAN_ID
          }
          setOpen={setOpen}
        />
      ) : (
        <CheckoutButton
          planId={planId}
          numberOfBanks={numberOfBanks}
          isYearly={
            planId === plans.FIRST_PLAN_ID || planId === plans.RESOLD_PLAN_ID
          }
        />
      )}
    </>
  );

  const startPlanData = {
    title: "Start",
    description:
      "Pour automatiser son reporting financier via l'agrégation bancaire.",
    price: <Statistic value={0} suffix="€ / mois (HT)" />,
    cta: (
      <Button style={{ margin: "25px 10px 5px" }} disabled>
        Démarrer
      </Button>
    ),
    features: [
      {
        title: "Suivi de trésorerie",
      },
      {
        title: "Catégorisation automatique",
      },
    ],
  };

  const isGclExpert = me?.promotionCode === "GCLEGR50";
  const firstPlanData = {
    title: "First",
    description:
      "Un outil de pilotage de trésorerie pour anticiper et prendre les bonnes décisions.",
    price: isGclExpert
      ? yearly
        ? generatePriceSection(plansData[plans.RESOLD_PLAN_ID], yearly)
        : generatePriceSection(plansData[plans.RESOLD_MONTHLY_PLAN_ID], yearly)
      : yearly
      ? generatePriceSection(plansData[plans.FIRST_PLAN_ID], yearly)
      : generatePriceSection(plansData[plans.FIRST_MONTHLY_PLAN_ID], yearly),
    cta: isGclExpert
      ? yearly
        ? generateCtaSection(plans.RESOLD_PLAN_ID)
        : generateCtaSection(plans.RESOLD_MONTHLY_PLAN_ID)
      : yearly
      ? generateCtaSection(plans.FIRST_PLAN_ID)
      : generateCtaSection(plans.FIRST_MONTHLY_PLAN_ID),
    features: [
      {
        title: "Suivi de trésorerie",
      },
      {
        title: "Catégorisation automatique",
      },
      {
        title: "Prévisionnel de trésorerie",
      },
      {
        title: "Multi-scénarios",
      },
      {
        title: "Exports",
      },
    ],
  };

  return (
    <Modal
      bodyStyle={{ backgroundColor: "#f0f2f5" }}
      centered
      title={
        <div style={{ textAlign: "center" }}>
          <LogoZenfirst />
        </div>
      }
      className="BillingModal"
      open={open}
      onOk={handleOkModal}
      confirmLoading={modalLoading}
      onCancel={handleCancelModal}
      forceRender
      footer={null}
      width={1200}
      destroyOnClose
    >
      <>
        <div style={{ textAlign: "center", margin: "0px 10px 25px" }}>
          <Typography.Text strong>
            Gagnez en visibilité et prenez les bonnes décisions pour votre
            activité
          </Typography.Text>
        </div>
        <Row justify="center">
          <Col
            style={{
              margin: "0px 15px 25px",
              textAlign: "center",
            }}
          >
            <div>
              Mensuel{" "}
              <Switch checked={yearly} onChange={() => setYearly(!yearly)} />{" "}
              Annuel - 2 mois offerts
            </div>
            <div style={{ marginTop: 10 }}>Nombre de banques</div>
            <Space style={{ marginTop: 5 }} align="center">
              {items !== undefined &&
              numberOfBanks <=
                items.filter(
                  (item) => !item.isMigrationData && !item.isManualData
                ).length ? (
                <Tooltip
                  placement="topLeft"
                  title={
                    <>
                      Vous devez supprimer des connexions bancaires pour réduire
                      le nombre de banques.
                      <br />
                      <Link to="/banks">
                        Cliquez pour accéder à la page de gestion des banques.
                      </Link>
                    </>
                  }
                >
                  <Button
                    onClick={() => setNumberOfBanks(numberOfBanks - 1)}
                    disabled={true}
                  >
                    -
                  </Button>
                </Tooltip>
              ) : (
                <Button
                  onClick={() => setNumberOfBanks(numberOfBanks - 1)}
                  disabled={numberOfBanks <= 1}
                >
                  -
                </Button>
              )}
              <InputNumber
                value={numberOfBanks}
                onChange={(value) => setNumberOfBanks(value || 1)}
                step={1}
                min={1}
                max={4}
                controls={false}
                style={{ textAlign: "center" }}
              />
              <Button
                onClick={() => setNumberOfBanks(numberOfBanks + 1)}
                disabled={numberOfBanks >= 4}
              >
                +
              </Button>
            </Space>
          </Col>
        </Row>
        <Row gutter={[16, 16]} justify="center">
          {[
            startPlanData,
            firstPlanData,
            // goldPlanData
          ].map((item) => (
            <Col key={item.title} xs={24} sm={12} md={8} lg={8}>
              <Card
                style={{
                  height: "100%",
                }}
              >
                <Typography.Paragraph>
                  <Typography.Text type="secondary">
                    {item.description}
                  </Typography.Text>
                </Typography.Paragraph>
                <div style={{ textAlign: "center" }}>
                  {item.price}
                  {item.cta}
                </div>
                <Divider />
                <Typography.Paragraph>
                  <ul>
                    {item.features.map((feature) => (
                      <li key={feature.title}>{feature.title}</li>
                    ))}
                  </ul>
                </Typography.Paragraph>
              </Card>
            </Col>
          ))}
        </Row>
        <Divider />
        <Typography.Paragraph style={{ textAlign: "center" }}>
          <div>
            <Typography.Text strong>
              Aucune offre ne vous correspond ?
            </Typography.Text>
          </div>
          <Button type="link" onClick={handleChat}>
            Contactez-nous
          </Button>
        </Typography.Paragraph>
      </>
    </Modal>
  );
};

export default BillingModal;
