import Icon from "@mdi/react";
import { mdiBankOutline } from "@mdi/js";
import { Button, Divider, Select, Space } from "antd";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Account, Item } from "types";
import {
  formatAccountBalanceText,
  formatConsolidatedBalanceText,
} from "utils/amount";

interface Props {
  selectedAccount?: number;
  hasAmount?: boolean;
  handleChangeSelectedAccount: (selectedAccount: number) => void;
  items: Item[];
}

const AccountSelect: FC<Props> = ({
  selectedAccount,
  handleChangeSelectedAccount,
  items,
  hasAmount,
}) => {
  const navigate = useNavigate();
  const selectedKey = selectedAccount;

  const formattedAccounts = items
    ?.reduce<{ account: Account; fullname: string }[]>(
      (acc, item) => [
        ...acc,
        ...item.accounts.map((account) => ({
          fullname: `${item.bank.name} • ${account.customName || account.name}`,
          account,
        })),
      ],
      []
    )
    ?.filter(({ account }) => account.active);

  return (
    <Space>
      {hasAmount && (
        <Icon
          path={mdiBankOutline}
          size={0.65}
          style={{ verticalAlign: "middle" }}
        />
      )}
      <Select
        defaultValue={selectedKey ? selectedKey : 0}
        style={{
          width: hasAmount ? 500 : 250,
          marginRight: hasAmount ? 0 : 10,
        }}
        onChange={handleChangeSelectedAccount}
        getPopupContainer={(trigger) => trigger.parentNode}
        dropdownRender={(menu) => (
          <div>
            {menu}
            <Divider style={{ margin: "4px 0" }} />
            <div
              style={{
                display: "flex",
                flexWrap: "nowrap",
                padding: 8,
                justifyContent: "center",
              }}
            >
              <Button type="link" onClick={() => navigate("/banks")}>
                Gérer mes banques
              </Button>
            </div>
          </div>
        )}
      >
        {formattedAccounts.length === 0 && (
          <Select.Option value={0}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>Aucun compte activé</span>
            </div>
          </Select.Option>
        )}
        {formattedAccounts.length === 1 && (
          <Select.Option value={0} key={formattedAccounts[0].account.id}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span
                style={{
                  width: 340,
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                {formattedAccounts[0].fullname}
              </span>{" "}
              {hasAmount &&
                formatAccountBalanceText(formattedAccounts[0].account)}
            </div>
          </Select.Option>
        )}
        {formattedAccounts.length >= 2 && (
          <>
            <Select.Option value={0}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span>
                  Consolidé ({formattedAccounts.length}{" "}
                  {formattedAccounts.length > 1 ? "comptes" : "compte"}){" "}
                </span>
                {hasAmount &&
                  formatConsolidatedBalanceText(
                    formattedAccounts.map(({ account }) => account)
                  )}
              </div>
            </Select.Option>
            {formattedAccounts.map(({ account, fullname }) => (
              <Select.Option value={account.id} key={account.id}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span
                    style={{
                      width: 340,
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                    }}
                  >
                    {fullname}
                  </span>{" "}
                  {hasAmount && formatAccountBalanceText(account)}
                </div>
              </Select.Option>
            ))}
          </>
        )}
      </Select>
    </Space>
  );
};

export default AccountSelect;
