import { mdiNoteMultipleOutline } from "@mdi/js";
import Icon from "@mdi/react";
import { Input, Modal, Space, Typography } from "antd";
import { TransactionNoteModalParams } from "components/TransactionsTable";
import { useEffect, useState } from "react";

function TransactionNoteModal({
  addNote,
  modalParams,
  setModalParams,
}: {
  addNote: (transactionId: number, note: string) => Promise<void>;
  modalParams: TransactionNoteModalParams;
  setModalParams: any;
}) {
  const [buttonLoading, setButtonLoading] = useState(false);
  const [note, setNote] = useState<string>("");

  const { open, transaction } = modalParams;

  const handleOkModal = async () => {
    const { transaction } = modalParams;
    if (!transaction) {
      return;
    }
    setButtonLoading(true);
    await addNote(transaction.id, note);

    setModalParams({ open: false });
    setButtonLoading(false);
  };

  const handleCancelModal = () => {
    setModalParams({ open: false });
  };

  useEffect(() => {
    setNote(modalParams?.transaction?.note || "");
  }, [modalParams.transaction]);

  return (
    <Modal
      title={
        <Space>
          <Icon
            path={mdiNoteMultipleOutline}
            size={0.6}
            style={{ verticalAlign: "middle" }}
          />
          Mémo
          <Typography.Text code>
            {
              // @ts-ignore
              transaction?.raw_description || transaction?.description
            }
          </Typography.Text>
        </Space>
      }
      open={open}
      onOk={handleOkModal}
      onCancel={handleCancelModal}
      okText="Mettre à jour le mémo"
      okButtonProps={{ loading: buttonLoading }}
      destroyOnClose
      width="40%"
    >
      <Input
        value={note}
        onChange={(e) => setNote(e.target.value)}
        onPressEnter={() => handleOkModal()}
      />
    </Modal>
  );
}

export default TransactionNoteModal;
