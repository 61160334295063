import { Button, Form, Input, InputRef, Modal, Radio, Typography } from "antd";
import { FC, useEffect, useRef, useState } from "react";
import errorHandler from "utils/errorHandler";
import "./index.css";

const { Text } = Typography;

const colors = [
  {
    name: "Rouge",
    value: "#ff3a22",
  },
  {
    name: "Jaune",
    value: "#feb300",
  },
  {
    name: "Bleu",
    value: "#39a0ca",
  },
  {
    name: "Vert",
    value: "#657a00",
  },
];
interface Props {
  createScenario: (values: { color: string; name: string }) => Promise<void>;
  open: boolean;
  setOpen: (open: boolean) => void;
}

const ScenarioCreateForm: FC<Props> = ({ createScenario, open, setOpen }) => {
  const [form] = Form.useForm();
  const [modalLoading, setModalLoading] = useState(false);
  const ref = useRef<InputRef>(null);

  useEffect(() => {
    if (open === true) {
      setTimeout(() => ref.current!.focus());
    }
  }, [open]);

  const handleOkModal = () => {
    const values = form.getFieldsValue();

    setModalLoading(true);
    createScenario(values)
      .then(() => {
        setModalLoading(false);
        setOpen(false);
        form.resetFields();
      })
      .catch(errorHandler());
  };

  const handleCancelModal = () => {
    setOpen(false);
    form.resetFields();
  };

  return (
    <div className="ScenarioCreateForm">
      <Modal
        title={
          <span>
            Créer un scénario alternatif au{" "}
            <Text mark strong>
              Scénario de base
            </Text>
          </span>
        }
        open={open}
        onOk={handleOkModal}
        confirmLoading={modalLoading}
        onCancel={handleCancelModal}
        forceRender
        destroyOnClose
        footer={
          <>
            <Button onClick={handleCancelModal}>Annuler</Button>
            <Button onClick={handleOkModal} type="primary">
              Créer
            </Button>
          </>
        }
      >
        <div>
          <Typography.Paragraph>
            Créer un scénario alternatif au <Text strong>Scénario de base</Text>{" "}
            pour mesurer l'impact d'hypothèses sur votre trésorerie.
          </Typography.Paragraph>
          <Typography.Paragraph>
            <i>
              Le nouveau scénario se comporte comme un calque: les valeurs du
              Scénario de base remontent sur votre nouveau scénario sauf pour
              les valeurs modifiées.
            </i>
          </Typography.Paragraph>
          <Form
            form={form}
            layout="vertical"
            initialValues={{ color: colors[0].value }}
          >
            <Form.Item
              name="name"
              label="Nom du nouveau scénario"
              rules={[{ required: true, message: "Requis" }]}
            >
              <Input ref={ref} onPressEnter={handleOkModal} />
            </Form.Item>
            <Form.Item name="color" label="Couleur du nouveau scénario">
              <Radio.Group>
                {colors.map((color) => (
                  <Radio.Button
                    style={{
                      color: color.value,
                    }}
                    key={color.value}
                    value={color.value}
                  >
                    {color.name}
                  </Radio.Button>
                ))}
              </Radio.Group>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  );
};

export default ScenarioCreateForm;
