import Loader from "components/Loader";
import { ReactElement, useEffect, useState } from "react";
import { Navigate, useLocation, useParams } from "react-router-dom";
import integrationService from "services/integrationService";
import { providerEnum } from "utils/constants";
import errorHandler from "utils/errorHandler";

function IntegrationCallbackPage() {
  const [loaded, setLoaded] = useState(false);
  const location = useLocation();
  const params = useParams();

  // need handling of errors
  const [, setError] = useState<ReactElement | undefined>(undefined);
  // const history = useHistory();

  useEffect(() => {
    const { provider } = params;
    if (provider) {
      const query = new URLSearchParams(location.search);

      if (provider === providerEnum.axonaut) {
        const access_token = query.get("access_token") || "";

        integrationService
          .connectCallback({ provider, access_token })
          .then(() => {
            setLoaded(true);
          })
          .catch((e) => {
            setLoaded(true);
            setError(e);
            throw e;
          })
          .catch(errorHandler());
      } else if (provider === providerEnum.evoliz) {
        const access_token = query.get("access_token") || "";
        const access_secret = query.get("access_secret") || "";

        integrationService
          .connectCallback({ provider, access_token, access_secret })
          .then(() => {
            setLoaded(true);
          })
          .catch((e) => {
            setLoaded(true);
            setError(e);
            throw e;
          })
          .catch(errorHandler());
      } else if (provider === providerEnum.gesco) {
        const access_token = query.get("access_token") || "";
        const groupId = query.get("groupId") || "";

        integrationService
          .connectCallback({ provider, access_token, groupId })
          .then(() => {
            setLoaded(true);
          })
          .catch((e) => {
            setLoaded(true);
            setError(e);
            throw e;
          })
          .catch(errorHandler());
      } else if (provider === providerEnum.ingeneo) {
        const groupId = query.get("groupId") || "";

        integrationService
          .connectCallback({ provider, groupId })
          .then(() => {
            setLoaded(true);
          })
          .catch((e) => {
            setLoaded(true);
            setError(e);
            throw e;
          })
          .catch(errorHandler());
      } else if (provider === "intuit") {
        const refreshToken = query.get("refresh_token") || "";
        const realmId = query.get("raw[realmId]") || "";

        integrationService
          .connectCallback({ provider, refreshToken, groupId: realmId })
          .then(() => {
            setLoaded(true);
          })
          .catch((e) => {
            setLoaded(true);
            setError(e);
            throw e;
          })
          .catch(errorHandler());
      } else if (provider === "sellsy") {
        const access_token = query.get("access_token") || "";
        const access_secret = query.get("access_secret") || "";

        integrationService
          .connectCallback({ provider, access_token, access_secret })
          .then(() => {
            setLoaded(true);
          })
          .catch((e) => {
            setLoaded(true);
            setError(e);
            throw e;
          })
          .catch(errorHandler());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!loaded) {
    return <Loader />;
  }

  return <Navigate to="/integrations" replace={true} />;
}

export default IntegrationCallbackPage;
