import { Button, Card, Col, Row } from "antd";
import BillingModal from "components/BillingModal";
import { CheckoutSessionSetupIntentButton } from "components/BillingModal/CheckoutSessionSetupIntentButton";
import { PortalButton } from "components/BillingModal/PortalButton";
import GeneralLayout from "components/GeneralLayout";
import Loader from "components/Loader";
import { AuthContext } from "contexts";
import { format } from "date-fns";
import { useContext, useState } from "react";
import stripeService from "services/stripeService";
import useSWR from "swr";
import { Company } from "types";
import { applicationName, plans } from "utils/constants";
import marketingTools from "utils/marketingTools";
import "./index.css";

const describePlan = (company: Company) => {
  const { plan, isYearly, numberOfBanks, trialEndDate, cancelAtPeriodEnd } =
    company;
  if (trialEndDate) {
    return (
      <div>
        Vous êtes en période d'essai de la version complète (jusqu'au{" "}
        {format(new Date(company.trialEndDate), "dd/MM/yyyy")})
      </div>
    );
  } else if (cancelAtPeriodEnd) {
    return (
      <div>
        Votre abonnement prendra fin automatiquement à la fin de la période de
        facturation.
      </div>
    );
  } else if (plan === plans.START_PLAN_ID) {
    return (
      <div>Vous êtes en version gratuite et disposez d'un accès limité</div>
    );
  } else if (
    plan === plans.FIRST_PLAN_ID ||
    plan === plans.RESOLD_PLAN_ID ||
    plan === plans.FIRST_MONTHLY_PLAN_ID ||
    plan === plans.RESOLD_MONTHLY_PLAN_ID
  ) {
    return (
      <div>
        Vous êtes en abonnement {isYearly ? "annuel" : "mensuel"} (
        {numberOfBanks} banque
        {company.numberOfBanks > 1 ? "s" : ""}){" "}
      </div>
    );
  } else if (plan === plans.PARTNER_PLAN_ID) {
    return <div>Vous êtes un partenaire {applicationName}.</div>;
  } else {
    return <div></div>;
  }
};

function BillingPage() {
  const [open, setOpen] = useState(false);
  const { data: plansData } = useSWR(
    "stripeService.setup",
    stripeService.setup
  );
  const { me } = useContext(AuthContext);

  const showBillingModal = () => {
    setOpen(true);

    marketingTools.hasOpenedPricing();
  };

  return (
    <GeneralLayout title={"Abonnement"}>
      <div className="BillingPage">
        {!plansData || !me || !me?.company ? (
          <Loader />
        ) : (
          <Card>
            <BillingModal
              open={open}
              setOpen={setOpen}
              plansData={plansData}
              company={me.company}
            />
            <Row gutter={[16, 16]}>
              <Col span={24} style={{ textAlign: "center" }}>
                {<div>{describePlan(me.company)}</div>}
              </Col>
              {me.company.plan !== plans.PARTNER_PLAN_ID && (
                <>
                  {!me.stripeId && me.company.stripeId && (
                    <Col span={24} style={{ textAlign: "center" }}>
                      <PortalButton />
                    </Col>
                  )}
                  {me.stripeId &&
                    (me.hasDefaultPaymentMethod ? (
                      <Col span={24} style={{ textAlign: "center" }}>
                        <PortalButton />
                      </Col>
                    ) : (
                      <Col span={24} style={{ textAlign: "center" }}>
                        <CheckoutSessionSetupIntentButton />
                      </Col>
                    ))}
                  <Col span={24} style={{ textAlign: "center" }}>
                    <Button
                      onClick={showBillingModal}
                      disabled={!!me.stripeId && !me.hasDefaultPaymentMethod}
                    >
                      Changer d'abonnement
                    </Button>
                  </Col>
                </>
              )}
              {me.company.plan === plans.PARTNER_PLAN_ID && (
                <>
                  {me.stripeId && (
                    <Col span={24} style={{ textAlign: "center" }}>
                      <PortalButton />
                    </Col>
                  )}
                  <Col span={24} style={{ textAlign: "center" }}>
                    <CheckoutSessionSetupIntentButton />
                  </Col>
                </>
              )}
            </Row>
          </Card>
        )}
      </div>
    </GeneralLayout>
  );
}

export default BillingPage;
