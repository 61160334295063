import { Item } from "types";
import axios from "utils/setupAxios";

export async function create(): Promise<Item> {
  return axios.post("/items", {}).then((res) => res.data);
}

export async function getAll(): Promise<Item[]> {
  return axios.get("/items/").then((res) => res.data);
}

export async function remove(itemId: number): Promise<Item> {
  return axios.delete(`/items/${itemId}`).then((res) => res.data);
}

export async function update(
  itemId: number,
  values: { toRefresh: boolean }
): Promise<Item> {
  return axios.put(`/items/${itemId}`, values).then((res) => res.data);
}

const itemService = { create, getAll, remove, update };
export default itemService;
