import {
  differenceInDays,
  format,
  getDate,
  getMonth,
  getYear,
  startOfMonth,
} from "date-fns";

const monthStrings = [
  "Jan",
  "Fév",
  "Mars",
  "Avril",
  "Mai",
  "Juin",
  "Juil",
  "Août",
  "Sept",
  "Oct",
  "Nov",
  "Déc",
];

const monthFullStrings = [
  "Janvier",
  "Février",
  "Mars",
  "Avril",
  "Mai",
  "Juin",
  "Juillet",
  "Août",
  "Septembre",
  "Octobre",
  "Novembre",
  "Décembre",
];

const getFormattedDateTime = (date: Date) =>
  `${getDate(date)} ${monthStrings[getMonth(date)]} à ${format(date, "HH:mm")}`;

const getFormattedFullDate = (date: Date) =>
  `${getDate(date)} ${monthStrings[getMonth(date)]} ${getYear(date)}`;

const getHowLongAgo = (date: Date) => {
  const differenceInDaysWithToday = differenceInDays(new Date(), date);
  if (differenceInDaysWithToday === 0) {
    return "Aujourd'hui";
  } else if (differenceInDaysWithToday === 1) {
    return "Hier";
  } else {
    return `Il y a ${differenceInDaysWithToday} jours`;
  }
};

const getFormattedDate = (date: Date) =>
  `${getDate(date)} ${monthStrings[getMonth(date)]}`;

const isPastMonth = (month: Date) =>
  month.getTime() < startOfMonth(new Date()).getTime();

const isPresentMonth = (month: Date) =>
  month.getTime() === startOfMonth(new Date()).getTime();

export {
  monthStrings,
  monthFullStrings,
  getFormattedFullDate,
  getHowLongAgo,
  getFormattedDateTime,
  getFormattedDate,
  isPastMonth,
  isPresentMonth,
};
