import { mdiPlus } from "@mdi/js";
import Icon from "@mdi/react";
import { Button, Divider, Form, InputNumber, Select } from "antd";
import { FC, useState } from "react";
import { vatRateList } from "utils/constants";

export const VatSelect: FC = () => {
  const [customRate, setCustomRate] = useState<number>(0);

  return (
    <Form.Item
      noStyle
      shouldUpdate={(prevValues, currentValues) =>
        prevValues.vat !== currentValues.vat
      }
    >
      {({ getFieldValue, setFieldsValue }) => {
        const currentVatRate = getFieldValue("vat");

        const vatRates =
          vatRateList.indexOf(currentVatRate) === -1
            ? [...vatRateList, currentVatRate]
            : vatRateList;

        const handleCustomRate = () => {
          setFieldsValue({ vat: customRate });
          setCustomRate(0);
        };

        return (
          <Form.Item name="vat" label="Taux de TVA" initialValue={0}>
            <Select
              onDropdownVisibleChange={() => {
                setCustomRate(0);
              }}
              placeholder="TVA"
              style={{
                width: "100%",
              }}
              getPopupContainer={(trigger) => trigger.parentNode}
              dropdownRender={(menu) => (
                <div>
                  {menu}
                  <Divider style={{ margin: "4px 0" }} />
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "nowrap",
                      padding: 8,
                    }}
                  >
                    <InputNumber
                      min={0}
                      max={100}
                      step={1}
                      precision={2}
                      value={customRate}
                      onChange={(value) => setCustomRate(value || 0)}
                      onPressEnter={handleCustomRate}
                    />
                    <Button type="link" onClick={handleCustomRate}>
                      <Icon
                        path={mdiPlus}
                        size={0.8}
                        style={{ verticalAlign: "-22%" }}
                      />
                      Ajouter
                    </Button>
                  </div>
                </div>
              )}
              options={vatRates
                .sort((a, b) => a - b)
                .map((vatRate) => ({
                  label: `${vatRate} %`,
                  value: vatRate,
                  key: vatRate,
                }))}
            />
          </Form.Item>
        );
      }}
    </Form.Item>
  );
};
