import { SwapOutlined } from "@ant-design/icons";
import { Popover, Statistic, Typography } from "antd";
import numeral from "numeral";
import Highlighter from "react-highlight-words";
import { Account, Invoice, Transaction } from "types";
import { convert, CurrencyCode, defaultCurrencyCode } from "./currency";
import { KindEnum } from "./kind";

const privateProperties = {
  locale: "fr",
  defaultCurrencyCode: defaultCurrencyCode,
};

const setLocale = (locale: string) => {
  privateProperties.locale = locale;
};

const setCurrency = (defaultCurrencyCode: CurrencyCode) => {
  privateProperties.defaultCurrencyCode = defaultCurrencyCode;
};

const isCashIn = (operation: Transaction | Invoice) => {
  return (
    operation.kind === KindEnum.cashIn ||
    (operation.amount > 0 && operation.kind !== KindEnum.cashOut)
  );
};

const isCashOut = (operation: Transaction | Invoice) => {
  return (
    operation.kind === KindEnum.cashOut ||
    (operation.amount < 0 && operation.kind !== KindEnum.cashIn)
  );
};

const sumOfAmounts = (amounts: Array<number>) => {
  return (
    amounts.reduce((sum: number, amount: number) => {
      return sum + amount * 100;
    }, 0) / 100
  );
};

const formatCurrency = (
  amount: number,
  currencyCode: CurrencyCode,
  options?: { signDisplay: boolean }
) => {
  return new Intl.NumberFormat(privateProperties.locale, {
    style: "currency",
    currency: currencyCode,
    minimumFractionDigits: 2,
    ...(options?.signDisplay && { signDisplay: "exceptZero" }),
  }).format(amount);
};

const formatTransactionAmountText = (
  transaction:
    | Transaction
    | Invoice
    | { amount: number; currency_code: CurrencyCode; ignored?: boolean },
  searchText = ""
) => {
  const { amount, currency_code, ignored } = transaction;

  if (ignored) {
    return (
      <Typography.Text delete>
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText, searchText.replace(".", ",")]}
          autoEscape
          textToHighlight={formatCurrency(amount, currency_code, {
            signDisplay: true,
          })}
        />
      </Typography.Text>
    );
  }

  const isPositive = amount >= 0;
  const signSymbol = isPositive ? "+" : "";
  const absoluteAmount = Math.abs(amount);
  return (
    <span className={isPositive ? "amount-positive" : ""}>
      <Highlighter
        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
        searchWords={[searchText, searchText.replace(".", ",")]}
        autoEscape
        textToHighlight={`${signSymbol}${formatCurrency(
          absoluteAmount,
          currency_code
        )}`}
      />
    </span>
  );
};

const formatTransactionAmount = (
  transaction:
    | Transaction
    | Invoice
    | { amount: number; currency_code: CurrencyCode; ignored?: boolean },
  searchText = ""
) => {
  const { amount, currency_code } = transaction;

  if (currency_code === privateProperties.defaultCurrencyCode) {
    return <span>{formatTransactionAmountText(transaction, searchText)}</span>;
  }

  const convertedAmount = convert(amount, {
    to: defaultCurrencyCode,
    from: currency_code,
  });

  return (
    <Popover
      placement="left"
      content={formatTransactionAmountText(transaction, searchText)}
    >
      <span style={{ cursor: "pointer" }}>
        <SwapOutlined />{" "}
        {formatTransactionAmountText(
          {
            ...transaction,
            amount: convertedAmount,
            currency_code: privateProperties.defaultCurrencyCode,
          },
          searchText
        )}
      </span>
    </Popover>
  );
};

const computeSumOfOperation = (operations: (Transaction | Invoice)[]) =>
  operations?.reduce((sumOfOperation, { amount, currency_code }) => {
    const convertedAmount = convert(amount, {
      to: defaultCurrencyCode,
      from: currency_code,
    });

    return sumOfAmounts([sumOfOperation, convertedAmount]);
  }, 0);

const formatSumOfOperation = (operations: (Transaction | Invoice)[]) => {
  const sum = computeSumOfOperation(operations);

  return formatTransactionAmountText({
    amount: sum,
    currency_code: privateProperties.defaultCurrencyCode,
  });
};

const formatAccountBalanceText = (
  account: Account | { balance: number; currency_code: CurrencyCode }
) => {
  const { balance, currency_code } = account;

  const isPositive = balance >= 0;
  return (
    <span className={isPositive ? "" : "amount-negative"}>{`${formatCurrency(
      balance,
      currency_code
    )}`}</span>
  );
};

const formatAccountBalance = (
  account: Account | { balance: number; currency_code: CurrencyCode }
) => {
  const { balance, currency_code } = account;

  if (currency_code === defaultCurrencyCode) {
    return <span>{formatAccountBalanceText(account)}</span>;
  }

  const convertedBalance = convert(balance, {
    to: defaultCurrencyCode,
    from: currency_code,
  });

  return (
    <Popover placement="left" content={formatAccountBalanceText(account)}>
      <span style={{ cursor: "pointer" }}>
        <SwapOutlined />{" "}
        {formatAccountBalanceText({
          ...account,
          balance: convertedBalance,
          currency_code: defaultCurrencyCode,
        })}
      </span>
    </Popover>
  );
};

const computeConsolidatedBalance = (accounts: Account[]) =>
  accounts?.reduce((sumOfBalance, { balance, currency_code }) => {
    const convertedBalance = convert(balance, {
      to: defaultCurrencyCode,
      from: currency_code,
    });

    return sumOfAmounts([sumOfBalance, convertedBalance]);
  }, 0);

const formatConsolidatedBalanceText = (accounts: Account[]) => {
  const balance = computeConsolidatedBalance(accounts);
  const currency_code = CurrencyCode.EUR;

  const isPositive = balance >= 0;
  return (
    <span className={isPositive ? "" : "amount-negative"}>{`${formatCurrency(
      balance,
      currency_code
    )}`}</span>
  );
};

const formatConsolidatedBalance = (accounts: Account[], blueStyle = false) => {
  const cashAvailable = computeConsolidatedBalance(accounts);

  return (
    <Statistic
      title="Aujourd'hui"
      suffix="€"
      value={cashAvailable}
      formatter={(value) => numeral(value).format("0,0", Math.floor)}
      valueStyle={
        blueStyle
          ? {
              color: "rgb(92, 166, 239)",
            }
          : {
              color:
                cashAvailable && cashAvailable < 0
                  ? "rgb(226, 130, 127)"
                  : "rgb(137, 199, 184)",
              fontSize: 24,
            }
      }
    />
  );
};

export {
  setLocale,
  setCurrency,
  formatCurrency,
  formatAccountBalanceText,
  formatAccountBalance,
  formatTransactionAmountText,
  formatTransactionAmount,
  formatSumOfOperation,
  computeSumOfOperation,
  computeConsolidatedBalance,
  formatConsolidatedBalanceText,
  formatConsolidatedBalance,
  isCashIn,
  isCashOut,
  sumOfAmounts,
};
