import OnboardingLayout from "components/OnboardingLayout";
import { FC } from "react";
import { ChangeCompanyForm } from "./ChangeCompanyForm";
import "./index.css";

const ChangeCompanyPage: FC = () => {
  return (
    <OnboardingLayout>
      <div className="ChangeCompanyPage">
        <div className="animated">
          <h1>Mes entreprises</h1>
          <div
            style={{
              marginTop: 40,
            }}
          >
            <ChangeCompanyForm />
          </div>
        </div>
      </div>
    </OnboardingLayout>
  );
};

export default ChangeCompanyPage;
