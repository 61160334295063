import { PaginationProps, Popover, Table, Tag } from "antd";
import { ColumnsType } from "antd/es/table";
import { FC } from "react";
import Highlighter from "react-highlight-words";
import { Category, Invoice } from "types";
import { formatTransactionAmount } from "utils/amount";
import { providerEnum } from "utils/constants";
import { getFormattedFullDate } from "utils/date";
import { findTree } from "utils/tree";

interface Props {
  invoices: Invoice[];
  categories: Category[];
  changeParams: (
    pagination: PaginationProps,
    filters: any,
    sorter: any
  ) => void;
  pagination: PaginationProps;
  loading: boolean;
  selectedInvoices: Invoice[];
  setSelectedInvoices: (invoices: Invoice[]) => void;
  searchText?: string;
}

const ReconciliationInvoicesTable: FC<Props> = ({
  invoices,
  categories,
  changeParams,
  pagination,
  loading,
  selectedInvoices,
  setSelectedInvoices,
  searchText = "",
}) => {
  const columns: ColumnsType<Invoice> = [
    {
      title: "Date échéance",
      dataIndex: "dueDate",
      key: "dueDate",
      width: "12%",
      sorter: true,
      render: (date: string) => getFormattedFullDate(new Date(date)),
    },
    {
      title: "Libellé",
      dataIndex: "description",
      key: "description",
      width: "30%",
      ellipsis: true,
      sorter: true,
      render: (_: any, invoice) => (
        <Popover
          mouseEnterDelay={1}
          placement="topLeft"
          content={
            <div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span>
                  <Tag>{providerEnum[invoice.provider] || "Ajout manuel"}</Tag>
                </span>
                <span>
                  <Highlighter
                    highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={
                      invoice.note
                        ? `${invoice.description} • ${invoice.note}`
                        : invoice.description
                    }
                  />
                </span>
              </div>
            </div>
          }
        >
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={
              invoice.note
                ? `${invoice.description} • ${invoice.note}`
                : invoice.description
            }
          />
        </Popover>
      ),
    },
    {
      title: "Statut",
      dataIndex: "dueDate",
      key: "status",
      render: (_: any, invoice) => {
        if (invoice.paid) {
          return <Tag color="green">Payée</Tag>;
        } else if (new Date(invoice.dueDate).getTime() > new Date().getTime()) {
          return <Tag color="gold">En attente</Tag>;
        } else {
          return <Tag color="red">En retard</Tag>;
        }
      },
      filters: [
        { text: "Payée", value: "paid" },
        { text: "En attente", value: "awaiting" },
        { text: "En retard", value: "late" },
      ],
      filterMultiple: false,
    },
    {
      title: "Catégorie",
      dataIndex: "category",
      key: "category",
      width: "25%",
      sorter: true,
      render: (_: any, invoice) => (
        <Tag>
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={
              findTree({
                categories: categories || [],
                categoryId: invoice.category,
              })?.name || "Pas de catégorie"
            }
          />
        </Tag>
      ),
    },
    {
      title: "Montant TTC",
      dataIndex: "amount",
      key: "amount",
      width: "12%",
      render: (_: any, invoice) => formatTransactionAmount(invoice, searchText),
      sorter: true,
      align: "right",
    },
  ];

  return (
    <Table
      locale={{
        emptyText: "Aucune facture à afficher",
      }}
      columns={columns}
      dataSource={invoices.map((invoice) => ({
        ...invoice,
        key: invoice.id,
      }))}
      size="small"
      onChange={changeParams}
      loading={loading}
      pagination={pagination}
      rowKey="id"
      showSorterTooltip={false}
      rowClassName={(invoice) => (invoice.ignored ? "table-row-ignored" : "")}
      onRow={(invoice, rowIndex) => ({
        onClick: () => {
          setSelectedInvoices([...selectedInvoices, invoice]);
        },
        style: { cursor: "pointer" },
      })}
    />
  );
};

export default ReconciliationInvoicesTable;
