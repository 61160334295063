import { Card, Menu } from "antd";
import BankConnector from "components/BankConnector";
import GeneralLayout from "components/GeneralLayout";
import { AuthContext } from "contexts";
import { useContext } from "react";
import { Link, useLocation } from "react-router-dom";

function BanksPage() {
  const location = useLocation();
  const { me } = useContext(AuthContext);
  const isInvoiceEnabled = me?.company?.isInvoiceEnabled;

  const menu = (
    <Menu
      selectedKeys={[location?.pathname]}
      mode="horizontal"
      items={[
        { key: "/banks", label: <Link to="/banks">Banques</Link> },
        {
          key: "/integrations",
          label: <Link to="/integrations">Intégrations</Link>,
        },
      ]}
    />
  );

  return (
    <GeneralLayout title={isInvoiceEnabled ? menu : "Banques"}>
      <div className="BanksPage">
        <Card>
          <BankConnector isRegister={false} />
        </Card>
      </div>
    </GeneralLayout>
  );
}

export default BanksPage;
