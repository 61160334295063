import { addMonths, format, getMonth } from "date-fns";
import numeral from "numeral";
import { FC } from "react";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Line,
  LineChart,
  ReferenceArea,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Scenario } from "types";
import { StatisticData } from "utils/dashboardUtils";
import { isPastMonth, isPresentMonth, monthFullStrings } from "utils/date";

const colors = {
  green: [
    "rgba(48, 206, 143, 1)",
    "rgba(48, 206, 143, 0.5)",
    "rgba(48, 206, 143, 0.2)",
  ],
  red: [
    "rgba(255, 91, 54, 1)",
    "rgba(255, 91, 54, 0.5)",
    "rgba(255, 91, 54, 0.2)",
  ],
};
const barSize = 22;

const formatChartData = ({
  startingMonth,
  checkedScenarios,
  data,
  scenarioId,
  scenarios,
}: {
  startingMonth: Date;
  checkedScenarios: Record<number | string, boolean>;
  data: StatisticData;
  scenarioId: number;
  scenarios: Scenario[];
}) => {
  const startingMonthIndex = getMonth(startingMonth);

  // LINE CHART
  let balance = 0;
  const dataLineChart = Array.from(Array(13).keys()).map((n) => {
    const currentMonth = addMonths(startingMonth, n);
    const currentMonthString = format(currentMonth, "yyyy-MM-dd");

    if (n === 0) {
      balance = data["cashAtBeginning"][currentMonthString][scenarioId];
    }
    const currentBalance = balance;
    if (n !== 12) {
      balance +=
        data["cashInTotal"][currentMonthString].goal[scenarioId] -
        data["cashOutTotal"][currentMonthString].goal[scenarioId];
    }
    const previousMonth = addMonths(startingMonth, n - 1);
    const previousMonthString = format(previousMonth, "yyyy-MM-dd");
    const monthId = startingMonthIndex + n;
    return scenarios
      .filter(
        (scenario) =>
          checkedScenarios[scenario.id] || scenario.id === scenarioId
      )
      .reduce(
        (acc: any, scenario) => ({
          ...acc,
          [scenario.id]:
            n !== 12
              ? data["cashAtBeginning"][currentMonthString][scenario.id]
              : data["cashAtEnd"][previousMonthString][scenario.id],
        }),
        {
          currentScenarioWithPastGoals: checkedScenarios["compareWithPastGoals"]
            ? currentBalance
            : null,
          date: monthId,
        }
      );
  });

  // BAR CHART
  const dataBarChart = Array.from(Array(12).keys()).map((n) => {
    const currentMonth = addMonths(startingMonth, n);
    const currentMonthString = format(currentMonth, "yyyy-MM-dd");
    const monthId = startingMonthIndex + n;
    const isPast = isPastMonth(currentMonth);
    const isPresent = isPresentMonth(currentMonth);

    const output: any = { date: monthId };
    if (isPast || isPresent) {
      output["paidCashIn"] =
        data["cashInTotal"][currentMonthString].sumOfTransactions;
      output["paidCashOut"] =
        data["cashOutTotal"][currentMonthString].sumOfTransactions;
    }
    if (!isPast) {
      output["awaitingCashIn"] =
        data["cashInTotal"][currentMonthString].sumOfInvoices;
      output["awaitingCashOut"] =
        data["cashOutTotal"][currentMonthString].sumOfInvoices;

      output["remainingCashIn"] = Math.max(
        data["cashInTotal"][currentMonthString].forecast[scenarioId] -
          data["cashInTotal"][currentMonthString].sumOfTransactions -
          data["cashInTotal"][currentMonthString].sumOfInvoices,
        0
      );
      output["remainingCashOut"] = Math.max(
        data["cashOutTotal"][currentMonthString].forecast[scenarioId] -
          data["cashOutTotal"][currentMonthString].sumOfTransactions -
          data["cashOutTotal"][currentMonthString].sumOfInvoices,
        0
      );
    }
    return output;
  });

  return {
    dataLineChart,
    dataBarChart,
  };
};

interface Props {
  data: StatisticData;
  startingMonth: Date;
  loaded: boolean;
  checkedScenarios: Record<number | string, boolean>;
  scenarioId: number;
  scenarios: Scenario[];
  overdraft: number | undefined;
  graphicView: string;
}

const MainReChart: FC<Props> = ({
  data,
  startingMonth,
  loaded,
  checkedScenarios,
  scenarioId,
  scenarios,
  overdraft = 0,
  graphicView,
}) => {
  const { dataLineChart, dataBarChart } = loaded
    ? formatChartData({
        startingMonth,
        checkedScenarios,
        data,
        scenarioId,
        scenarios,
      })
    : {
        dataLineChart: [],
        dataBarChart: [],
      };

  const currentScenario = scenarios.find(
    (scenario) => scenario.id === scenarioId
  );

  if (!currentScenario) {
    throw new Error("Scenario not found");
  }

  const CustomTooltip = ({
    active,
    payload,
    label,
  }: {
    active: boolean;
    payload: { name: string; value: number; color: string; dataKey: string }[];
    label: number;
  }) => {
    if (active) {
      return (
        <div
          style={{
            background: "white",
            padding: "9px 12px",
            border: "1px solid #ccc",
          }}
        >
          {payload.length && (
            <>
              <strong>{`1er ${monthFullStrings[label % 12]}`}</strong>
              <br />
              Trésorerie disponible
              <br />
              <br />
            </>
          )}
          {payload.map((point) => (
            <div style={{ color: point.color }} key={point.dataKey}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ marginRight: 10 }}>{point.name}:</div>
                <div>{`${numeral(point.value).format(
                  "0,0",
                  Math.floor
                )} €`}</div>
              </div>
            </div>
          ))}
        </div>
      );
    }

    return null;
  };

  const CustomBarChartTooltip = ({
    active,
    payload,
    label,
  }: {
    active: boolean;
    payload: { name: string; value: number; color: string; dataKey: string }[];
    label: number;
  }) => {
    if (active) {
      return (
        <div
          style={{
            background: "white",
            padding: "9px 12px",
            border: "1px solid #ccc",
          }}
        >
          {payload.length && (
            <>
              <strong>{monthFullStrings[label % 12]}</strong>
              <br />
              <br />
            </>
          )}
          {payload.map((point) => (
            <div style={{ color: point.color }} key={point.dataKey}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ marginRight: 10 }}>{point.name}:</div>
                <div>{`${numeral(point.value).format(
                  "0,0",
                  Math.floor
                )} €`}</div>
              </div>
            </div>
          ))}
        </div>
      );
    }

    return null;
  };

  const CustomizedAxisTick = ({
    x,
    y,
    width,
    visibleTicksCount,
  }: {
    x: number;
    y: number;
    width: number;
    visibleTicksCount: number;
  }) => {
    const offset = width / (visibleTicksCount - 1) / 2;
    return (
      <g transform={`translate(${x + offset},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="middle" fill="#666">
          {/* {monthStrings[payload.value % 12]} */}
        </text>
      </g>
    );
  };

  const formatter = (value: number) =>
    Math.abs(value) >= 1000 ? `${value / 1000} k` : `${value}`;

  const CustomizedBarChartAxisTick = ({ x, y }: { x: number; y: number }) => {
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="middle" fill="#666">
          {/* {`${monthStrings[payload.value % 12]}`} */}
        </text>
      </g>
    );
  };

  // Config Reference Area
  const startingMonthIndex = getMonth(startingMonth);

  const endingMonthOffset = Array.from(Array(13).keys()).find((n) => {
    if (n < 12) {
      return new Date() < addMonths(startingMonth, n + 1);
    }
    return true;
  }) as number;

  const endingMonthIndex =
    startingMonthIndex + endingMonthOffset + (graphicView !== "0" ? -1 : 0);

  return (
    <>
      {graphicView === "0" && (
        <div style={{ height: 300 }}>
          <ResponsiveContainer>
            <LineChart height={300} data={dataLineChart}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                orientation="bottom"
                dataKey="date"
                tick={CustomizedAxisTick}
              />
              <YAxis width={60} tickFormatter={formatter} />
              <Tooltip
                content={
                  // @ts-ignore
                  <CustomTooltip />
                }
              />
              <ReferenceArea
                x1={startingMonthIndex}
                x2={endingMonthIndex}
                strokeOpacity={0.1}
              />
              <ReferenceLine x={endingMonthIndex} stroke="rgb(92, 166, 239)" />
              <ReferenceLine y={0} stroke="#000" ifOverflow="extendDomain" />
              <ReferenceLine
                y={overdraft}
                stroke="#e25c3b"
                ifOverflow="extendDomain"
                label={{
                  position: "bottom",
                  value: `Seuil de trésorerie minimum`,
                  fill: "#777",
                  fontSize: 14,
                }}
              />
              {/* <Legend /> */}
              <Line
                type="monotone"
                key={currentScenario.id}
                name={currentScenario.name}
                dataKey={currentScenario.id}
                stroke={currentScenario.color}
                strokeWidth={2}
              />
              <Line
                type="monotone"
                key={"currentScenarioWithPastGoals"}
                name={`En suivant les objectifs depuis le 1er ${
                  monthFullStrings[startingMonthIndex % 12]
                }`}
                dataKey={"currentScenarioWithPastGoals"}
                strokeWidth={2}
                stroke={currentScenario?.color}
                strokeDasharray="3 3"
              />
              {scenarios
                .filter((scenario) => scenario.id !== scenarioId)
                .map((scenario) => (
                  <Line
                    type="monotone"
                    key={scenario.id}
                    name={scenario.name}
                    dataKey={scenario.id}
                    stroke={scenario.color}
                    strokeWidth={2}
                  />
                ))}
            </LineChart>
          </ResponsiveContainer>
        </div>
      )}
      {graphicView === "1" && (
        <div style={{ height: 300 }}>
          <ResponsiveContainer>
            <ComposedChart data={dataBarChart}>
              <XAxis
                orientation="bottom"
                dataKey="date"
                tick={CustomizedBarChartAxisTick}
              />
              <YAxis width={60} tickFormatter={formatter} />
              <CartesianGrid strokeDasharray="3 3" vertical={false} />
              <Tooltip
                content={
                  // @ts-ignore
                  <CustomBarChartTooltip />
                }
              />
              <ReferenceArea
                x1={startingMonthIndex}
                x2={endingMonthIndex}
                strokeOpacity={0.1}
              />
              <ReferenceLine y={0} stroke="#000" ifOverflow="extendDomain" />
              {/* <Legend verticalAlign="top" height={48} /> */}
              <Bar
                name="Entrées réalisées"
                dataKey="paidCashIn"
                xAxisId={0}
                stackId="cashIn"
                barSize={barSize}
                fill={colors.green[0]}
              />
              <Bar
                name="Entrées en attente"
                dataKey="awaitingCashIn"
                xAxisId={0}
                stackId="cashIn"
                fill={colors.green[1]}
              />

              <Bar
                name="Reste à faire"
                dataKey="remainingCashIn"
                xAxisId={0}
                stackId="cashIn"
                fill={colors.green[2]}
              />
              <Bar
                name="Sorties réalisées"
                dataKey="paidCashOut"
                xAxisId={0}
                stackId="cashOut"
                barSize={barSize}
                fill={colors.red[0]}
              />
              <Bar
                name="Sorties en attente"
                dataKey="awaitingCashOut"
                xAxisId={0}
                stackId="cashOut"
                fill={colors.red[1]}
              />

              <Bar
                name="Reste à faire"
                dataKey="remainingCashOut"
                xAxisId={0}
                stackId="cashOut"
                fill={colors.red[2]}
              />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      )}
      {graphicView === "2" && (
        <div style={{ height: 300 }}>
          <ResponsiveContainer>
            <ComposedChart
              data={dataBarChart.reduce((acc, elem, index) => {
                return [...acc, { ...dataLineChart[index], ...elem }];
              }, [])}
            >
              <XAxis
                orientation="bottom"
                dataKey="date"
                tick={CustomizedBarChartAxisTick}
              />
              <YAxis width={60} tickFormatter={formatter} />
              <CartesianGrid strokeDasharray="3 3" vertical={false} />
              <Tooltip
                content={
                  // @ts-ignore
                  <CustomBarChartTooltip />
                }
              />
              <ReferenceArea
                x1={startingMonthIndex}
                x2={endingMonthIndex}
                strokeOpacity={0.1}
              />
              <ReferenceLine y={0} stroke="#000" ifOverflow="extendDomain" />
              <ReferenceLine
                y={overdraft}
                stroke="#e25c3b"
                ifOverflow="extendDomain"
                label={{
                  position: "bottom",
                  value: `Seuil de trésorerie minimum`,
                  fill: "#777",
                  fontSize: 14,
                }}
              />
              {/* <Legend verticalAlign="top" height={48} /> */}
              <Bar
                name="Entrées réalisées"
                dataKey="paidCashIn"
                xAxisId={0}
                stackId="cashIn"
                barSize={barSize}
                fill={colors.green[0]}
              />
              <Bar
                name="Entrées en attente"
                dataKey="awaitingCashIn"
                xAxisId={0}
                stackId="cashIn"
                fill={colors.green[1]}
              />
              <Bar
                name="Reste à faire"
                dataKey="remainingCashIn"
                xAxisId={0}
                stackId="cashIn"
                fill={colors.green[2]}
              />
              <Bar
                name="Sorties réalisées"
                dataKey="paidCashOut"
                xAxisId={0}
                stackId="cashOut"
                barSize={barSize}
                fill={colors.red[0]}
              />
              <Bar
                name="Sorties en attente"
                dataKey="awaitingCashOut"
                xAxisId={0}
                stackId="cashOut"
                fill={colors.red[1]}
              />
              <Bar
                name="Reste à faire"
                dataKey="remainingCashOut"
                xAxisId={0}
                stackId="cashOut"
                fill={colors.red[2]}
              />
              <Line
                type="monotone"
                key={currentScenario.id}
                name={currentScenario.name}
                dataKey={currentScenario.id}
                stroke={currentScenario.color}
                strokeWidth={2}
              />
              <Line
                type="monotone"
                key={"currentScenarioWithPastGoals"}
                name={`En suivant les objectifs depuis le 1er ${
                  monthFullStrings[startingMonthIndex % 12]
                }`}
                dataKey={"currentScenarioWithPastGoals"}
                strokeWidth={2}
                stroke={currentScenario?.color}
                strokeDasharray="3 3"
              />
              {scenarios
                .filter((scenario) => scenario.id !== scenarioId)
                .map((scenario) => (
                  <Line
                    type="monotone"
                    key={scenario.id}
                    name={scenario.name}
                    dataKey={scenario.id}
                    stroke={scenario.color}
                    strokeWidth={2}
                  />
                ))}
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      )}
    </>
  );
};

export default MainReChart;
