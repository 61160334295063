import { Badge, Card, Col, Row, Tabs } from "antd";
import GeneralLayout from "components/GeneralLayout";
import TransactionsTableContainer from "components/TransactionsTableContainer";
import { useState } from "react";
import categoryService from "services/categoryService";
import transactionService, {
  TransactionQuickFilterType,
} from "services/transactionService";
import useSWR from "swr";

function TransactionsPage() {
  const {
    data: categories,
    // error
  } = useSWR(
    "categoryService.getAllWithoutDefault",
    categoryService.getAllWithoutDefault
  );
  const { data: uncategorizedCount } = useSWR(
    "transactionService.getAllUncategorizedCount",
    transactionService.getAllUncategorizedCount
  );
  const { data: precategorizedCount } = useSWR(
    "transactionService.getAllPrecategorizedCount",
    transactionService.getAllPrecategorizedCount
  );
  const [key, setKey] = useState<TransactionQuickFilterType>("all");

  const onChangeTab = (key: TransactionQuickFilterType) => {
    setKey(key);
  };

  return (
    <GeneralLayout title={"Transactions bancaires"}>
      <div className="TransactionsPage">
        <Card>
          <Row>
            <Col span={24}>
              <Tabs
                activeKey={key}
                animated={false}
                // @ts-ignore
                onChange={onChangeTab}
                items={[
                  { label: "Tous les mouvements bancaires", key: "all" },
                  {
                    label: (
                      <Badge
                        offset={[12, -5]}
                        count={precategorizedCount}
                        overflowCount={10000}
                      >
                        <span>Précatégorisés</span>
                      </Badge>
                    ),
                    key: "precategorized",
                  },
                  {
                    label: (
                      <Badge
                        offset={[12, -5]}
                        count={uncategorizedCount}
                        overflowCount={10000}
                      >
                        <span>À catégoriser</span>
                      </Badge>
                    ),
                    key: "uncategorized",
                  },
                ]}
              />
              <TransactionsTableContainer
                categories={categories || []}
                quickFilter={key}
              />
            </Col>
          </Row>
        </Card>
      </div>
    </GeneralLayout>
  );
}

export default TransactionsPage;
